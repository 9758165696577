// tslint:disable:template-accessibility-elements-content
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { FormFieldErrors, SelectOption } from 'src/app/core';

const noop = () => {};

@Component({
  selector: 'app-segmented-datepicker',
  templateUrl: './segmented-datepicker.component.html',
  styleUrls: ['./segmented-datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SegmentedDatepickerComponent),
      multi: true,
    },
  ],
})
export class SegmentedDatepickerComponent implements OnInit, OnChanges {
  currentValue = {};

  @Input() name: string;
  @Input() label: string;
  @Input() required: boolean;
  @Input() formControlName: string;
  @Input() formSubmitted: boolean;
  @Input() errors: FormFieldErrors;
  @Input() minDate: NgbDate = new NgbDate(1900, 1, 1);
  @Input() maxDate: NgbDate = this.calendar.getToday();
  @Input() markDisabled: NgbDate;
  @Input() oneYearLimit: boolean;
  @Input() disabled: boolean;
  @Input() iconClass: string;
  @Input() tooltipContent: string;
  // tslint:disable-next-line:no-output-native
  @Output() select: EventEmitter<string> = new EventEmitter<string>();

  currentMonthValue: any;
  currentDayValue: any;
  currentYearValue: any;

  monthOptions: SelectOption[] = [];
  dayOptions: SelectOption[] = [];
  yearOptions: SelectOption[] = [];

  monthOutOfBounds = false;
  dayOutOfBounds = false;
  yearOutOfBounds = false;

  months: Month[] = [
    { name: 'January', value: 1, numberOfDays: 31 },
    { name: 'February', value: 2, numberOfDays: 28 },
    { name: 'March', value: 3, numberOfDays: 31 },
    { name: 'April', value: 4, numberOfDays: 30 },
    { name: 'May', value: 5, numberOfDays: 31 },
    { name: 'June', value: 6, numberOfDays: 30 },
    { name: 'July', value: 7, numberOfDays: 31 },
    { name: 'August', value: 8, numberOfDays: 31 },
    { name: 'September', value: 9, numberOfDays: 30 },
    { name: 'October', value: 10, numberOfDays: 31 },
    { name: 'November', value: 11, numberOfDays: 30 },
    { name: 'December', value: 12, numberOfDays: 31 },
  ];

  onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  @Input()
  get value(): any {
    return this.currentValue;
  }

  set value(val: any) {
    this.currentValue = val;
    this.change.emit(this.currentValue);
    this.valueChange.emit(this.currentValue);
    this.onChangeCallback(this.currentValue);
  }

  // tslint:disable-next-line:no-output-native
  @Output()
  change: EventEmitter<object> = new EventEmitter<object>();

  @Output()
  valueChange: EventEmitter<object> = new EventEmitter<object>();

  get monthValue(): SelectOption {
    return this.currentMonthValue;
  }

  set monthValue(option: SelectOption) {
    this.monthOutOfBounds = false;
    this.currentMonthValue = option;
    if (option) {
      this.setDayOptions();
    }
    this.writeValue();
  }

  get dayValue(): SelectOption {
    return this.currentDayValue;
  }

  set dayValue(option: SelectOption) {
    this.dayOutOfBounds = false;
    this.currentDayValue = option;
    this.writeValue();
  }

  get yearValue(): SelectOption {
    return this.currentYearValue;
  }

  set yearValue(option: SelectOption) {
    this.yearOutOfBounds = false;
    this.currentYearValue = option;
    if (option) {
      this.setMonthOptions();
    }
    this.writeValue();
  }

  writeValue(value?: string): void {
    if (value) {
      // Parse incoming value (yyyy-mm-dd or mm/dd/yyyy) into parts
      if (/\d{4}-\d{2}-\d{2}/.test(value)) {
        const parts = value.split('-');
        this.assignDateParts({ day: parts[2], month: parts[1], year: parts[0] });
      } else if (/\d{2}\/\d{2}\/\d{4}/.test(value)) {
        const parts = value.split('/');
        this.assignDateParts({ day: parts[1], month: parts[0], year: parts[2] });
      }
    } else {
      value =
        this.monthValue && this.dayValue && this.yearValue
          ? [
              this.yearValue.value,
              this.padDatePart(this.monthValue.value),
              this.padDatePart(this.dayValue.value),
            ].join('-')
          : undefined;
      this.currentValue = value;
      this.onChangeCallback(value);
    }
  }

  private assignDateParts(parts: { day: string; month: string; year: string }): void {
    const { day, month, year } = parts;
    this.yearValue = this.yearOptions.find((option) => option.value.toString() === year);
    this.monthValue = this.monthOptions.find((option) => this.padDatePart(option.value) === month);
    this.dayValue = this.dayOptions.find((option) => this.padDatePart(option.value) === day);
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  constructor(private calendar: NgbCalendar) {}

  ngOnInit(): void {
    this.setYearOptions();
    if (this.oneYearLimit) {
      this.maxDate = this.calendar.getToday();
      this.maxDate.year += 1;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.ngbDateChanged(changes.minDate) || this.ngbDateChanged(changes.maxDate)) {
      this.setYearOptions();
    }
  }

  private ngbDateChanged(change: SimpleChange): boolean {
    if (!change) {
      return false;
    }
    const { currentValue, previousValue } = change;
    return (
      (currentValue && !previousValue) ||
      previousValue.day !== currentValue.day ||
      previousValue.month !== currentValue.month ||
      previousValue.year !== currentValue.year
    );
  }

  private setYearOptions(): void {
    const yearOptions: SelectOption[] = [];
    for (let y = this.maxDate.year; y >= this.minDate.year; y--) {
      yearOptions.push({ label: y.toString(), value: y });
    }
    this.yearOptions = yearOptions;

    if (this.yearValue && !yearOptions.some((option) => option.value === this.yearValue.value)) {
      this.yearValue = undefined;
      this.yearOutOfBounds = true;
    }
    this.setMonthOptions();
  }

  private setMonthOptions(): void {
    let monthOptions: SelectOption[] = this.months.map((month) => ({
      label: month.name,
      value: month.value,
    }));
    if (this.yearValue?.value === this.minDate.year) {
      monthOptions = monthOptions.filter((option) => option.value >= this.minDate.month);
    } else if (this.yearValue?.value === this.maxDate.year) {
      monthOptions = monthOptions.filter((option) => option.value <= this.maxDate.month);
    }
    this.monthOptions = monthOptions;

    if (this.monthValue && !monthOptions.some((option) => option.value === this.monthValue.value)) {
      this.monthValue = undefined;
      this.monthOutOfBounds = true;
    }
    if (this.monthValue) {
      this.setDayOptions();
    }
  }

  private setDayOptions(): void {
    let dayOptions: SelectOption[] = [];
    const monthValue = this.monthValue.value;
    let { numberOfDays } = this.months.find((month) => month.value === monthValue);
    if (this.isLeapYear && monthValue === 2) {
      numberOfDays = 29;
    }

    for (let d = 1; d <= numberOfDays; d++) {
      dayOptions.push({ label: d.toString(), value: d });
    }
    if (
      this.yearValue?.value === this.minDate.year &&
      this.monthValue?.value === this.minDate.month
    ) {
      dayOptions = dayOptions.filter((option) => option.value >= this.minDate.day);
    } else if (
      this.yearValue?.value === this.maxDate.year &&
      this.monthValue?.value === this.maxDate.month
    ) {
      dayOptions = dayOptions.filter((option) => option.value <= this.maxDate.day);
    }
    this.dayOptions = dayOptions;

    if (this.dayValue && !dayOptions.some((option) => option.value === this.dayValue.value)) {
      this.dayValue = undefined;
      this.dayOutOfBounds = true;
    }
  }

  private padDatePart(part: number | string): string {
    return part.toString().padStart(2, '0');
  }

  get isLeapYear(): boolean {
    const yearValue = this.yearValue?.value;
    const currentYear = typeof yearValue === 'number' ? yearValue : parseInt(yearValue, 10);
    return currentYear % 4 === 0;
  }

  get outOfBounds(): boolean {
    return this.monthOutOfBounds || this.dayOutOfBounds || this.yearOutOfBounds;
  }
}

interface Month {
  name: string;
  value: number;
  numberOfDays: number;
}
