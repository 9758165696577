<div class="cart" [ngClass]="{ 'd-none d-lg-block': !cartOpened, 'ghost-mode-active': ghostModeActive }" *ngIf="!cartHidden">
  <div class="my-cart-toggle navbar-toggler justify-content-between">
    <a (click)="closeCart()" class="d-lg-none">
      <img
        class="pl-4 navbar-brand"
        height="30px"
        src="assets/images/close.svg"
        alt="Close"
      />
    </a>

    <div class="d-flex">
      <img class="pl-3" height="20px" src="assets/images/shopping-cart.svg" alt="Shopping cart" />
      <a class="pr-3 pt-1 nav-text">MY CART</a>
    </div>

    <button *ngIf="!startOverHidden" type="button" class="start-over-btn btn btn-link text-white" (click)="startOver()">Start Over</button>
  </div>
  <div class="cart-container">
    <div class="container plan-container d-flex flex-column">
      <!-- Membership Plan Type -->
      <div class="d-flex justify-content-between align-items-center">
        <span class="family-subtotal-text pr-2">{{ planName }}</span>
        <span class="family-subtotal-amount">{{ planPrice | currency }}</span>
      </div>

      <div *ngIf="isFamilyPlan" class="d-flex justify-content-between align-items-center mt-2">
        <span class="family-subtotal-text pr-2">{{ addedJuniorPlanName }}</span>
        <span class="family-subtotal-amount">{{ addedJuniorPlanPrice | currency }}</span>
      </div>

      <div *ngFor="let addOn of addOnItems">
        <div class="d-flex justify-content-between align-items-center mt-2">
          <span class="pr-2">
            <span class="family-subtotal-text">{{ addOn.productTitle }}</span>
            <span class="d-block">{{ variantTitle(addOn) }}</span>
          </span>
          <span class="family-subtotal-amount">{{ addOn.price | currency }}</span>
        </div>
      </div>

      <!-- Spot Insurance -->
      <!-- <div *ngIf="spotQuantity > 0" class="d-flex justify-content-between align-items-center mt-2">
        <div class="pr-1">
          <span class="family-subtotal-text">Spot Insurance</span>
          <br />
          <span class="small">*Charged by Spot</span>
        </div>
        <span class="family-subtotal-amount">{{ spotInsurancePrice * spotQuantity | currency }}</span>
      </div> -->

    </div>

    <div *ngIf="isFamilyPlan" class="family-members">
      <div
        class="family-member"
        [ngClass]="{
          'family-member--selected': member.is_selected,
          'family-member--not-selected': !member.is_selected
        }"
        *ngFor="let member of family?.familyMembers; index as i;"
      >
        <p class="p-0 m-0 member-controls" *ngIf="member.is_family_representative">
          <span class="member-name" [ngClass]="{ 'member-name--selected': member.is_selected }"
            >{{ member.first_name }} {{ member.last_name }}</span
          >
          <span class="family-rep pl-2">Family Rep.</span>
        </p>

        <p class="p-0 m-0 member-controls" *ngIf="!member.is_family_representative">
          <span class="member-name" [ngClass]="{ 'member-name--selected': member.is_selected }"
            >{{ member.first_name }} {{ member.last_name }}</span
          >
          <span class="family-rep pl-2">{{ member.person_type }}</span>
        </p>
      </div>
    </div>
    <div
      class="family-member family-member--selected"
      *ngIf="isIndividualPlan"
    >
      <p class="p-0 m-0">
        <span class="member-name member-name--selected"
          >{{ registration.first_name }}
          {{ registration.last_name }}</span
        >
      </p>
      <p *ngIf="personType" class="member-type p-0 m-0">
        {{ personType }}
      </p>
    </div>
  </div>
</div>
