<app-toast [toasts]="toasts"></app-toast>

<div>
  <span class="d-flex justify-content-end pt-3 pr-3"
    ><img class="close-button" src="assets/images/x-circle.svg" alt="X" (click)="activeModal.dismiss()"
  /></span>
  <div class="container modal-container">
    <div class="row password-container">
      <div class="col-12 col-md-9">
        <h2 class="forgot-password-title text-center">Password Reset Sent</h2>

        <p class="forgot-password-description text-center mb-4">
          We sent an email to <span style="font-weight: bold;">{{profile.profile_email}}</span> to reset your password.
          If you do not receive it shortly, you can resend the email using the link below. If you need further assistance, contact
          Member Services.
        </p>
        <div class="col-lg-12">
          <p class="text-center text-danger mb-2" *ngIf="resentMailer">Resent Password Reset Email</p>
          <button class="btn btn-primary btn-block px-3 col-4 mx-auto mt-3 py-3" (click)="goToLogin()">
            LOG IN
          </button>
        </div>
      </div>
    </div>
    <div class="text-center mt-5">
      <button class=" btn-block col-6 mx-auto py-3 resend-link" (click)="resendPasswordReset()">
        Resend Password Reset
      </button>
      <span>Contact <a href="https://usacyclinghelp.zendesk.com/hc/en-us/requests/new" style="text-decoration: underline;">Member
          Services</a></span>
    </div>
  </div>
</div>
