import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FamilyMember, MembershipOrdersService, ProfileService } from 'src/app/core';
import {
  AddOnsFacade,
  FamilyMembershipFacade,
  Plans,
  RegistrationFacade,
} from 'src/app/core/facades';
import { Product, ProductVariant } from 'src/app/core/models';
import { AddOnItem, PersonTypes, RegistrationLocal } from 'src/app/core/models/registration.model';
import { AuthService, ShopifyService } from 'src/app/core/services';
import { environment } from 'src/environments/environment';

import { ConfirmationModalComponent } from '../../confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  @Input() cartOpened: boolean;
  @Input() cartHidden: boolean;
  @Input() startOverHidden: boolean;

  @Output() cartClosedEmmiter: EventEmitter<boolean> = new EventEmitter<boolean>();

  planTypes = Plans;
  personTypes = PersonTypes;
  variantsBySku: { [key: string]: ProductVariant };

  planSkus = this.shopifyService.planSkus;

  get registrationState(): any {
    return this.registrationFacade.currentState;
  }

  get registration(): RegistrationLocal {
    return this.registrationState?.registration;
  }

  get personType(): PersonTypes {
    return this.isIndividualPlan
      ? this.registration?.person_type
      : this.familyRepresentative?.person_type;
  }

  get gender(): string {
    return this.registration?.gender;
  }

  get planType(): Plans {
    return this.registrationState?.plan;
  }

  get planName(): string {
    let name: string;

    if (this.isInternational) {
      name = 'International';
    } else if (this.isPro) {
      name = 'Professional';
    } else {
      name = 'Race';
    }

    if (this.isPlus) {
      name += ' Plus';
    }

    if (this.isCollegiate) {
      name += ' (Collegiate)';
    } else if (this.isJunior) {
      name += ' (Junior)';
    }

    return name;
  }

  get addedJuniorPlanName(): string {
    const name = this.familyFacade.juniorHasSpot ? 'Race Plus' : 'Race';
    return name + ' (Junior)';
  }

  get isIndividualPlan(): boolean {
    return this.registrationState?.isIndividualPlan;
  }

  get isAdult(): boolean {
    return this.personType === PersonTypes.ADULT;
  }

  get isJunior(): boolean {
    return this.personType === PersonTypes.JUNIOR;
  }

  get isAdultPlus(): boolean {
    return this.isAdult && this.familyFacade.adultHasSpot;
  }

  get isJuniorPlus(): boolean {
    return this.isJunior && this.familyFacade.juniorHasSpot;
  }

  get isPlus(): boolean {
    return this.isAdultPlus || this.isJuniorPlus;
  }

  get isCollegiate(): boolean {
    return this.registrationState.isCollegiate;
  }

  get isFamilyPlan(): boolean {
    return this.registrationState?.isFamilyPlan;
  }

  get selectedMember(): FamilyMember {
    return this.familyFacade.selectedMember;
  }

  get familyRepresentative(): FamilyMember {
    return this.familyFacade.representative;
  }

  get isInternational(): boolean {
    return this.registrationState.isInternational;
  }

  get isJuniorInternational(): boolean {
    return this.isJunior && this.isInternational;
  }

  get isCollegiateInternational(): boolean {
    return this.isCollegiate && this.isInternational;
  }

  get isCollegiateAdultInternational(): boolean {
    return this.isCollegiate && this.isAdult && this.isInternational;
  }

  get isPro(): boolean {
    return this.registrationState.isPro;
  }

  // get spotQuantity(): number {
  //   return this.familyFacade.spotQuantity;
  // }
  // @returns string but needs to be a numeric value because of the 'currency' pipe.
  getPriceAmount(sku: string): string {
    if (this.variantsBySku && sku in this.variantsBySku && this.variantsBySku[sku]?.priceV2) {
      return this.variantsBySku[sku].priceV2.amount;
    }

    // Shouldn't get to this 👇. If it does, there's an error.
    // Make it obvious with a high price tag.
    return '9999';
  };


  get planPrice(): string {

    const priceSubset = this.isAdult ? this.planSkus.adult : this.planSkus.junior;

    if (this.isInternational) {
      return this.getPriceAmount(this.isPlus ? priceSubset.internationalPlus : priceSubset.international);
    }

    if (this.isPro) {
      return this.getPriceAmount(this.isPlus ? this.planSkus.professionalPlus : this.planSkus.professional);
    }

    if (this.isCollegiate) {
      return this.getPriceAmount(this.isPlus ? this.planSkus.racePlusCollegiate : this.planSkus.raceCollegiate);
    }

    return this.getPriceAmount(this.isPlus ? priceSubset.racePlus : priceSubset.race);
  }


  get addedJuniorPlanPrice(): string {
    const priceSubset = this.planSkus.junior;
    return this.familyFacade.juniorHasSpot
      ? this.variantsBySku[priceSubset.racePlus].priceV2.amount
      : this.variantsBySku[priceSubset.race].priceV2.amount;
  }

  get isRenewingPremium(): boolean {
    return this.registrationState?.isRenewingPremium;
  }

  get isRace(): boolean {
    return this.registrationState?.isRace;
  }

  get showPremium(): boolean {
    return (
      (this.isRenewingPremium || this.isRace || this.isInternational || this.isPro) &&
      !this.isJunior &&
      // TODO: @Brennan, @Chad - confirm that collegiate should not see premium add-on
      !this.isCollegiate &&
      !this.isCollegiateInternational
    );
  }

  get family(): any {
    return this.familyFacade.currentState;
  }

  get addOnItems(): AddOnItem[] {
    return [...this.addOnsFacade.adult.items, ...this.addOnsFacade.junior.items];
  }

  constructor(
    private authService: AuthService,
    private modal: NgbModal,
    private familyFacade: FamilyMembershipFacade,
    private registrationFacade: RegistrationFacade,
    private shopifyService: ShopifyService,
    private membershipOrdersService: MembershipOrdersService,
    private profileService: ProfileService,
    private addOnsFacade: AddOnsFacade
  ) { }

  closeCart(): void {
    this.cartOpened = false;
    this.cartClosedEmmiter.emit(false);
  }

  ngOnInit(): void {
    this.shopifyService.getProducts().then((products) => this.setVariantsBySku(products));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setVariantsBySku(products: Product[]): void {
    this.variantsBySku = products
      .flatMap((product) => product.variants)
      .reduce((acc, productVariant) => {
        acc[productVariant.sku] = productVariant;
        return acc;
      }, {});
  }

  startOver(): void {
    const modal = this.modal.open(ConfirmationModalComponent);

    modal.componentInstance.message =
      'Performing this action will reset all of your selected options. Do you want to proceed?';

    modal.result
      .then(() => {
        if (this.registrationFacade.getOrderId) {
          this.membershipOrdersService
            .remove(this.registrationFacade.getOrderId)
            .pipe(
              finalize(() => {
                this.clear();
              })
            )
            .subscribe();
        } else {
          this.clear();
        }
      })
      .catch(() => { });
  }

  clear(): void {
    // Logout user and clear state
    this.authService.doLogout().subscribe((resp) => {
      if (resp) {
        window.location.assign(environment.publicUrl);
      }
    });
  }

  variantTitle(addOn: AddOnItem): string {
    return addOn.variantTitle === 'Default Title' ? '' : addOn.variantTitle;
  }

  get ghostModeActive(): boolean {
    return this.profileService.hasGhostModeCookie;
  }
}
