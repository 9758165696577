import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Interest } from '../models';

@Injectable({
  providedIn: 'root',
})
export class InterestsService {
  getInterests(): Observable<Interest[]> {
    return of([
      {
        id: 1,
        name: 'Road',
        imageUrl: 'assets/checkboxes/road.jpg',
        disciplines: [
          {
            id: 19,
            name: 'Road Racing',
            collegiateCategories: [
              {
                id: 39,
                name: 'Category D',
                requiresPremium: false,
                requiresApproval: false,
              },
              {
                id: 42,
                name: 'Category C',
                requiresPremium: false,
                requiresApproval: true,
              },
              {
                id: 46,
                name: 'Category B',
                requiresPremium: false,
                requiresApproval: true,
              },
              {
                id: 44,
                name: 'Category A',
                requiresPremium: false,
                requiresApproval: true,
              },
            ],
            nonCollegiateCategories: [
              {
                id: 4,
                name: 'Novice',
                requiresPremium: false,
                requiresApproval: false,
              },
              {
                id: 3,
                name: 'Category 4',
                requiresPremium: true,
                requiresApproval: false,
              },
              {
                id: 11,
                name: 'Category 3',
                requiresPremium: true,
                requiresApproval: true,
              },
              {
                id: 7,
                name: 'Category 2',
                requiresPremium: true,
                requiresApproval: true,
              },
              {
                id: 1,
                name: 'Category 1',
                requiresPremium: true,
                requiresApproval: true,
              },
            ],
          },
        ],
      },
      {
        id: 6,
        name: 'Gravel',
        imageUrl: 'assets/checkboxes/gravel.jpg',
        disciplines: [
          {
            id: 25,
            name: 'Gravel',
            collegiateCategories: [],
            nonCollegiateCategories: [],
          },
        ],
      },
      {
        id: 2,
        name: 'Mountain',
        imageUrl: 'assets/checkboxes/mountain.jpg',
        disciplines: [
          {
            id: 31,
            name: 'Mountain Slalom',
            collegiateCategories: [
              {
                id: 154,
                name: 'Category D',
                requiresPremium: false,
                requiresApproval: false,
              },
              {
                id: 155,
                name: 'Category C',
                requiresPremium: false,
                requiresApproval: true,
              },
              {
                id: 156,
                name: 'Category B',
                requiresPremium: false,
                requiresApproval: true,
              },
              {
                id: 157,
                name: 'Category A',
                requiresPremium: false,
                requiresApproval: true,
              },
            ],
            nonCollegiateCategories: [
              {
                id: 65,
                name: 'Novice',
                requiresPremium: false,
                requiresApproval: false,
              },
              {
                id: 64,
                name: 'Category 2',
                requiresPremium: true,
                requiresApproval: false,
              },
              {
                id: 63,
                name: 'Category 1',
                requiresPremium: true,
                requiresApproval: true,
              },
            ],
          },
          {
            id: 32,
            name: 'Mountain Gravity',
            collegiateCategories: [
              {
                id: 150,
                name: 'Category D',
                requiresPremium: false,
                requiresApproval: false,
              },
              {
                id: 151,
                name: 'Category C',
                requiresPremium: false,
                requiresApproval: true,
              },
              {
                id: 152,
                name: 'Category B',
                requiresPremium: false,
                requiresApproval: true,
              },
              {
                id: 153,
                name: 'Category A',
                requiresPremium: false,
                requiresApproval: true,
              },
            ],
            nonCollegiateCategories: [
              {
                id: 62,
                name: 'Novice',
                requiresPremium: false,
                requiresApproval: false,
              },
              {
                id: 61,
                name: 'Category 2',
                requiresPremium: true,
                requiresApproval: false,
              },
              {
                id: 60,
                name: 'Category 1',
                requiresPremium: true,
                requiresApproval: true,
              },
            ],
          },
          {
            id: 33,
            name: 'Mountain Endurance',
            collegiateCategories: [
              {
                id: 74,
                name: 'Category D',
                requiresPremium: false,
                requiresApproval: false,
              },
              {
                id: 73,
                name: 'Category C',
                requiresPremium: false,
                requiresApproval: true,
              },
              {
                id: 72,
                name: 'Category B',
                requiresPremium: false,
                requiresApproval: true,
              },
              {
                id: 71,
                name: 'Category A',
                requiresPremium: false,
                requiresApproval: true,
              },
            ],
            nonCollegiateCategories: [
              {
                id: 30,
                name: 'Novice',
                requiresPremium: false,
                requiresApproval: false,
              },
              {
                id: 14,
                name: 'Category 2',
                requiresPremium: true,
                requiresApproval: false,
              },
              {
                id: 13,
                name: 'Category 1',
                requiresPremium: true,
                requiresApproval: true,
              },
            ],
          },
        ],
      },
      {
        id: 3,
        name: 'Track',
        imageUrl: 'assets/checkboxes/track.jpg',
        disciplines: [
          {
            id: 20,
            name: 'Track Racing',
            collegiateCategories: [
              {
                id: 66,
                name: 'Category D',
                requiresPremium: false,
                requiresApproval: false,
              },
              {
                id: 36,
                name: 'Category C',
                requiresPremium: false,
                requiresApproval: true,
              },
              {
                id: 37,
                name: 'Category B',
                requiresPremium: false,
                requiresApproval: true,
              },
              {
                id: 38,
                name: 'Category A',
                requiresPremium: false,
                requiresApproval: true,
              },
            ],
            nonCollegiateCategories: [
              {
                id: 58,
                name: 'Novice',
                requiresPremium: false,
                requiresApproval: false,
              },
              {
                id: 57,
                name: 'Category 4',
                requiresPremium: true,
                requiresApproval: false,
              },
              {
                id: 43,
                name: 'Category 3',
                requiresPremium: true,
                requiresApproval: true,
              },
              {
                id: 29,
                name: 'Category 2',
                requiresPremium: true,
                requiresApproval: true,
              },
              {
                id: 50,
                name: 'Category 1',
                requiresPremium: true,
                requiresApproval: true,
              },
            ],
          },
        ],
      },
      {
        id: 5,
        name: 'BMX',
        imageUrl: 'assets/checkboxes/bmx.jpg',
        disciplines: [
          {
            id: 22,
            name: 'BMX Racing',
            collegiateCategories: [],
            nonCollegiateCategories: [],
          },
          {
            id: 30,
            name: 'BMX Freestyle',
            collegiateCategories: [],
            nonCollegiateCategories: [],
          },
        ],
      },
      {
        id: 4,
        name: 'Cyclocross',
        imageUrl: 'assets/checkboxes/cyclocross.jpg',
        disciplines: [
          {
            id: 23,
            name: 'Cyclocross Racing',
            collegiateCategories: [
              {
                id: 70,
                name: 'Category D',
                requiresPremium: false,
                requiresApproval: false,
              },
              {
                id: 69,
                name: 'Category C',
                requiresPremium: false,
                requiresApproval: true,
              },
              {
                id: 68,
                name: 'Category B',
                requiresPremium: false,
                requiresApproval: true,
              },
              {
                id: 67,
                name: 'Category A',
                requiresPremium: false,
                requiresApproval: true,
              },
            ],
            nonCollegiateCategories: [
              {
                id: 23,
                name: 'Novice',
                requiresPremium: false,
                requiresApproval: false,
              },
              {
                id: 28,
                name: 'Category 4',
                requiresPremium: true,
                requiresApproval: false,
              },
              {
                id: 12,
                name: 'Category 3',
                requiresPremium: true,
                requiresApproval: true,
              },
              {
                id: 48,
                name: 'Category 2',
                requiresPremium: true,
                requiresApproval: true,
              },
              {
                id: 31,
                name: 'Category 1',
                requiresPremium: true,
                requiresApproval: true,
              },
            ],
          },
        ],
      },
    ]);
  }
}
