import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FamilyMembershipFacade, RegistrationFacade } from 'src/app/core/facades';

import { AgreementModalComponent } from '../agreement-modal/agreement-modal.component';
import { NoWaiverModalComponent } from '../no-waiver-modal/no-waiver-modal.component';

@Component({
  selector: 'app-pre-prompt',
  templateUrl: './pre-prompt.component.html',
  styleUrls: ['./pre-prompt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrePromptComponent implements OnInit {
  memberInfo: any;

  constructor(
    private modal: NgbModal,
    private familyFacade: FamilyMembershipFacade,
    private registrationFacade: RegistrationFacade,
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.memberInfo = this.registrationFacade.isFamilyPlan()
      ? this.familyFacade.selectedMember
      : this.registrationFacade.registration;
  }

  openAgreementModal(): void {
    const modal = this.modal.open(AgreementModalComponent, {
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
    });
    modal.result.then(() => this.activeModal.close('signed')).catch(() => {});
  }

  openNoWaiverModal(): void {
    const modal = this.modal.open(NoWaiverModalComponent, {
      centered: true,
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
    });
    modal.result.then(() => this.activeModal.close('dismissed')).catch(() => {});
  }
}
