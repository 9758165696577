<div class="card product-collection-card" [ngClass]="{'no-border': removeBorder, 'member-offers': memberOfferStyle}">
  <div class="card-body text-center">
    <!-- Image -->
    <div class="mb-2">
      <img [src]="imageSrc(primaryProductImage)" [alt]="imageAlt(primaryProductImage)" />
    </div>

    <!-- Title, Description, & Variant Select -->
    <div class="">
      <h5>{{ product.title }} <i *ngIf="product.description" placement="bottom" tooltipClass="custom-tooltip" tabindex="0" class="icon icon-information" (click)="openDescription()"></i></h5>
      <p *ngIf="showPrice" class="price mb-1">
        <span *ngIf="compareAtPrice">
          <span class="compared-price">{{ compareAtPrice | currency }}</span>&nbsp;
        </span>
        <span>{{ price | currency }}</span>
      </p>
      <p class="mb-1" *ngIf="showDescription">{{ product.description }}</p>

      <form *ngIf="hasVariants" [formGroup]="variantForm" class="my-2">
        <ng-select
          formControlName="variant"
          class="select text-left"
          id="variant"
          [items]="variantOptions"
          [clearable]="false"
          [searchable]="false"
          placeholder="Select..."
        ></ng-select>
      </form>
    </div>

    <!-- Add to Cart Button -->
    <div [ngClass]="{'mt-1': hasVariants, 'mt-2': !hasVariants}">
      <button class="btn {{ btnClass }} btn-block"
        (click)="toggleAddToCart()"
        [disabled]="!selectedVariant"
      >{{ btnText }}</button>

      <button *ngIf="showSizeChart && hasSizeChartImages"
        class="btn btn-link p-0"
        (click)="openSizeChartModal()"
      >
        <small>Size Chart</small>
      </button>
    </div>
  </div>
</div>
