<div class="col-lg-12 p-4">
  <div class="col-lg-12 mb-3">
    <div class="description-modal-header">
      <h4 class="product-title">{{product.title}}</h4>
      <!-- close button -->
      <button type="button" class="close-modal" aria-label="Close" (click)="closeModal()">
        <img aria-hidden="true" src="assets/images/x-circle.svg" alt="X" (click)="closeModal()" />
      </button>
    </div>
    <span class="product-price">{{ price | currency }}</span>
  </div>
  <div class="row col-lg-12 description-modal-body">
    <div class="description-container col-lg-7">
      <p *ngIf="product.description" class="product-description pb-6">{{product.description}}</p>
      <!-- <div class="add-to-cart" [ngClass]="{'mt-1': hasVariants, 'mt-2': !hasVariants}">
      <form *ngIf="hasVariants" [formGroup]="variantForm" class="my-2">
        <ng-select formControlName="variant" class="select text-left" id="variant" [items]="variantOptions"
        [clearable]="false" [searchable]="false" placeholder="Select..."></ng-select>
      </form>
        <button 
        class="btn {{ btnClass }} btn-block"
        (click)="toggleAddToCart()"
        [disabled]="!selectedVariant"
        >
          {{ btnText}}
        </button>
      </div> -->
    </div>
    
    <div class="mb-2 col-lg-5">
      <img class="product-img" [src]="imageSrc(primaryProductImage)" [alt]="imageAlt(primaryProductImage)" />
    </div>
  </div>

</div>