import { Component, Input, OnInit } from '@angular/core';
import {
  Address,
  LicenseCategory,
  LicenseGroup,
  LicenseType,
  Membership,
  Profile,
  Toast,
} from 'src/app/core/models';
import { AuthService, LicenseService, ProfileService } from 'src/app/core/services';

@Component({
  selector: 'app-technical-license-menu',
  templateUrl: './technical-license-menu.component.html',
  styleUrls: ['./technical-license-menu.component.scss'],
})
export class TechnicalLicenseMenuComponent implements OnInit {
  @Input() user: Profile;

  loading = true;
  membershipsLoading = true;

  profileAddress: Address;
  licenseGroups: LicenseGroup[] = [];
  licenseTypes: LicenseType[] = [];
  licenseCategories: LicenseCategory[] = [];
  allMemberships: Membership[];

  selectedLicenses: LicenseCategory[] = [];

  toasts: Toast[] = [];
  toastQueue: any = {};

  constructor(
    private authService: AuthService,
    private licenseService: LicenseService,
    private profileService: ProfileService
  ) {}

  ngOnInit(): void {
    this.loadLicenses();
    this.profileService
      .getMemberships(this.user.profile_id)
      .subscribe((memberships: Membership[]) => {
        if (memberships) {
          this.allMemberships = memberships;
        }
        this.membershipsLoading = false;
      });
  }

  private loadLicenses(): void {
    this.authService.currentUser$.subscribe((user: Profile) => {
      this.user = user;
      if (user.profile_id) {
        this.profileService.getLicenses(user.profile_id).subscribe(() => {
          this.licenseGroups = this.profileService.licenseGroups;
          this.loadLicenseTypes();
        });
      }
    });
  }

  private loadLicenseTypes(): void {
    this.licenseService.getTechnicalLicenseTypes().subscribe((licenseTypes: LicenseType[]) => {
      this.licenseTypes = licenseTypes;
      licenseTypes.forEach((type: LicenseType) => {
        type.license_categories.forEach((category) => this.licenseCategories.push(category));
      });
      this.loading = false;
    });
  }

  addRemoveLicenses(values: any, key: string, license: LicenseCategory): void {
    if (!!values[key] && !this.selectedLicenses.find((lic) => lic.id === license.id)) {
      this.selectedLicenses.push(license);
      // TODO: Improve toast logic, if we want to implement it
      // this.toasts.push(new Toast('success', 'Saved', 'License added to cart.', true, true, 5000));
    } else if (!values[key]) {
      const previouslySelected = this.selectedLicenses.find((lic) => lic.id === license.id);
      if (previouslySelected) {
        this.selectedLicenses.splice(
          this.selectedLicenses.findIndex((lic) => lic.id === license.id),
          1
        );
      }
    }
  }

  updateSelectedLicenses(values: any): void {
    Object.keys(values).forEach((key) => {
      const license = this.licenseCategories.find(
        (cat) => cat.shopify_variant && cat.shopify_variant.sku === key
      );
      if (license) {
        this.addRemoveLicenses(values, key, license);
      } else {
        this.licenseCategories.forEach((cat) => {
          // TODO: @Charlie, @Brennan - also check for category eligibility ?
          if (cat.base_shopify_variant && cat.base_shopify_variant.sku === key) {
            this.addRemoveLicenses(values, key, cat);
          }
        });
      }
    });
  }

  get licenseCount(): number {
    return this.selectedLicenses.length;
  }

  get licensesSelected(): boolean {
    return !!this.licenseCount;
  }
}
