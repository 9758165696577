import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import {
  LicenseCategory,
  LicenseCategoryStatus,
  LicenseGroup,
  LicenseStatus,
  LicenseType,
  LicenseTypeId,
} from '../models';

import { BaseService } from './base-service';

@Injectable({
  providedIn: 'root',
})
export class LicenseService extends BaseService {
  officialsSkus = [
    'T-OFFICIAL-IC-1',
    'T-OFFICIAL-EC-1',
    'T-OFFICIAL-NC-1',
    'T-OFFICIAL-A-1',
    'T-OFFICIAL-B-1',
    'T-OFFICIAL-C-1',
    'T-OFFICIAL-NO-1',
    'T-MANAGER-TM',
  ];

  officialsAndMotoRefSkus = [
    ...this.officialsSkus,
    'T-OFFICIAL-M-C',
    'T-OFFICIAL-M-B',
    'T-OFFICIAL-M-A',
    'T-OFFICIAL-M-P',
    'T-OFFICIAL-M-NO',
  ];

  techLicenseTypeIds: LicenseTypeId[] = [
    LicenseTypeId.COACHES,
    LicenseTypeId.MECHANICS,
    LicenseTypeId.RACE_DIRECTOR,
    LicenseTypeId.OFFICIALS,
    LicenseTypeId.DRIVERS,
    LicenseTypeId.BMX,
    LicenseTypeId.INTERNATIONAL_COACH,
    LicenseTypeId.UCI_SUPPORT,
    LicenseTypeId.TEAM_MANAGER,
  ];

  // @Charlie used for if a user upgrades to a domestic race license without having an active domestic license
  defaultRaceLicenses = [
    {
      category_id: 4,
      category_name: 'Road Novice',
      discipline_name: 'Road Racing',
    },
    {
      category_id: 23,
      category_name: 'Cyclo-Cross Novice',
      discipline_name: 'Cyclocross Racing',
    },
    {
      category_id: 30,
      category_name: 'Mountain XC Novice',
      discipline_name: 'Mountain Endurance',
    },
    {
      category_id: 58,
      category_name: 'Track Novice',
      discipline_name: 'Track Racing',
    },
    {
      category_id: 62,
      category_name: 'Mountain Gravity Novice',
      discipline_name: 'Mountain Gravity',
    },
    {
      category_id: 65,
      category_name: 'Mountain MX Novice',
      discipline_name: 'Mountain Cross',
    },
  ];
  // @Charlie used for if a user upgrades to a collegiate race license without having an active collegiate license
  defaultCollegiateLicenses = [
    {
      category_id: 39,
      category_name: 'Road Collegiate Category D',
      discipline_name: 'Road Collegiate',
    },
    {
      category_id: 66,
      category_name: 'Track Collegiate Category D',
      discipline_name: 'Track Collegiate',
    },
    {
      category_id: 70,
      category_name: 'Cross Collegiate Category D',
      discipline_name: 'CycloCross Collegiate',
    },
    {
      category_id: 74,
      category_name: 'Mountain XC Collegiate Category D',
      discipline_name: 'Mountain Endurance Collegiate',
    },
    {
      category_id: 150,
      category_name: 'Mountain Gravity/DH Collegiate Category D',
      discipline_name: 'Mountain Gravity Collegiate',
    },
    {
      category_id: 154,
      category_name: 'Mountain MX/DS Collegiate Category D',
      discipline_name: 'Mountain Cross Collegiate',
    },
  ];

  // === API Calls ===
  getTechnicalLicenseTypes(): Observable<LicenseType[]> {
    return this.http.get<LicenseType[]>(this.url('licenses/technical'), this.options).pipe(
      tap(() => this.log('Loaded technical license types')),
      catchError(this.handleError<LicenseType[]>('getTechnicalLicenseTypes', []))
    );
  }

  createAdminCreatedMembership(payload: any): Observable<any> {
    return this.http
      .post<any>(this.url('admin/admin-membership-creation'), payload, this.options)
      .pipe(
        tap(() => {
          this.log('Created Admin Created Membership');
        }),
        catchError(
          this.handleError<any>('createAdminCreatedMembership', null, { payload })
        )
      );
  }

  // === Helpers ===
  // = License Categories =
  licenseCategoryIconClass(status: string): string {
    switch (status) {
      case LicenseCategoryStatus.VALID:
        return 'icon-check-circle';
      case LicenseCategoryStatus.INVALID:
      case LicenseCategoryStatus.EXPIRED:
        return 'icon-warning';
      case LicenseCategoryStatus.ELIGIBLE:
        return 'icon-circle-plus';
      case LicenseCategoryStatus.INELIGBLE:
        return 'icon-circle-minus';
      default:
        return '';
    }
  }

  licenseCategoryStatusClass(status: string): string {
    switch (status) {
      case LicenseCategoryStatus.VALID:
        return 'text-success';
      case LicenseCategoryStatus.INVALID:
      case LicenseCategoryStatus.EXPIRED:
        return 'text-warning';
      case LicenseCategoryStatus.ELIGIBLE:
      case LicenseCategoryStatus.INELIGBLE:
      default:
        return '';
    }
  }

  // = License Category Groups =
  licenseCategoryGroupHasStatus(categories: LicenseCategory[], status: string): boolean {
    return categories.some((category) => category.status === status);
  }

  licenseCategoryGroupStatus(categories: LicenseCategory[]): string {
    let status = LicenseCategoryStatus.INELIGBLE;
    if (this.licenseCategoryGroupHasStatus(categories, LicenseCategoryStatus.VALID)) {
      status = LicenseCategoryStatus.VALID;
    } else if (this.licenseCategoryGroupHasStatus(categories, LicenseCategoryStatus.INVALID)) {
      status = LicenseCategoryStatus.INVALID;
    } else if (this.licenseCategoryGroupHasStatus(categories, LicenseCategoryStatus.EXPIRED)) {
      status = LicenseCategoryStatus.EXPIRED;
    } else if (this.licenseCategoryGroupHasStatus(categories, LicenseCategoryStatus.ELIGIBLE)) {
      status = LicenseCategoryStatus.ELIGIBLE;
    } else if (this.licenseCategoryGroupHasStatus(categories, LicenseCategoryStatus.INVALID)) {
      status = LicenseCategoryStatus.INVALID;
    }
    return status;
  }

  // = License Groups =
  licenseGroupIconClass(licenseGroup: LicenseGroup): string {
    const today = new Date();
    if (
      (licenseGroup.expiration && new Date(licenseGroup.expiration) < today) ||
      [LicenseStatus.PENDING, LicenseStatus.SUSPENDED].includes(licenseGroup.status)
    ) {
      return 'icon-warning';
    } else {
      return [LicenseStatus.RESOLVED, null].includes(licenseGroup.status)
        ? 'icon-check-circle'
        : '';
    }
  }

  licenseGroupStatusClass(licenseGroup: LicenseGroup): string {
    if (
      (licenseGroup.expiration && new Date(licenseGroup.expiration) < new Date()) ||
      licenseGroup.status === LicenseStatus.SUSPENDED
    ) {
      return 'text-danger';
    } else {
      return licenseGroup.status === LicenseStatus.PENDING
        ? `font-italic text-warning`
        : 'text-success';
    }
  }

  licenseGroupStatusText(licenseGroup: LicenseGroup): string {
    const today = new Date();
    if (licenseGroup.expiration && new Date(licenseGroup.expiration) < today) {
      return 'Expired';
    } else if (licenseGroup.status === LicenseStatus.SUSPENDED) {
      return 'Suspended';
    } else {
      return licenseGroup.status === LicenseStatus.PENDING ? 'Pending' : 'Valid';
    }
  }
}
