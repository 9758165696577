<app-header [cartHidden]="false"></app-header>

<app-loader *ngIf="isLoading" [overlay]="true"></app-loader>

<div class="container">
  <div class="row">
    <div class="col-12 col-md-10 offset-md-1 col-lg-9 offset-lg-0">
      <!-- Member Status Indicator -->
      <app-status-indicator-header *ngIf="userLoaded"
        [user]="user"
        [firstName]="user.profile_first_name"
        [lastName]="user.profile_last_name"
        [email]="user.profile_email"
        [allMemberships]="allMemberships"
        [loading]="membershipsLoading"
      ></app-status-indicator-header>
      <hr>
    </div>
    <div class="col-12 col-md-10 offset-md-1 col-lg-9 offset-lg-0">
      <!-- Step Progress Bar -->
      <app-step-progress-bar
        [currentStep]="currentStep"
        [nextStep]="nextStep"
        [isRace]="true"
        [isFamily]="isFamily"
      ></app-step-progress-bar>
    </div>

    <div class="col-12 offset-md-1 col-md-10 offset-lg-0 col-lg-9 p-3">
      <app-form-title>Membership Payment</app-form-title>

      <div class="p-5 mx-4">
        <h4>How will this License be Paid?</h4>

        <div class="create-fee-description my-3">
          <p>
            If already paid, you will need to open the draft order generated upon submission and manually mark it as paid.
          </p>
          <p>
            If the member is paying, we will create a pending checkout to be emailed to the customer with a link to complete the
            purchase. We will also add a pending charge to the member's my-account with a link to complete the purchase.
          </p>
        </div>

        <div class="row">
          <div class="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
            <button
              class="btn btn-secondary btn-block px-3"
              [disabled]="isLoading"
              (click)="handleCheckout(true)"
            >
              Paid in Full
            </button>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-4 mt-2">
            <button
              class="btn btn-primary btn-block px-3"
              [disabled]="isLoading"
              (click)="handleCheckout(false)"
            >
              Member to Pay
            </button>
          </div>
        </div>
      </div>

      <div class="row mt-3" *ngIf="!isLoading">
        <!-- Navigation Buttons -->
        <div class="col-12 col-sm-6 col-md-3 col-lg-2 mb-2">
          <button id="back-btn" class="btn btn-secondary btn-block" type="button"
            (click)="back()">Back</button>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
