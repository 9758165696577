import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Event,
  GuardianRelationship,
  PersonTypes,
  Profile,
  SignedWaiver,
  Waiver,
} from 'src/app/core/models';
import { WaiverService } from 'src/app/core/services';

@Component({
  selector: 'app-non-enrollment-waiver-modal',
  templateUrl: './non-enrollment-waiver-modal.component.html',
  styleUrls: ['./non-enrollment-waiver-modal.component.scss'],
})
export class NonEnrollmentWaiverModalComponent implements OnInit {
  @Input() user: Profile;
  @Input() event: Event;
  @Input() eventDateIds: number[];
  @Input() waiverName = '';

  @Output() waiverId: EventEmitter<number> = new EventEmitter();

  agreementForm: FormGroup;
  waiver: Waiver;
  loading = true;
  fullNameError = false;
  buttonDisabled = false;
  isSubmitting = false;
  personTypes = PersonTypes;

  relationships = [
    { id: 1, name: GuardianRelationship.PARENT },
    { id: 2, name: GuardianRelationship.GUARDIAN },
  ];

  get codeOfConduct(): string {
    return this.waiverService.codeOfConduct;
  }

  get f(): any {
    return this.agreementForm.controls;
  }

  get fullName(): string {
    return this.f.fullName.value;
  }

  get parentName(): string {
    return `${this.f.parent_first_name.value} ${this.f.parent_last_name.value}`;
  }

  get parentRelationship(): string {
    return this.f.parent_relationship.value;
  }

  get signerEmail(): string {
    return this.f.signer_email.value;
  }

  get isAdult(): boolean {
    // Members under age 18, or 19 in Alabama, will need a parent/guardian signature
    const { address, profile_current_age, profile_race_age } = this.user;
    return address && address.address_state === 'AL'
      ? profile_current_age > 18
      : profile_race_age >= 18;
  }

  get waiverText(): string {
    return this.waiver ? this.waiver.waiver_text : null;
  }

  get waiverAgreement(): string {
    return this.waiver
      ? this.isAdult
        ? this.waiver.waiver_adult_agreement
        : this.waiver.waiver_jr_agreement
      : null;
  }

  get age(): number {
    return this.user.profile_current_age;
  }

  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private waiverService: WaiverService
  ) { }

  ngOnInit(): void {
    this.createAgreementForm();
    if (this.eventDateIds) {
      this.waiverService
        .getOneDayWaiver(this.eventDateIds)
        .subscribe((waiver) => this.setWaiver(waiver));
    } else {
      const waiverNameQuery = this.waiverName ? `?waiver_name=${this.waiverName}` : '';
      this.waiverService.getWaiver(waiverNameQuery).subscribe((waiver) => this.setWaiver(waiver));
    }
  }

  setWaiver(waiver: Waiver): void {
    if (waiver) {
      this.waiver = waiver;
    }
    this.loading = false;
  }

  compareNames(): void {
    let nameIsMatch: boolean;

    if (this.isAdult) {
      nameIsMatch = this.fullName.length > 0; // MBR-2900
      // nameIsMatch =
      //   this.fullName.localeCompare(
      //     `${this.user.profile_first_name} ${this.user.profile_last_name}`,
      //     undefined,
      //     {
      //       sensitivity: 'accent',
      //     }
      //   ) === 0;
    } else {
      // If there is a guardian, check their name instead
      nameIsMatch =
        this.fullName.localeCompare(this.parentName, undefined, {
          sensitivity: 'accent',
        }) === 0;
    }

    if (!nameIsMatch) {
      this.fullNameError = true;
      this.buttonDisabled = true;
    } else {
      this.buttonDisabled = false;
      this.fullNameError = false;
    }
  }

  signWaiver(e: any): void {
    e.preventDefault();

    if (this.agreementForm.valid) {
      this.isSubmitting = true;

      const signedWaiver: SignedWaiver = {
        participant_name: `${this.user.profile_first_name} ${this.user.profile_last_name}`,
        participant_dob: this.user.profile_birthdate,
        participant_rider_id: this.user.profile_id,
        waiver_text: this.waiver.waiver_text,
        sig_date: new Date(),
        event_name: this.event ? this.event.event_name : null,
        is_adult: this.isAdult ? 'Y' : 'N',
        age: this.age,
        parent_name: this.isAdult ? null : this.parentName,
        parent_relationship: this.isAdult ? null : this.parentRelationship,
        signature: this.fullName,
        signer_email: this.isAdult ? this.user.profile_email : this.signerEmail,
        sig_adult_agreement: this.waiver.waiver_adult_agreement,
        sig_jr_agreement: this.waiver.waiver_jr_agreement,
        waiver_id: this.waiver.waiver_id,
        code_of_conduct_agreed: this.f.codeOfConductAgreed.value,
      };

      this.waiverService.signWaiver(signedWaiver).subscribe((signedWaiverId) => {
        if (signedWaiverId) {
          this.waiverId.emit(signedWaiverId);
          this.activeModal.close();
        } else {
          // TODO: @Charlie, @Brennan - Handle waiver creation error
        }
      });
    } else {
      this.agreementForm.markAllAsTouched();
    }
  }

  private createAgreementForm(): void {
    let formGroup = {
      fullName: ['', Validators.required],
      agreed: [false, Validators.requiredTrue],
      codeOfConductAgreed: [false, Validators.requiredTrue],
    };

    if (!this.isAdult) {
      formGroup = Object.assign({}, formGroup, {
        parent_first_name: ['', Validators.required],
        parent_last_name: ['', Validators.required],
        signer_email: ['', [Validators.required, Validators.email]],
        parent_relationship: [null, Validators.required],
      });
    }

    this.agreementForm = this.fb.group(formGroup);
  }
}
