<!-- <div class="mb-4">
  <ngb-accordion (panelChange)="onPanelChange($event)">
    <ngb-panel>
      <ng-template ngbPanelHeader>
        <div class="row d-flex justify-content-between">
          <button ngbPanelToggle class="btn flex-grow-1 text-left">
            <h4>{{licenseType.name}}</h4>
          </button>
          <button ngbPanelToggle class="text-right btn icon-btn"><i class="icon {{toggleIcon}}"></i></button>
        </div>
      </ng-template>

      <ng-template ngbPanelContent> -->

<div class="card mb-4">
  <div class="card-header pt-3">
    <h4>{{licenseType.name}}</h4>
  </div>

<div class="card-body">
  <!-- License Categories -->
  <div *ngIf="!isGrouped && !!licenseForm">
    <div *ngFor="let category of licenseCategories">
      <div class="row px-2 mb-5">
        <div class="col-md-5">
          <h5>{{category.name}}</h5>
          <p *ngIf="category.base_shopify_variant">{{category.base_shopify_variant.price | currency}} USD</p>
          <p *ngIf="!category.base_shopify_variant && category.shopify_variant">{{category.shopify_variant.price | currency}} USD</p>
          <!-- <p>{{category.description}}</p> -->
        </div>
        <div class="col-md-4">
          <p class="{{statusClass(category.status)}} weight-500">
            <i class="icon {{iconClass(category.status)}}"></i> {{category.status}}
          </p>
          <p *ngIf="showExpiration(category)" class="weight-500">Expires: {{category.expiration | date:'shortDate'}}</p>
          <p>
            {{requirementStatus(category)}}
            <span class="text-warning font-italic cursor-pointer" (click)="openRequirementsModal(category)">View</span>
          </p>
        </div>
        <div class="col-md-3 text-md-right">
          <button class="btn {{btnClass(category)}} btn-block"
            [disabled]="!categoryControl(category) || categoryControl(category).disabled"
            (click)="toggleControlValue(category)"
          >{{btnText(category)}}</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Grouped License Categories (i.e. Officials) -->
  <div *ngIf="isGrouped && !!licenseForm">
    <div *ngFor="let group of licenseCategoryGroups | keyvalue: sortOrder">
      <div class="row px-2 mb-5">
        <div class="col-md-5">
          <h5>{{group.value.baseVariant.title}}</h5>
          <p>{{group.value.baseVariant.price | currency}} USD</p>
          <!-- TODO: @Charlie - Where would we get a "group" description? -->
          <!-- <p>{{group.value.baseVariant.description}}</p> -->
        </div>
        <div class="col-md-4">
          <p class="{{statusClass(groupStatus(group.value.categories))}} weight-500">
            <i class="icon {{iconClass(groupStatus(group.value.categories))}}"></i> {{groupStatus(group.value.categories)}}
          </p>
          <p *ngIf="showGroupExpiration(group.value.categories)" class="weight-500">Expires: {{groupExpiration(group.value.categories) | date:'shortDate'}}</p>
          <p>
            {{groupRequirementStatus(group.value.categories)}}
            <span class="text-warning font-italic cursor-pointer" (click)="openRequirementsModal(null, group.key)">View</span>
          </p>
        </div>
        <div class="col-md-3 text-md-right">
          <button class="btn {{groupBtnClass(group.key)}} btn-block"
            [disabled]="!categoryGroupControl(group.key) || categoryGroupControl(group.key).disabled"
            (click)="toggleControlValue(null, group.key)"
          >{{groupBtnText(group.key)}}</button>
        </div>
      </div>
    </div>
  </div>
      <!-- </ng-template>
    </ngb-panel>
  </ngb-accordion> -->
</div>
