import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { Profile } from 'src/app/core';
import { RegistrationFacade } from 'src/app/core/facades';
import { AuthService, MembershipOrdersService, ProfileService } from 'src/app/core/services';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  constructor(
    private authService: AuthService,
    private modal: NgbModal,
    private membershipOrdersService: MembershipOrdersService,
    private profileService: ProfileService,
    private registrationFacade: RegistrationFacade
  ) {}

  @Input() title = 'Membership Enrollment';
  @Input() cartHidden: boolean;
  @Input() cartType = 'standard';
  @Input() showLogoPrompt = true;
  @Input() hideCartContent: boolean;
  @Input() logoutRedirectUrl: string;
  @Input() startOverHidden: boolean;
  @Input() showLogout: boolean;
  @Input() user: Profile;
  @Input() rideTechUpgrade: boolean;
  @Input() spotInsuranceUpgrade: boolean;

  cartOpened = false;
  @Output() cartOpenedEmmiter: EventEmitter<boolean> = new EventEmitter<boolean>();

  changeCartState(newState: boolean): void {
    this.cartOpened = newState;
  }

  leave(): void {
    this.registrationFacade.reset();
    window.location.href = this.usacMainUrl;
  }

  confirmLogout(): void {
    const modal = this.modal.open(ConfirmationModalComponent);

    modal.componentInstance.message =
      'You are now exiting membership management. Do you want to proceed?';

    modal.result
      .then(() => {
        if (this.registrationFacade.getOrderId) {
          this.membershipOrdersService
            .remove(this.registrationFacade.getOrderId)
            .pipe(
              finalize(() => {
                this.logout();
              })
            )
            .subscribe();
        } else {
          this.logout();
        }
      })
      .catch(() => this.modal.dismissAll());
  }

  logout(): void {
    // Logout user and clear state
    this.authService.doLogout().subscribe((resp) => {
      if (resp) {
        window.location.assign(environment.publicUrl);
      }
    });
  }

  get usacMainUrl(): string {
    return environment.mainUrl;
  }

  get standardCart(): boolean {
    // TODO: If other cart types later added, update this catch-all
    return this.cartType === 'standard' || this.cartType !== 'upgrade';
  }

  get upgradeCart(): boolean {
    return this.cartType === 'upgrade';
  }

  get ghostModeActive(): boolean {
    return this.profileService.hasGhostModeCookie;
  }
}
