/**
 * Represents a race gender.
 *
 * This gives member only two options regardless of whether they claimed a non-binary status
 * or did not want to disclose their gender.
 */
export interface RaceGender {
  value: RaceGenderCode;
  label: string;
}

export enum RaceGenderCode {
  Male = 'M',
  Female = 'F',
}
