<div class="modal-container">
  <div class="d-flex justify-content-between modal-content__header">
    <h4>{{title}} Requirements</h4>
    <img class="close-button" src="assets/images/x-circle.svg" alt="X" (click)="closeModal()"/>
  </div>
  <p class="modal-content__subheader">
    <!-- {{description}} -->
  </p>

  <div id="requirements-status">
    <h5>Your Status</h5>
    <p class="font-italic {{requirementClass}}">{{requirementStatus}}</p>
    <p *ngIf="showExpiration">
      <span class="mr-4">Expires:</span>
      {{expiration | date:'shortDate'}}
    </p>
  </div>

  <div *ngFor="let requirement of requirements" class="row px-3 mb-4">
    <div class="col-2 col-sm-1">
      <i class="icon {{iconClass(requirement)}}"></i>
    </div>
    <div class="col-10 col-sm-7">
      <h5>{{requirement.name}}</h5>
      <!-- TODO: Uncomment requirement descriptions once final ones provided -->
      <!-- <p>{{requirement.description}}</p> -->
    </div>
    <div class="col-sm-4 text-sm-right">
      <button
        class="btn btn-secondary btn-block"
        (click)="openActionLink(requirement)"
        [disabled]="shouldDisableActionButton(requirement)"
      >{{actionText(requirement)}}</button>
    </div>
  </div>

  </div>
