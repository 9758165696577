<app-header [cartHidden]="true" [cartType]="'upgrade'" [user]="user" [showLogout]="true"></app-header>

<app-loader *ngIf="showLoader" [overlay]="true"></app-loader>

<div class="container">
  <div class="row">
    <div class="col-12">
      <app-status-indicator-header
        [user]="user"
        [allMemberships]="memberships"
        [firstName]="user.profile_first_name"
        [lastName]="user.profile_last_name"
        [email]="user.profile_email"
        [loading]="isLoading">
      </app-status-indicator-header>
      <hr>
    </div>
    <div class="col-12 col-lg-10 offset-lg-1 p-4">
      <app-form-title>Upgrade Your Membership</app-form-title>

      <!-- Login Module / Empty State -->
      <div *ngIf="!loggedIn" class="login-container d-flex align-items-center row p-3 m-0 mt-5">
        <div class="col-2 col-md-1">
          <img src="assets/user-check.svg" alt="User" />
        </div>
        <div class="col-10 col-md-6">
          <p class="m-0">
            Upgrade your USA Cycling membership. Log In to get started.
          </p>
        </div>
        <div class="d-flex justify-content-center justify-content-md-end col-12 col-md-4 offset-md-1 pt-4 pt-md-0">
          <button type="button" class="btn btn-primary btn-block px-3" (click)="login()">LOG IN</button>
        </div>
      </div>

      <div *ngIf="loggedIn && !isLoading" class="mt-5">
        <!-- Empty State (not a member / no upgrades available) -->
        <div *ngIf="!activeMembership || !upgradeOptions.length" class="login-container d-flex align-items-center row p-3 m-0">
          <div class="col-2 col-md-1">
            <img src="assets/user-check.svg" alt="User" />
          </div>
          <div class="col-10 col-md-6">
            <p class="m-0">
              {{ ineligibilityText }}
            </p>
          </div>
          <div
            class="d-flex justify-content-center justify-content-md-end col-12 col-md-4 offset-md-1 pt-4 pt-md-0"
          >
            <button type="button" class="btn btn-primary btn-block px-3" (click)="ineligibilityBtnAction()">
              {{ ineligibilityBtnText }}
            </button>
          </div>
        </div>

        <!-- Upgrade Menu -->
        <div *ngIf="activeMembership && !!variantsBySku" class="row">
          <app-upgrade-card *ngFor="let option of upgradeOptions"
            class="col-sm-6 col-lg-4 p-2"
            [title]="option.title"
            [type]="option.type"
            [price]="option.price || getPrice(option.sku)"
            [perYear]="option.perYear"
            [sku]="option.sku"
            [benefits]="option.benefits"
            [boldBorder]="option.boldBorder"
            [spotItem]="option.spotItem"
            [hideAutoRenew]="option.hideAutoRenew || isRaceAge18"
            (addToCartUpdate)="selectUpgrade($event)"
          ></app-upgrade-card>
        </div>
      </div>

  </div>
</div>

<app-footer [fullWidth]="true"></app-footer>
