<nav class="navbar fixed-top header">
  <div class="header-text-container d-flex justify-content-start align-items-center">
    <a href="https://usacycling.org/">
    <img
      width="67"
      class="p-3 navbar-brand icon"
      src="assets/images/usac-logo.png"
      alt="USA Cycling"
    />
    </a>
    <h3 class="d-none d-xl-block header-text m-0">USA Cycling Membership Enrollment</h3>
  </div>
  <div class="d-flex .d-sm-none my-cart-toggle navbar-toggler">
    <div class="d-flex align-items-center login-text" *appShowAuthed="false">
      Have an existing account?<button
        type="button"
        class="login-button btn btn-link font-weight-bold text-white ml-1 p-0"
        (click)="login()"
      >
        Log In
      </button>
    </div>

    <div class="d-flex align-items-center login-text" *appShowAuthed="true">
      <button
        type="button"
        class="login-button btn btn-link font-weight-bold text-white ml-1 p-0"
        (click)="logout()"
      >
        Log out
      </button>
    </div>
  </div>
</nav>
