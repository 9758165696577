import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Gender } from '../models';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class GendersService {
  constructor(private apiService: ApiService) {}

  getAll(): Observable<Gender[]> {
    return this.apiService.get('/genders');
  }
}
