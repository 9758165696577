<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="create-fee-modal">
        <h4>Member Information for Draft Order</h4>

        <div class="create-fee-description my-3">
          {{ description }}

          <ol class="mt-3">
            <li>Click "Open Shopify" to open the draft order edit page.</li>
            <li>You may edit the draft order if needed at this stage.</li>
            <li>When ready, click "Collect payment".</li>
            <li>Then click "Mark as paid".</li>
            <li>In the modal that appears, click "Create order".</li>
          </ol>
        </div>

        <div class="shopify-tags my-4">
          <p>Profile ID tag: <strong>profile_id-{{profile.profile_id}}</strong></p>
          <p>Comp ID tag: <strong>comp_id-{{profile.profile_comp_id}}</strong></p>
          <p>Customer Email: <strong>{{profile.profile_email}}</strong></p>
        </div>

        <div class="shopify-user-address mb-5">
          <p>Address: <strong *ngIf="!showAddress">N/A</strong></p>
          <strong *ngIf="showAddress">
            <p>{{profile.profile_first_name}} {{profile.profile_last_name}}</p>
            <p>{{address.address_street}}</p>
            <p>{{address.address_city}}, {{address.address_state}} {{address.address_postal_code}}</p>
            <p>{{address.address_country}}</p>
          </strong>
        </div>

        <div class="row">
          <div class="col-4">
            <button class="btn btn-secondary btn-block px-3" (click)="closeModal()">Close</button>
          </div>
          <div class="col-4">
            <button class="btn btn-primary btn-block px-3" (click)="openShopify()">Open Shopify</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
