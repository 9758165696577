import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { Country, SelectOption } from '../models';

import { BaseService } from './base-service';

@Injectable({
  providedIn: 'root',
})
export class CountryService extends BaseService {
  countries: Country[] = [];
  countryOptions: SelectOption[] = [];

  getShopifyCountries(): Observable<Country[]> {
    return this.http.get<Country[]>(this.url('shopify/countries'), this.options).pipe(
      tap((countries: Country[]) => {
        this.log('fetched country list');
        this.countryOptions = countries.map((country) => ({
          value: country.code,
          label: country.name,
        }));
        this.countryOptions.unshift({ value: '', label: 'Select...' });
        this.countries = countries;
      }),
      catchError(this.handleError<Country[]>('getCountries', null, {}))
    );
  }
}
