import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import {
  Category,
  Discipline,
  InterestsService,
  MembershipLevel,
  PersonTypes,
  Registration,
  RegistrationService,
} from 'src/app/core';
import { UpgradeToPremiumModalComponent } from 'src/app/shared/upgrade-to-premium-modal/upgrade-to-premium-modal.component';

@Component({
  selector: 'app-race-categories-modal',
  templateUrl: './race-categories-modal.component.html',
  styleUrls: ['./race-categories-modal.component.scss'],
})
export class RaceCategoriesModalComponent implements OnInit {
  @Input()
  registrationId: string;

  disciplines: Discipline[];
  registration: Registration;

  isLoading = true;
  isSaving = false;

  raceCategoriesForm: FormGroup;

  get domesticCategories(): FormArray {
    return this.raceCategoriesForm.get('domesticCategories') as FormArray;
  }

  get collegiateCategories(): FormArray {
    return this.raceCategoriesForm.get('collegiateCategories') as FormArray;
  }

  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    public modal: NgbModal,
    private registrationService: RegistrationService,
    private interestsService: InterestsService
  ) {
    this.createForms();
  }

  ngOnInit(): void {
    this.registrationService
      .getRegistration(this.registrationId)
      .pipe(
        tap((registration: Registration) => {
          this.loadCategories(registration);
        })
      )
      .subscribe();
  }

  private createForms(): void {
    this.raceCategoriesForm = this.fb.group({
      domesticCategories: new FormArray([]),
      collegiateCategories: new FormArray([]),
    });
  }

  serializeCategories(): any[] {
    // Raw value retrieves all control values regardless of the disabled state
    const rawValue = this.raceCategoriesForm.getRawValue();
    const categories: any[] = [];

    // Add collegiate categories and their associated categories
    if (this.registration.is_collegiate_cyclist) {
      rawValue.collegiateCategories.forEach((collegiateCategory: Category, index: number) => {
        categories.push({
          cd_id: this.disciplines[index].id,
          cd_level: collegiateCategory.requiresPremium
            ? MembershipLevel.Premium
            : MembershipLevel.Standard,
          cd_type: collegiateCategory.id,
        });
      });
    }

    // Add domestic categories and their associated categories
    rawValue.domesticCategories.forEach((domesticCategory: Category, index: number) => {
      categories.push({
        cd_id: this.disciplines[index].id,
        cd_level: domesticCategory.requiresPremium
          ? MembershipLevel.Premium
          : MembershipLevel.Standard,
        cd_type: domesticCategory.id,
      });
    });

    return categories;
  }

  saveDisciplineCategories(): void {
    const categories = this.serializeCategories();

    this.isSaving = true;
    this.registrationService
      .updateRacingCategories(this.registration.id, categories)
      .pipe(
        tap(() => {
          this.activeModal.close();
        }),
        catchError((error) => throwError(error)),
        finalize(() => {
          this.isSaving = false;
        })
      )
      .subscribe();
  }

  onChange(model: Category, controlIndex: number): void {
    if (
      model.requiresPremium &&
      !this.registration.is_premium &&
      this.registration.person_type === PersonTypes.ADULT &&
      !this.registration.is_collegiate_cyclist
    ) {
      const modal = this.modal.open(UpgradeToPremiumModalComponent, {
        centered: true,
        size: 'xl',
      });

      modal.result
        .then(() => {
          this.registration.is_premium = true;
          this.registrationService
            .updateOptions(this.registration.id, { is_premium: true })
            .pipe(
              catchError((error) => {
                this.registration.is_premium = false;
                return throwError(error);
              })
            )
            .subscribe();
        })
        .catch(() => {
          this.domesticCategories.controls[controlIndex].setValue(
            this.disciplines[controlIndex].nonCollegiateCategories[0]
          );
        });
    }
  }

  private isEligibleForPremium(registration: Registration): boolean {
    return (
      registration.is_premium ||
      registration.is_collegiate_cyclist ||
      registration.person_type === PersonTypes.JUNIOR
    );
  }

  private createCategoryControl(defaultValue: Category): FormControl {
    return new FormControl(
      {
        value: defaultValue,
        disabled: true,
      },
      Validators.required
    );
  }

  private setupCategoriesForm(disciplines: Discipline[]): void {
    disciplines.forEach((discipline) => {
      const domesticNovice = discipline.nonCollegiateCategories[0];
      const domesticCategoryControl = this.createCategoryControl(domesticNovice);
      this.domesticCategories.push(domesticCategoryControl);

      const collegiateNovice = discipline.collegiateCategories[0];
      const collegiateCategoryControl = this.createCategoryControl(collegiateNovice);
      this.collegiateCategories.push(collegiateCategoryControl);
    });
  }

  private populateCategoriesForm(disciplines: Discipline[], categoryIds: number[]): void {
    disciplines.forEach((discipline, index) => {
      const domesticCategory = discipline.nonCollegiateCategories.find(
        (category) => categoryIds.indexOf(category.id) !== -1
      );

      if (domesticCategory) {
        this.domesticCategories.controls[index].setValue(domesticCategory);
      }

      // There are no selectable collegiate categories other than Category D
      const collegiateCategory = discipline.collegiateCategories.find(
        (category) => categoryIds.indexOf(category.id) !== -1
      );

      if (collegiateCategory) {
        this.collegiateCategories.controls[index].setValue(collegiateCategory);
      }
    });
  }

  private normalizeCategoriesForm(
    disciplines: Discipline[],
    opts: { allowPremium: boolean }
  ): void {
    disciplines.forEach((discipline, index) => {
      const domesticNovice = discipline.nonCollegiateCategories[0];
      const domesticCategoryControl = this.domesticCategories.controls[index];
      const domesticCategory = domesticCategoryControl.value as Category;

      if (domesticCategory.requiresPremium && !opts.allowPremium) {
        domesticCategoryControl.setValue(domesticNovice);
      }
    });
  }

  private loadCategories(registration: Registration): void {
    const categories = registration.racing_categories || [];

    this.interestsService
      .getInterests()
      .pipe(
        tap((allInterests) => {
          // Disable category selection for categories that require approval
          const disciplines = allInterests
            .flatMap((x) => x.disciplines)
            .filter(
              (x) => x.collegiateCategories.length > 0 && x.nonCollegiateCategories.length > 0
            );

          disciplines.forEach((discipline) => {
            discipline.nonCollegiateCategories.forEach((category) => {
              if (category.requiresApproval) {
                category.disabled = true;
              }
            });

            discipline.collegiateCategories.forEach((category) => {
              if (category.requiresApproval) {
                category.disabled = true;
              }
            });
          });

          this.setupCategoriesForm(disciplines);

          // Persist the registration
          this.registration = registration;
          this.disciplines = disciplines;
        }),
        tap(() => {
          const categoryIds = categories.map((category) => category.cd_type);
          this.populateCategoriesForm(this.disciplines, categoryIds);
          this.normalizeCategoriesForm(this.disciplines, {
            allowPremium: this.isEligibleForPremium(registration),
          });

          // Enable inputs for selected disciplines
          this.disciplines.forEach((discipline, index) => {
            const isSelected = registration.disciplines.some((x) => x.cd_id === discipline.id);
            if (isSelected) {
              this.domesticCategories.controls[index].enable();
            }
          });

          this.isLoading = false;
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      )
      .subscribe();
  }
}
