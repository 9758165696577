import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { Event, EventDate } from '../models';

import { BaseService } from './base-service';

@Injectable({
  providedIn: 'root',
})
export class EventService extends BaseService {
  oneDaySkus = {
    19: ['1DAY-B-01', '1DAY-E-01'], // Road
    20: ['1DAY-B-03', '1DAY-E-03'], // Track
    21: ['1DAY-B-02', '1DAY-E-02'], // Mountain
    22: ['1DAY-12'], // BMX Racing
    23: ['1DAY-B-04', '1DAY-E-04'], // Cyclocross
    24: ['1DAY-07'], // Gran Fondo
    25: ['1DAY-06'], // Gravel
    26: ['1DAY-10'], // Camp
    27: ['1DAY-08'], // Fun Ride
    28: ['1DAY-11'], // Clinic
    29: ['1DAY-09'], // Training Ride
    30: ['1DAY-05'], // BMX Freestyle
  };

  getEvent(eventId: number): Observable<Event> {
    return this.http.get<Event>(this.url('event/' + eventId), this.options).pipe(
      tap(() => this.log('loaded event')),
      catchError(
        this.handleError<Event>('getEvent', null, { eventId })
      )
    );
  }

  getEventDates(eventId: number | string): Observable<EventDate[]> {
    return this.http.get<EventDate[]>(this.url(`eventdate/event/${eventId}`), this.options).pipe(
      tap(() => this.log('fetched event dates')),
      catchError(
        this.handleError<EventDate[]>('getEventDates', [], { eventId })
      )
    );
  }

  sortEventDates(eventDates: EventDate[]): EventDate[] {
    return eventDates.sort(this.compareEventDates);
  }

  private compareEventDates(a: EventDate, b: EventDate): number {
    const startA = a.event_date_start;
    const startB = b.event_date_start;

    let comparison = 0;
    if (startA > startB) {
      comparison = 1;
    } else if (startA < startB) {
      comparison = -1;
    }
    return comparison;
  }
}
