<div class="container" *ngIf="memberInfo">
  <div class="pre-prompt">
    <div class="row">
      <div class="col-12 text-center">
        <h1 class="pre-prompt__title">Is {{memberInfo.first_name}} {{memberInfo.last_name}} present?</h1>

        <p class="pre-prompt__description">
          This member must sign their own waiver. If the member is not present that's ok we can send
          an email and have them sign it.
        </p>
      </div>
      <div class="d-flex justify-content-around align-items-center w-100">
        <button type="button" (click)="openAgreementModal()" class="pre-prompt__btn btn btn-primary mr-1">Yes</button>
        <button type="button" (click)="openNoWaiverModal()" class="pre-prompt__btn btn btn-outline-primary ml-1">No</button>
      </div>
    </div>
  </div>
</div>
