import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NgbCalendar,
  NgbDate,
  NgbModal,
  NgbModalOptions,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject, of, throwError } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/core';
import { AppFacade, FamilyMembershipFacade, Plans, RegistrationFacade } from 'src/app/core/facades';
import {
  FamilyMember,
  License,
  MemberInfo,
  Membership,
  MembershipOption,
  MembershipStep,
  MembershipType,
  MerchantAccountTag,
  PersonTypes,
  Product,
  ProductVariant,
  Profile,
  RaceGender,
  Registration,
  SelectOption,
} from 'src/app/core/models';
import {
  CountryService,
  ProfileService,
  RaceGendersService,
  RegistrationService,
  ShopifyService,
  TransactionService,
} from 'src/app/core/services';
import { EnrollmentInProccessModalComponent } from 'src/app/shared/enrollment-in-proccess-modal/enrollment-in-proccess-modal.component';
import { MatchingAccountModalComponent } from 'src/app/shared/matching-account-modal/matching-account-modal.component';
import { digitRegex, nameRegex, passwordRegex } from 'src/app/shared/regexes';
import { RenewMembershipModalComponent } from 'src/app/shared/renew-membership-modal/renew-membership-modal.component';
import { confirmPasswordValidator, phoneNumberValidator } from 'src/app/shared/validators';
import { environment } from 'src/environments/environment';

import { AgreementModalComponent } from '../agreement-modal/agreement-modal.component';
import { GetStartedModalComponent } from '../get-started-modal/get-started-modal.component';
import { JuniorWaiverModalComponent } from '../junior-waiver-modal/junior-waiver-modal.component';

@Component({
  selector: 'app-member-information-step',
  templateUrl: './member-information-step.component.html',
  styleUrls: ['./member-information-step.component.scss'],
})
export class MemberInformationStepComponent implements OnInit, OnDestroy {
  registrationData: any;
  user: Profile;
  profileLicenses: License[] = [];
  membershipOption: MembershipOption;
  addedJunior: boolean;
  skipPremiumPrompt: boolean;

  memberForm: FormGroup;
  juniorSelectForm: FormGroup;
  verificationForm: FormGroup;
  isPremiumRequired = false;
  isAuthenticated = false;
  plan: string;
  person_type: string;

  planTypes = Plans;
  personTypes = PersonTypes;
  personType = PersonTypes.ADULT;
  raceGenders: RaceGender[];
  profileGender: string;

  allMemberships: Membership[];
  statusIndicatorFirstName = '';
  statusIndicatorLastName = '';
  statusIndicatorEmail = '';

  isLoading = true;
  userLoaded = false;
  licensesLoading = true;
  membershipLookupStarted = false;
  membershipsLoading = true;
  isSubmitting = false;
  isNavigating = false;
  needsAddress = true;
  errors$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  getStartedModal: NgbModalRef;
  renewalModal: NgbModalRef;
  premiumModal: NgbModalRef;

  // Combine single registration and family registration in one object
  vm$ = this.appFacade.vm$;

  todaySignDate = new Date();
  today: NgbDate = this.calendar.getToday();
  maxDate: NgbDate;
  minDate: NgbDate;

  maxDateString = '';
  minDateString = '1900-01-01';
  birthdayErrorMessage = '';
  countries: SelectOption[];

  get ghostModeActive(): boolean {
    return this.profileService.hasGhostModeCookie;
  }

  get adminUrl(): string {
    return environment.adminUrl;
  }

  get isRide(): boolean {
    return this.membershipOption === MembershipOption.Ride;
  }

  get isRace(): boolean {
    return this.membershipOption === MembershipOption.Race;
  }

  get Step(): typeof MembershipStep {
    return MembershipStep;
  }

  get mf(): any {
    return this.memberForm.controls;
  }

  get jsf(): any {
    return this.juniorSelectForm.controls;
  }

  get vf(): any {
    return this.verificationForm.controls;
  }

  get firstName(): FormControl {
    return this.mf.first_name as FormControl;
  }

  get lastName(): FormControl {
    return this.mf.last_name as FormControl;
  }

  get dob(): FormControl {
    return this.mf.birthdate as FormControl;
  }

  get email(): FormControl {
    return this.mf.email as FormControl;
  }

  get emergencyContactName(): FormControl {
    return this.mf.contact_emergency_contact_name as FormControl;
  }

  get emergencyContactPhone(): FormControl {
    return this.mf.contact_emergency_contact_number as FormControl;
  }

  get inheritEmail(): FormControl {
    return this.mf.inherit_email as FormControl;
  }

  get raceGender(): FormControl {
    return this.mf.race_gender as FormControl;
  }

  get verifyProfileCitizenship(): FormControl {
    return this.vf.verify_is_us_citizen as FormControl;
  }

  get verifyUsaSportingNationality(): FormControl {
    return this.vf.verify_is_usa_sporting_nationality as FormControl;
  }

  get isJunior(): boolean {
    return this.registrationFacade.registration.person_type === PersonTypes.JUNIOR;
  }

  get isAdult(): boolean {
    return this.registrationFacade.registration.person_type === PersonTypes.ADULT;
  }

  get addFamily(): boolean {
    return this.juniorSelectForm && this.jsf.family_membership.value;
  }

  get juniors(): FormControl {
    return this.jsf.juniors as FormControl;
  }

  get isFamilyPlan(): boolean {
    return this.registrationFacade.currentState.isFamilyPlan;
  }

  get isExistingUser(): boolean {
    return this.user && !!this.user.profile_id;
  }

  get currentStatusIndicatorProfile(): any {
    return this.isExistingUser ? this.user : this.registrationFacade.currentState.registration;
  }

  get isInternational(): boolean {
    return this.registrationFacade.currentState.isInternational;
  }

  get isPro(): boolean {
    return this.registrationFacade.currentState.isPro;
  }

  get juniorDisabled(): boolean {
    return this.isInternational || this.isPro;
  }

  get licenseSelectionText(): string {
    return this.isInternational ? 'international license' : 'professional license';
  }

  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private countryService: CountryService,
    private appFacade: AppFacade,
    private fb: FormBuilder,
    private authService: AuthService,
    private registrationFacade: RegistrationFacade,
    private familyFacade: FamilyMembershipFacade,
    private calendar: NgbCalendar,
    private modal: NgbModal,
    private profileService: ProfileService,
    private raceGenderService: RaceGendersService,
    private registrationService: RegistrationService,
    private route: ActivatedRoute,
    private router: Router,
    private shopifyService: ShopifyService,
    private transactionService: TransactionService
  ) {
    this.setMinMaxDates();
    this.createForms();
    this.registrationData = this.registrationFacade.registration$;
  }

  ngOnDestroy(): void {
    this.closeGetStartedModal();
    this.clearModals();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.countryService.getShopifyCountries().subscribe(() => {
      this.countries = this.countryService.countryOptions;
    });
    this.registrationFacade.reset();
    const snapshot = this.route.snapshot;
    const routeData = snapshot.data;
    this.membershipOption = routeData.membershipOption;
    this.addedJunior = !!routeData.addedJunior;

    if (this.authService.currentUser && !this.addedJunior) {
      this.authService.populate();
    }

    const { premiumPrompt, ref } = snapshot.queryParams;
    this.skipPremiumPrompt = premiumPrompt === 'false';
    const isRacePlus = ['raceplus', 'juniorplus'].includes(ref);
    if (!this.registrationFacade.registration.id && !this.addedJunior) {
      this.familyFacade.updatejuniorHasSpot(isRacePlus);
      this.familyFacade.updateAdultHasSpot(isRacePlus);
      this.mf.has_spot_insurance.setValue(isRacePlus);
    }

    this.registrationFacade.updateIsRace(this.isRace);
    this.authService.isAuthenticated$.subscribe((isAuthenticated) => {
      this.isAuthenticated = isAuthenticated;
      // Unrequire password if authenticated and not added junior
      if (isAuthenticated && !this.addedJunior) {
        this.removeFieldValidators(['password', 'password_confirmation']);
        this.closeGetStartedModal();
      }
      if (isAuthenticated) {
        this.removeValidationFromValidators(['profile_citizenship', 'profile_racing_nationality', 'verify_is_us_citizen', 'verify_is_usa_sporting_nationality', 'verify_is_dob_accurate', 'verify_is_sex_accurate', 'guardian_name', 'guardian_email', 'guardian_phone', 'guardian_sign_date']);
      }
    });

    this.authService.currentUserFetched$.subscribe((userFetched) => {
      if (userFetched && !this.isAuthenticated) {
        this.isLoading = false;

        if (!this.user && !this.addedJunior) {
          this.registrationFacade.reset();
          this.openGetStartedModal();
        }
      }
    });

    this.authService.currentUser$
      .pipe(
        tap((profile: Profile) => {
          if (!profile) {
            return;
          }

          if (profile && profile.profile_id) {
            this.user = profile;
            this.statusIndicatorFirstName = profile.profile_first_name;
            this.statusIndicatorLastName = profile.profile_last_name;
            this.statusIndicatorEmail = profile.profile_email;

            if (this.licensesLoading) {
              this.profileService
                .getLicenses(profile.profile_id, { active: true })
                .subscribe((licenses: License[]) => {
                  this.profileLicenses = licenses;
                  this.licensesLoading = false;
                });
            }

            if (!this.membershipLookupStarted) {
              this.membershipLookupStarted = true;
              this.profileService
                .getMemberships(profile.profile_id)
                .subscribe((memberships: Membership[]) => {
                  if (memberships) {
                    this.allMemberships = memberships;
                    // Check renewal ability if authed and no registration yet started
                    if (!this.registrationId && !this.addedJunior) {
                      const password: string = null;
                      const renewalData = { profile: this.user, memberships, password };
                      this.handleRenewalModal(renewalData);
                    }
                  }
                  this.membershipsLoading = false;
                  this.isLoading = false;
                });
            }
          } else {
            this.membershipsLoading = false;
          }
          this.subscribeToDemographics();

          this.userLoaded = true;
        })
      )
      .subscribe();

    this.loadRaceGenders();

    if (this.addedJunior) {
      // Update family facade & set junior as current registration ?
      const juniorMember = this.familyFacade.addedJunior;
      this.familyFacade.setSelectedMember(juniorMember);
      this.registrationFacade.resetupdateFromProfileFamily(juniorMember);
      // this.registrationFacade.updatePlan(Plans.FAMILY);
      if (!this.selectedMember?.id && this.familyFacade.adultHasSpot) {
        this.familyFacade.updatejuniorHasSpot(true);
        this.mf.has_spot_insurance.setValue(true);
      }
    }

    if (this.selectedMember?.id) {
      this.registrationService.getRegistration(this.selectedMember.id).subscribe(() => {
        this.setDataFromRegistration();
      });
    } else {
      this.setDataFromRegistration();
    }

    this.mf.is_auto_renewal.setValue(false);
  }

  private setMinMaxDates(): void {
    const maxDate = this.calendar.getToday();
    maxDate.year -= 6;
    this.maxDate = maxDate;
    this.maxDateString = [
      maxDate.year,
      this.padDatePart(maxDate.month),
      this.padDatePart(maxDate.day),
    ].join('-');
    this.minDate = new NgbDate(1900, 1, 1);
  }

  private padDatePart(part: number): string {
    return part.toString().padStart(2, '0');
  }

  openGetStartedModal(): void {
    this.getStartedModal = this.modal.open(GetStartedModalComponent, {
      size: 'lg',
      centered: true,
    });
  }

  closeGetStartedModal(): void {
    if (this.getStartedModal) {
      this.getStartedModal.close();
    }
  }

  private clearModals(): void {
    this.getStartedModal = null;
    this.renewalModal = null;
    this.premiumModal = null;
  }

  private setDataFromRegistration(): void {
    const familyState = this.familyFacade.currentState;

    this.registrationFacade.updatePlan(
      familyState.familyMembers.length > 1 ? Plans.FAMILY : Plans.INDIVIDUAL
    );

    const currentState = this.registrationFacade.currentState;
    const registration = currentState.registration;

    // On individual plan prepopulate from the registration facade
    if (
      currentState.isIndividualPlan ||
      (currentState.isFamilyPlan && this.selectedMember.is_family_representative)
    ) {
      this.prepopulate(registration);
    } else {
      // Prepopulate from selected member
      if (this.selectedMember) {
        if (this.selectedMember.id === registration.id) {
          this.prepopulate(registration, false);
        } else {
          const { first_name, last_name, email, person_type } = this.selectedMember;
          this.personType = person_type;

          this.prepopulate({ first_name, last_name, email }, false);
        }
      }
      // If order ID missing from facade, get family rep registration to assign ID
      if (this.addedJunior && !currentState.orderId) {
        const familyRepId = this.familyFacade.representative.id;
        if (familyRepId) {
          this.registrationService
            .getRegistration(familyRepId, false)
            .subscribe((familyRepRegistration: Registration) => {
              this.registrationFacade.updateOrderId(familyRepRegistration.order.order_id);
            });
        }
      }
    }

    // *Only needed if ride path re-introduced
    // if (currentState.isRenewingPremium && !this.addedJunior) {
    //   this.openPremiumUpgradeModal();
    // }

    if (registration.id) {
      // Remove validators if registration is in process
      this.removeFieldValidators(['password', 'password_confirmation']);
      this.removeValidationFromValidators(['profile_citizenship', 'profile_racing_nationality', 'verify_is_us_citizen', 'verify_is_usa_sporting_nationality', 'verify_is_dob_accurate', 'verify_is_sex_accurate', 'guardian_name', 'guardian_email', 'guardian_phone', 'guardian_sign_date']);
    }

    this.subscribeToMembershipType();
  }

  /** Removes validators from an array of fields */
  private removeFieldValidators(fields: string[]): void {
    fields.forEach((field) => {
      if (!!this.mf[field]) {
        this.mf[field].clearValidators();
        this.mf[field].updateValueAndValidity();
      }
    });
  }

  private removeValidationFromValidators(fields: string[]): void {
    fields.forEach((field) => {
      if (!!this.vf[field]) {
        this.vf[field].clearValidators();
        this.vf[field].updateValueAndValidity();
      }
    });
  }

  private addValidationFromValidators(fields: string[]): void {
    fields.forEach((field) => {
      if (!!this.vf[field]) {
        this.vf[field].setValidators([Validators.required]);
        this.vf[field].updateValueAndValidity();
      }
    });
  }

  private loadRaceGenders(): void {
    this.raceGenderService
      .getAll()
      .pipe(
        tap((raceGenders: RaceGender[]) => {
          this.raceGenders = raceGenders;
        })
      )
      .subscribe();
  }

  private subscribeToDemographics(): void {
    this.firstName.valueChanges.subscribe((first_name) => {
      if (!this.isAuthenticated) {
        this.statusIndicatorFirstName = first_name;
        this.registrationFacade.updateRegistrationByKey('first_name', first_name);
      }
      if (!this.isAuthenticated || this.addedJunior) {
        this.updateSelectedFamilyMember({ first_name });
      }
    });
    this.lastName.valueChanges.subscribe((last_name) => {
      if (!this.isAuthenticated) {
        this.statusIndicatorLastName = last_name;
        this.registrationFacade.updateRegistrationByKey('last_name', last_name);
      }
      if (!this.isAuthenticated || this.addedJunior) {
        this.updateSelectedFamilyMember({ last_name });
      }
    });
    this.email.valueChanges.subscribe((email) => {
      if (!this.isAuthenticated) {
        this.statusIndicatorEmail = email;
        this.registrationFacade.updateRegistrationByKey('email', email);
      }
      if (!this.isAuthenticated || this.addedJunior) {
        this.updateSelectedFamilyMember({ email });
      }
    });
    this.dob.valueChanges.subscribe(() => {
      if (!this.isAuthenticated) {
        if (!this.isJunior) {
          this.removeValidationFromValidators([
            'guardian_name',
            'guardian_email',
            'guardian_phone',
            'guardian_sign_date',
          ]);
          return;
        }
        this.addValidationFromValidators([
          'guardian_name',
          'guardian_email',
          'guardian_phone',
          'guardian_sign_date',
        ]);
      }
    });
  }

  private updateSelectedFamilyMember(data: any): void {
    if (this.addFamily || this.addedJunior) {
      this.familyFacade.updateCurrentFamilyMember({
        ...this.selectedMember,
        ...data,
      });
    }
  }

  private getValidForData(): any {
    const formData: any = {
      profile_citizenship: this.verificationForm.value.verify_is_us_citizen
        ? 'USA'
        : this.verificationForm.value.profile_citizenship,
      profile_racing_nationality: this.verificationForm.value.verify_is_usa_sporting_nationality
        ? 'USA'
        : this.verificationForm.value.profile_racing_nationality,
      verify_is_us_citizen: this.verificationForm.value.verify_is_us_citizen,
      verify_is_usa_sporting_nationality: this.verificationForm.value.verify_is_usa_sporting_nationality,
      verify_is_dob_accurate: this.verificationForm.value.verify_is_dob_accurate,
      verify_is_sex_accurate: this.verificationForm.value.verify_is_sex_accurate,
    };

    // if junior send the data needed for attestation_junior_guardian_confirmation_details to be saved to profile
    if (this.isJunior) {
      formData.attestation_junior_guardian_confirmation_details = JSON.stringify({
        guardian_name: this.verificationForm.value.guardian_name,
        guardian_email: this.verificationForm.value.guardian_email,
        guardian_phone: this.verificationForm.value.guardian_phone,
        guardian_sign_date: this.verificationForm.value.guardian_sign_date,
      });
    }

    return formData;
  }

  onSubmit(memberInfoForm: MemberInfo): void {
    if (this.memberForm.valid) {
      const memberValidData = this.getValidForData();
      let memberInfoCopy: MemberInfo = { ...memberInfoForm };
      if (!this.isAuthenticated) {
        memberInfoCopy = { ...memberInfoForm, ...memberValidData };
      }

      memberInfoCopy.membership_type = this.registrationFacade.isFamilyPlan()
        ? MembershipType.Family
        : MembershipType.Individual;

      this.isSubmitting = true;

      const registrationId = this.registrationFacade.registrationId;

      // If registration doesn't exist, register user, otherwise just update data
      if (!registrationId.length) {
        if (this.registrationFacade.getOrderId !== '') {
          // Set the order_id
          memberInfoCopy.order_id = this.registrationFacade.getOrderId;
        }

        if (this.isAuthenticated && !this.addedJunior) {
          this.newRegistration(memberInfoCopy);
        } else {
          this.checkForExistingAccount(memberInfoCopy);
        }
      } else {
        if (this.registrationFacade.getOrderId !== '') {
          // Set the order_id
          memberInfoCopy.order_id = this.registrationFacade.getOrderId;
        }

        this.updateMemberInfo(memberInfoCopy)
          .pipe(
            tap(() => {
              this.registrationFacade.updateMemberInfoFilled(true);
            }),
            takeUntil(this.unsubscribe$)
          )
          .subscribe();
      }
    } else {
      this.memberForm.markAllAsTouched();
    }
  }

  clearErrors(): void {
    this.errors$.next([]);
  }

  private createForms(): void {
    this.memberForm = this.fb.group(
      {
        first_name: ['', [Validators.required, Validators.pattern(nameRegex)]],
        last_name: ['', [Validators.required, Validators.pattern(nameRegex)]],
        email: ['', [Validators.required, Validators.email]],
        contact_emergency_contact_name: [''],
        contact_emergency_contact_number: ['', phoneNumberValidator()],
        inherit_email: [false],
        birthdate: [
          '',
          [Validators.required, Validators.minLength(10), Validators.pattern(digitRegex)],
        ],
        gender: [null],
        race_gender: [null, [Validators.required]],
        membership_type: [MembershipType.Individual],
        password: ['', [Validators.required, Validators.pattern(passwordRegex)]],
        password_confirmation: ['', Validators.required],
        is_auto_renewal: [false],
        has_spot_insurance: [false],
      },
      { validators: [confirmPasswordValidator] }
    );

    this.juniorSelectForm = this.fb.group({
      family_membership: [false],
      adults: [1, [Validators.required, Validators.min(1), Validators.max(2)]],
      // juniors: [0, [Validators.required, Validators.min(0), Validators.max(5)]],
    });

    this.verificationForm = this.fb.group({
      verify_is_us_citizen: [null, [Validators.required]],
      verify_is_usa_sporting_nationality: [null, [Validators.required]],
      verify_is_dob_accurate: [null, [Validators.required]],
      verify_is_sex_accurate: [null, [Validators.required]],
      profile_citizenship: [null],
      profile_racing_nationality: [null],
      guardian_name: [null, Validators.required],
      guardian_email: [null, Validators.required],
      guardian_phone: [null, phoneNumberValidator()],
      guardian_sign_date: [null, Validators.required],
    });

    // Watch for changes in birthday to show an error message
    this.dob.valueChanges.subscribe(() => this.validateBirthdate());
    // If no gender provided from existing profile, default to same as race gender selection
    this.raceGender.valueChanges.subscribe((gender: string) => {
      if (!this.profileGender) {
        this.mf.gender.setValue(gender);
      }
    });

    this.inheritEmail.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((inheritEmail) => {
      if (inheritEmail) {
        const familyRepresentative = this.familyFacade.representative;
        this.email.setValue(`${familyRepresentative.email} (inherited)`);
        this.email.disable();
      } else {
        this.email.setValue('');
        this.email.enable();
      }
    });

    this.verifyProfileCitizenship.valueChanges.subscribe((val) => {
      if (!val) {
        this.vf.profile_citizenship.setValidators([Validators.required]);
        this.vf.profile_citizenship.updateValueAndValidity();
      } else {
        this.vf.profile_citizenship.clearValidators();
        this.vf.profile_citizenship.updateValueAndValidity();
      }
    });

    this.verifyUsaSportingNationality.valueChanges.subscribe((val) => {
      if (!val) {
        this.vf.profile_racing_nationality.setValidators([Validators.required]);
        this.vf.profile_racing_nationality.updateValueAndValidity();
      } else {
        this.vf.profile_racing_nationality.clearValidators();
        this.vf.profile_racing_nationality.updateValueAndValidity();
      }
    });
  }

  juniorSelectValues($event: FormGroup): void {
    this.juniorSelectForm = $event;
    const membershipType =
      this.addFamily && this.juniors.value > 0 ? MembershipType.Family : MembershipType.Individual;
    this.mf.membership_type.setValue(membershipType);
  }

  private subscribeToMembershipType(): void {
    this.mf.membership_type.valueChanges.subscribe((type: MembershipType) => {
      this.updateMembershipPlan(type);
      this.validateBirthdate();
    });
  }

  readOnlyInput(controlName = ''): boolean {
    if (this.addedJunior) {
      return !!this.registrationId;
    } else {
      const profileKey = 'profile_' + controlName;
      return this.isAuthenticated && !!this.user[profileKey];
    }
  }

  private validateBirthdate(): void {
    if (!this.birthdate || this.birthdate.length < 10 || this.birthdate === 'Invalid date') {
      return;
    }

    const raceAge = this.raceAge;

    if (this.birthdate > this.maxDateString || this.birthdate < this.minDateString) {
      this.birthdayErrorMessage = 'Date out of bounds.';
      this.setBirthdateErrors();
    } else {
      this.dob.setErrors(null);
      this.birthdayErrorMessage = '';

      if ((raceAge && raceAge < 19) || raceAge === 0) {
        if (
          this.birthdate &&
          !this.addedJunior &&
          (this.mf.membership_type.value === MembershipType.Family ||
            !!this.jsf.family_membership.value)
        ) {
          this.birthdayErrorMessage = 'Adult memberships are for those 19 years of age or older';
          this.setBirthdateErrors();
          return;
        }
      }

      if (this.addedJunior && raceAge && raceAge >= 19) {
        this.birthdayErrorMessage = 'Junior memberships are for those under race age 19';
        this.setBirthdateErrors();
        return;
      }

      if (!!raceAge) {
        const personType = raceAge < 19 ? PersonTypes.JUNIOR : PersonTypes.ADULT;
        this.registrationFacade.updatePersonType(personType);
      }
    }
  }

  private setBirthdateErrors(): void {
    this.dob.setErrors({ incorrect: true });
  }

  get registrationId(): string {
    return this.registrationFacade.registrationId;
  }

  get birthdate(): string {
    return this.dob.value;
  }

  get raceAge(): number {
    return this.today.year - parseInt(this.birthdate.split('-')[0], 10);
  }

  get minBirthdate(): NgbDate {
    if (this.addedJunior) {
      const minYear = this.maxDate.year - 18;
      return new NgbDate(minYear, 1, 1);
    } else {
      return this.minDate;
    }
  }

  get disableContinue(): boolean {
    if (this.isAuthenticated) {
      return (
        this.memberForm.invalid ||
        this.isSubmitting ||
        this.isNavigating
      );
    }
    return (
      this.memberForm.invalid ||
      this.isSubmitting ||
      this.isNavigating ||
      this.verificationForm.invalid
    );
  }

  get submitBtnText(): string {
    return 'Continue';
  }

  get isRaceFlow(): boolean {
    return this.isRace || this.isJunior;
  }

  get showAddedJuniorSelect(): boolean {
    return this.isRace && !this.isJunior && !this.addedJunior && !this.ghostModeActive;
  }

  get currentStep(): MembershipStep {
    return this.addedJunior ? MembershipStep.JuniorMember : MembershipStep.MemberInfo;
  }

  get nextStep(): MembershipStep {
    return this.isRaceFlow ? MembershipStep.SelectOptions : MembershipStep.Checkout;
  }

  // Prepopulate with registration/custom data
  private prepopulate(registration: any, useProfile = true): void {
    if (!registration.id) {
      delete registration.has_spot_insurance;
    }
    this.memberForm.patchValue(registration);
    this.raceGender.setValue(registration.gender);
    if (this.addedJunior && registration.email === this.familyFacade.representative.email) {
      this.inheritEmail.setValue(true);
    }

    if (useProfile) {
      this.authService.currentUser$
        .pipe(
          tap((profile: Profile) => {
            if (!profile) {
              return;
            }

            if (profile.profile_id) {
              this.removeFieldValidators(['password', 'password_confirmation']);
              this.removeValidationFromValidators([
                'verify_is_us_citizen',
                'verify_is_usa_sporting_nationality',
                'verify_is_dob_accurate',
                'verify_is_sex_accurate',
                'guardian_name',
                'guardian_email',
                'guardian_phone',
                'guardian_sign_date',
              ]);
            }

            if (profile.profile_first_name) {
              this.memberForm.patchValue({
                first_name: profile.profile_first_name,
              });
            }

            if (profile.profile_last_name) {
              this.memberForm.patchValue({
                last_name: profile.profile_last_name,
              });
            }

            if (profile.profile_email) {
              this.memberForm.patchValue({
                email: profile.profile_email,
              });
            }

            if (profile.profile_sex) {
              this.profileGender = profile.profile_sex;
              this.memberForm.patchValue({
                gender: profile.profile_sex,
              });
            }

            if (profile.profile_contact) {
              const {
                contact_emergency_contact_name,
                contact_emergency_contact_number,
              } = profile.profile_contact;
              this.memberForm.patchValue({
                contact_emergency_contact_name,
                contact_emergency_contact_number,
              });
            }

            const binaryGenders = ['M', 'F'];
            const race_gender =
              profile.profile_race_gender ||
              (binaryGenders.includes(profile.profile_sex) ? profile.profile_sex : null);

            this.memberForm.patchValue({ race_gender });

            if (profile.profile_birthdate && profile.profile_birthdate !== '0000-00-00') {
              this.memberForm.patchValue({ birthdate: profile.profile_birthdate });
            }
          })
        )
        .subscribe();
    }
  }

  private newRegistration(memberInfoForm: MemberInfo): void {
    this.register(memberInfoForm)
      .pipe(
        tap(() => {
          this.registrationFacade.updateMemberInfoFilled(true);
        }),
        catchError((err) => {
          this.isSubmitting = false;
          return throwError(err);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private register(memberInfoForm: MemberInfo): Observable<Registration> {
    this.clearErrors();

    const memberInfoData = this.setMemberInfoData(memberInfoForm);

    return this.registrationService.createRegistrationAndLogin(memberInfoData).pipe(
      tap((registration: Registration) => {
        if (this.registrationFacade.isFamilyPlan()) {
          this.updateFamilyFacade(registration);
        }
        this.updateRegistrationFacadeAndContinue(registration);
      }),
      catchError((err: any) => {
        this.handleRegistrationError(err);
        return throwError(err);
      }),
      takeUntil(this.unsubscribe$)
    );
  }

  private updateMemberInfo(memberInfoForm: MemberInfo): Observable<Registration> {
    this.clearErrors();

    const memberInfoData = this.setMemberInfoData(memberInfoForm, true);

    return this.registrationService.updateMemberInfo(this.registrationId, memberInfoData).pipe(
      tap((registration: Registration) => {
        if (this.registrationFacade.isFamilyPlan()) {
          this.updateFamilyFacade(registration);
        }
        this.updateRegistrationFacadeAndContinue(registration);
      }),
      catchError((err: any) => {
        this.handleRegistrationError(err);
        return throwError(err);
      }),
      takeUntil(this.unsubscribe$)
    );
  }

  private setMemberInfoData(memberInfoForm: MemberInfo, isUpdate = false): MemberInfo {
    // Make a copy so parameter is not mutated
    const memberInfoCopy: MemberInfo = { ...memberInfoForm };

    if (
      isUpdate ||
      (this.isAuthenticated && !this.addedJunior) ||
      (this.addedJunior && this.selectedMember.id)
    ) {
      // Don't send password when updating
      delete memberInfoCopy.password;
      delete memberInfoCopy.password_confirmation;
    }
    // Don't send auto renewal value at this step if in race flow
    if (this.isRaceFlow) {
      delete memberInfoCopy.is_auto_renewal;
    }

    const currentState = this.registrationFacade.currentState;
    memberInfoCopy.is_premium = currentState.isRenewingPremium || this.isRaceFlow;

    if (!!this.inheritEmail.value) {
      memberInfoCopy.email = this.familyFacade.representative.email;
    }

    return memberInfoCopy;
  }

  private updateFamilyFacade(registration: Registration): void {
    const currentFamilyState = this.familyFacade.currentState;

    const familyMembers = [...currentFamilyState.familyMembers];
    const currentMemberIndex = familyMembers.indexOf(
      familyMembers.find((member) => member.is_selected)
    );

    familyMembers[currentMemberIndex].id = registration.id;
    familyMembers[currentMemberIndex].first_name = registration.first_name;
    familyMembers[currentMemberIndex].last_name = registration.last_name;
    familyMembers[currentMemberIndex].email = registration.email;

    const selectedMember = {
      ...currentFamilyState.selectedMember,
    };

    selectedMember.id = registration.id;
    selectedMember.first_name = registration.first_name;
    selectedMember.last_name = registration.last_name;
    selectedMember.email = registration.email;

    this.familyFacade.updateState({
      ...currentFamilyState,
      familyMembers,
      selectedMember,
    });
  }

  private updateRegistrationFacadeAndContinue(registration: Registration): void {
    this.registrationFacade.updateMemberInfoFilled(true);
    this.registrationFacade.updateRegistration(registration);
    this.registrationFacade.updateOrderId(registration.order.order_id);

    this.continueToNextStep();
  }

  private handleRegistrationError(err: any): void {
    const errors = err.errors || {};
    Object.values(errors).forEach((e: any) => {
      this.errors$.next(this.errors$.getValue().concat([e]));
    });
    this.isSubmitting = false;
  }

  private checkout(registration: Registration): void {
    const skus: string[] = [];
    const lineItems: any[] = [];

    // tslint:disable: cyclomatic-complexity
    this.shopifyService.getProducts().then((products: Product[]) => {
      try {
        const variantsBySku: AnalyserOptions = products
          .flatMap((product: Product) => product.variants)
          .reduce((acc: AnalyserOptions, productVariant: ProductVariant) => {
            acc[productVariant.sku] = productVariant;
            return acc;
          }, {});

        // These are required for the custom thank you page redirect to work
        const customAttributes: Array<any> = [
          {
            key: 'source',
            value: 'membership',
          },
          {
            key: 'membership_order_id',
            value: registration.order.order_id,
          },
        ];

        const note = `membership, ${MerchantAccountTag.Membership}`;

        skus.push('INV-STD');

        skus.forEach((sku) => {
          if (variantsBySku[sku]) {
            lineItems.push({
              variantId: variantsBySku[sku].id,
              quantity: 1,
            });
          } else {
            console.warn(`Missing Variant: SKU => '${sku}'`);
          }
        });

        return this.shopifyService
          .createCheckout(lineItems, customAttributes, note)
          .then((checkoutModel) => {
            const transaction = {
              shopify_uuid: checkoutModel.checkout.id,
              registrations: [{ id: registration.id }],
            };

            return new Promise((resolve, reject) => {
              this.transactionService.create(transaction).subscribe(
                () => {
                  // Redirect for updated checkout flow (usac-checkout)
                  window.location.href = this.transactionService.getCheckoutUrl(
                    checkoutModel.checkout.id,
                    {
                      membershipOrder: registration.order.order_id,
                      email: registration.email,
                      firstName: registration.first_name,
                      lastName: registration.last_name,
                    }
                  );
                  resolve();
                },
                () => {
                  reject();
                }
              );
            });
          });
      } catch (error) {
        return Promise.reject(error);
      }
    });
  }

  get disableBack(): boolean {
    return this.isSubmitting || this.isNavigating;
  }

  // toSpotInsurance(): void {
  //   this.isNavigating = true;
  //   this.router.navigate(['enrollment', 'race', 'spot-insurance']);
  // }

  toRaceDetails(): void {
    this.isNavigating = true;
    this.router.navigate(['enrollment', 'race', 'details']);
  }

  private continueToNextStep(): void {
    if (this.isRaceFlow) {
      const route = ['enrollment', 'race'];
      route.push(this.addedJunior ? 'junior-member-offers' : 'options');
      this.router.navigate(route);
    }
    // else {
    //   const route = ['enrollment', 'ride', 'spot-insurance'];
    //   this.router.navigate(route);
    // }
  }

  get selectedMember(): FamilyMember {
    return this.familyFacade.selectedMember;
  }

  get passwordFields(): boolean {
    this.juniorValidation();
    return (
      !this.isAuthenticated ||
      (this.addedJunior && !(this.selectedMember && this.selectedMember.id))
    );
  }

  juniorValidation(): void {
    this.vm$.subscribe((data) => {
      this.plan = data.registration.plan;
      this.person_type = data.registration.registration.person_type;
    });
  }

  showJuniorWaiver(registration: Registration): boolean {
    // Members under age 18, or 19 in Alabama, will need a parent/guardian signature
    const { current_age, state } = registration;
    return current_age < 18 || (current_age === 18 && state === 'AL');
  }

  signWaiver(registration: Registration): void {
    const modalOptions: NgbModalOptions = {
      centered: true,
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
    };
    const showJuniorWaiver = this.showJuniorWaiver(registration);
    const modal = showJuniorWaiver
      ? this.modal.open(JuniorWaiverModalComponent, modalOptions)
      : this.modal.open(AgreementModalComponent, modalOptions);

    // Set parameters
    modal.componentInstance.showLegalGuardianCopy = showJuniorWaiver;

    // Set callbacks
    modal.result.then(() => this.checkout(registration)).catch(() => { });
  }

  // *Only needed if ride path re-introduced
  // private openPremiumUpgradeModal(): void {
  //   if (!this.skipPremiumPrompt && !this.premiumModal) {
  //     this.premiumModal = this.modal.open(NeedPremiumModalComponent, {
  //       centered: true,
  //       size: 'xl',
  //       backdrop: 'static',
  //       keyboard: false,
  //     });
  //     this.premiumModal.componentInstance.personType = this.personType;
  //     this.premiumModal.result
  //       .then(() => {
  //         // Route to Race path if premium upgrade maintained
  //         if (this.isRide) {
  //           this.router.navigate(['enrollment', 'race', 'member-info'], {
  //             queryParams: { premiumPrompt: false },
  //           });
  //         }
  //       })
  //       .catch(() => {
  //         this.isLoading = true;
  //         const registrationId = this.registrationId || this.registrationFacade.registrationId;
  //         this.registrationService.optOutPremium(registrationId).subscribe((registration) => {
  //           if (registration.id) {
  //             this.registrationFacade.updateIsRenewingPremium(false);
  //             // Route to or stay on Ride path if member opts out of premium
  //             if (this.isRace) {
  //               this.router.navigate(['enrollment', 'ride', 'member-info']);
  //             } else {
  //               this.isLoading = false;
  //             }
  //           } else {
  //             this.isLoading = false;
  //           }
  //         });
  //       });
  //   }
  // }

  // private closePremiumUpgradeModal(): void {
  //   if (this.premiumModal) {
  //     this.premiumModal.close();
  //   }
  // }

  private updateMembershipPlan(type: MembershipType): void {
    // Switch family/individual plans
    if (type) {
      const plan = type === MembershipType.Family ? Plans.FAMILY : Plans.INDIVIDUAL;
      this.registrationFacade.updatePlan(plan);

      if (plan === Plans.FAMILY) {
        const family: FamilyMember[] = [];
        // create adult family members
        this.createAdultFamilyMember(family);

        // create junior family members
        this.createJuniorFamilyMembers(family);

        // set first family member as representative
        this.setFamilyRepresentative(family);

        this.familyFacade.updateFamilyMembership(family);
      } else {
        this.familyFacade.reset();
      }
    }
  }

  private setFamilyRepresentative(family: FamilyMember[]): void {
    family[0] = {
      person_type: PersonTypes.ADULT,
      email: this.email.value,
      first_name: this.firstName.value,
      last_name: this.lastName.value,
      is_family_representative: true,
      is_selected: true,
    };
  }

  private createJuniorFamilyMembers(family: FamilyMember[]): void {
    const familyMember: FamilyMember = {
      is_family_representative: false,
      is_selected: false,
      person_type: PersonTypes.JUNIOR,
      first_name: 'Junior',
      last_name: 'Member',
    };
    family.push(familyMember);
    // for (let i = 0; i < this.jsf.juniors.value; i++) {
    //   const familyMember: FamilyMember = {
    //     is_family_representative: false,
    //     is_selected: false,
    //     person_type: PersonTypes.JUNIOR,
    //     first_name: 'Junior Member',
    //     last_name: `${i + 1}`,
    //   };
    //   family.push(familyMember);
    // }
  }

  private createAdultFamilyMember(family: FamilyMember[]): void {
    const familyMember: FamilyMember = {
      is_family_representative: false,
      is_selected: false,
      person_type: PersonTypes.ADULT,
      first_name: this.firstName.value,
      last_name: this.lastName.value,
    };
    family.push(familyMember);
  }

  private checkForEnrollmentInProcess(memberInfo: MemberInfo): Observable<Registration> {
    return this.registrationService.getRegistrationByEmail(memberInfo.email).pipe(
      tap((registration: Registration) => {
        this.profileService
          .getMembershipOrder(registration.order.order_id)
          .subscribe((order: any) => {
            if (registration.membership_type && registration.membership_type === 2) {
              this.familyFacade.setStateFromLogin(registration, order);
            }

            this.registrationFacade.updateRegistration(registration);
            this.modal.open(EnrollmentInProccessModalComponent, {
              centered: true,
              size: 'xl',
            });
          });
      }),
      catchError((err: any) => {
        this.newRegistration(memberInfo);
        return of(err);
      }),
      takeUntil(this.unsubscribe$)
    );
  }

  private checkForExistingAccount(memberInfo: MemberInfo): void {
    const accountFilters = {
      profile_email: memberInfo.email,
      profile_first_name: memberInfo.first_name,
      profile_last_name: memberInfo.last_name,
      profile_birthdate: moment(memberInfo.birthdate).format('YYYY-MM-DD'),
    };

    this.profileService.checkAccount(accountFilters).subscribe((accounts) => {
      if (accounts && accounts.length) {
        // Account exists, open matching account modal to log in
        const matchingModalRef = this.modal.open(MatchingAccountModalComponent, {
          centered: true,
          size: 'xl',
        });

        matchingModalRef.componentInstance.matchingAccounts = accounts;
        matchingModalRef.componentInstance.addedJunior = this.addedJunior;
        matchingModalRef.componentInstance.email = this.email.value;

        matchingModalRef.result
          .then(() => {
            this.profileService.getCurrentUser().subscribe((user) => {
              // Use existing profile
              if (user) {
                this.profileService
                  .getMemberships(user.profile_id)
                  .subscribe((memberships: Membership[]) => {
                    if (memberships) {
                      const profileData = {
                        profile: user,
                        memberships,
                        password: this.mf.password.value,
                      };
                      this.handleRenewalModal(profileData, true);
                    }
                  });
              } else {
                this.isSubmitting = false;
              }
            });
          })
          .catch(() => (this.isSubmitting = false));
      } else {
        this.registrationFacade.updateIsRace(this.isRaceFlow);
        // No matching accounts found - continue
        this.checkForEnrollmentInProcess(memberInfo).subscribe();
      }
    });
  }

  private handleRenewalModal(data: any, accountCheck = false): void {
    // Grab the license
    const { memberships, password, profile } = data;

    if (!(profile.profile_has_admin_hold || profile.profile_is_suspended)) {
      // Sort the license by expiration date (desc)
      const latest = memberships.sort(
        (a: any, b: any) =>
          new Date(b.membership_expiration_date).getTime() -
          new Date(a.membership_expiration_date).getTime()
      )[0];

      // Membership Expired
      if (latest) {
        if (this.addedJunior) {
          // Fetch licenses for added junior (not included on limited profile resource)
          this.profileService.getLicenses(profile.profile_id).subscribe((licenses) => {
            this.openRenewalModal(profile, password, latest, licenses, accountCheck);
          });
        } else {
          this.openRenewalModal(profile, password, latest, null, accountCheck);
        }
      }

      if (!latest && accountCheck) {
        const payload = this.addedJunior
          ? { membershipType: 2, orderId: this.registrationFacade.currentState.orderId }
          : {};
        this.registrationService
          .registrationCreateFromProfile(profile.profile_id, payload)
          .subscribe(() => {
            this.onSubmit(this.memberForm.value);
          });
      }
    }
  }

  private openRenewalModal(
    profile: Profile,
    password: string,
    latest: Membership,
    licenses: License[],
    accountCheck: boolean
  ): void {
    const modalOptions: NgbModalOptions = {
      centered: true,
      size: 'xl',
    };

    // Prevent modal dimissal for added juniors if clicking outside modal
    if (this.addedJunior) {
      modalOptions.backdrop = 'static';
      modalOptions.keyboard = false;
    }

    this.renewalModal = this.modal.open(RenewMembershipModalComponent, modalOptions);

    // Pass profile ID and password
    this.renewalModal.componentInstance.profile = profile;
    this.renewalModal.componentInstance.password = password;
    this.renewalModal.componentInstance.currentMembership = latest;
    this.renewalModal.componentInstance.expirationDate = latest.membership_expiration_date;
    this.renewalModal.componentInstance.isExpired = latest.membership_status === 'Expired';
    this.renewalModal.componentInstance.addedJunior = this.addedJunior;
    this.renewalModal.componentInstance.licenses = licenses;
    this.renewalModal.componentInstance.createMethod =
      accountCheck && !this.addedJunior ? 'login' : 'profile';

    this.renewalModal.result
      .then((registration: Registration) => {
        // If triggered from matching account check, continue to next step
        if (accountCheck) {
          this.onSubmit(this.memberForm.value);
        } else {
          // Otherwise, remain on member info & populate family membership selection from registration
          if (registration.is_family && this.isRace) {
            this.jsf.family_membership.setValue(true);
          }
        }
      })
      .catch(() => {
        if (this.addedJunior) {
          // Removing added junior: Update main enrollment membership type to individual
          const representativeId = this.familyFacade.representative.id;
          this.registrationService
            .updateMembershipType(representativeId, Plans.INDIVIDUAL)
            .subscribe(() => {
              // Update family & registration facades
              this.familyFacade.setSelectedMember(this.familyFacade.representative);
              const familyMembersCopy = [...this.familyFacade.currentState.familyMembers];
              const juniorMemberIndex = familyMembersCopy.findIndex(
                (member) => !member.is_family_representative
              );
              familyMembersCopy.splice(juniorMemberIndex, 1);
              this.familyFacade.updateFamilyMembership(familyMembersCopy);
              this.registrationFacade.updatePlan(Plans.INDIVIDUAL);
              // Redirect to race details step
              this.toRaceDetails();
              // this.toSpotInsurance();
            });
        } else {
          // Logout user and clear state
          this.authService.doLogout().subscribe((resp) => {
            if (resp) {
              window.location.assign(environment.publicUrl);
            }
          });
        }
      });
  }
}
