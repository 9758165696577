import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Membership, Profile } from 'src/app/core';
import {
  BraintreeCustomer,
  BraintreePlan,
  BraintreeSubscription,
} from 'src/app/core/models/braintree';
import { AuthService, BraintreeService, ProfileService } from 'src/app/core/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ride-upgrade-options',
  templateUrl: './ride-upgrade-options.component.html',
  styleUrls: ['./ride-upgrade-options.component.scss'],
})
export class RideUpgradeOptionsComponent implements OnInit {
  loading = true;
  submitted = false;
  submitting = false;
  membershipsLoading = true;
  user: Profile;
  logoutRedirectUrl = environment.publicUrl;

  allMemberships: Membership[];
  braintreeCustomer: BraintreeCustomer;
  subscriptions: BraintreeSubscription[];
  plans: BraintreePlan[];

  upgradeData: any;
  openUpgradeConfirmation = false;
  successfulUpgrade = false;
  upgradeEligible = false;
  billingDateText: string;
  message: string;
  planIds = {
    RIDE: 'Ride Membership',
    'RACE-2021': 'Race Membership',
    'RACE-PLUS-2021': 'Race+ Membership',
  };
  updatedPlanName: string;

  racePlusBenefits = ['Includes Race License', 'Includes Spot Insurance'];
  raceBenefits = [
    'Discounted upgrade to Race membership',
    'Applicable for current Standard members',
  ];
  rideBenefits = ['Includes Spot Insurance', 'Access to USAC member benefits'];

  get loggedIn(): boolean {
    return !!this.user.profile_id;
  }

  get showLoader(): boolean {
    return this.loading || this.submitting;
  }

  constructor(
    private authService: AuthService,
    private braintreeService: BraintreeService,
    private profileService: ProfileService
  ) {}

  ngOnInit(): void {
    this.authService.currentUser$.subscribe((user: Profile) => {
      this.user = user;
      if (this.loggedIn) {
        this.getSubscriptionsAndPlans();
        this.profileService
          .getMemberships(this.user.profile_id)
          .subscribe((memberships: Membership[]) => {
            if (memberships) {
              this.allMemberships = memberships;
            }
            this.membershipsLoading = false;
          });
      }
    });
  }

  getSubscriptionsAndPlans(): void {
    const { profile_email, profile_first_name, profile_last_name, profile_id } = this.user;

    this.braintreeService
      .getCustomer({ profile_email, profile_first_name, profile_last_name, profile_id })
      .subscribe((resp) => {
        this.braintreeCustomer = resp.customer;
        if (this.braintreeCustomer && !this.subscriptions) {
          const extraParams = { profileId: this.user.profile_id };
          this.braintreeService.setBraintreePlansAndSubscriptions(
            this.braintreeCustomer,
            extraParams
          );
        }
      });
    this.braintreeService.subscriptions.subscribe((subscriptions) => {
      this.subscriptions = subscriptions;

      if (subscriptions) {
        const currentSubscription = subscriptions.find((subscription) =>
          /^INV-STD/.test(subscription.planId)
        );
        if (currentSubscription) {
          this.upgradeEligible = true;
          if (currentSubscription.nextBillingDate) {
            this.billingDateText = `Once confirmed, you will renew at your new rate on ${moment(
              currentSubscription.nextBillingDate.date
            ).format('MMM D, YYYY')}.`;
          }
        } else {
          const updatedSubscription = subscriptions.find((subscription) =>
            Object.keys(this.planIds).includes(subscription.planId)
          );
          if (updatedSubscription) {
            this.setSuccessMessage(updatedSubscription);
          } else {
            this.message = 'Only renewing Standard Ride members can upgrade.';
          }
        }
        this.loading = false;
      }
    });
    this.braintreeService.plans.subscribe((plans) => (this.plans = plans));
  }

  updateSubscriptionPlan(data: any): void {
    this.submitted = true;
    this.submitting = true;
    this.braintreeService
      .changePlan(this.subscriptions[0].id, {
        planId: data.sku,
        neverExpires: true,
      })
      .subscribe((updatedSubscription) => {
        if (updatedSubscription) {
          this.submitting = false;
          this.upgradeEligible = false;
          this.setSuccessMessage(updatedSubscription);
        }
      });
  }

  login(): void {
    this.authService.doLogin(window.location.href);
  }

  setSuccessMessage(subscription: BraintreeSubscription): void {
    const updatedPlanName = this.planIds[subscription.planId];
    this.message = `Your membership has been upgraded to ${updatedPlanName} and will be reflected when your current membership term ends.
    Thank you!`;
    this.successfulUpgrade = true;
  }

  toAdmin(): void {
    window.location.assign(`${environment.accountUrl}/profile/${this.user.profile_id}/membership`);
  }
}
