import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { OneDayMembership, SkillSummary } from '../models';

import { BaseService } from './base-service';

@Injectable({
  providedIn: 'root',
})
export class OneDayService extends BaseService {
  getOneDayMemberships(eventId: number, profileId: number): Observable<OneDayMembership[]> {
    return this.http
      .get<OneDayMembership[]>(
        this.url(`one_days?event_id=${eventId}&profile_id=${profileId}`),
        this.options
      )
      .pipe(
        tap(() => this.log('loaded one day memberships')),
        catchError(
          this.handleError<OneDayMembership[]>('getOneDayMemberships', [], { eventId })
        )
      );
  }

  getOneDayMembership(oneDayId: number): Observable<OneDayMembership> {
    return this.http.get<OneDayMembership>(this.url('one_days/' + oneDayId), this.options).pipe(
      tap(() => this.log('loaded one day membership')),
      catchError(
        this.handleError<OneDayMembership>('getOneDayMembership', null, { oneDayId })
      )
    );
  }

  createOneDayMembership(oneDay: OneDayMembership): Observable<OneDayMembership> {
    return this.http.post<OneDayMembership>(this.url('one_days'), oneDay, this.options).pipe(
      tap(() => this.log('created one day membership')),
      catchError(
        this.handleError<OneDayMembership>('createOneDayMembership', null, { oneDay })
      )
    );
  }

  updateOneDayMembership(oneDay: OneDayMembership): Observable<OneDayMembership> {
    return this.http
      .put<OneDayMembership>(this.url('one_days/' + oneDay.one_day_id), oneDay, this.options)
      .pipe(
        tap(() => this.log('updated one day membership')),
        catchError(
          this.handleError<OneDayMembership>('updateOneDayMembership', null, { oneDay })
        )
      );
  }

  checkSkillLevelByEvent(eventId: number): Observable<SkillSummary> {
    return this.http
      .get<SkillSummary>(this.url('check_skill_level_by/event/' + eventId), this.options)
      .pipe(
        tap(() => this.log('checked skill level by event')),
        catchError(
          this.handleError<SkillSummary>('checkSkillLevelByEvent', null, { eventId })
        )
      );
  }

  checkSkillLevelByDiscipline(disciplineId: number): Observable<SkillSummary> {
    return this.http
      .get<SkillSummary>(this.url('check_skill_level_by/discipline/' + disciplineId), this.options)
      .pipe(
        tap(() => this.log('checked skill level by discipline')),
        catchError(
          this.handleError<SkillSummary>('checkSkillLevelByDiscipline', null, { disciplineId })
        )
      );
  }
}
