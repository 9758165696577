import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { RegistrationFacade } from 'src/app/core/facades';
import { Guardian, GuardianRelationship } from 'src/app/core/models/registration.model';
import { RegistrationService } from 'src/app/core/services';

import { AgreementModalComponent } from '../agreement-modal/agreement-modal.component';

@Component({
  selector: 'app-junior-waiver-modal',
  templateUrl: './junior-waiver-modal.component.html',
  styleUrls: ['./junior-waiver-modal.component.scss'],
})
export class JuniorWaiverModalComponent {
  @Input() showLegalGuardianCopy: boolean;

  legalGuardianForm: FormGroup;

  relationships = [
    { id: 1, name: GuardianRelationship.PARENT },
    { id: 2, name: GuardianRelationship.GUARDIAN },
  ];

  isSubmitting = false;

  get f(): any {
    return this.legalGuardianForm.controls;
  }

  constructor(
    private fb: FormBuilder,
    private modal: NgbModal,
    private activeModal: NgbActiveModal,
    private registrationFacade: RegistrationFacade,
    private registrationService: RegistrationService
  ) {
    this.createLegalGuardianForm();
  }

  submitForm(): void {
    if (this.legalGuardianForm.valid) {
      this.isSubmitting = true;
      this.registrationService
        .updateJuniorGuardianInfo(this.registrationFacade.registrationId, {
          legal_guardian_first_name: this.f.first_name.value,
          legal_guardian_last_name: this.f.last_name.value,
          legal_guardian_email: this.f.email.value,
          legal_guardian_relationship: this.f.relationship.value,
        })
        .pipe(
          tap(() => {
            this.openJuniorAgreementModal(this.legalGuardianForm.value);
          }),
          catchError((error) => {
            console.log(error);
            this.isSubmitting = false;
            return throwError(error);
          })
        )
        .subscribe();
    } else {
      this.legalGuardianForm.markAllAsTouched();
    }
  }

  private createLegalGuardianForm(): void {
    this.legalGuardianForm = this.fb.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      relationship: [null, Validators.required],
    });
  }

  private openJuniorAgreementModal(guardian: Guardian): void {
    // TODO: likely not needed but keeping for backward compatibility
    this.registrationFacade.updateGuardian(guardian);

    const modal = this.modal.open(AgreementModalComponent, {
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
    });

    modal.componentInstance.guardian = guardian;
    modal.result.then(() => this.activeModal.close()).catch(() => {});
  }
}
