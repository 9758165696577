<div [ngClass]="{ 'header-container': !ghostModeActive, 'header-container-with-ghost-mode': ghostModeActive }">
  <nav class="navbar fixed-top header">
    <div class="header-text-container d-flex justify-content-start align-items-center">
      <img
        width="67"
        class="p-3 navbar-brand icon"
        src="assets/images/usac-logo.png"
        alt="USA Cycling"
        placement="bottom-left"
        [ngbPopover]="popContent"
        [autoClose]="'outside'"
        popoverClass="popover-custom"
        #popoverHome="ngbPopover"
      />
      <!-- Overlay direct link to USAC home (i.e. for post-purchase screens) -->
      <a *ngIf="!showLogoPrompt" [href]="usacMainUrl" class="position-absolute">
        <img
          width="67"
          class="p-3 navbar-brand icon"
          src="assets/images/usac-logo.png"
          alt="USA Cycling"
          placement="bottom-left"
        />
      </a>
      <h3 class="d-none d-md-block header-text m-0">USA Cycling {{ title }}</h3>
    </div>

    <ng-template #popContent
      ><p class="confirmReturnText">Are you sure you want to return to USACycling.com without completing your purchase?</p>
      <div class="row">
        <div class="col-6">
          <a class="btn btn-outline-secondary btn-block" (click)="leave()">Leave</a>
        </div>
        <div class="col-6">
          <button type="button" class="btn btn-secondary btn-block" (click)="popoverHome.toggle()">Stay</button>
        </div>
      </div>
    </ng-template>

    <div *ngIf="!cartHidden && !ghostModeActive" class="d-flex .d-sm-none my-cart-toggle navbar-toggler" (click)="changeCartState(true)">
      <img class="pl-3" height="20px" src="assets/images/shopping-cart.svg" alt="Shopping cart" />
      <p class="pr-3 nav-text">MY CART</p>
    </div>

    <div *ngIf="showLogout || ghostModeActive" class="d-flex .d-sm-none my-cart-toggle navbar-toggler">
      <div *appShowAuthed="true" (click)="confirmLogout()">
        <button
          type="button"
          class="login-button btn btn-link font-weight-bold text-white ml-1 p-0"
          (click)="confirmLogout()"
        >
          Log out
        </button>
      </div>
    </div>
  </nav>
  <app-admin-ghost-mode></app-admin-ghost-mode>
</div>

<app-cart *ngIf="standardCart"
  [cartHidden]="cartHidden"
  [cartOpened]="cartOpened"
  [startOverHidden]="startOverHidden"
  (cartClosedEmmiter)="changeCartState($event)"
></app-cart>
<!-- TODO: @Brennan - Add upgrade cart  -->
<app-premium-upgrade-cart *ngIf="upgradeCart"
  [cartHidden]="cartHidden"
  [cartOpened]="cartOpened"
  [hideCartContent]="hideCartContent"
  [startOverHidden]="startOverHidden"
  [user]="user"
  [rideTechUpgrade]="rideTechUpgrade"
  [spotInsuranceUpgrade]="spotInsuranceUpgrade"
  (cartClosedEmmiter)="changeCartState($event)"
></app-premium-upgrade-cart>
