import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import qs from 'qs';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import {
  Address,
  License,
  LicenseGroup,
  LicenseStatus,
  MemberInfo,
  Membership,
  MembershipOrder,
  Profile,
  ProfilePublic,
} from '../models';

import { BaseService } from './base-service';
import { LicenseService } from './license.service';

// import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends BaseService {
  constructor(
    protected http: HttpClient,
    protected cookie: CookieService,
    private licenseService: LicenseService
  ) {
    super(http, cookie);
  }

  licenseGroups: LicenseGroup[] = [];

  get hasGhostModeCookie(): boolean {
    return this.cookie.check('USACGHOST');
  }

  get ghostModeCookieData(): any {
    return JSON.parse(this.cookie.get('USACGHOST'));
  }

  get hasDeprecatedAuthToken(): any {
    return this.cookie.check('user_token');
  }

  deleteDeprecatedAuthToken(): void {
    this.cookie.delete('user_token');
  }

  createProfile(profileData: MemberInfo, autoLogin = false): Observable<Profile> {
    if (autoLogin) {
      Object.assign(profileData, { login: 'yes' });
    }

    const payload = { ...profileData, profile_password: profileData.password };

    return this.http.post<Profile>(this.url('signup'), payload, this.options).pipe(
      tap(() => {
        this.log('created profile');
      }),
      catchError(
        this.handleError<Profile>('createProfile', null, { profileData })
      )
    );
  }

  updateProfile(payload: any): Observable<Profile> {
    const profileId = payload.profile_id || 'self';
    return this.http.put<Profile>(this.url('profile/' + profileId), payload, this.options).pipe(
      tap(() => {
        this.log('updated profile');
      }),
      catchError(
        this.handleError<Profile>('updateProfile', null, { profileId })
      )
    );
  }

  getProfileById(profileId: number): Observable<Profile | ProfilePublic> {
    return this.http
      .get<Profile | ProfilePublic>(this.url('profile/' + profileId), this.options)
      .pipe(
        tap((_) => this.log('fetched profile')),
        catchError(
          this.handleError<Profile | ProfilePublic>('getProfile', null, { profileId })
        )
      );
  }

  getMemberByCompId(compId: number): Observable<Profile> {
    return this.http.get<Profile>(this.url('profile/member/' + compId), this.options).pipe(
      tap((_) => this.log('fetched member')),
      catchError(
        this.handleError<Profile>('getMemberByCompId', null, { compId })
      )
    );
  }

  getCurrentUser(): Observable<Profile> {
    return this.http.get<Profile>(this.url('profile/self'), this.options).pipe(
      tap((_) => {
        this.log('fetched current user');
      }),
      catchError(this.handleError<Profile>('getCurrentUser'))
    );
  }

  checkAccount(filters: any): Observable<ProfilePublic[]> {
    const queryString = qs.stringify(filters, { addQueryPrefix: true });
    return this.http
      .get<ProfilePublic[]>(this.url('check_profile' + queryString), this.options)
      .pipe(
        tap((_) => {
          this.log('check for matching accounts');
        }),
        catchError(
          this.handleError<ProfilePublic[]>('checkAccount', [], { filters })
        )
      );
  }

  ghostResetProfile(): Observable<Profile> {
    return this.http.get<Profile>(this.url('admin/ghost/reset'), this.options).pipe(
      tap(() => {
        this.log('ghost resetting profile');
      }),
      catchError(this.handleError<Profile>('ghostResetProfile', null, {}))
    );
  }

  getPrimaryAddress(profileId: number): Observable<Address> {
    return this.http.get<Address>(this.url(`profiles/${profileId}/addresses`), this.options).pipe(
      tap((_) => {
        this.log('fetched current profile addresses');
      }),
      catchError(
        this.handleError<Address>('getPrimaryAddress', null, { profileId })
      )
    );
  }

  getProfileAddress(profileId: number, addressId: number): Observable<Address> {
    return this.http
      .get<Address>(this.url(`profiles/${profileId}/addresses/${addressId}`), this.options)
      .pipe(
        tap((_) => {
          this.log('fetched current profile address');
        }),
        catchError(
          this.handleError<Address>('getProfileAddress', null, { profileId, addressId })
        )
      );
  }

  createProfileAddress(profileId: number, address: Address): Observable<Address> {
    return this.http
      .post<Address>(this.url(`profiles/${profileId}/addresses`), address, this.options)
      .pipe(
        tap((_) => {
          this.log('created profile address');
        }),
        catchError(
          this.handleError<Address>('createProfileAddress', null, { profileId, address })
        )
      );
  }

  resetProfilePassword(profile: any): Observable<any> {
    const birthdateSpliced = profile.profile_birthdate.split('/');
    const formatedBirthday = `${birthdateSpliced[2]}-${birthdateSpliced[0]}-${birthdateSpliced[1]}`;
    const payload = {
      profile_email: profile.profile_email,
      profile_birthdate: formatedBirthday,
    };
    return this.http
      .post<any>(this.url(`profile_send_password_reset_email`), payload, this.options)
      .pipe(
        tap(() => {
          this.log('Password Reset Email Sent');
        }),
        catchError(
          this.handleError<any>('resetProfilePassword', null, { payload })
        )
      );
  }

  updateProfileAddress(profileId: number, address: Address): Observable<Address> {
    return this.http
      .put<Address>(
        this.url(`profiles/${profileId}/addresses/${address.address_id}`),
        address,
        this.options
      )
      .pipe(
        tap((_) => {
          this.log('updated profile addresses');
        }),
        catchError(
          this.handleError<Address>('updateProfileAddress', null, { profileId, address })
        )
      );
  }

  getProfileMemberships(profileId: number): Observable<Membership[]> {
    return this.http
      .get<Membership[]>(this.url(`profiles/${profileId}/memberships`), this.options)
      .pipe(
        tap((_) => {
          this.log('fetched current profile address');
        }),
        catchError(
          this.handleError<Membership[]>('getProfileMemberships', null, { profileId })
        )
      );
  }

  /**
   * TODO: Finish full refactor/updates to standardize usage of api services
   *  - Usage of .pipe() vs .subscribe() etc
   */

  getLicenses(id: number, filters = {}): Observable<License[]> {
    const queryString = qs.stringify(filters, { addQueryPrefix: true });
    return this.http
      .get<License[]>(this.url('/profiles/' + id + '/licenses' + queryString), this.options)
      .pipe(
        tap((licenses) => {
          this.log('fetched licenses');
          this.licenseGroups = this.groupLicensesByTypeAndDate(licenses, true);
        }),
        catchError(
          this.handleError<License[]>('getLicenses', [], { id, filters })
        )
      );
  }

  getMemberships(id: number): Observable<Membership[]> {
    return this.http.get<Membership[]>(this.url('/profiles/' + id + '/memberships', this.options));
  }

  getOrder(id: string): Observable<any> {
    return this.http.get<any>(this.url('/order/' + id, this.options));
  }

  getMembershipOrder(id: string): Observable<MembershipOrder> {
    return this.http.get<MembershipOrder>(this.url('/order/' + id), this.options).pipe(
      tap((_) => {
        this.log('fetched membership order');
      }),
      catchError(
        this.handleError<Membership[]>('getMembershipOrder', null, { id })
      )
    );
  }

  getProfile(id?: number): Observable<Profile> {
    if (!id) {
      return this.http.get<Profile>(this.url('/profile/self', this.options));
    }

    return this.http.get<Profile>(this.url('/profile/' + id, this.options));
  }

  getPastCategories(request: { email: string; dob: string }): Observable<any[]> {
    return this.http
      .get<any[]>(
        this.url(
          `/profile/familymembers/categories/clubs?email=${request.email}&dob=${request.dob}`
        )
      )
      .pipe(
        tap((_) => {
          this.log('fetched past categories');
        }),
        catchError(
          this.handleError<any[]>('getPastCategories', [], { request })
        )
      );
  }

  groupLicensesByTypeAndDate(licenses: License[], latestOnly = false): LicenseGroup[] {
    let groups: LicenseGroup[] = [];
    licenses.forEach((license) => {
      const licenseExpiration = Date.parse(license.expiration_date);
      const range = 1000 * 60 * 60 * 24 * 7; // TODO: @Charlie - What is a reasonable date range to use here?
      const groupMatch = groups.find(
        (group) =>
          group.type === license.license_type &&
          Math.abs(Date.parse(group.expiration) - licenseExpiration) <= range
      );

      if (groupMatch) {
        const groupExpiration = Date.parse(groupMatch.expiration);
        // If any licenses in the group are unresolved, group is unresolved
        if ([LicenseStatus.PENDING, LicenseStatus.SUSPENDED].includes(license.status)) {
          groupMatch.status = license.status;
        }
        if (licenseExpiration < groupExpiration) {
          groupMatch.expiration = license.expiration_date;
        }
        groupMatch.licenses.push(license);
      } else {
        this.addLicenseGroup(license, groups);
      }
    });
    groups.sort((a, b) => Date.parse(b.expiration) - Date.parse(a.expiration));

    if (latestOnly) {
      const latestGroups: LicenseGroup[] = [];
      // console.log(groups);
      groups.forEach((group) => {
        if (
          this.licenseService.licenseGroupStatusText(group) !== 'Expired' ||
          !latestGroups.find((g) => g.type === group.type)
        ) {
          latestGroups.push(group);
        }
      });
      groups = latestGroups;
    }

    return groups;
  }

  private addLicenseGroup(license: License, groups: LicenseGroup[]): void {
    groups.push({
      type: license.license_type,
      type_name: license.license_type_name,
      status: license.status,
      expiration: license.expiration_date,
      licenses: [license],
    });
  }
}
