import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import qs from 'qs';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import {
  BraintreeAddress,
  BraintreeCustomer,
  BraintreeCustomerResponse,
  BraintreePlan,
  BraintreeSubscription,
  BraintreeSubscriptionsResponse,
} from '../models/braintree';

import { BaseService } from './base-service';
import { ShopifyService } from './shopify.service';

@Injectable({
  providedIn: 'root',
})
export class BraintreeService extends BaseService {
  constructor(
    protected cookie: CookieService,
    protected http: HttpClient,
    protected router: Router,
    protected shopifyService: ShopifyService
  ) {
    super(http, cookie);
  }

  customer: BehaviorSubject<BraintreeCustomer> = new BehaviorSubject(null);
  clientToken: BehaviorSubject<string> = new BehaviorSubject(null);
  subscriptions: BehaviorSubject<BraintreeSubscription[]> = new BehaviorSubject(null);
  plans: BehaviorSubject<BraintreePlan[]> = new BehaviorSubject(null);

  email: string = null;
  firstName: string = null;
  lastName: string = null;
  streetAddress: string = null;
  extendedAddress: string = null;
  locality: string = null;
  region: string = null;
  postalCode: string = null;
  phone: string = null;
  countryName = 'United States';
  countryCodeAlpha2 = 'US';

  get address(): BraintreeAddress {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      streetAddress: this.streetAddress,
      extendedAddress: this.extendedAddress,
      locality: this.locality,
      region: this.region,
      postalCode: this.postalCode,
      countryName: this.countryName,
      countryCodeAlpha2: this.countryCodeAlpha2,
    };
  }

  get addressIncomplete(): boolean {
    const requiredFields = [
      'firstName',
      'lastName',
      'streetAddress',
      'locality',
      'postalCode',
      'countryName',
    ];
    for (const [key, value] of Object.entries(this.address)) {
      if (requiredFields.includes(key) && !value) {
        return true;
      }
    }
    return false;
  }

  getCustomer(customerParams = {}): Observable<BraintreeCustomerResponse> {
    const queryString = qs.stringify(customerParams, { addQueryPrefix: true });
    return this.http
      .get<BraintreeCustomerResponse>(this.url('braintree/customer' + queryString), this.options)
      .pipe(
        tap((resp) => {
          this.log('fetched Braintree customer');
          const customer = new BraintreeCustomer(resp.customer);
          this.customer.next(customer);
          this.clientToken.next(resp.clientToken);

          this.email = customer.email;
          this.firstName = customer.firstName;
          this.lastName = customer.lastName;
          this.phone = customer.phone;
        }),
        catchError(this.handleError<BraintreeCustomerResponse>('getCustomer', null, {}))
      );
  }

  getSubscriptions(customerParams = {}): Observable<BraintreeSubscriptionsResponse> {
    const queryString = qs.stringify(customerParams, { addQueryPrefix: true });
    return this.http
      .get<BraintreeSubscriptionsResponse>(
        this.url('braintree/subscriptions' + queryString),
        this.options
      )
      .pipe(
        tap((_) => this.log('fetched Braintree subscriptions')),
        catchError(this.handleError<BraintreeSubscriptionsResponse>('getSubscriptions', null, {}))
      );
  }

  updateSubscription(
    subscriptionId: string,
    payload: any,
    customerParams = {}
  ): Observable<BraintreeSubscriptionsResponse> {
    const queryString = qs.stringify(customerParams, { addQueryPrefix: true });
    return this.http
      .put<BraintreeSubscriptionsResponse>(
        this.url(`braintree/subscriptions/${subscriptionId}` + queryString),
        payload,
        this.options
      )
      .pipe(
        tap((_) => this.log('updated Braintree subscription')),
        catchError(this.handleError<BraintreeSubscriptionsResponse>('updateSubscription', null, {}))
      );
  }

  changePlan(
    subscriptionId: string,
    payload: any,
    customerParams = {}
  ): Observable<BraintreeSubscription> {
    const queryString = qs.stringify(customerParams, { addQueryPrefix: true });
    return this.http
      .put<BraintreeSubscription>(
        this.url(`braintree/subscriptions/${subscriptionId}/change_plan` + queryString),
        payload,
        this.options
      )
      .pipe(
        tap((_) => this.log('updated Braintree subscription')),
        catchError(this.handleError<BraintreeSubscription>('changePlan', null, {}))
      );
  }

  // setBraintreeCustomer(customerParams = {}) {
  //   const merchantAccountId = ''; // this.merchantAccountId;
  //   const data = merchantAccountId ? Object.assign({}, customerParams, { merchantAccountId }) : customerParams;
  //   this.getCustomer(data).subscribe(resp => {
  //     if (resp) {
  //       const customer = new BraintreeCustomer(resp.customer);
  //       this.customer.next(customer);
  // this.clientToken.next(resp.clientToken);

  // this.email = customer.email;
  // this.firstName = customer.firstName;
  // this.lastName = customer.lastName;
  // this.phone = customer.phone;
  //     } else {
  //       this.router.navigate(['404']);
  //     }
  //   });
  // }

  // assignAddress(params: any) {
  //   Object.keys(this.address).forEach(key => {
  //     this[key] = params[key];
  //   });
  // }

  setBraintreePlansAndSubscriptions(customer: BraintreeCustomer, customerParams = {}): void {
    const { id, firstName, lastName, email } = customer;
    const payload = { id, firstName, lastName, email, ...customerParams };
    this.getSubscriptions(payload).subscribe((resp) => {
      this.plans.next(resp.plans);
      this.subscriptions.next(resp.subscriptions);
    });
  }
}
