import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { LicenseGroup, LicenseStatus, LicenseTypeId, Profile } from 'src/app/core/models';
import { LicenseService } from 'src/app/core/services';

@Component({
  selector: 'app-license-card',
  templateUrl: './license-card.component.html',
  styleUrls: ['./license-card.component.scss'],
})
export class LicenseCardComponent implements OnInit {
  @Input() user: Profile;
  @Input() licenseGroup: LicenseGroup;
  @Input() premiumMembership: boolean;
  @Input() useDateRange: string;
  @Input() showSafeSport = true;
  @Input() inPostConfirmation: boolean;

  clubs: string[] = [];
  disciplineCategories: any[] = [];
  currentYear = new Date().getFullYear();

  constructor(private licenseService: LicenseService) { }

  ngOnInit(): void {
    this.setClubsAndCategories();
  }

  private setClubsAndCategories(): void {
    const clubs: string[] = [];
    const categories: any[] = [];

    if (this.licenseGroup.licenses) {
      this.licenseGroup.licenses.forEach((license) => {
        if (license.club_name) {
          clubs.push(license.club_name);
        }
        if (license.team_name) {
          clubs.push(license.team_name);
        }
        if (license.discipline_name) {
          categories.push({ discipline: license.discipline_name, category: license.category_name });
        }
      });
      this.clubs = [...new Set(clubs)];
      this.disciplineCategories = categories;
    }
  }

  get statusClass(): string {
    return this.licenseService.licenseGroupStatusClass(this.licenseGroup);
  }

  get statusText(): string {
    return this.licenseService.licenseGroupStatusText(this.licenseGroup);
  }

  get iconClass(): string {
    return this.licenseService.licenseGroupIconClass(this.licenseGroup);
  }

  get isPending(): boolean {
    return this.licenseGroup.status === LicenseStatus.PENDING;
  }

  get isSuspended(): boolean {
    return this.licenseGroup.status === LicenseStatus.SUSPENDED;
  }

  get isResolved(): boolean {
    return this.licenseGroup.status === LicenseStatus.RESOLVED || !this.licenseGroup.status;
  }

  get isRideMembership(): boolean {
    return this.licenseGroup.type === LicenseTypeId.RIDE_MEMBERSHIP;
  }

  get licenseHeaderColor(): string {
    return this.isRideMembership ? '#c69214' : '#c3211e';
  }

  get state(): string {
    return this.user?.address?.address_state || this.user?.address?.address_province;
  }

  get isInternational(): boolean {
    return (
      this.licenseGroup.type_name.includes('International') ||
      this.licenseGroup.type_name.includes('UCI')
    );
  }

  get raceAge(): number {
    return this.user.profile_race_age;
  }

  get licenseStripeTitle(): string {
    return this.isPending || this.isSuspended ? 'TEMPORARY' : 'EXPIRES:';
  }

  get showClubs(): boolean {
    return !!this.clubs.length;
  }

  get showDisciplineCategories(): boolean {
    return !!this.disciplineCategories.length && !this.isRideMembership;
  }

  get showExtraDetails(): boolean {
    return this.showClubs || this.showDisciplineCategories;
  }

  get licenseTypeName(): string {
    return this.isRideMembership ? 'NON-COMPETITIVE' : this.licenseGroup.type_name;
  }

  get licenseDateRange(): string {
    if (this.inPostConfirmation) {
      const startDate = moment(this.licenseGroup.expiration).subtract(1, 'year').format('MM/DD/YY');
      const endDate = moment(this.licenseGroup.expiration).format('MM/DD/YY');

      return `${startDate} - ${endDate}`;
    }
    if (this.useDateRange) {
      const startDate = moment().format('MM/DD/YY');
      const endDate = moment(this.licenseGroup.expiration).format('MM/DD/YY');

      return `${startDate} - ${endDate}`;
    }

    return moment(this.licenseGroup.expiration).format('MM/DD/YYYY');
  }
}
