export enum MembershipOption {
  Ride = 'ride',
  Race = 'race',
  Supporting = 'supporting',
  RacePlus = 'raceplus',
}

export enum MembershipStep {
  MemberInfo = 'member-info',
  SelectOptions = 'select-options',
  RaceDetails = 'race-details',
  JuniorMember = 'junior-member',
  UpgradeOptions = 'upgrade-options',
  // SpotInsurance = 'spot-insurance',
  // JuniorSpotInsurance = 'junior-spot-insurance',
  Checkout = 'checkout',
  PostPurchase = 'post-purchase',
}
