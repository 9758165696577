import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-license-step-progress-bar',
  templateUrl: './license-step-progress-bar.component.html',
  styleUrls: ['./license-step-progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LicenseStepProgressBarComponent {
  @Input() currentStep: string;
  @Input() previousStep: string;
  @Input() nextStep: string;
  @Input() customLinkClass: string;
}
