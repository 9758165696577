import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/core';
import {
  AddOnsFacade,
  FamilyMembershipFacade,
  Plans,
  RegistrationFacade,
} from 'src/app/core/facades';
import {
  AddOnItem,
  FamilyMember,
  Membership,
  MembershipStep,
  MerchantAccountTag,
  PersonTypes,
  Product,
  Profile,
  Registration,
} from 'src/app/core/models';
import {
  MembershipOrdersService,
  ProfileService,
  RegistrationService,
  ShopifyService,
  TransactionService,
} from 'src/app/core/services';
import { ProductVariant } from 'src/app/core/services/shopify.types';

import { AgreementModalComponent } from '../agreement-modal/agreement-modal.component';
import { JuniorWaiverModalComponent } from '../junior-waiver-modal/junior-waiver-modal.component';
import { MemberOfferModalComponent } from '../member-offer-modal/member-offer-modal.component';

@Component({
  selector: 'app-member-offers',
  templateUrl: './member-offers.component.html',
  styleUrls: ['./member-offers.component.scss'],
})
export class MemberOffersComponent implements OnInit, OnDestroy {
  registrationData: any;
  user: Profile;

  registrationId: string;
  registration: Registration;
  isAuthenticated = false;

  isLoading = true;
  userLoaded = false;
  membershipsLoading = true;
  isNavigating = false;
  isSubmitting = false;
  errors$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  addedJunior: boolean;
  products: Product[] = [];
  selectedVariants: ProductVariant[] = [];
  skuPrefixes: any;
  reportingTags: string[] = [];
  allMemberships: Membership[];
  addToCart = false;
  cartItems: any[] = [];
  hasSpotInsurance: boolean;

  loading = true;

  racePlusBenefits = ['Get $0 deductible on-the-bike accident coverage for just $240/year'];

  get Step(): typeof MembershipStep {
    return MembershipStep;
  }

  get isJunior(): boolean {
    return this.registration && this.registration.person_type === PersonTypes.JUNIOR;
  }

  get isAdult(): boolean {
    return this.registration && this.registration.person_type === PersonTypes.ADULT;
  }

  get isFamily(): boolean {
    return this.registration && this.registration.is_family;
  }

  get addOnItems(): AddOnItem[] {
    return this.isJunior ? this.addOnsFacade.junior.items : this.addOnsFacade.adult.items;
  }

  get ghostModeActive(): boolean {
    return this.profileService.hasGhostModeCookie;
  }

  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private addOnsFacade: AddOnsFacade,
    private authService: AuthService,
    private familyFacade: FamilyMembershipFacade,
    private profileService: ProfileService,
    private registrationFacade: RegistrationFacade,
    private registrationService: RegistrationService,
    private modal: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private shopifyService: ShopifyService,
    private location: Location,
    private transactionService: TransactionService,
    private membershipOrderService: MembershipOrdersService
  ) {
    this.registrationId = this.registrationFacade.registrationId;
    this.registrationData = this.registrationFacade.registration$;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    const snapshot = this.route.snapshot;
    const routeData = snapshot.data;
    this.addedJunior = !!routeData.addedJunior;

    this.authService.isAuthenticated$.subscribe((isAuthenticated) => {
      this.isAuthenticated = isAuthenticated;
    });

    this.authService.currentUser$
      .pipe(
        tap((profile: Profile) => {
          if (!profile) {
            return;
          }
          this.user = profile;
          if (profile.profile_id) {
            this.profileService
              .getMemberships(profile.profile_id)
              .subscribe((memberships: Membership[]) => {
                if (memberships) {
                  this.allMemberships = memberships;
                }
                this.membershipsLoading = false;
              });
          }
          this.userLoaded = true;
        })
      )
      .subscribe();

    this.loadRegistration();
    this.loadUserAndProducts();
  }

  get isFamilyAdult(): boolean {
    return this.isFamily && !this.addedJunior;
  }

  private loadRegistration(): void {
    const registrationId = this.registrationFacade.registrationId;
    if (registrationId) {
      this.registrationId = registrationId;
      this.registrationService
        .getRegistration(registrationId, false)
        .subscribe((registration: Registration) => {
          if (registration) {
            this.setRegistrationAndFormValues(registration);
          } else {
            this.getRegistrationByProfile();
          }
        });
    } else {
      this.getRegistrationByProfile();
    }
  }

  private getRegistrationByProfile(): void {
    // If registration removed from local storage, retrieve latest enrollment using authed user's profile ID
    if (this.user) {
      this.registrationService
        .getRegistrationByProfile(this.user.profile_id)
        .pipe(
          tap((reg: Registration) => {
            this.setRegistrationAndFormValues(reg);
          }),
          catchError((err) => {
            // Registration not found - log out and return to intro form
            this.authService.doLogout();
            return throwError(err);
          })
        )
        .subscribe();
    }
  }

  private setRegistrationAndFormValues(registration: Registration): void {
    this.registration = registration;
    this.updateEnrollmentId(registration);
    this.registrationFacade.updateRegistration(registration);
    this.registrationFacade.updatePlan(registration.is_family ? Plans.FAMILY : Plans.INDIVIDUAL);
    this.hasSpotInsurance = this.registration.has_spot_insurance;
    this.isLoading = false;
  }

  private updateEnrollmentId(registration: Registration): void {
    if (this.isAdult) {
      this.addOnsFacade.updateAdultEnrollmentId(registration.id);
    } else if (this.isJunior) {
      this.addOnsFacade.updateJuniorEnrollmentId(registration.id);
    }
  }

  back(): void {
    if (this.inEarlyRenewalFlow) {
      this.registrationFacade.reset();
      this.location.back();
    } else {
      this.toSelectOptions();
    }
  }

  continue(): void {
    if (this.inEarlyRenewalFlow) {
      this.toCheckout();
    } else {
      this.toRaceDetails();
    }
  }

  clearErrors(): void {
    this.errors$.next([]);
  }

  private toSelectOptions(): void {
    this.isNavigating = true;
    const route = this.addedJunior
      ? ['enrollment', 'race', 'junior-member-info']
      : ['enrollment', 'race', 'options'];
    this.router.navigate(route);
  }

  private toRaceDetails(): void {
    const route = this.addedJunior
      ? ['enrollment', 'race', 'junior-details']
      : ['enrollment', 'race', 'details'];
    this.router.navigate(route);
  }

  get currentStep(): MembershipStep {
    return this.addedJunior ? MembershipStep.JuniorMember : MembershipStep.UpgradeOptions;
  }

  get nextStep(): MembershipStep {
    return this.addedJunior ? MembershipStep.Checkout : MembershipStep.RaceDetails;
  }

  private loadUserAndProducts(): void {
    this.authService.currentUser$.subscribe((user: Profile) => {
      this.user = user;
      if (user.profile_id) {
        this.loadProducts();
        this.profileService
          .getMemberships(this.user.profile_id)
          .subscribe((memberships: Membership[]) => {
            if (memberships) {
              this.allMemberships = memberships;
            }
            this.membershipsLoading = false;
          });
      } else {
        this.membershipsLoading = false;
      }
    });
  }

  private loadProducts(): void {
    if (this.ghostModeActive) {
      this.loading = false;
    } else {
      this.shopifyService.getProducts(['member-offers']).then((products) => {
        this.products = this.shopifyService.sortProducts(products);
        this.loading = false;
      });
    }
  }

  toggleRacePlus(): void {
    this.hasSpotInsurance = !this.hasSpotInsurance;
    this.updateSpotOptions({ has_spot_insurance: this.hasSpotInsurance });
  }

  updateAddOnItems(item: any): void {
    if (this.isAdult) {
      const itemSelected = this.addOnsFacade.adult.items.some(
        (addOnItems) => addOnItems.sku === item.variant.sku
      );
      if (itemSelected) {
        this.addOnsFacade.removeAdultAddOnItems(item);
      } else {
        this.addOnsFacade.addAdultAddOnItems(item);
      }
    } else if (this.isJunior) {
      const itemSelected = this.addOnsFacade.junior.items.some(
        (addOnItems) => addOnItems.sku === item.variant.sku
      );

      if (itemSelected) {
        this.addOnsFacade.removeJuniorAddOnItems(item);
      } else {
        this.addOnsFacade.addJuniorAddOnItems(item);
      }
    }
  }

  private updateSpotOptions(values: any): void {
    this.registrationService
      .updateOptions(this.registrationId, values)
      .subscribe((updatedRegistration) => {
        this.registration = updatedRegistration;
        this.updateFamilyFacadeSpot();
      });
  }

  get inEarlyRenewalFlow(): boolean {
    const queryParams = this.route.snapshot.queryParams;
    return queryParams.renew;
  }

  private updateFamilyFacadeSpot(): void {
    const { has_spot_insurance } = this.registration;
    this.isJunior
      ? this.familyFacade.updatejuniorHasSpot(has_spot_insurance)
      : this.familyFacade.updateAdultHasSpot(has_spot_insurance);
  }

  get btnClass(): string {
    return !!this.hasSpotInsurance ? 'btn-primary' : 'btn-secondary';
  }

  get btnText(): string {
    return !!this.hasSpotInsurance ? 'Remove' : 'Add to Cart';
  }

  openDescriptionModal(product: Product): void {
    const memberOfferModalRef = this.modal.open(MemberOfferModalComponent, {
      centered: true,
      size: 'lg',
    });

    memberOfferModalRef.componentInstance.product = product;
    memberOfferModalRef.componentInstance.addOnItems = this.addOnItems;
    memberOfferModalRef.componentInstance.cartUpdate.subscribe((item: any) =>
      this.updateAddOnItems(item)
    );
  }

  toCheckout(): void {
    // ADULT w/ ADDED JUNIOR NEXT
    this.signWaiver(this.registration);
  }

  showJuniorWaiver(registration: Registration): boolean {
    // Members under age 18, or 19 in Alabama, will need a parent/guardian signature
    const { current_age, state } = registration;
    return current_age < 18 || (current_age === 18 && state === 'AL');
  }

  private signWaiver(registration: Registration): void {
    if (this.ghostModeActive) {
      this.proceedToNextStep(registration);
    } else if (!registration.sig_id) {
      const modalOptions: NgbModalOptions = {
        centered: true,
        size: 'xl',
        backdrop: 'static',
        keyboard: false,
      };
      const showJuniorWaiver = this.showJuniorWaiver(registration);
      const modal = showJuniorWaiver
        ? this.modal.open(JuniorWaiverModalComponent, modalOptions)
        : this.modal.open(AgreementModalComponent, modalOptions);

      // Set parameters
      modal.componentInstance.showLegalGuardianCopy = showJuniorWaiver;

      // Set callbacks
      modal.result.then(() => this.proceedToNextStep(registration)).catch(() => { });
    } else {
      this.proceedToNextStep(registration);
    }
  }

  private proceedToNextStep(registration: Registration): void {
    console.log('registration', registration);
    if (this.isFamilyAdult) {
      // ADULT w/ ADDED JUNIOR NEXT
      // Redirect to member-info step
      this.isSubmitting = false;
      this.router.navigate(['enrollment', 'race', 'junior-member-info']);
    } else {
      // SINGLE MEMBER or ADDED JUNIOR - Send to checkout
      if (this.ghostModeActive) {
        this.isSubmitting = false;
        this.router.navigate(['enrollment', 'admin-membership-payment']);
      } else if (this.addedJunior) {
        const familyRepId = this.familyFacade.representative.id;
        this.registrationService
          .getRegistration(familyRepId)
          .subscribe((familyRepReg: Registration) => {
            if (familyRepReg) {
              this.checkout(familyRepReg);
            } else {
              this.checkout(registration);
            }
          });
      } else {
        this.checkout(registration);
      }
    }
  }

  private checkout(registration: Registration): void {
    const skus: string[] = [];
    const lineItems: any[] = [];

    // tslint:disable: cyclomatic-complexity
    this.shopifyService.getProducts().then((products: Product[]) => {
      try {
        const variantsBySku: any = products
          .flatMap((product: Product) => product.variants)
          .reduce((acc: any, productVariant: any) => {
            acc[productVariant.sku] = productVariant;
            return acc;
          }, {});

        // These are required for the custom thank you page redirect to work
        const customAttributes: any[] = [
          {
            key: 'source',
            value: 'membership',
          },
          {
            key: 'membership_order_id',
            value: registration.order.order_id,
          },
        ];

        const note = `membership, ${MerchantAccountTag.Membership}, early-renwal`;
        console.log(registration);

        return this.membershipOrderService
          .getById(registration.order.order_id)
          .subscribe((order) => {
            if (order) {
              order.enrollments.forEach((enrollment) => {
                console.log(enrollment);
                let licenseSku: string;
                const {
                  has_spot_insurance,
                  intl_bmx_category,
                  is_collegiate_cyclist,
                  is_international,
                  person_type,
                  is_professional,
                } = enrollment;

                if (is_international) {
                  licenseSku = 'INT';
                } else if (is_professional) {
                  licenseSku = 'PRO';
                } else {
                  licenseSku = 'RACE';
                }

                if (has_spot_insurance) {
                  licenseSku += '-PLUS';
                }
                if (is_collegiate_cyclist && !is_international && !is_professional) {
                  licenseSku += '-COL';
                } else if (person_type === PersonTypes.JUNIOR && !is_professional) {
                  licenseSku += '-JUN';
                }
                if (is_international && intl_bmx_category) {
                  licenseSku += '-BMX';
                }

                skus.push(licenseSku);
                // TODO: @Brennan - Include collegiate add-on if not RACE?
              });

              this.addOnItems.forEach((addOn) => skus.push(addOn.sku));

              skus.forEach((sku) => {
                const variant = variantsBySku[sku];
                if (variant) {
                  const existingLineItem = lineItems.find((item) => item.variantId === variant.id);
                  if (existingLineItem) {
                    existingLineItem.quantity += 1;
                  } else {
                    lineItems.push({
                      variantId: variantsBySku[sku].id,
                      quantity: 1,
                    });
                  }
                } else {
                  console.warn(`Missing Variant: SKU => '${sku}'`);
                }
              });

              return this.createCheckoutAndTransaction(
                lineItems,
                customAttributes,
                note,
                registration
              );
            } else {
              return Promise.reject();
            }
          });
      } catch (error) {
        return Promise.reject(error);
      }
    });
  }

  private async createCheckoutAndTransaction(
    lineItems: any[],
    customAttributes: any[],
    note: string,
    registration: Registration
  ): Promise<any> {
    const checkoutModel = await this.shopifyService.createCheckout(
      lineItems,
      customAttributes,
      note
    );
    const registrations = this.addedJunior
      ? this.familyFacade.currentState.familyMembers.map((member: FamilyMember) => {
        return { id: member.id };
      })
      : [{ id: registration.id }];
    const transaction = {
      shopify_uuid: checkoutModel.checkout.id,
      registrations,
    };
    return await this.createTransaction(transaction, checkoutModel, registration);
  }

  private createTransaction(
    transaction: any,
    checkoutModel: any,
    registration: Registration
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.transactionService.create(transaction).subscribe(
        () => {
          this.resetFacades();
          // Redirect for updated checkout flow  (usac-checkout)
          window.location.href = this.transactionService.getCheckoutUrl(checkoutModel.checkout.id, {
            membershipOrder: registration.order.order_id,
            email: registration.email,
            firstName: registration.first_name,
            lastName: registration.last_name,
          });
          this.isSubmitting = false;
          resolve(true);
        },
        () => {
          reject();
        }
      );
    });
  }

  resetFacades(): void {
    this.registrationFacade.reset();
    this.familyFacade.reset();
    this.addOnsFacade.reset();
  }
}
