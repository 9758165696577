import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { httpInterceptorProviders } from './interceptors';

@NgModule({
  imports: [CommonModule],
  providers: [httpInterceptorProviders],
})
export class CoreModule {}
