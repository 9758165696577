<div class="license-card">
  <div class="license-card--header d-flex" [style.background-color]="licenseHeaderColor">
    <img src="../../../../assets/images/usac-logo.png" alt="USA Cycling logo">
    <div class="license-card--titles">
      <span class="usac-title">USA CYCLING</span>
      <hr>
      <span class="license-title">{{ licenseTypeName | uppercase }}
        <span *ngIf="isPending">*</span>
      </span>
    </div>
  </div>
  <ng-container *ngTemplateOutlet="status"></ng-container>
  <div class="license-card--details">
    <div class="row">
      <div class="license-status {{ statusClass }}">
        <i class="icon {{ iconClass }}"></i> {{ statusText }}
      </div>
      <p *ngIf="isRideMembership" style="width: 80%;" class="ride-lic-text text-right"> NOT VALID FOR RACING</p>
    </div>
      <!-- Profile details -->
    <hr>
    <div class="row">
      <app-license-detail title="Name" value="{{ user.profile_first_name }} {{ user.profile_last_name }}" class="col-sm-6 col-md-4"></app-license-detail>
      <app-license-detail title="Email" [value]="user.profile_email" class="col-sm-6 col-md-4"></app-license-detail>
      <app-license-detail title="Birthdate" [value]="user.profile_birthdate" [isDate]="true" class="col-sm-6 col-md-4"></app-license-detail>
      <app-license-detail *ngIf="state" title="State" [value]="state" class="col-sm-6 col-md-4"></app-license-detail>
      <app-license-detail title="Sex" [value]="user.profile_race_gender || 'N/A'" class="col-sm-6 col-md-4"></app-license-detail>
      <app-license-detail title="Race Age for {{ currentYear }}" [value]="raceAge" class="col-sm-6 col-md-4"></app-license-detail>
    </div>
    <!-- License details -->
    <hr>
    <div class="row">
      <app-license-detail title="License" [value]="licenseGroup.type_name" [valueClass]="'usac-primary-dark uppercase'" class="col-sm-6 col-md-6"></app-license-detail>
      <app-license-detail title="Status" [value]="statusText" [valueClass]="'usac-primary-dark text-italic'" class="col-sm-6 col-md-6"></app-license-detail>
      <app-license-detail title="License ID#" [value]="user.profile_comp_id" [valueClass]="''" class="col-sm-6 col-md-6"></app-license-detail>
      <!-- Since the displayed "license" is actually a collection of license records, there currently is no single ID -->
      <!-- <app-license-detail title="License ID#" [value]="license.id" class="col-sm-6"></app-license-detail> -->
      <app-license-detail title="Expiration" [value]="licenseGroup.expiration" [valueClass]="''" [isDate]="true" class="col-sm-6 col-md-6"></app-license-detail>
    </div>
    <!-- International License details -->
    <!-- TODO: @Brennan, @Charlie - Confirm this with international licenses -->
    <div *ngIf="isInternational">
      <hr>
      <div class="row">
        <app-license-detail title="UCI ID#" [value]="user.profile_uci_id" class="col-sm-6"></app-license-detail>
        <app-license-detail title="Nationality" [value]="user.profile_racing_nationality" class="col-sm-6"></app-license-detail>
      </div>
    </div>
    <div class="row align-items-center">
      <div *ngIf="isInternational" class="col-sm-6">
        <img src="assets/images/uci-logo.svg" width="100px" alt="UCI logo">
      </div>
      <div *ngIf="showSafeSport" class="col-sm-6 safesport-logo">
        <img src="assets/images/usac-safesport.png" width="130px" alt="SafeSport logo">
      </div>
    </div>
  </div>
  <div *ngIf="showExtraDetails" class="license-card--extra-details">
    <div *ngIf="showClubs" class="extra-details--container">
      <div class="extra-details--title">Teams and Clubs</div>
      <div *ngFor="let club of clubs">
        <span class="club-title">CLUB/TEAM:</span> <span class="club-name">{{ club || 'N/A' }}</span>
      </div>
    </div>

    <div *ngIf="showDisciplineCategories">
      <div class="extra-details--title">Discipline Categories</div>
      <div class="row">
        <app-license-detail *ngFor="let disciplineCategory of disciplineCategories"
          [title]="disciplineCategory.discipline"
          [value]="disciplineCategory.category"
          [titleClass]="'usac-primary-dark weight-800'"
          class="col-sm-6 col-md-4"
        ></app-license-detail>
      </div>
    </div>

  </div>
  <ng-container *ngTemplateOutlet="status"></ng-container>
</div>


<ng-template #status>
  <div class="license-card--stripe">
    <span *ngIf="!useDateRange" class="stripe-title">{{ licenseStripeTitle }}</span>
    <span class="stripe-details">
      <span *ngIf="isPending">*Temporary Credential, Requires Validation</span>
      <span *ngIf="isSuspended">*Suspended Credential, Requires Attention</span>
      <span *ngIf="isResolved">{{ licenseDateRange }}</span>
    </span>
  </div>
</ng-template>
