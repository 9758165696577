<div>
  <div class="d-flex justify-content-end pt-3 pr-3">
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <img aria-hidden="true" class="close-button" src="assets/images/x-circle.svg" alt="X" (click)="closeModal()" />
    </button>
  </div>

  <div class="container modal-container">

    <div class="row justify-content-center">
      <h2 color="#031e41">GET STARTED</h2>
    </div>

    <div class="mt-3 mb-5 mx-5">
      <div class="row buttons-row">
        <div class="col-12 col-md-6 mt-3 get-started-section">
          <h4>Existing User</h4>
          <p>Already have an existing USA Cycling membership or account? Log in to continue.</p>
          <div class="mt-4">
            <button type="button" class="btn btn-secondary btn-block" (click)="login()">LOG IN</button>
          </div>
        </div>

        <div class="vertical-divider"></div>
        <div class="horizontal-divider"></div>

        <div class="col-12 col-md-6 mt-3 get-started-section">
          <h4>New Membership</h4>
          <p>New to USA Cycling? Click Sign Up to create a new account.</p>
          <div class="mt-4">
            <button type="button" class="btn btn-primary btn-block" (click)="closeModal()">SIGN UP</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
