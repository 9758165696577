import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject, concat, of, throwError } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  switchMap,
  tap,
} from 'rxjs/operators';
import { ClubsService, InterestsService } from 'src/app/core';
import { Club, Discipline, Interest } from 'src/app/core/models';
import { MembershipCategoryDiscipline, Registration } from 'src/app/core/models/registration.model';

@Component({
  selector: 'app-interests',
  templateUrl: './interests.component.html',
  styleUrls: ['./interests.component.scss'],
})
export class InterestsComponent implements OnInit {
  @Input() registration: Registration;
  @Input() showRaceRide = true;
  @Input() isSubmitting = false;

  @Output() formValues: EventEmitter<FormGroup> = new EventEmitter();

  domesticClubs$: Observable<Club[]>;
  domesticClubsInput$ = new Subject<string | null>();
  domesticClubsLoading = false;

  primaryInterestId: number;
  selectedInterests: Interest[] = [];
  interestsForm: FormGroup;

  allInterests: Interest[];

  get f(): any {
    return this.interestsForm.controls;
  }

  get raceParticipation(): FormControl {
    return this.f.race_participation as FormControl;
  }

  get domesticClubMember(): FormControl {
    return this.f.is_domestic_club_member as FormControl;
  }

  get domesticClub(): FormControl {
    return this.f.domestic_club as FormControl;
  }

  get domesticClubId(): FormControl {
    return this.f.domestic_club_id as FormControl;
  }

  get interests(): FormControl {
    return this.f.disciplines as FormControl;
  }

  constructor(
    private fb: FormBuilder,
    private clubsService: ClubsService,
    private interestsService: InterestsService
  ) {
    this.createInterestsForm();
  }

  ngOnInit(): void {
    this.setRaceParticipationFromRegistration();
    this.loadDomesticClubs();
    this.loadInterests();
    this.emitFormValues();
    this.subscribeToForm();
  }

  private setRaceParticipationFromRegistration(): void {
    if (
      this.registration.is_premium ||
      (this.registration.racing_categories &&
        this.registration.racing_categories.some((category) => category.cd_level > 1)) ||
      this.registration.is_collegiate_cyclist ||
      !!this.registration.race_gender
    ) {
      this.raceParticipation.setValue(true);
    }
  }

  private createInterestsForm(): void {
    this.interestsForm = this.fb.group({
      race_participation: [false],
      is_domestic_club_member: [false],
      domestic_club: [null],
      domestic_club_id: [null],
      disciplines: [[], Validators.required],
    });
  }

  private subscribeToForm(): void {
    this.interestsForm.valueChanges.subscribe(() => {
      this.emitFormValues();
    });
    this.domesticClubMember.valueChanges.subscribe((val: boolean) => {
      if (!val && !!this.domesticClub.value) {
        this.domesticClub.patchValue(null);
      }
    });
    this.domesticClub.valueChanges.subscribe((club: any) => {
      const id: number = club ? club.club_id : null;
      this.domesticClubId.patchValue(id);
    });
  }

  isInterestSelected(interest: Interest): boolean {
    return this.selectedInterests.includes(interest);
  }

  isInterestPrimary(interest: Interest): boolean {
    return this.primaryInterestId === interest.id;
  }

  interestHasSubSelect(interest: Interest): boolean {
    if (interest.name.toUpperCase() === 'BMX') {
      return interest.disciplines.length > 1;
    }

    return false;
  }

  showInterestSelectSub(interest: Interest): boolean {
    return this.isInterestSelected(interest) && this.interestHasSubSelect(interest);
  }

  isSubSelected(interest: Interest, discipline: Discipline): boolean {
    return interest.subSelected === discipline.id;
  }

  selectSub(interest: Interest, discipline: Discipline): void {
    if (this.isInterestSelected(interest)) {
      this.selectedInterests = this.selectedInterests.map((selected) => {
        if (interest.id === selected.id) {
          const sub = interest.disciplines.find((disc) => disc.id === discipline.id);

          selected.subSelected = sub.id;
        }

        return selected;
      });
      this.interests.setValue(this.interestDisciplinesData());
    }
  }

  interestDisciplinesData(): any[] {
    return this.selectedInterests.reduce((acc, interest) => {
      if (interest.subSelected) {
        const discipline = interest.disciplines.find((d) => d.id === interest.subSelected);
        this.pushDiscipline(acc, interest, discipline);
      } else {
        interest.disciplines.forEach((discipline) => {
          this.pushDiscipline(acc, interest, discipline);
        });
      }

      return acc;
    }, []);
  }

  pushDiscipline(disciplines: any[], interest: Interest, discipline: Discipline): any[] {
    disciplines.push({
      cd_id: discipline.id,
      cd_name: discipline.name,
      is_primary: interest.id === this.primaryInterestId,
    });
    return disciplines;
  }

  toggleInterest(interest: Interest): void {
    const isSelected = this.selectedInterests.some(
      (selectedInterest) => selectedInterest.id === interest.id
    );

    if (isSelected) {
      this.removeInterest(interest);
    } else {
      this.addInterest(interest);
    }

    this.interests.setValue(this.interestDisciplinesData());
  }

  removeInterest(interest: Interest): void {
    this.selectedInterests = this.selectedInterests.filter(
      (selectedInterest) => interest.id !== selectedInterest.id
    );

    if (this.primaryInterestId === interest.id) {
      this.primaryInterestId =
        this.selectedInterests.length > 0 ? this.selectedInterests[0].id : null;
    }
  }

  addInterest(interest: Interest): void {
    if (
      this.interestHasSubSelect(interest) &&
      !interest.subSelected &&
      interest.disciplines.length > 0
    ) {
      interest.subSelected = interest.disciplines[0].id;
    }

    this.selectedInterests.push(interest);

    if (this.selectedInterests.length === 1) {
      this.primaryInterestId = interest.id;
    }
  }

  makePrimary(interest: Interest): void {
    this.primaryInterestId = interest.id;
    this.interests.setValue(this.interestDisciplinesData());
  }

  /** Selects a domestic club if the user is a domestic club member and has a domestic club id  */
  private selectDomesticClub(registration: Registration): void {
    if (
      registration.domestic_club_id &&
      registration.domestic_club_id > 0 &&
      registration.is_domestic_club_member
    ) {
      this.domesticClubsLoading = true;
      this.clubsService
        .queryDomesticClubs()
        .pipe(
          tap((clubs: any) => {
            const selectedClub = clubs.find(
              (club: any) => club.club_id === registration.domestic_club_id
            );
            this.domesticClub.patchValue(selectedClub);
          }),
          catchError((error) => {
            return throwError(error);
          }),
          finalize(() => {
            this.domesticClubsLoading = false;
          })
        )
        .subscribe();
    }
  }

  private loadDomesticClubs(): void {
    this.domesticClubs$ = concat(
      of([]), // default items
      this.domesticClubsInput$.pipe(
        debounceTime(250),
        distinctUntilChanged(),
        tap(() => (this.domesticClubsLoading = true)),
        switchMap((term: string) =>
          this.clubsService.queryDomesticClubs(term).pipe(
            catchError(() => of([])),
            finalize(() => (this.domesticClubsLoading = false))
          )
        )
      )
    );
  }

  trackClubsBy(club: Club): number {
    return club.club_id;
  }

  private loadInterests(): void {
    this.interestsService
      .getInterests()
      .pipe(
        tap((interests) => {
          this.allInterests = interests;
          this.setInterests();
        })
      )
      .subscribe();
  }

  private toInterests(disciplines: MembershipCategoryDiscipline[]): Interest[] {
    const interests = disciplines
      .map((discipline) =>
        this.allInterests.find((interest) =>
          interest.disciplines.some((x) => x.id === discipline.cd_id)
        )
      )
      .filter((interest) => interest)
      .map((interest) => {
        if (this.interestHasSubSelect(interest)) {
          const subSelected =
            disciplines.find((disc) => interest.disciplines.some((y) => y.id === disc.cd_id)) ||
            interest.disciplines[0];
          interest.subSelected = subSelected.cd_id;
        }
        return interest;
      });

    const uniqueInterests = [...new Set(interests)];

    return uniqueInterests;
  }

  private setInterests(): void {
    const disciplines = this.registration.disciplines || [];

    this.selectedInterests = this.toInterests(disciplines);

    const primaryInterest = this.selectedInterests.find((interest) =>
      disciplines.some(
        (discipline) =>
          discipline.is_primary && interest.disciplines.some((x) => x.id === discipline.cd_id)
      )
    );

    if (primaryInterest) {
      this.primaryInterestId = primaryInterest.id;
    }

    this.interests.setValue(this.interestDisciplinesData());

    this.interestsForm.patchValue({
      is_domestic_club_member: !!this.registration.is_domestic_club_member,
    });

    this.selectDomesticClub(this.registration);
  }

  private emitFormValues(): void {
    this.formValues.emit(this.interestsForm);
  }
}
