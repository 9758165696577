import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService, Profile, ProfileService } from 'src/app/core';
import { AddOnsFacade, FamilyMembershipFacade, RegistrationFacade } from 'src/app/core/facades';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-ghost-mode',
  templateUrl: './admin-ghost-mode.component.html',
  styleUrls: ['./admin-ghost-mode.component.scss'],
})
export class AdminGhostModeComponent implements OnInit {
  currentUser: Profile;
  activeProfile: Profile;
  ghostModeForm: FormGroup;
  loading = false;

  get f(): any {
    return this.ghostModeForm.controls;
  }

  get disable(): boolean {
    return !this.currentUser;
  }

  get ghosting(): boolean {
    return !this.disable && this.ghostModeForm.value.ghostMode;
  }

  get showGhostModeBar(): boolean {
    return this.ghostModeActive;
  }

  get self(): boolean {
    return (
      this.currentUser &&
      this.activeProfile &&
      this.currentUser.profile_id === this.activeProfile.profile_id
    );
  }

  get profileUrl(): string {
    return this.activeProfile && `/profile/${this.activeProfile.profile_id}`;
  }

  get status(): string {
    return this.ghosting ? 'acting as:' : 'viewing:';
  }

  get showCancelButton(): boolean {
    return this.activeProfileNameAndId !== 'Self' && !this.ghostModeActive;
  }

  get activeProfileNameAndId(): string {
    if (!this.currentUser.profile_id) {
      return '';
    }

    if (this.ghostModeActive) {
      return `${this.currentUser.profile_first_name} ${this.currentUser.profile_last_name} ${this.currentUser.profile_id}`;
    } else {
      return this.self || this.self === null
        ? 'Self'
        : `${this.currentUser.profile_first_name} ${this.currentUser.profile_last_name} ${this.currentUser.profile_id}`;
    }
  }

  get ghostModeActive(): boolean {
    return this.profileService.hasGhostModeCookie;
  }

  get adminInfo(): string {
    if (this.ghostModeActive) {
      const data = this.profileService.ghostModeCookieData;
      return `${data.admin_name} ${data.admin_id}`;
    } else {
      return `${this.currentUser.profile_first_name} ${this.currentUser.profile_last_name} ${this.currentUser.profile_id}`;
    }
  }

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private profileService: ProfileService,
    private addOnsFacade: AddOnsFacade,
    private registrationFacade: RegistrationFacade,
    private familyMembershipFacade: FamilyMembershipFacade
  ) {}

  ngOnInit(): void {
    this.authService.currentUser$.subscribe((user: Profile) => {
      this.currentUser = user;
    });

    this.ghostModeForm = this.fb.group({
      ghostMode: [this.ghostModeActive],
    });
  }

  resetGhostMode(): void {
    if (confirm('Are you sure you want to logout as this ghosted user?')) {
      this.loading = true;

      this.profileService.ghostResetProfile().subscribe((profile: Profile) => {
        if (profile) {
          this.registrationFacade.reset();
          this.familyMembershipFacade.reset();
          this.addOnsFacade.reset();
          window.location.assign(environment.adminUrl + `/profile/${profile.profile_id}`);
        }
        this.loading = false;
      });
    }
  }
}
