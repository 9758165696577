export interface SpotInsurance {
  id: number;
  certificateNumber: string;
  firstName: string;
  lastName: string;
  email: string;
  startDate: string;
  endDate: string;
  status: string;
  canceled: boolean;
  canceledOn: string;
}

export interface ProfileSpotInsurance {
  event_id: number;
  customer_id: number;
}

export enum SpotClassOneIds {
  STAGING_ID_2022 = 9628,
  PRODUCTION_ID_2022 = 4505,
  STAGING_ID_2023 = 22780,
  PRODUCTION_ID_2023 = 8392,
}
