import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Address, Profile } from 'src/app/core';

@Component({
  selector: 'app-license-create-fee-modal',
  templateUrl: './license-create-fee-modal.component.html',
  styleUrls: ['./license-create-fee-modal.component.scss'],
})
export class LicenseCreateFeeModalComponent {
  @Input() profile: Profile;
  @Input() isPaid: boolean;
  @Input() draftOrderLink: string;

  constructor(public activeModal: NgbActiveModal) {}

  openShopify(): void {
    window.open(this.draftOrderLink, '_blank');
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }

  get description(): string {
    let text = 'A Draft Order has been created to pay this fee';
    text += this.isPaid ? '.' : ', and an email has been sent to the member to pay it.';
    text += ` You can use this information to check the accuracy of the draft order.
      If the member has already paid, please make sure to update that the draft order has been paid:`;

    return text;
  }

  get address(): Address {
    return this.profile.address;
  }

  get showAddress(): boolean {
    return (
      this.address &&
      !!this.address.address_street &&
      !!this.address.address_city &&
      !!this.address.address_state
    );
  }
}
