import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core';

@Component({
  selector: 'app-get-started-modal',
  templateUrl: './get-started-modal.component.html',
  styleUrls: ['./get-started-modal.component.scss'],
})
export class GetStartedModalComponent implements OnInit {
  constructor(private authService: AuthService, private activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  closeModal(): void {
    this.activeModal.close();
  }

  login(): void {
    this.authService.doLogin(window.location.href);
    this.activeModal.close();
  }
}
