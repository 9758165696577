import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { IntlDisciplineSelectOption, UciCountry } from '../models';

import { BaseService } from './base-service';

@Injectable({
  providedIn: 'root',
})
export class UciService extends BaseService {
  countries: UciCountry[] = [];
  licenseCategoryOptions: IntlDisciplineSelectOption[] = [
    {
      label: 'Road',
      value: {
        discipline: { cd_id: 19, cd_name: 'Road Racing', is_primary: true },
        sku: 'INT',
      },
    },
    {
      label: 'Mountain',
      value: {
        discipline: { cd_id: 21, cd_name: 'Mountain Bike Racing', is_primary: true },
        sku: 'INT',
      },
    },
    {
      label: 'Track',
      value: {
        discipline: { cd_id: 20, cd_name: 'Track Racing', is_primary: true },
        sku: 'INT',
      },
    },
    {
      label: 'Cyclocross',
      value: {
        discipline: { cd_id: 23, cd_name: 'Cyclocross Racing', is_primary: true },
        sku: 'INT',
      },
    },
    // {
    //   label: 'Gravel',
    //   value: {
    //     discipline: { cd_id: 25, cd_name: 'Gravel', is_primary: true },
    //     sku: 'INT',
    //   },
    // },
    {
      label: 'BMX Race Challenge',
      value: {
        discipline: { cd_id: 22, cd_name: 'BMX Racing', is_primary: true },
        lc_id: 209,
        sku: 'INT-BMX',
      },
    },
    {
      label: 'BMX Race Championship (Jr., U23, Elite)',
      value: {
        discipline: { cd_id: 22, cd_name: 'BMX Racing', is_primary: true },
        lc_id: 210,
        sku: 'INT-BMX',
      },
    },
    {
      label: 'BMX Masters (Vet Pro)',
      value: {
        discipline: { cd_id: 22, cd_name: 'BMX Racing', is_primary: true },
        lc_id: 214,
        sku: 'INT-BMX',
      },
    },
    {
      label: 'BMX Freestyle (UCI Events)',
      value: {
        discipline: { cd_id: 30, cd_name: 'BMX Freestyle', is_primary: true },
        lc_id: 211,
        sku: 'INT-BMX',
      },
    },
  ];

  getCountries(): Observable<UciCountry[]> {
    return this.http.get<UciCountry[]>(this.url('uci/proxy/countries'), this.options).pipe(
      tap((countries) => {
        this.log('loaded UCI countries');
        this.countries = countries;
      }),
      catchError(this.handleError<UciCountry[]>('getCountries', []))
    );
  }
}
