import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { MembershipOrdersService, Profile } from 'src/app/core';
import { Plans, RegistrationFacade } from 'src/app/core/facades';
import { Product, ProductVariant } from 'src/app/core/models';
import { PersonTypes } from 'src/app/core/models/registration.model';
import { AuthService, ProfileService, ShopifyService } from 'src/app/core/services';
import { environment } from 'src/environments/environment';

import { ConfirmationModalComponent } from '../../confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-premium-upgrade-cart',
  templateUrl: './premium-upgrade-cart.component.html',
  styleUrls: ['./premium-upgrade-cart.component.scss'],
})
export class PremiumUpgradeCartComponent implements OnInit {
  @Input() cartOpened: boolean;
  @Input() cartHidden: boolean;
  @Input() hideCartContent: boolean;
  @Input() startOverHidden: boolean;
  @Input() user: Profile;
  @Input() rideTechUpgrade: boolean;
  @Input() spotInsuranceUpgrade: boolean;

  @Output() cartClosedEmmiter: EventEmitter<boolean> = new EventEmitter<boolean>();

  planTypes = Plans;
  personTypes = PersonTypes;
  variantsBySku: { [key: string]: ProductVariant };

  premiumUpgradePrice = this.shopifyService.premiumUpgradePrice;
  spotInsurancePrice = this.shopifyService.spotInsurancePrice;

  get loggedIn(): boolean {
    return !!this.user.profile_id;
  }

  get ghostModeActive(): boolean {
    return this.profileService.hasGhostModeCookie;
  }

  get fullName(): string {
    return this.loggedIn ? this.user.profile_first_name + ' ' + this.user.profile_last_name : null;
  }

  get personType(): PersonTypes {
    return this.loggedIn ? this.user.profile_person_type : null;
  }

  get isAdult(): boolean {
    return this.personType === PersonTypes.ADULT;
  }

  get isJunior(): boolean {
    return this.personType === PersonTypes.JUNIOR;
  }

  get rideTechPrice(): string {
    return this.variantsBySku[this.shopifyService.planSkus.rideTech].priceV2.amount;
  }

  get upgradeTitle(): string {
    if (this.rideTechUpgrade) {
      return 'Ride Tech';
    } else if (this.spotInsuranceUpgrade) {
      return 'Spot Insurance';
    } else {
      return 'Race License';
    }
  }

  get upgradePrice(): number | string {
    if (this.rideTechUpgrade) {
      return this.rideTechPrice;
    } else if (this.spotInsuranceUpgrade) {
      return this.spotInsurancePrice;
    } else {
      return this.premiumUpgradePrice;
    }
  }

  constructor(
    private authService: AuthService,
    private modal: NgbModal,
    private profileService: ProfileService,
    private registrationFacade: RegistrationFacade,
    private shopifyService: ShopifyService,
    private membershipOrdersService: MembershipOrdersService
  ) {}

  ngOnInit(): void {
    this.shopifyService.getProducts().then((products) => this.setVariantsBySku(products));
  }

  private setVariantsBySku(products: Product[]): void {
    this.variantsBySku = products
      .flatMap((product) => product.variants)
      .reduce((acc, productVariant) => {
        acc[productVariant.sku] = productVariant;
        return acc;
      }, {});
  }

  closeCart(): void {
    this.cartOpened = false;
    this.cartClosedEmmiter.emit(false);
  }

  startOver(): void {
    const modal = this.modal.open(ConfirmationModalComponent);

    modal.componentInstance.message = 'Are you sure you want to log out?';

    modal.result
      .then(() => {
        if (this.registrationFacade.getOrderId) {
          this.membershipOrdersService
            .remove(this.registrationFacade.getOrderId)
            .pipe(
              finalize(() => {
                this.clear();
              })
            )
            .subscribe();
        } else {
          this.clear();
        }
      })
      .catch(() => {});
  }

  clear(): void {
    // Logout user and clear state
    this.authService.doLogout().subscribe((resp) => {
      if (resp) {
        window.location.assign(environment.publicUrl);
      }
    });
  }
}
