<ng-container *ngIf="vm$ | async as vm">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="agreement">
          <h1 class="agreement__title">USAC Cycling Waiver</h1>
          <p class="agreement__subtitle">Please Read & Agree to the Below</p>

          <p class="agreement__participant">
            Participant:
            <strong>
              {{ vm.app.registration.registration.first_name }}
              {{ vm.app.registration.registration.last_name }}</strong
            >
          </p>

          <div class="agreement-card" [innerHTML]="vm.waiver.waiver_text | html"></div>

          <form [formGroup]="agreementForm" class="agreement-form">
            <h2 class="agreement-form__title">Sign & Agree</h2>

            <div class="form-group mt-1">
              <label for="fullName"
                >{{
                  guardian
                    ? "Legal Guardian's Full Name"
                    : 'Enter your Full Name'
                }}<span class="star">*</span></label
              >
              <input
                [ngClass]="{ 'border border-danger': fullNameError }"
                formControlName="fullName"
                type="text"
                class="form-control"
                id="fullName"
                placeholder="Jane Smith"
                #fullName
                (keyup)="
                  compareNames(
                    fullName.value,
                    vm.app.registration.registration.first_name,
                    vm.app.registration.registration.last_name
                  )
                "
              />

              <span class="text-danger" *ngIf="fullNameError">{{
                guardian
                  ? 'The legal guardian must enter their full name here to sign'
                  : 'The name must match the first and last name associated with this membership'
              }}</span>
            </div>

            <div class="card custom-control custom-checkbox">
              <input
                formControlName="agreed"
                class="card-body custom-control-input"
                type="checkbox"
                value=""
                id="defaultCheck1"
                />
              <label
                class="ml-2 mt-2 mb-2 custom-control-label"
                for="defaultCheck1"
                [innerHtml]="
                  guardian
                    ? vm.waiver.waiver_jr_agreement
                    : vm.waiver.waiver_adult_agreement
                "
              >
              </label>
            </div>

            <div class="card custom-control custom-checkbox mt-4">
              <input
                formControlName="codeOfConductAgreed"
                class="card-body custom-control-input"
                type="checkbox"
                value=""
                id="codeOfConductCheck"
                />
              <label
                class="ml-2 mt-2 mb-2 custom-control-label"
                for="codeOfConductCheck"
                [innerHtml]="codeOfConduct"
              >
              </label>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <footer class="agreement__footer">
    <button
      type="button"
      (click)="
        signWaiver(
          vm.waiver,
          vm.app.registration.registration.id
        )
      "
      [disabled]="agreementForm.invalid || buttonDisabled || isSubmitting"
      class="agreement__button btn btn-primary"
    >
      <span *ngIf="isSubmitting" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
      I Agree to the Above
    </button>
  </footer>
</ng-container>

<ng-template #noWaiver>
  <div class="container-fluid">
    <div class="row py-3">
      <div class="col-12">
        <p class="text-danger">Unable to fetch waiver info</p>
      </div>
    </div>
  </div>
</ng-template>
