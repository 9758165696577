import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService, ProfilePublic, ProfileService, Toast, Alert } from 'src/app/core';

@Component({
  selector: 'app-forgot-password-modal',
  templateUrl: './forgot-password-modal.component.html',
  styleUrls: ['./forgot-password-modal.component.scss'],
})
export class ForgotPasswordModalComponent {
  @Input() profile: ProfilePublic;

  resentMailer: boolean = false;
  toasts: Toast[] = [];
  success: Alert[] = [];
  alerts: Alert[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private authService: AuthService,
    private profileService: ProfileService
  ) { }

  resendPasswordReset(): void {
    this.resentMailer = true;
    this.profileService.resetProfilePassword(this.profile).subscribe(resp => {
      if (resp) {
        this.showSuccessAlert('Password Reset Email has been sent! Check your email.');
        this.showSuccessToast();
      } else {
        this.showErrorAlert('Unable to send password reset email. Make sure the info provided is accurate.');
      }
    });
  }

  showSuccessToast(): void {
    this.toasts.push(new Toast('success', `Password Reset Email has been sent! Check your email.`, 'Success', false));
  }

  showErrorAlert(message: string): void {
    this.alerts.push(new Alert('danger', message, null, 'warning'));
  }

  showSuccessAlert(message: string): void {
    this.success.push(new Alert('success', message, null, 'success'));
  }

  goToLogin(): void {
    this.authService.doLogin(window.location.href);
  }
}
