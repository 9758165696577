import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { AddOnPayload } from '../models/registration.model';

export interface AddOnsState {
  adult: AddOnPayload;
  junior: AddOnPayload;
}

// tslint:disable-next-line: variable-name
let _state: AddOnsState = {
  adult: {
    enrollmentId: '',
    items: [],
  },
  junior: {
    enrollmentId: '',
    items: [],
  },
};

@Injectable({
  providedIn: 'root',
})
export class AddOnsFacade {
  get adult(): AddOnPayload {
    return this.store.getValue().adult;
  }

  get junior(): AddOnPayload {
    return this.store.getValue().junior;
  }

  get currentState(): AddOnsState {
    return this.store.getValue();
  }

  private readonly store: BehaviorSubject<AddOnsState> = new BehaviorSubject<AddOnsState>(
    this.restoreState() || _state
  );

  private readonly state$: Observable<AddOnsState> = this.store.asObservable();

  adult$: Observable<AddOnPayload> = this.state$.pipe(
    map((state: AddOnsState) => {
      return state.adult;
    }),
    distinctUntilChanged()
  );

  junior$: Observable<AddOnPayload> = this.state$.pipe(
    map((state: AddOnsState) => {
      return state.junior;
    }),
    distinctUntilChanged()
  );

  /**
   * Viewmodel that resolves once all the data is ready (or updated)...
   */
  vm$: Observable<AddOnsState> = combineLatest([this.adult$, this.junior$]).pipe(
    map(([adult, junior]) => ({
      adult,
      junior,
    }))
    // Type casting, infers wrong type without this
  ) as Observable<AddOnsState>;

  /** Resets registration data store to default values */
  reset(): void {
    this.updateState({
      ..._state,
      adult: {
        enrollmentId: '',
        items: [],
      },
      junior: {
        enrollmentId: '',
        items: [],
      },
    });
  }

  /** Updates adult enrollment id */
  updateAdultEnrollmentId(enrollmentId: string): void {
    this.updateState({
      ...this.currentState,
      adult: {
        enrollmentId,
        items: this.currentState.adult.items,
      },
    });
  }

  /** Updates junior enrollment id */
  updateJuniorEnrollmentId(enrollmentId: string): void {
    this.updateState({
      ...this.currentState,
      junior: {
        enrollmentId,
        items: this.currentState.junior.items,
      },
    });
  }

  addAdultAddOnItems(item: any): void {
    this.updateState({
      ...this.currentState,
      adult: {
        enrollmentId: this.currentState.adult.enrollmentId,
        items: [
          ...this.currentState.adult.items,
          {
            sku: item.variant.sku,
            productTitle: item.product.title,
            variantTitle: item.variant.title,
            price: item.variant.priceV2.amount,
            variantId: item.variant.id,
          },
        ],
      },
    });
  }

  removeAdultAddOnItems(item: any): void {
    this.updateState({
      ...this.currentState,
      adult: {
        enrollmentId: this.currentState.adult.enrollmentId,
        items: this.currentState.adult.items.filter(
          (currentItem) => currentItem.sku !== item.variant.sku
        ),
      },
    });
  }

  addJuniorAddOnItems(item: any): void {
    this.updateState({
      ...this.currentState,
      junior: {
        enrollmentId: this.currentState.junior.enrollmentId,
        items: [
          ...this.currentState.junior.items,
          {
            sku: item.variant.sku,
            productTitle: item.product.title,
            variantTitle: item.variant.title,
            price: item.variant.priceV2.amount,
            variantId: item.variant.id,
          },
        ],
      },
    });
  }

  removeJuniorAddOnItems(item: any): void {
    this.updateState({
      ...this.currentState,
      junior: {
        enrollmentId: this.currentState.junior.enrollmentId,
        items: this.currentState.junior.items.filter(
          (currentItem) => currentItem.sku !== item.variant.sku
        ),
      },
    });
  }

  updateState(state: AddOnsState): void {
    this.store.next((_state = state));

    localStorage.setItem('add-ons-state', JSON.stringify(this.store.getValue()));
  }

  private restoreState(): AddOnsState {
    return JSON.parse(localStorage.getItem('add-ons-state'));
  }
}
