<div class="container" *ngIf="memberInfo">
  <div class="pre-prompt">
    <div class="row">
      <div class="col-12 text-center">
        <h2 class="title">
          Is the parent or legal guardian of {{ memberInfo.first_name }}
          {{ memberInfo.last_name }} present?
        </h2>

        <p class="pre-prompt__description">
          Members under the age of 18 (or 19 in Alabama) require a legal guardian to sign their waiver.
          If the legal guardian is not present, you can still continue with your membership, and we will
          send an email to your guardian to sign.
        </p>
      </div>
      <div class="d-flex justify-content-around align-items-center w-100">
        <button type="button" (click)="openJuniorWaiverModal()" class="pre-prompt__btn btn btn-primary mr-1">
          Yes
        </button>
        <button
          type="button"
          (click)="openJuniorNoWaiverModal()"
          class="pre-prompt__btn btn btn-outline-primary ml-1"
        >
          No
        </button>
      </div>
    </div>
  </div>
</div>
