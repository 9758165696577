<div *ngIf="raceSelected" class="form-card">
  <div class="row">
    <div *ngIf="vm$ | async as vm" class="col-12">
      <app-form-title [customClasses]="['title-md', 'text-left']">Your Race Details</app-form-title>
      <app-form-subtitle [customClasses]="['text-left']">
        Let’s fill in the last bits of information so we can finalize your USAC license.
        <span *ngIf="!raceSelected">
          Not planning on racing? That's OK. Every USA Cycling membership comes with
          the opportunity to participate in USA Cycling events, even fun rides.
        </span>
      </app-form-subtitle>

      <form [formGroup]="raceCategoriesForm">
        <div class="mb-3 py-4">
          <h5 class="section-header">Racing Categories</h5>
          <p class="section-text">
            Your USA Cycling race license lets you race as a Novice in any domestic cycling race across
            all disciplines. If you need to race in a higher category, you can make those selections
            here.
          </p>

          <!-- view categories section -->
          <div class="d-flex flex-wrap details-list-container px-3" *ngIf="!isEditing">
            <div class="col-10 col-md-9 d-flex align-items-center justify-content-start flex-wrap p-0">
              <table class="my-3 mr-3">
                <thead *ngIf="registration.is_collegiate_cyclist">
                  <tr>
                    <th class="list-name pr-sm-4 pr-md-5"></th>
                    <th class="list-name px-2 px-sm-4 px-md-5">Domestic</th>
                    <th class="list-name px-2 px-sm-4 px-md-5">Collegiate</th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let discipline of disciplines; let idx=index">
                    <th class="list-name pr-sm-4 pr-md-5">{{ discipline.name }}</th>
                    <td class="list-value px-2 px-sm-4 px-md-5">{{ isLoading ? '-' : domesticCategories.getRawValue()[idx].name }}</td>
                    <td *ngIf="registration.is_collegiate_cyclist" class="list-value px-2 px-sm-4 px-md-5">{{ isLoading ? '-' : collegiateCategories.getRawValue()[idx].name }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="col-2 col-md-3 d-flex align-items-start align-items-md-center justify-content-end p-0">
              <img class="pr-1 d-none d-md-block" src="assets/images/trophy.svg" alt="trophy" />
              <button
                type="button"
                (click)="toggleEditMode()"
                class="edit-link btn btn-link d-none d-md-block pl-0"
              >
                Update categories
              </button>
              <button
                type="button"
                (click)="toggleEditMode()"
                class="edit-link btn btn-link d-block d-md-none"
              >
                Edit
              </button>
            </div>
          </div>

          <div *ngIf="isEditing && registration.is_collegiate_cyclist" class="d-none d-sm-flex align-items-center justify-content-end p-2">
            <div></div>
            <div class="d-flex pr-3">
              <div style="width: 150px;">
                <div class="category-type d-flex align-items-center">
                  Collegiate
                  <i
                    class="icon icon-information ml-1"
                    placement="bottom"
                    [ngbTooltip]="tipContentCollegiate"
                    tooltipClass="custom-tooltip"
                    [openDelay]="250"
                    tabindex="0"
                  ></i>
                </div>
              </div>
            </div>

            <div style="width: 165px;">
              <div class="category-type d-flex align-items-center">
                Domestic
              </div>
            </div>
          </div>

          <ng-template #tipContentCollegiate>
            You can select a category above D from your admin portal or by contacting
            <span class="font-weight-bold">membership@usacycling.org</span> after you complete your
            membership and request to be upgraded to a higher category.
          </ng-template>

          <!-- edit categories section -->
          <div *ngIf="isEditing">
            <div
              class="edit-category-item d-flex align-items-center justify-content-between px-4 py-2 mb-3 flex-column flex-sm-row"
              *ngFor="let discipline of disciplines; let idx=index"
            >
              <span class="category-name-edit pb-2 pb-sm-0">{{ discipline.name }}</span>
              <div class="d-flex flex-column flex-sm-row justify-content-around">
                <div *ngIf="registration.is_collegiate_cyclist" formArrayName="collegiateCategories" class="d-flex flex-column">
                  <div class="d-flex align-items-center d-sm-none category-type py-2">
                    Collegiate
                    <i
                      class="icon icon-information ml-1"
                      placement="bottom"
                      [ngbTooltip]="tipContentCollegiate"
                      tooltipClass="custom-tooltip"
                      [openDelay]="250"
                      tabindex="0"
                    ></i>
                  </div>

                  <ng-select
                    class="category-select"
                    [items]="discipline.collegiateCategories"
                    bindLabel="name"
                    [clearable]="false"
                    [searchable]="false"
                    [formControlName]="idx"
                  >
                    <ng-template ng-label-tmp let-item="item">
                      <span>{{ item.name }}</span>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                      <div class="d-flex justify-content-between align-items-center">
                        <span>{{ item.name }}</span>
                        <img
                          *ngIf="item.requiresApproval"
                          class="ml-1"
                          height="15"
                          width="15"
                          src="assets/images/Information.svg"
                          alt="i"
                          placement="bottom"
                          [ngbTooltip]="tipContentCollegiate"
                          tooltipClass="custom-tooltip"
                          container="body"
                        />
                      </div>
                    </ng-template>
                  </ng-select>

                  <ng-template #tipContentCollegiate>
                    You can select a category above D from your admin portal or by contacting
                    <span class="font-weight-bold">membership@usacycling.org</span>
                    after you complete your membership and request to be upgraded to a higher category.
                  </ng-template>
                </div>

                <div formArrayName="domesticCategories" class="d-flex flex-column ml-sm-3">
                  <span *ngIf="registration.is_collegiate_cyclist" class="d-inline d-sm-none category-type py-2">
                    Domestic
                  </span>

                  <ng-select
                    class="category-select"
                    [items]="discipline.nonCollegiateCategories"
                    bindLabel="name"
                    [clearable]="false"
                    [searchable]="false"
                    [formControlName]="idx"
                  >
                    <ng-template ng-label-tmp let-item="item">
                      <div class="d-flex justify-content-between align-items-center">
                        <span>{{ item.name }}</span>
                        <img
                          class="mx-1"
                          *ngIf="item.requiresPremium === true"
                          height="15"
                          width="15"
                          src="assets/images/trophy.svg"
                          alt="Trophy"
                        />
                      </div>
                    </ng-template>

                    <ng-template ng-option-tmp let-item="item">
                      <div class="d-flex justify-content-between align-items-center">
                        <span>{{ item.name }}</span>
                        <img
                          class="mx-1"
                          *ngIf="item.requiresPremium === true && !item.requiresApproval"
                          height="15"
                          width="15"
                          src="assets/images/trophy.svg"
                          alt="Trophy"
                        />
                        <img
                          class="ml-1"
                          *ngIf="item.requiresApproval"
                          height="15"
                          width="15"
                          src="assets/images/Information.svg"
                          alt="i"
                          placement="bottom"
                          [ngbTooltip]="tipContent"
                          tooltipClass="custom-tooltip"
                          container="body"
                        />
                      </div>
                    </ng-template>
                  </ng-select>

                  <ng-template #tipContent>
                    Selecting this category requires approval from an upgrade coordinator. Contact
                    <span class="font-weight-bold">membership@usacycling.org</span>
                    after you complete your membership and request to be upgraded to a higher category.
                  </ng-template>

                </div>
              </div>
            </div>

            <div class="d-flex align-items-center row pt-4">
              <div class="col-12 offset-md-9 col-md-3">
                <button type="button" class="btn btn-secondary btn-block" [disabled]="isLoading || isSaving" (click)="saveDisciplineCategories(true)">
                  <span *ngIf="isSaving" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                  {{ isSaving ? 'Saving…' : 'Save' }}
                </button>
              </div>
            </div>
          </div>

        </div>
      </form>

      <div class="py-4" *ngIf="registration">
        <h5 class="section-header">License Details</h5>
        <p class="section-text">Review your license Information</p>

        <div class="d-flex flex-wrap details-list-container px-3">
          <div class="col-10 d-flex align-items-center justify-content-start justify-content-md-between flex-wrap p-0">
            <div class="d-flex flex-column p-3">
              <span class="user-name">{{ registration.first_name }} {{ registration.last_name }}</span>
              <span class="user-birthday">{{ registration.birthdate }}</span>
            </div>

            <div class="d-flex flex-column p-3">
              <div class="d-flex align-items-center">
                <div class="d-flex align-items-center">
                  <span class="list-name mr-1">Sex</span>
                  <i
                    class="icon icon-information ml-1"
                    placement="bottom"
                    [ngbTooltip]="tipContent"
                    tooltipClass="custom-tooltip"
                    [openDelay]="250"
                    tabindex="0"
                  ></i>
                </div>

                <ng-template #tipContent>
                  Many cycling events utilize binary gender designations per UCI standards. Please contact
                  <span class="font-weight-bold">membership@usacycling.org</span>
                  with any questions.
                </ng-template>
              </div>

              <span class="list-value">{{ registration.race_gender | gender }}</span>
            </div>

            <div class="d-flex flex-column p-3">
              <span class="list-name">Contact Information</span>
              <span class="list-value">{{ registration.email }}</span>
              <span class="list-value">{{ registration.phone_number }}</span>
            </div>

            <div class="d-flex flex-column p-3">
              <span class="list-name">Address</span>
              <div *ngIf="hasAddress" class="d-flex flex-column">
                <span class="list-value">{{ registration.address_street }}</span>
                <span *ngIf="registration.address_street2" class="list-value">{{ registration.address_street2 }}</span>
                <span class="list-value">
                  {{ registration.city }}<span><span *ngIf="registration.city && registration.state">,</span> {{ registration.state }}</span>
                  {{ registration.zip }}
                </span>
              </div>
              <span *ngIf="!hasAddress" class="list-value">N/A</span>
            </div>
          </div>

          <div class="d-flex col-2 d-flex align-items-start align-items-md-center justify-content-end p-0">
            <button
              type="button"
              class="edit-link btn btn-link edit-member-info"
              (click)="openMemberInfoModal()"
            >
              Edit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
