export class Alert {
  type: string;
  title: string;
  message: string;
  icon: string;
  linkText: string;
  link: string;
  autoDismissTime: number;
  dismissible: boolean;

  constructor(
    type: string,
    message: string,
    title?: string,
    icon?: string,
    linkText?: string,
    link?: string,
    autoDismissTime?: number,
    dismissible: boolean = true
  ) {
    this.type = type;
    this.message = message;
    this.title = title;
    this.icon = icon;
    this.linkText = linkText;
    this.link = link;
    this.autoDismissTime = autoDismissTime;
    this.dismissible = dismissible;
  }
}