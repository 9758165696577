import { GenderCode } from './gender.model';
import { RaceGenderCode } from './race-gender.model';

/**
 * Represents an in-process membership enrollment. Please note that the Registration
 * is not the same as Membership. Registration actually precedes the Membership.
 * Membership is created out of a Registration (a.k.a. Membership Enrollment).
 * The idea was to treat Registration (Membership Enrollment) as an ephemeral
 * object and wipe it out of the database after completing the purchase and
 * creating a Membership record. At the time of writing this comment, it doesn't
 * reflect the reality.
 *
 * Registration is also known as Membership Enrollment in other places.
 */
export interface Registration {
  readonly id: string;

  /**
   * Concatenation of first_name + ' ' + last_name.
   *
   * This is being computed and retrieved by the backend.
   */
  readonly full_name: string;

  first_name: string;
  last_name: string;
  email: string;

  /**
   * A valid US phone number.
   *
   * Backend doesn't validate this field at the moment and will
   * allow any string.
   */
  phone_number: string;

  /**
   * Member's date of birth
   *
   * ISO8601-formatted datetime in UTC
   * https://en.wikipedia.org/wiki/ISO_8601
   */
  birthdate: string;

  /**
   * Member's current age
   *
   * This field is computed and retrieved by the back-end.
   */
  readonly current_age: number;

  /**
   * Age as used for races - member's age at the end of the current year.
   *
   * This field is computed and retrieved by the back-end.
   */
  readonly race_age: number;

  /**
   * Member's claimed gender
   */
  gender: GenderCode;

  /**
   * An indicator of whether or not this user must select a race gender.
   *
   * This field is computed and retrieved by the back-end.
   *
   * True if gender is one of Undisclosed or NonBinary,
   * False otherwise
   */
  readonly needs_race_gender: boolean;

  /**
   * Gender as used in races. This can only be Male or Female whereas
   * member can choose not to disclose or claim a non-binary gender.
   */
  race_gender?: RaceGenderCode;

  address_street: string;
  address_street2?: string;
  city: string;

  /**
   * ISO3166-1 Alpha-2 code (2 letters)
   * https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
   */
  state: string;

  /**
   * 5-digit ZIP code (we don't support ZIP+4 code with 9 digits)
   */
  zip: string;

  /**
   * Account password.
   *
   * Please note that this is a required field regardless of whether
   * this member has an existing account or not.
   */
  password: string;

  /**
   * Account password. Serves as a means of verification.
   *
   * Please note that this is a required field regardless of whether
   * this member has an existing account or not.
   */
  password_confirmation: string;

  /**
   * Indicates whether this member is a collegiate cyclist.
   * Please note that when this is True, collegiate_club_id becomes a required field.
   */
  is_collegiate_cyclist?: boolean;
  collegiate_club_id?: number;

  /**
   * Indicate whether this member is a domestic cyclist.
   */
  is_domestic_cyclist?: boolean;

  /**
   * Indicates whether this member is member of a domestic cycling club.
   * Please note that when this is True, domestic_club_id becomes a required field.
   */
  is_domestic_club_member?: boolean;
  domestic_club_id?: number;

  disciplines?: MembershipCategoryDiscipline[];
  racing_categories?: MembershipRacingCategory[];

  /**
   * Adults who have regular contact with juniors need to check this
   * option and they'll subsequently receive an email from USAC.
   */
  has_regular_contact_with_junior?: boolean;

  /**
   * ID of the signed waiver record for this member
   */
  readonly sig_id?: number;

  person_type: PersonTypes;

  /**
   * The date when this registration was initially created.
   *
   * ISO8601-formatted datetime in UTC
   * https://en.wikipedia.org/wiki/ISO_8601
   */
  readonly created_at: string;

  /**
   * The date when this registration was last updated.
   *
   * ISO8601-formatted datetime in UTC
   * https://en.wikipedia.org/wiki/ISO_8601
   */
  readonly updated_at: string;

  membership_type?: MembershipType;

  /**
   * Indicates if this member opted-in for a family membership.
   */
  is_family?: boolean;

  /**
   * Indicates if this member opted-in for a premium membership.
   * True if membership_level === 2 (Premium) and vice versa.
   */
  is_premium: boolean;

  /**
   * Indicates user's preferred membership level.
   *
   * This field is computed and retrieved by the back-end based on the is_premium
   * flag.
   */
  membership_level?: MembershipLevel;

  readonly links: RegistrationLinks;

  /**
   * This registration's status
   */
  readonly status: MembershipEnrollmentStatus;

  /**
   * Indicates if this member opted-in for auto-renewals
   */
  is_auto_renewal: boolean;

  /**
   * Indicates if this member opted-in for a podium package
   */
  is_podium: boolean;

  /**
   * Indicates if this member opted-in for a pro license.
   */
  is_professional?: boolean;

  /**
   * Club ID for selected pro team.
   */
  pro_club_id?: number;

  /**
   * Indicates if this member opted-in for an international license.
   */
  is_international?: boolean;

  /**
   * Union Cycliste Internationale code.
   */
  uci_id?: number;

  dual_citizen?: boolean;
  dual_citizen_country?: string;
  permanent_resident?: boolean;

  /**
   * ISO 3166-1 alpha-3. For Legal or jurisdistic purposes.
   */
  citizenship?: string;

  /**
   * ISO 3166-1 alpha-3. For Legal or jurisdistic purposes.
   */
  racing_nationality?: string;

  /**
   * Foreign key to `license_type.id`.
   */
  license_type_id?: number;

  intl_club_id?: number;
  intl_license_level?: LicenseLevel;
  intl_bmx_category?: number;

  /**
   * The order is used for grouping of the family plan members' enrollments
   */
  readonly order: MembershipOrderSummary;

  readonly account_id?: number;
  readonly profile_id?: number;
  readonly membership_id?: number;
  readonly license_id?: number;

  /**
   * Indicates if this member paid for a family membership.
   */
  readonly is_family_paid?: boolean;

  /**
   * Indicates if this member paid for a premium license.
   */
  readonly is_premium_paid?: boolean;

  /**
   * Indicates if this member paid for a podium package.
   */
  readonly is_podium_paid?: boolean;

  /**
   * Indicates if this member paid for a professional license.
   */
  readonly is_professional_paid?: boolean;

  /**
   * Indicates if this member paid for an international license.
   */
  readonly is_international_paid?: boolean;

  /**
   * Shopify Checkout ID (UUID).
   * Backend will back-fill this field.
   */
  readonly checkout_id?: string;

  /**
   * Indicates if this member opted-in for spot insurance coverage.
   */
  has_spot_insurance: boolean;

  /**
   * Indicates if this member opted-in for spot auto-renewals.
   */
  spot_auto_renewal: boolean;

  /**
   * Indicates if this member paid for spot insurance coverage.
   */
  is_spot_paid: boolean;
}

export enum MembershipType {
  Individual = 1,
  Family = 2,
}

export enum MembershipEnrollmentStatus {
  MatchingAccount = 'matching-account',
  InProcess = 'in-process',
  ActiveExistingMembership = 'active-existing',
  Duplicate = 'duplicate',
  Expired = 'expired',
  Paid = 'paid',
  Validated = 'validated',
}

export enum PersonTypes {
  JUNIOR = 'Junior',
  ADULT = 'Adult',
}

export enum MembershipLevel {
  Standard = 1,
  Premium = 2,
}

export enum ShirtType {
  Male = 'M',
  Female = 'F',
}

export enum ShirtSize {
  ExtraSmall = 'XS',
  Small = 'S',
  Medium = 'M',
  Large = 'L',
  ExtraLarge = 'XL',
  ExtraExtraLarge = '2XL',
}

export interface RegistrationLinks {
  readonly self: string;
}

export interface MembershipOrderSummary {
  readonly order_id: string;
  readonly items: number;
  readonly adults: number;
  readonly juniors: number;
  readonly total: number;
}

export interface MembershipCategoryDiscipline {
  /**
   * Discipline ID
   */
  cd_id: number;
  cd_name?: string;
  is_primary: boolean;
}

export interface MembershipRacingCategory {
  /**
   * Discipline ID
   */
  cd_id: number;

  /**
   * Membership level. This should not be necessary as the backend
   * can track down this value through the provided cd_type (lc_id).
   */
  cd_level: MembershipLevel;

  /**
   * License Category ID
   */
  cd_type: number;
}

// All types that appear below this comment are client-side only

export interface RegistrationLocal {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  person_type: PersonTypes;
  gender: string;
  race_gender?: string;
  guardian?: Guardian;
  phone_number: string;
  birthdate: string;
  address_street: string;
  address_street2: string;
  city: string;
  state: string;
  zip: string;
  is_collegiate?: boolean;
  is_international?: boolean;
  is_professional?: boolean;
  has_spot_insurance?: boolean;
}

export interface RegistrationCreateResponse {
  cookie: string;
  enrollment: Registration;
  profile_id: number;
  success?: string;
  user_token: string;
}

export interface Guardian {
  first_name: string;
  last_name: string;
  email: string;
  relationship: GuardianRelationship;
}

export enum GuardianRelationship {
  PARENT = 'Parent',
  GUARDIAN = 'Legal Guardian',
}

export enum LicenseLevel {
  Elite = 'E',
  Junior = 'J',
  Master = 'M',
  U23 = 'U',
}

export interface AddOnPayload {
  enrollmentId: string;
  items: AddOnItem[];
}

export interface AddOnItem {
  sku: string;
  productTitle: string;
  variantTitle: string;
  price: string;
  variantId: string;
}
