
<form [formGroup]="collegiateForm" novalidate>
  <!-- If user participates in events -->
  <div *ngIf="raceSelected">
    <!-- Collegiate Questions -->
    <div class="form-card">
      <div class="row">
        <div class="col-12">
          <h3 class="form-title d-flex align-items-center">
            Are you a Collegiate cyclist?
            <i
              class="icon icon-information small ml-2"
              placement="bottom"
              [ngbTooltip]="tipContent"
              tooltipClass="custom-tooltip"
              [openDelay]="250"
              tabindex="0"
            ></i>
            <ng-template #tipContent
              ><p>
                Please choose the collegiate club you are currently associated with.
              </p>
              <p class="mb-0">
                If your club is not listed, select the 'I don't see my School/My School is not active' option to proceed.
              </p>
            </ng-template>
          </h3>
        </div>

        <div class="col-4 col-md-2">
          <div class="custom-control custom-radio ">
            <input
              formControlName="is_collegiate_cyclist"
              class="custom-control-input"
              type="radio"
              name="is_collegiate_cyclist"
              id="is-collegiate-true"
              [value]="true"
            />
            <label class="custom-control-label" for="is-collegiate-true">
              Yes
            </label>
          </div>
        </div>
        <div class="col-6 col-md-1">
          <div class="custom-control custom-radio custom-control-inline">
            <input
              formControlName="is_collegiate_cyclist"
              class="custom-control-input"
              type="radio"
              name="is_collegiate_cyclist"
              id="is-collegiate-false"
              [value]="false"
              checked
            />
            <label class="custom-control-label" for="is-collegiate-false"> No </label>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="is_collegiate_cyclist.value">
        <div class="col-12 col-md-8 col-xl-6">
          <p class="collegiate-club mb-2 mt-4">Collegiate club <span class="star">*</span></p>

          <ng-select
            [items]="collegiateClubs$ | async"
            bindLabel="club_name"
            [hideSelected]="true"
            [clearable]="false"
            [loading]="collegiateClubsLoading"
            [typeahead]="collegiateClubsInput$"
            [minTermLength]="2"
            [trackByFn]="trackClubsBy"
            typeToSearchText="Please enter 2 or more characters"
            notFoundText="No Collegiate Clubs Found"
            placeholder="Enter Club Name"
            formControlName="collegiate_club"
          >
          </ng-select>

          <p class="collegiate__small">
            Collegiate riders must select an active collegiate club.
          </p>
        </div>
      </div>

      <div *ngIf="collegiateClub.value" class="club-box">
        <div class="row">
          <div class="col-12">
            <span class="club-box__small">Collegiate Club</span>
          </div>
          <div class="col-md-4">
            <strong class="club-box__name">
              {{ collegiateClub.value.club_name || collegiateClub.value.club_id }}
            </strong>
          </div>

          <div class="club-box__content col-md-8 d-flex align-items-center">
            <img
              class="club-box__flag"
              src="assets/images/school.svg"
              alt=""
            />

            <p class="club-box__description">
              This club will be used for all of your collegiate racing disciplines. Only
              one collegiate club can be assigned per collegiate member.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
