<div class="header-wrapper">
  <app-individual-membership-header></app-individual-membership-header>
</div>
<div class="col-12 col-lg-10 offset-lg-1 p-4">
  <!-- <div class="header-container d-flex align-items-center">
    <h2 class="header m-0">USA Cycling Membership</h2>
  </div>
  <h5 class="sub-header">Juniors age 6 and up and Adults 19 and older</h5>
  <div class="col-12 col-md-8 px-0 pb-4"> -->
    <!-- <p class="font-weight-bold m-0">
      Not Racing This Year?
      <a class="text-primary" [href]="shopifyStoreUrl">Buy a Supporting Membership or Make a Donation</a>
    </p> -->
  <!-- </div> -->

  <!-- <div class="existing-member-container d-flex align-items-center row p-3 m-0" *appShowAuthed>
    <div class="col-2 col-md-1">
      <img src="assets/user-check.svg" alt="User" />
    </div>
    <div class="col-10 col-md-8">
      <p class="m-0">
        Already have a USA Cycling membership or have a partially completed membership to finish?
        <a class="text-primary" href="/" (click)="login()">Log in</a>
        to get started. Or Sign Up to
        <a class="text-primary" [href]="signupUrl">Register an Account</a>
        if you don't need a membership.
      </p>
    </div>
    <div
      class="d-flex justify-content-center justify-content-md-start col-12 col-md-2 offset-md-1 pt-4 pt-md-0"
    >
      <button type="button" class="btn btn-secondary btn-block" (click)="login()">LOG IN</button>
    </div>
  </div> -->

  <h3 class="subsection-header text-center mt-5">Ooops! Looks like this page has been relocated.</h3>
  <p class="text-center">Please click the button below to visit the desired page</p>
  <div class="header-border mb-5"></div>

  <div class="row text-center">
      <button type="button" class="btn btn-block btn-primary mx-1" (click)="visitShopifyStore()">
        Visit Membership
      </button>
    <!-- <app-membership-option *ngFor="let membership of memberships"
      class="col-md-4 px-lg-0"
      [membershipOption]="membership.membershipOption"
      [benefitsSubheader]="membership.benefitsSubheader"
      [benefitsSubtext]="membership.benefitsSubtext"
      [benefitsInfoText]="membership.benefitsInfoText"
      [benefits]="membership.benefits"
      [imageSrc]="membership.imageSrc"
      [btn1Text]="membership.btn1Text"
      [btn2Text]="membership.btn2Text"
    ></app-membership-option> -->
  </div>

  <!-- <h3 class="subsection-header text-center mt-5">COMPARE BENEFITS</h3>
  <div class="header-border"></div>

  <app-benefits-chart></app-benefits-chart> -->

  <app-individual-membership-footer></app-individual-membership-footer>
</div>
