export class Toast {
  type: string;
  title: string;
  message: string;
  show: boolean;
  autohide: boolean;
  delay: number;
  timeAgo: string;
  time: number;

  constructor(
    type: string,
    title: string,
    message: string,
    show = true,
    autohide = true,
    delay = 10000,
    timeAgo = 'Just now',
    time: number = Date.now()
  ) {
    this.type = type;
    this.title = title;
    this.message = message;
    this.show = show;
    this.autohide = autohide;
    this.delay = delay;
    this.timeAgo = timeAgo;
    this.time = time;
  }

  get key(): string {
    return `${this.type}-${this.title}-${this.message}`
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]/gi, '-');
  }
}
