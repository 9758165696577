import { Component, EventEmitter, Input, OnInit, Output, } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AddOnItem, Product, ProductVariant, SelectOption } from 'src/app/core/models';
import { ShopifyService } from 'src/app/core/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Image } from 'src/app/core/services/shopify.types';
import { AddOnsFacade } from 'src/app/core/facades';

@Component({
  selector: 'app-member-offer-modal',
  templateUrl: './member-offer-modal.component.html',
  styleUrls: ['./member-offer-modal.component.scss'],
})
export class MemberOfferModalComponent implements OnInit {
  @Input() product: Product;
  @Input() addOnItems: AddOnItem[];
  @Output() cartUpdate: EventEmitter<any> = new EventEmitter(null);

  addedToCart = false;
  selectedVariant: ProductVariant;
  variantOptions: SelectOption[] = [];
  variantForm: FormGroup;

  get f(): any {
    return this.variantForm.controls;
  }

  constructor(
    private addOnsFacade: AddOnsFacade,
    private fb: FormBuilder,
    private modal: NgbModal,
    private shopifyService: ShopifyService
  ) { }

  ngOnInit(): void {
    if (this.hasVariants) {
      this.createForm();
      this.subscribeToForm();
    } else {
      this.selectedVariant = this.defaultVariant;
    }

    if (this.addOnItems) {
      this.itemSelected();
    }
  }

  createForm(): void {
    this.variants.forEach((variant) => {
      this.variantOptions.push({ label: variant.title, value: variant.sku });
    });
    this.variantForm = this.fb.group({
      variant: [null, [Validators.required]],
    });
  }

  subscribeToForm(): void {
    this.f.variant.valueChanges.subscribe((option: SelectOption) => {
      this.selectedVariant = this.variants.find((variant) => variant.sku === option.value);
      if (this.addedToCart && !this.addOnItems) {
        // Emit event with selected variant & addToCart value, if changing after adding to cart
        this.submitCartUpdateEvent();
      }
    });
  }

  control(variant: ProductVariant): FormControl {
    return this.f[variant.sku];
  }

  toggleAddToCart(): void {
    if (this.selectedVariant) {
      this.addedToCart = !this.addedToCart;
      // Emit event with selected variant & addToCart value
      this.submitCartUpdateEvent();
    }
  }

  submitCartUpdateEvent(): void {
    this.cartUpdate.emit({
      product: this.product,
      variant: this.selectedVariant,
      addedToCart: this.addedToCart,
    });
  }

  imageSrc(image: Image): string {
    return image ? image.originalSrc : null;
  }

  imageAlt(image: Image): string {
    return image ? image.altText : null;
  }

  get productDescription(): string {
    return this.product.description;
  }

  itemSelected(): void {
    const itemSelected = this.addOnItems.find(
      (addOnItems) => addOnItems.productTitle === this.product.title
    );
    this.addedToCart = !!itemSelected;

    if (itemSelected && this.hasVariants) {
      const variantOption = this.variantOptions.find((option) => option.value === itemSelected.sku);
      this.f.variant.setValue(variantOption);
    }
  }

  get btnClass(): string {
    return this.inCart() ? 'btn-primary' : 'btn-secondary';
  }

  inCart(): boolean {
    const selectedAddons = [...this.addOnsFacade.adult.items, ...this.addOnsFacade.junior.items];
    if (this.selectedVariant) {
      return selectedAddons.some(item => item.sku === this.selectedVariant.sku)
    }
    return false
  }

  get btnText(): string {
    return this.inCart() ? 'Remove' : 'Add to Cart';
  }

  get variants(): ProductVariant[] {
    return this.product.variants;
  }

  get defaultVariant(): ProductVariant {
    return this.shopifyService.defaultVariant(this.product);
  }

  get compareAtPrice(): string {
    return this.selectedVariant
      ? this.selectedVariant.compareAtPrice
      : this.defaultVariant.compareAtPrice;
  }

  get price(): string {
    return this.selectedVariant && !this.hasPaymentPlan
      ? this.selectedVariant.priceV2.amount
      : this.defaultVariant.priceV2.amount;
  }

  get hasVariants(): boolean {
    return this.variants.length > 1;
  }

  get hasPaymentPlan(): boolean {
    return this.product.tags.some((tag) => tag.value === 'has-payment-plan');
  }

  get productImages(): Image[] {
    return this.product.images;
  }

  get primaryProductImage(): Image {
    return this.productImages[0];
  }

  get sizeChartImages(): Image[] {
    return this.productImages.slice(1);
  }

  get hasSizeChartImages(): boolean {
    return !!this.sizeChartImages.length;
  }

  get abbreviatedTitle(): string {
    return this.shopifyService.abbreviatedTitle(this.product);
  }

  get productType(): string {
    return this.shopifyService.odaProductType(this.product);
  }

  closeModal(): void {
    this.modal.dismissAll();
  }

}