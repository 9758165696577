import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { SignedWaiver, Waiver } from '../models';

import { BaseService } from './base-service';

@Injectable({
  providedIn: 'root',
})
export class WaiverService extends BaseService {
  bylawsLink = 'https://usacycling.org/docs/bylaws-USA-Cycling';
  codeOfConductLink = 'https://usacycling.org/about-us/governance/code-conduct';
  safeSportLink = 'https://usacycling.org/safesport';
  rulesAndRegulationsLink = 'https://usacycling.org/resources/rulebook';

  get codeOfConduct(): string {
    return (
      'I acknowledge and agree that as a member of USA Cycling ' +
      `I am bound by and will comply with USA Cycling's <a href=${this.bylawsLink} target='_blank' class='text-primary'>Bylaws</a>, ` +
      `<a href=${this.codeOfConductLink} target='_blank' class='text-primary'>Code of Conduct</a>, ` +
      `<a href=${this.safeSportLink} target='_blank' class='text-primary'>Safe Sport Program</a>, and all other USA Cycling ` +
      `<a href=${this.rulesAndRegulationsLink} target='_blank' class='text-primary'>rules, regulations and policies</a>.`
    );
  }

  getWaiver(query = ''): Observable<Waiver> {
    return this.http.get<Waiver>(this.url('waiver' + query), this.options).pipe(
      tap(() => this.log('loaded waiver')),
      catchError(this.handleError<Waiver>('getWaiver', null))
    );
  }

  getOneDayWaiver(eventDateIds: number[]): Observable<Waiver> {
    let eventDateQuery = '?section=eventDate';
    eventDateIds.forEach((id) => {
      eventDateQuery += `&section_data[]=${id}`;
    });
    return this.getWaiver(eventDateQuery);
  }

  signWaiver(signedWaiver: SignedWaiver): Observable<number> {
    return this.http.post<number>(this.url('waiver'), signedWaiver, this.options).pipe(
      tap(() => this.log('signed waiver')),
      catchError(
        this.handleError<number>('signWaiver', null, { signedWaiver })
      )
    );
  }
}
