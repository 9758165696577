import { NgModule } from '@angular/core';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '../shared';

import { LicenseDetailComponent } from './atoms';
import { LicensePostPurchaseComponent, TechnicalLicensesComponent } from './ecosystems';
import { RideUpgradeOptionsComponent } from './ecosystems/ride-upgrade-options/ride-upgrade-options.component';
import { ManagementRoutingModule } from './management-routing.module';
import {
  LicenseCardComponent,
  LicenseStepProgressBarComponent,
  MemberLicensesComponent,
  RequirementsModalComponent,
} from './molecules';
import {
  LicenseCartComponent,
  LicenseMenuAccordionComponent,
  TechnicalLicenseLandingComponent,
  TechnicalLicenseMenuComponent,
} from './organisms';

// const modals = [

// ];
@NgModule({
  declarations: [
    // Atoms
    LicenseDetailComponent,

    // Molecules
    LicenseCardComponent,
    LicenseStepProgressBarComponent,
    MemberLicensesComponent,
    RequirementsModalComponent,

    // Organisms
    LicenseCartComponent,
    LicenseMenuAccordionComponent,
    TechnicalLicenseLandingComponent,
    TechnicalLicenseMenuComponent,

    // Ecosystems
    LicensePostPurchaseComponent,
    RideUpgradeOptionsComponent,
    TechnicalLicensesComponent,

    // ...modals,
  ],
  imports: [SharedModule, ManagementRoutingModule, NgbAlertModule],
  exports: [LicenseCardComponent, MemberLicensesComponent],
})
export class ManagementModule {}
