import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ShopifyService } from 'src/app/core/services';

@Component({
  selector: 'app-upgrade-to-premium-modal',
  templateUrl: './upgrade-to-premium-modal.component.html',
  styleUrls: ['./upgrade-to-premium-modal.component.scss'],
})
export class UpgradeToPremiumModalComponent {
  @Input()
  newCategory: any;

  premiumUpgradePrice = this.shopifyService.premiumUpgradePrice;

  constructor(public activeModal: NgbActiveModal, private shopifyService: ShopifyService) {}

  upgrade(): void {
    this.activeModal.close();
  }

  downgrade(): void {
    this.activeModal.dismiss();
  }
}
