import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-restricted-user-modal',
  templateUrl: './restricted-user-modal.component.html',
  styleUrls: ['./restricted-user-modal.component.scss'],
})
export class RestrictedUserModalComponent {
  constructor(public activeModal: NgbActiveModal) {}

  get restrictedUserMessage(): string {
    return 'Thank you for your interest in USA Cycling. There is an issue processing your request.';
  }

  get modalTitle(): string {
    return 'Action Prohibited';
  }
}
