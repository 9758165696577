import { MembershipLevel } from './registration.model';
/**
 * The Membership represents an existing and paid for membership. Not to be
 * confused for Membership Enrollment which precedes the creation of a Membership.
 */
export interface Membership {
  membership_id: number;

  /**
   * Membership level. Indicates whether this user is a Premium or Standard user.
   */
  membership_level: MembershipLevel;

  /**
   * Membership level (name). Indicates whether this user is a Premium or Standard user.
   */
  membership_level_name: MembershipLevelName;

  /**
   * Status of this membership based on the expiration date
   */
  membership_status: MembershipStatus;

  /**
   * Indicates if the membership plan includes Spot insurance coverage.
   */
  has_spot_insurance: boolean;

  /**
   * The date when this membership was paid for.
   *
   * ISO8601-formatted datetime in UTC
   * https://en.wikipedia.org/wiki/ISO_8601
   */
  membership_purchase_date: string;

  /**
   * The expiration date for this membership. Can be in the past or future.
   *
   * ISO8601-formatted datetime in UTC
   * https://en.wikipedia.org/wiki/ISO_8601
   */
  membership_expiration_date: string;

  /**
   * Indicates if this membership is a family membership or individual
   * It's being used by the legacy app.
   */
  readonly profile_family: number;
}

export enum MembershipLevelName {
  Standard = 'Standard',
  Premium = 'Premium',
}

export enum MembershipStatus {
  Expired = 'Expired',
  Active = 'Active',
}
