import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';

import { Address } from '../models';
import {
  Guardian,
  GuardianRelationship,
  PersonTypes,
  Registration,
  RegistrationLocal,
} from '../models/registration.model';

export enum Plans {
  INDIVIDUAL = 'individual',
  FAMILY = 'family',
}

export interface RegistrationState {
  registration: RegistrationLocal;
  memberInfoFilled: boolean;
  yourInterestsFilled: boolean;
  raceDetailsFilled: boolean;
  plan: Plans;
  isIndividualPlan: boolean;
  isFamilyPlan: boolean;
  isRenewingPremium: boolean;
  isRace: boolean;
  isCollegiate: boolean;
  isInternational: boolean;
  isPro: boolean;
  bypassCheckExistingAccount: boolean;
  orderId: string;
  guardian?: Guardian;
}

// tslint:disable-next-line: variable-name
let _state: RegistrationState = {
  registration: {
    email: '',
    id: '',
    first_name: '',
    last_name: '',
    person_type: PersonTypes.ADULT,
    gender: '',
    race_gender: '',
    phone_number: '',
    birthdate: '',
    address_street: '',
    address_street2: '',
    city: '',
    state: '',
    zip: '',
    is_collegiate: false,
    is_international: false,
    is_professional: false,
    has_spot_insurance: false,
  },
  plan: Plans.INDIVIDUAL,
  memberInfoFilled: false,
  raceDetailsFilled: false,
  yourInterestsFilled: false,
  isIndividualPlan: true,
  isFamilyPlan: false,
  isRenewingPremium: false,
  isRace: false,
  isCollegiate: false,
  isInternational: false,
  isPro: false,
  bypassCheckExistingAccount: false,
  orderId: '',
  guardian: {
    email: '',
    first_name: '',
    last_name: '',
    relationship: GuardianRelationship.PARENT,
  },
};

@Injectable({
  providedIn: 'root',
})
export class RegistrationFacade {
  get registrationId(): string {
    return this.store.getValue().registration.id || '';
  }

  get getOrderId(): string {
    return this.store.getValue().orderId || '';
  }

  get plan(): Plans {
    return this.store.getValue().plan;
  }

  get registration(): RegistrationLocal {
    return this.store.getValue().registration;
  }

  get bypassCheckExistingAccount(): boolean {
    return this.store.getValue().bypassCheckExistingAccount;
  }

  get isRenewingPremium(): boolean {
    return this.store.getValue().isRenewingPremium;
  }

  get isRace(): boolean {
    return this.store.getValue().isRace;
  }

  get currentState(): RegistrationState {
    return this.store.getValue();
  }

  private readonly store: BehaviorSubject<RegistrationState> = new BehaviorSubject<
    RegistrationState
  >(this.restoreState() || _state);

  private readonly state$: Observable<RegistrationState> = this.store.asObservable();

  registration$: Observable<RegistrationLocal> = this.state$.pipe(
    map((state: RegistrationState) => {
      return state.registration;
    }),
    distinctUntilChanged()
  );

  plan$: Observable<Plans> = this.state$.pipe(
    map((state: RegistrationState) => {
      return state.plan;
    }),
    distinctUntilChanged()
  );

  registrationId$: Observable<string> = this.state$.pipe(
    map((state: RegistrationState) => {
      return state.registration.id;
    }),
    distinctUntilChanged()
  );

  orderId$: Observable<string> = this.state$.pipe(
    map((state: RegistrationState) => {
      return state.orderId;
    }),
    distinctUntilChanged()
  );

  memberInfoFilled$: Observable<boolean> = this.state$.pipe(
    map((state: RegistrationState) => {
      return state.memberInfoFilled;
    }),
    distinctUntilChanged()
  );

  yourInterestsFilled$: Observable<boolean> = this.state$.pipe(
    map((state: RegistrationState) => {
      return state.yourInterestsFilled;
    }),
    distinctUntilChanged()
  );

  raceDetailsFilled$: Observable<boolean> = this.state$.pipe(
    map((state: RegistrationState) => state.raceDetailsFilled),
    distinctUntilChanged()
  );

  isIndividualPlan$: Observable<boolean> = this.state$.pipe(
    map((state: RegistrationState) => {
      return state.plan === Plans.INDIVIDUAL;
    }),
    distinctUntilChanged()
  );

  isFamilyPlan$: Observable<boolean> = this.state$.pipe(
    map((state: RegistrationState) => {
      return state.plan === Plans.FAMILY;
    }),
    distinctUntilChanged()
  );

  isRenewingPremium$: Observable<boolean> = this.state$.pipe(
    map((state: RegistrationState) => {
      return state.isRenewingPremium;
    }),
    distinctUntilChanged()
  );

  guardian$: Observable<Guardian> = this.state$.pipe(
    map((state: RegistrationState) => {
      return state.guardian;
    }),
    distinctUntilChanged()
  );

  isInternationalSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isInternational$: Observable<boolean> = this.state$.pipe(
    map((state: RegistrationState) => {
      return state.isInternational;
    }),
    distinctUntilChanged(),
    tap((isInternational: boolean) => {
      this.isInternationalSubject.next(isInternational);
    })
  );

  isCollegiateSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isCollegiate$: Observable<boolean> = this.state$.pipe(
    map((state: RegistrationState) => {
      return state.isCollegiate;
    }),
    distinctUntilChanged(),
    tap((isCollegiate: boolean) => {
      this.isCollegiateSubject.next(isCollegiate);
    })
  );

  /**
   * Viewmodel that resolves once all the data is ready (or updated)...
   */
  vm$: Observable<RegistrationState> = combineLatest([
    this.registration$,
    this.memberInfoFilled$,
    this.yourInterestsFilled$,
    this.raceDetailsFilled$,
    this.plan$,
    this.isIndividualPlan$,
    this.isFamilyPlan$,
    this.guardian$,
    this.orderId$,
  ]).pipe(
    map(
      ([
        registration,
        memberInfoFilled,
        yourInterestsFilled,
        raceDetailsFilled,
        plan,
        isIndividualPlan,
        isFamilyPlan,
        guardian,
        orderId,
      ]) => ({
        registration,
        memberInfoFilled,
        yourInterestsFilled,
        raceDetailsFilled,
        plan,
        isIndividualPlan,
        isFamilyPlan,
        guardian,
        orderId,
      })
    )
    // Type casting, infers wrong type without this
  ) as Observable<RegistrationState>;

  /** Checks is current plan is family */
  isFamilyPlan(): boolean {
    return this.store.getValue().plan === Plans.FAMILY;
  }

  /** Checks is current plan is individual */
  isIndividualPlan(): boolean {
    return this.store.getValue().plan === Plans.INDIVIDUAL;
  }

  /** Resets registration data store to default values */
  reset(): void {
    this.updateState({
      ..._state,
      registration: {
        email: '',
        id: '',
        first_name: '',
        last_name: '',
        person_type: PersonTypes.ADULT,
        gender: '',
        phone_number: '',
        birthdate: '',
        address_street: '',
        address_street2: '',
        city: '',
        state: '',
        zip: '',
      },
      isInternational: false,
      isCollegiate: false,
      orderId: null,
      plan: Plans.INDIVIDUAL,
      memberInfoFilled: false,
      raceDetailsFilled: false,
      yourInterestsFilled: false,
    });
  }

  /** Resets registration data store to default values */
  resetupdateFromProfileFamily(newFamilyMember: any): void {
    this.updateState({
      ..._state,
      registration: {
        email: '',
        id: '',
        first_name: newFamilyMember.first_name,
        last_name: newFamilyMember.last_name,
        person_type: newFamilyMember.person_type,
        gender: '',
        phone_number: '',
        birthdate: '',
        address_street: '',
        address_street2: '',
        city: '',
        state: '',
        zip: '',
      },
      plan: Plans.FAMILY,
      memberInfoFilled: false,
      raceDetailsFilled: false,
      yourInterestsFilled: false,
    });
  }

  /** Updates guardian info for junior member */
  updateGuardian(guardian: Guardian): void {
    this.updateState({ ..._state, guardian });
  }

  /** Changes what plan is currently selected */
  updatePlan(plan: Plans): void {
    this.updateState({
      ..._state,
      plan,
      isIndividualPlan: plan === Plans.INDIVIDUAL,
      isFamilyPlan: plan === Plans.FAMILY,
    });
  }

  /** Changes what person type is currently selected */
  updatePersonType(person_type: PersonTypes): void {
    this.updateState({
      ..._state,
      registration: {
        ..._state.registration,
        person_type,
      },
    });
  }

  /** Updates if collegiate desgination is currently selected */
  updateCollegiate(is_collegiate: boolean): void {
    this.updateState({
      ..._state,
      isCollegiate: is_collegiate,
      registration: {
        ..._state.registration,
        is_collegiate,
      },
    });
  }

  /** Updates if international license is currently selected */
  updateInternational(is_international: boolean): void {
    this.updateState({
      ..._state,
      isInternational: is_international,
      registration: {
        ..._state.registration,
        is_international,
      },
    });
  }

  /** Updates if pro license is currently selected */
  updateProfessional(is_professional: boolean): void {
    this.updateState({
      ..._state,
      isPro: is_professional,
      registration: {
        ..._state.registration,
        is_professional,
      },
    });
  }

  updateRegistration(newRegistration: Registration): void {
    const registration: RegistrationLocal = {
      id: newRegistration.id,
      email: newRegistration.email,
      first_name: newRegistration.first_name,
      last_name: newRegistration.last_name,
      person_type: newRegistration.person_type,
      gender: newRegistration.gender,
      race_gender: newRegistration.race_gender,
      phone_number: newRegistration.phone_number,
      birthdate: newRegistration.birthdate,
      address_street: newRegistration.address_street,
      address_street2: newRegistration.address_street2,
      city: newRegistration.city,
      state: newRegistration.state,
      zip: newRegistration.zip,
      is_collegiate: newRegistration.is_collegiate_cyclist,
      is_international: newRegistration.is_international,
      is_professional: newRegistration.is_professional,
      has_spot_insurance: newRegistration.has_spot_insurance,
    };

    this.updateState({ ..._state, registration });
  }

  setRegistration(registration: any): void {
    const state = this.store.getValue();

    this.updateState({ ...state, registration });
  }

  updateMemberInfoFilled(memberInfoFilled: boolean): void {
    this.updateState({ ..._state, memberInfoFilled });
  }

  updateOrderId(orderId: string): void {
    this.updateState({ ..._state, orderId });
  }

  updateYourInterestsFilled(yourInterestsFilled: boolean): void {
    this.updateState({ ..._state, yourInterestsFilled });
  }

  updateRaceDetailsFilled(raceDetailsFilled: boolean): void {
    this.updateState({ ..._state, raceDetailsFilled });
  }

  updateIsRenewingPremium(isRenewingPremium: boolean): void {
    this.updateState({ ..._state, isRenewingPremium });
  }

  updateIsRace(isRace: boolean): void {
    this.updateState({ ..._state, isRace });
  }

  updateBypassCheckExistingAccount(bypassCheckExistingAccount: boolean): void {
    this.updateState({ ..._state, bypassCheckExistingAccount });
  }

  updateRegistrationByKey(key: string, value: any): void {
    this.updateState({
      ..._state,
      registration: {
        ..._state.registration,
        [key]: value,
      },
    });
  }

  updateFromProfile(
    id: string,
    first_name: string,
    last_name: string,
    email: string,
    person_type: PersonTypes,
    gender: string,
    membership_type: number,
    orderId: string
  ): void {
    this.updateState({
      ..._state,
      plan: membership_type === 1 ? Plans.INDIVIDUAL : Plans.FAMILY,
      isIndividualPlan: membership_type === 1 ? true : false,
      isFamilyPlan: membership_type === 1 ? false : true,
      orderId,
      registration: {
        ..._state.registration,
        id,
        first_name,
        last_name,
        email,
        person_type,
        gender,
      },
    });
  }

  setAddressFromProfile(address: Address): void {
    if (address) {
      this.updateState({
        ..._state,
        registration: {
          ..._state.registration,
          address_street: address.address_street,
          address_street2: address.address_street2,
          city: address.address_city,
          state: address.address_state,
          zip: address.address_postal_code,
        },
      });
    }
  }

  updateState(state: RegistrationState): void {
    this.store.next((_state = state));

    localStorage.setItem('registration-state', JSON.stringify(this.store.getValue()));
  }

  private restoreState(): RegistrationState {
    return JSON.parse(localStorage.getItem('registration-state'));
  }
}
