<app-toast [toasts]="toasts"></app-toast>

<div class="container">
  <div class="row">
    <div class="col-12">
      <app-status-indicator-header
        [user]="user"
        [allMemberships]="allMemberships"
        [firstName]="user.profile_first_name"
        [lastName]="user.profile_last_name"
        [email]="user.profile_email"
        [loading]="membershipsLoading"
      ></app-status-indicator-header>
      <hr>
    </div>
    <div class="col-12" [ngClass]="{'col-lg-9': licensesSelected}">
      <!-- Add status indicators here, add conditional for loggedIn -->
      <app-license-step-progress-bar
        class="mb-3"
        currentStep="license-menu"
      ></app-license-step-progress-bar>

      <app-loader *ngIf="loading"></app-loader>

      <div class="mt-5" *ngIf="!loading">
        <!-- Membership Information & Licenses -->
        <div class="mb-5">
          <!-- use this below component as a template for status indicator component -->
          <app-member-licenses [user]="user" [licenseGroups]="licenseGroups"></app-member-licenses>
        </div>

        <!-- License Purchase Menu -->
        <div class="pt-5">
          <app-form-title [customClasses]="['mb-4']">Technical Licenses</app-form-title>
          <h3>Select License Type</h3>
          <app-form-subtitle [customClasses]="['text-left']">
            Open a category to see your available licenses.
          </app-form-subtitle>

          <div *ngFor="let type of licenseTypes">
            <app-license-menu-accordion *ngIf="type.license_categories.length"
              [licenseType]='type'
              (selectedLicenses)='updateSelectedLicenses($event)'
            ></app-license-menu-accordion>
          </div>
        </div>

      </div>
    </div>

    <app-license-cart *ngIf="licensesSelected"
      [licenseCount]="licenseCount"
      [selectedLicenses]="selectedLicenses"
      [user]="user"
    ></app-license-cart>

  </div>
</div>
