import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject, concat, of, throwError } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  switchMap,
  tap,
} from 'rxjs/operators';
import { ClubsService } from 'src/app/core';
import { Club, Registration } from 'src/app/core/models';

@Component({
  selector: 'app-collegiate-questions',
  templateUrl: './collegiate-questions.component.html',
  styleUrls: ['./collegiate-questions.component.scss'],
})
export class CollegiateQuestionsComponent implements OnInit, OnChanges {
  @Input() registration: Registration;
  @Input() raceSelected = false;
  @Input() isSubmitting = false;
  @Input() preventEmit = false;

  @Output() formValues: EventEmitter<FormGroup> = new EventEmitter();

  collegiateClubs$: Observable<Club[]>;
  collegiateClubsInput$ = new Subject<string>();
  collegiateClubsLoading = false;
  customCollegiateClubError = '';

  collegiateForm: FormGroup;

  get f(): any {
    return this.collegiateForm.controls;
  }

  get is_collegiate_cyclist(): FormControl {
    return this.f.is_collegiate_cyclist as FormControl;
  }

  get collegiateClub(): FormControl {
    return this.f.collegiate_club as FormControl;
  }

  get collegiateClubId(): FormControl {
    return this.f.collegiate_club_id as FormControl;
  }

  constructor(private fb: FormBuilder, private clubsService: ClubsService) {
    this.createForms();
  }

  ngOnInit(): void {
    this.loadCollegiateClubs();
    this.emitFormValues();
    if (this.registration) {
      this.setRegistrationValues();
    }
    this.subscribeToForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.raceSelected) {
      // Reset form values if race participation deselected
      if (!this.raceSelected) {
        this.is_collegiate_cyclist.setValue(false);
        this.collegiateClub.setValue(null);
        this.collegiateClubId.setValue(null);
      }
    }
  }

  private createForms(): void {
    this.collegiateForm = this.fb.group({
      is_collegiate_cyclist: [false],
      collegiate_club: [null],
      collegiate_club_id: [null],
    });
  }

  private subscribeToForm(): void {
    this.is_collegiate_cyclist.valueChanges.subscribe((value: boolean) => {
      if (!value) {
        this.collegiateClub.patchValue(null);
        this.collegiateClubId.patchValue(null);
      }
      this.emitFormValues();
    });
    this.collegiateClub.valueChanges.subscribe((club: any) => {
      const id: number = club ? club.club_id : null;
      this.collegiateClubId.patchValue(id);
    });
    this.collegiateClubId.valueChanges.subscribe(() => this.emitFormValues());
  }

  submitForm(): void {
    // May not be required when using this as a child component
  }

  trackClubsBy(club: Club): number {
    return club.club_id;
  }

  private loadCollegiateClubs(): void {
    this.collegiateClubs$ = concat(
      of([]), // default items
      this.collegiateClubsInput$.pipe(
        debounceTime(250),
        distinctUntilChanged(),
        tap(() => (this.collegiateClubsLoading = true)),
        switchMap((term: string) =>
          this.clubsService.queryCollegiateClubs(term).pipe(
            catchError(() => of([])), // empty list on error
            finalize(() => (this.collegiateClubsLoading = false))
          )
        )
      )
    );
  }

  private setRegistrationValues(): void {
    this.collegiateForm.patchValue({
      is_collegiate_cyclist: !!this.registration.is_collegiate_cyclist,
    });

    this.selectCollegiateClub(this.registration);
  }

  /** Selects a collegiate club if the user is a collegiate cyclist and has a collegiate club id  */
  private selectCollegiateClub(registration: Registration): void {
    if (
      registration.collegiate_club_id &&
      registration.collegiate_club_id > 0 &&
      registration.is_collegiate_cyclist
    ) {
      this.collegiateClubsLoading = true;
      this.clubsService
        .queryCollegiateClubs()
        .pipe(
          tap((clubs: any) => {
            const selectedClub = clubs.find(
              (club: any) => club.club_id === registration.collegiate_club_id
            );
            this.f.collegiate_club.patchValue(selectedClub);
          }),
          catchError((error) => throwError(error)),
          finalize(() => {
            this.collegiateClubsLoading = false;
          })
        )
        .subscribe();
    }
  }

  private emitFormValues(): void {
    if (!this.preventEmit) {
      // Slight delay to ensure latest values updated before emitting
      setTimeout(() => this.formValues.emit(this.collegiateForm), 100);
    }
  }
}
