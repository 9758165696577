<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="information-container" *ngIf="vm$ | async as vm">
        <h2 class="header pl-0 pl-lg-5 pb-2">
          Member Information
        </h2>
        <form class="agreement-form" [formGroup]="memberInfoForm">
          <fieldset [disabled]="isSubmitting">
            <div class="row m-0">
              <div class="form-group col-12 col-lg-6 px-1 px-lg-5 mb-3">
                <label for="first-name">First Name <span class="required">*</span></label>
                <input
                  trim
                  formControlName="first_name"
                  class="form-control"
                  type="text"
                  id="first-name"
                  autocomplete="given-name"
                  readonly
                />
              </div>
              <div class="form-group col-12 col-lg-6 px-1 px-lg-5 mb-3">
                <label for="last-name">Last Name <span class="required">*</span></label>
                <input
                  trim
                  class="form-control"
                  type="text"
                  id="last-name"
                  formControlName="last_name"
                  autocomplete="family-name"
                  readonly
                />
              </div>
            </div>

            <div class="row m-0">
              <div class="form-group col-12 col-lg-6 px-1 px-lg-5 mb-3">
                <label for="email">Email Address <span class="required">*</span></label>
                <input class="form-control" type="email" id="email" formControlName="email" autocomplete="email" readonly />

                <div *ngIf="vm.registration.plan === planTypes.FAMILY && vm.family.selectedMember.person_type === personTypes.JUNIOR && !vm.family.selectedMember.is_family_representative"
                  class="inherit-email-checkbox custom-control custom-checkbox"
                >
                  <input formControlName="inherit_email" type="checkbox" class="custom-control-input" [value]="true" id="inherit-email" disabled>
                  <label class="custom-control-label" for="inherit-email">Use Family Representative email</label>
                </div>
              </div>
              <div class="form-group col-12 col-lg-6 px-1 px-lg-5 mb-3">
                <label for="phone">Phone Number</label>
                <input
                  id="phone"
                  formControlName="phone_number"
                  [cleave]="{
                    blocks: [0, 3, 0, 3, 4],
                    delimiters: ['(', ')', ' ', '-']
                  }"
                  type="tel"
                  class="form-control"
                  placeholder="(555) 555-5555"
                  autocomplete="tel"
                />
              </div>
            </div>

            <div class="row m-0">
              <div class="form-group col-12 col-lg-6 px-1 px-lg-5 mb-3">
                <label for="birth-date">Date of Birth <span class="required">*</span></label>
                <app-segmented-datepicker
                  formControlName="birthdate"
                  [name]="'birthdate'"
                  [disabled]="true"
                ></app-segmented-datepicker>
                <p class="text-danger mb-0 pb-0" *ngIf="birthdayErrorMessage">
                  {{ birthdayErrorMessage }}
                </p>
              </div>
              <div class="form-group col-12 col-lg-6 px-1 px-lg-5 mb-3">
                <label for="gender">Gender <span class="required">*</span></label>
                <ng-select
                  [items]="genders"
                  bindLabel="label"
                  bindValue="value"
                  formControlName="gender"
                  [clearable]="false"
                  [searchable]="false"
                  [loading]="gendersLoading"
                  loadingText="Loading Genders"
                  placeholder="Make Selection"
                  autocomplete="sex"
                >
                </ng-select>
              </div>
            </div>

            <div class="py-4">
              <div class="row m-0">
                <div class="form-group col-12 px-1 px-lg-5 mb-3">
                  <label for="address-1">Address 1 <span class="required">*</span></label>
                  <input
                    class="form-control"
                    type="text"
                    id="address-1"
                    formControlName="address_street"
                    autocomplete="street-address adress-line-1"
                  />
                </div>
              </div>

              <div class="row m-0">
                <div class="form-group col-12 px-1 px-lg-5 mb-3">
                  <label for="address-2">Address 2</label>
                  <input
                    class="form-control"
                    type="text"
                    id="address-2"
                    formControlName="address_street2"
                    autocomplete="street-address adress-line-1"
                  />
                </div>
              </div>
            </div>

            <div class="row m-0 px-0 px-lg-4">
              <div class="form-group col-12 col-lg-4 px-1 px-lg-4 mb-3">
                <label for="city">City <span class="required">*</span></label>
                <input class="form-control" type="text" id="city" formControlName="city" autocomplete="street-address adress-level2" />
              </div>
              <div class="form-group col-12 col-lg-4 px-1 px-lg-4 mb-3">
                <label for="address_state">State <span class="required">*</span></label>
                <ng-select
                  id="address_state"
                  [items]="states$ | async"
                  bindLabel="name"
                  bindValue="code"
                  formControlName="state"
                  [clearable]="false"
                  [searchable]="true"
                  [loading]="statesLoading"
                  [virtualScroll]="true"
                  loadingText="Loading States"
                  placeholder="Make Selection"
                  (change)="onStateChange($event)"
                  autocomplete="off"
                >
                </ng-select>
              </div>
              <div class="form-group col-12 col-lg-4 px-1 px-lg-4 mb-3">
                <label for="zip">Zip <span class="required">*</span></label>
                <input
                  formControlName="zip"
                  id="zip"
                  placeholder="55555"
                  [cleave]="{
                    numeral: true,
                    numeralPositiveOnly: true,
                    numeralDecimalMark: '',
                    delimiter: '',
                    stripLeadingZeroes: false
                  }"
                  type="text"
                  class="form-control"
                  maxlength="5"
                  autocomplete="postal-code"
                />
              </div>
            </div>

            <div>
              <ngb-alert *ngFor="let error of errors$ | async" type="danger" (close)="clearErrors()">
                <p class="text-capitalize m-0">{{ error }}</p>
              </ngb-alert>
            </div>

            <div class="d-flex justify-content-end align-items-center pt-3 row m-0">
              <button
                type="button"
                class="cancel-link btn btn-link text-center col-12 col-lg-1 offset-lg-6 pb-4 pb-lg-0 pt-0"
                (click)="activeModal.dismiss()"
              >
                Cancel
              </button>
              <div class="col-12 col-lg-4 pr-1 pr-lg-5 pl-0 pl-lg-3">
                <button
                  type="submit"
                  (click)="submit(memberInfoForm.value)"
                  [disabled]="isSubmitting"
                  class="btn btn-primary btn-block mr-0 mr-lg-5"
                >
                  <span *ngIf="isSubmitting" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                  Save
                </button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</div>
