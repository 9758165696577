<div class="container modal-container">
  <div class="row">
    <div class="col-12 text-center">
      <h2 class="in-proccess-title">Membership enrollment in process</h2>

      <p class="in-proccess-description">
        We found a partially completed membership associated with this account. We’ll bring you back
        to where you left off so you can finish enrolling.
      </p>

      <button type="button" class="in-proccess-button btn btn-primary" (click)="close()">
        Complete Membership
      </button>
    </div>
  </div>
</div>
