      <form [formGroup]="genderRegularContactForm" novalidate>

        <!-- Gender & Contact w/ Junior Athletes -->
        <div class="form-card">
          <div class="row">
            <div class="col-xs-12 col-md-6">
              <label for="gender" (click)="genderSelect.focus()">Gender <span class="star">*</span></label>
              <ng-select
                #genderSelect
                [items]="genders$ | async"
                id="gender"
                bindLabel="label"
                bindValue="value"
                formControlName="gender"
                [clearable]="false"
                [searchable]="false"
                [loading]="gendersLoading"
                loadingText="Loading Genders"
                placeholder="Make Selection"
                autocomplete="sex"
              >
              </ng-select>
            </div>

            <div *ngIf="showRegularContact" class="col-xs-12 col-md-6 pt-4">
              <div class="d-flex align-items-center">
                <div class="d-flex align-items-center">
                  <input
                    class="custom-checkbox mr-2"
                    type="checkbox"
                    name="junior"
                    id="junior"
                    formControlName="has_regular_contact_with_junior"
                  />
                  <label for="junior" class="m-0 mr-1">
                    Do you race, ride or train with athletes under 18 years old?
                  </label>
                  <i
                    placement="bottom"
                    [ngbTooltip]="tipContent"
                    tooltipClass="custom-tooltip"
                    [openDelay]="250"
                    tabindex="0"
                    class="icon icon-information"
                  ></i>
                </div>

                <ng-template #tipContent>
                  As someone who has regular contact with athletes under age 18, you are required
                  to take SafeSport training. You will shortly receive an email with
                  information on how to complete it.
                </ng-template>
              </div>
              <div class="text-center mt-3">
                <img style="height: 50px;" src="assets/images/usac-safesport.png" alt="SafeSport logo"/>
              </div>
            </div>
          </div>
        </div>
      </form>
