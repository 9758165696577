import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, tap } from 'rxjs/operators';
import { RestrictedUserModalComponent } from 'src/app/shared/restricted-user-modal/restricted-user-modal.component';
import { environment } from 'src/environments/environment';

import { AddOnsFacade, FamilyMembershipFacade, RegistrationFacade } from '../facades';
import { Profile } from '../models';
import { AuthRelayRepsonse } from '../models';

import { ApiService } from './api.service';
import { BaseService } from './base-service';
import { ProfileService } from './profile.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  private currentUserSubject = new BehaviorSubject<Profile>({} as Profile);
  currentUser$ = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());
  currentUser: Profile;

  private currentUserFetchedSubject = new BehaviorSubject<boolean>(false);
  currentUserFetched$ = this.currentUserFetchedSubject.asObservable();

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  isAuthenticated$ = this.isAuthenticatedSubject.asObservable();

  cookieName = 'USACLOGIN';

  constructor(
    protected http: HttpClient,
    protected cookie: CookieService,
    private apiService: ApiService,
    private profileService: ProfileService,
    private familyMembershipFacade: FamilyMembershipFacade,
    private modal: NgbModal,
    private registrationFacade: RegistrationFacade,
    private addOnsFacade: AddOnsFacade
  ) {
    super(http, cookie);
  }

  // This flow could probably use some refactoring (reuse populate method)
  doLogin(redirectUrl: string): void {
    window.location.assign(environment.adminUrl + `/login?redirectUrl=${redirectUrl}`);
  }

  login(user: string, password: string): Observable<AuthRelayRepsonse> {
    return this.http
      .post<AuthRelayRepsonse>(
        this.url('login'),
        { user_id: user, user_password: password },
        this.options
      )
      .pipe(
        tap((_) => this.log('attempting api login')),
        catchError(
          this.handleError<any>('doLogin', null, { user })
        )
      );
  }

  doLogout(): Observable<any> {
    return this.http.get<any>(this.url('logout'), this.options).pipe(
      tap((_) => {
        this.log('attempting api logout');
        this.resetFacades();
        this.purgeAuth();
      }),
      catchError(this.handleError<any>('doLogout', {}))
    );
  }

  resetFacades(): void {
    this.registrationFacade.reset();
    this.familyMembershipFacade.reset();
    this.addOnsFacade.reset();
  }

  storeAuth(profile: Profile): void {
    // Set current user data into observable
    this.currentUserSubject.next(profile);
    this.currentUser = profile;

    // CHECK FOR USACLOGIN COOKIE
    const hasCookie = this.cookie.check(this.cookieName);
    this.isAuthenticatedSubject.next(hasCookie);

    // Prevent users on USAC suspended list from using any membership flows
    if (profile.profile_has_admin_hold || profile.profile_is_suspended) {
      const modalRef = this.modal.open(RestrictedUserModalComponent, {
        centered: true,
        size: 'lg',
      });

      modalRef.result.catch(() =>
        this.doLogout().subscribe((resp) => {
          if (resp) {
            window.location.assign(environment.mainUrl);
          }
        })
      );
    }
  }

  purgeAuth(): void {
    // Set current user to an empty object
    this.currentUserSubject.next({} as Profile);
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
  }

  // TODO: @Charlie - Refactor for correct SSO token usage ("USACTOKEN")
  populate(): void {
    this.profileService.getCurrentUser().subscribe((profile) => {
      if (profile) {
        this.storeAuth(profile);
      } else {
        this.purgeAuth();
      }
      this.currentUserFetchedSubject.next(true);
    });
  }

  checkAccount(email: string): Observable<boolean> {
    const params: HttpParams = new HttpParams().set('profile_email', email);

    return this.apiService.get<boolean>('/check_profile', params).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }
}
