<div>
  <span class="d-flex justify-content-end pt-3 pr-3"
    ><img class="close-button" src="assets/images/x-circle.svg" alt="X" (click)="closeModal()"
  /></span>
  <div class="container modal-container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-7">
        <h2 class="modal-content__header">Sign Up</h2>
        <h5 class="modal-content__subheader">Create your profile with USA Cycling!</h5>

        <form [formGroup]="authForm" (ngSubmit)="onSubmit()" class="modal-register-form" novalidate>
          <fieldset [disabled]="isSubmitting">
            <!-- First name -->
            <div class="row">
              <div class="form-group col-lg-6 col-xl-4">
                <label for="first-name">First Name <span class="star">*</span></label>
                <input
                  trim
                  formControlName="profile_first_name"
                  id="first-name"
                  placeholder="John"
                  type="text"
                  class="modal-register-form__input form-control"
                  autocomplete="given-name"
                />
              </div>
              <!-- Last name -->
              <div class="form-group col-lg-6 col-xl-4">
                <label for="last-name">Last Name <span class="star">*</span></label>
                <input
                  trim
                  formControlName="profile_last_name"
                  id="last-name"
                  type="text"
                  class="modal-register-form__input form-control"
                  placeholder="Smith"
                  autocomplete="family-name"
                />
              </div>
              <!-- Email -->
              <div class="form-group col-lg-6 col-xl-4">
                <label for="profile_email">Email Address <span class="star">*</span></label>
                <input
                  trim
                  formControlName="profile_email"
                  id="profile_email"
                  placeholder="johndoe@gmail.com"
                  type="email"
                  class="modal-register-form__input form-control"
                  autocomplete="email"
                />
              </div>
              <!-- Phone -->
              <div class="form-group col-lg-6 col-xl-3">
                <label for="email">Phone Number</label>
                <input
                  trim
                  formControlName="contact_phone_1"
                  id="contact_phone_1"
                  placeholder="XXX-XXX-XXXX"
                  type="text"
                  class="modal-register-form__input form-control"
                  autocomplete="contact_phone_1"
                />
              </div>
              <!-- DOB -->
              <div class="form-group col-lg-7 col-xl-5">
                <label for="profile_birthdate">Date of Birth <span class="star">*</span></label>
                <app-segmented-datepicker
                  formControlName="profile_birthdate"
                  [name]="'profile_birthdate'"
                  [minDate]="minDate"
                  [maxDate]="maxDate"
                  [errors]="f.profile_birthdate.errors"
                ></app-segmented-datepicker>
                <p class="text-danger mb-0 pb-0" *ngIf="birthdayErrorMessage">
                  {{ birthdayErrorMessage }}
                </p>
              </div>
              <!-- Gender -->
              <div class="form-group col-lg-5 col-xl-4">
                <label for="profile_sex" (click)="genderSelect.focus()">Gender <span class="star">*</span></label>
                <ng-select
                  #genderSelect
                  [items]="genders$ | async"
                  id="profile_sex"
                  bindLabel="label"
                  bindValue="value"
                  formControlName="profile_sex"
                  [clearable]="false"
                  [searchable]="false"
                  [loading]="gendersLoading"
                  loadingText="Loading Genders"
                  placeholder="Make Selection"
                  autocomplete="sex"
                >
                </ng-select>
              </div>
            </div>

            <!-- Street Address, Apt Number -->
            <div class="row">
              <div class="form-group col-lg-8">
                <label for="address_street">Street Address <span class="star">*</span></label>
                <input
                  formControlName="address_street"
                  id="address_street"
                  placeholder="123 Main st"
                  type="text"
                  class="modal-register-form__input form-control"
                  autocomplete="given-name"
                />
              </div>
              <div class="form-group col-lg-4">
                <label for="address_street2">Street Address 2</label>
                <input
                  formControlName="address_street2"
                  id="address_street2"
                  type="text"
                  class="modal-register-form__input form-control"
                  placeholder="Apt 6"
                  autocomplete="family-name"
                />
              </div>
            </div>

            <!-- City, Zip code -->
            <div class="row">
              <div class="form-group col-lg-8">
                <label for="address_city">City <span class="star">*</span></label>
                <input
                  trim
                  formControlName="address_city"
                  id="address_city"
                  placeholder=""
                  type="text"
                  class="modal-register-form__input form-control"
                  autocomplete="given-name"
                />
              </div>
              <div class="form-group col-lg-4">
                <label for="address_postal_code">Zip Code <span class="star">*</span></label>
                <input
                  trim
                  formControlName="address_postal_code"
                  id="address_postal_code"
                  type="text"
                  class="modal-register-form__input form-control"
                  placeholder=""
                  autocomplete="family-name"
                />
              </div>
            </div>

            <!-- State, Country -->
            <div class="row">
              <div class="form-group col-lg-6" *ngIf="showStateInput">
                <label for="address_state" (click)="stateSelect.focus()">State <span class="star">*</span></label>
                <ng-select
                  #stateSelect
                  [items]="provinces"
                  id="address_state"
                  bindLabel="label"
                  bindValue="value"
                  formControlName="address_state"
                  [clearable]="false"
                  [searchable]="true"
                  [loading]="countriesLoading"
                  loadingText="Loading"
                  placeholder="Make Selection"
                  autocomplete="province"
                >
                </ng-select>
              </div>
              <div class="form-group col-lg-6" *ngIf="showProvinceInput">
                <label for="address_province" (click)="provinceSelect.focus()">Province <span class="star">*</span></label>
                <ng-select
                  #provinceSelect
                  [items]="provinces"
                  id="address_province"
                  bindLabel="label"
                  bindValue="value"
                  formControlName="address_province"
                  [clearable]="false"
                  [searchable]="true"
                  [loading]="countriesLoading"
                  loadingText="Loading"
                  placeholder="Make Selection"
                  autocomplete="province"
                >
                </ng-select>
              </div>
              <div class="form-group col-lg-6">
                <label for="address_country_code" (click)="countrySelect.focus()">Country <span class="star">*</span></label>
                <ng-select
                  #countrySelect
                  [items]="countries"
                  id="address_country_code"
                  bindLabel="label"
                  bindValue="value"
                  formControlName="address_country_code"
                  [clearable]="false"
                  [searchable]="true"
                  [loading]="countriesLoading"
                  loadingText="Loading Countries"
                  placeholder="Make Selection"
                  autocomplete="country"
                >
                </ng-select>
              </div>
            </div>

            <!-- Password -->
            <div class="row">
              <div class="form-group col-lg-6">
                <label for="password">Password <span class="star">*</span></label>
                <input
                  formControlName="password"
                  id="password"
                  placeholder="Enter Password"
                  type="password"
                  class="modal-register-form__input form-control"
                  autocomplete="new-password"
                />
                <p
                  class="text-danger mb-0 pb-0"
                  *ngIf="password.hasError('pattern')"
                >
                  Password must be minimum 6 characters long, and should contain at least one number
                  and letter.
                </p>
              </div>
              <div class="form-group col-lg-6">
                <label for="confirm-password">Confirm Password <span class="star">*</span></label>
                <input
                  formControlName="password_confirmation"
                  id="confirm-password"
                  type="password"
                  class="modal-register-form__input form-control"
                  placeholder="Re-enter Password"
                  autocomplete="new-password"
                />
              </div>
              <div
                *ngIf="
                  authForm.hasError('confirmPassword') &&
                  (authForm.touched || authForm.dirty) &&
                  !password.hasError('pattern')
                "
                class="col text-danger"
              >
                Passwords are not the same
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <ngb-alert *ngFor="let error of errors$ | async" type="danger" (close)="clearErrors()">
                  <p class="text-capitalize m-0">{{ error }}</p>
                </ngb-alert>
              </div>

              <div class="col-12 mt-4">
                <button id="login-button" [disabled]="disableSubmit" type="submit" class="btn btn-primary btn-block">
                  <span *ngIf="isSubmitting" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                  {{ isSubmitting ? 'Signing Up...' : 'Sign Up' }}
                </button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</div>
