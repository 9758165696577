<app-header [cartHidden]="false"></app-header>

<app-loader *ngIf="isLoading" [overlay]="true"></app-loader>

<div class="container">
  <div class="row">
    <div class="col-12 col-md-10 offset-md-1 col-lg-9 offset-lg-0">
      <!-- Member Status Indicator -->
      <app-status-indicator-header *ngIf="userLoaded"
        [user]="user"
        [firstName]="user.profile_first_name"
        [lastName]="user.profile_last_name"
        [email]="user.profile_email"
        [allMemberships]="allMemberships"
        [loading]="membershipsLoading"
      ></app-status-indicator-header>
      <hr>
    </div>
    <div class="col-12 col-md-10 offset-md-1 col-lg-9 offset-lg-0">
      <!-- Step Progress Bar -->
      <app-step-progress-bar
        [currentStep]="Step.SelectOptions"
        [nextStep]="Step.UpgradeOptions"
        [isRace]="true"
        [isFamily]="isFamily"
      ></app-step-progress-bar>
    </div>

    <div class="col-12 offset-md-1 col-md-10 offset-lg-0 col-lg-9 p-3">
      <app-form-title>Select Options</app-form-title>
      <!-- <app-form-subtitle>
        *** We should probably add a description here with instructions ***
      </app-form-subtitle> -->

      <p class="form-info">Required fields marked with <span class="star">*</span></p>

      <div *ngIf="!registration">
        <app-loader></app-loader>
      </div>

      <form *ngIf="registration"
        [formGroup]="optionsForm"
        class="membership-form m-0"
      >
        <!-- Gender & Contact w/ Junior Athletes -->
        <app-gender-and-regular-contact
          [registration]="registration"
          [isAdult]="isAdult"
          [isSubmitting]="isSubmitting"
          [preventEmit]="preventEmit"
          (formValues)="updateFormValues('genderRegularContactForm', $event)"
        ></app-gender-and-regular-contact>

        <!-- Collegiate Designation -->
        <app-collegiate-questions
          [registration]="registration"
          [isSubmitting]="isSubmitting"
          [raceSelected]="true"
          [preventEmit]="preventEmit"
          (formValues)="updateFormValues('collegiateForm', $event)"
        ></app-collegiate-questions>

        <!-- International License Opt-in -->
        <div class="form-card" [class.disabled]="intlDisabled">
          <div>
            <div class="license-title d-flex justify-content-between align-items-start">
              <h3 class="form-title">Do you want to add an international license?
                <small>
                  <i
                    class="icon icon-information"
                    placement="bottom"
                    [ngbTooltip]="intlLicenseTipContent"
                    tooltipClass="custom-tooltip"
                    [openDelay]="250"
                    tabindex="0"
                  ></i>
                </small>
              </h3>
              <img src="assets/images/uci-logo.svg" width="100px" class="pl-2" alt="UCI logo">
            </div>

            <ng-template #intlLicenseTipContent>
              A USAC International License is required for international competition
              as well as entry into most UCI races held in the United States. This
              license provides you with important additional liability insurance when
              racing outside the United States as well as a required UCI ID.
            </ng-template>

            <div class="d-flex align-items-center">
              <input
                class="custom-checkbox mr-2"
                type="checkbox"
                name="international_license"
                id="international_license"
                formControlName="international_license"
              />
              <label for="international_license" class="m-0 mr-1 p-0">
                Yes, add an international license.
              </label>
            </div>
            <app-international-license *ngIf="addInternational"
              [registration]="registration"
              [isSubmitting]="isSubmitting"
              [formRaceAge]="user.profile_race_age"
              [preventEmit]="preventEmit"
              (formValues)="updateFormValues('internationalLicenseForm', $event)"
            ></app-international-license>
          </div>
          <h4 *ngIf="intlDisabled" class="form-subtitle text-info mt-3 mb-0">
            *If you wish to add an international license, please de-select {{licenseSelectionText}}.
          </h4>
        </div>

        <!-- Pro License Opt-in -->
        <div *ngIf="proEligible" class="form-card" [class.disabled]="proDisabled">
          <div>
            <div class="license-title">
              <h3 class="form-title">UCI Road Professional License</h3>
              <h4 class="form-subtitle">Required for members of UCI Professional Road Teams</h4>
            </div>

            <div class="d-flex align-items-center">
              <input
                class="custom-checkbox mr-2"
                type="checkbox"
                name="pro_license"
                id="pro_license"
                formControlName="pro_license"
              />
              <label for="pro_license" class="m-0 mr-1 p-0">
                Add a pro license.
              </label>
            </div>
            <app-pro-license *ngIf="addPro"
              [registration]="registration"
              [isSubmitting]="isSubmitting"
              [preventEmit]="preventEmit"
              (formValues)="updateFormValues('proLicenseForm', $event)"
            ></app-pro-license>
          </div>
          <h4 *ngIf="proDisabled" class="form-subtitle text-info mt-3 mb-0">
            *If you wish to add a pro license, please de-select {{licenseSelectionText}}.
          </h4>
        </div>


        <div class="row">
          <div class="col-12">
            <ngb-alert *ngFor="let error of errors$ | async" type="danger" (close)="clearErrors()">
              <p class="text-capitalize m-0">{{ error }}</p>
            </ngb-alert>
          </div>

          <!-- Navigation Buttons -->
          <div class="col-12 col-sm-6 col-md-3 col-lg-2 offset-md-6 offset-lg-8 mb-2">
            <button
              id="back-btn"
              [disabled]="disableBack"
              class="btn btn-secondary btn-block"
              type="button"
              (click)="back()"
            >Back</button>
          </div>
          <div class="col-12 col-sm-6 col-md-3 col-lg-2">
            <button
              id="submit-btn"
              [disabled]="disableContinue"
              class="btn btn-primary btn-block"
              type="button"
              (click)="continue()"
            >
              <span *ngIf="isSubmitting" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              Continue
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <app-footer></app-footer>
</div>
