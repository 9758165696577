import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  getToken(): string {
    return localStorage.getItem('authToken');
  }

  saveToken(token: string): void {
    localStorage.setItem('authToken', token);
  }

  destroyToken(): void {
    localStorage.removeItem('authToken');
  }
}
