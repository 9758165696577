import { NgModule } from '@angular/core';

import { SharedModule } from '../shared';

import { BenefitsChartComponent } from './benefits-chart/benefits-chart.component';
import { GetStartedComponent } from './get-started/get-started.component';
import { IndividualMembershipFooterComponent } from './individual-membership-footer/individual-membership-footer.component';
import { IndividualMembershipHeaderComponent } from './individual-membership-header/individual-membership-header.component';
import { IndividualMembershipComponent } from './individual-membership.component';
import { MembershipOptionComponent } from './membership-option/membership-option.component';

@NgModule({
  declarations: [
    BenefitsChartComponent,
    IndividualMembershipComponent,
    GetStartedComponent,
    MembershipOptionComponent,
    IndividualMembershipHeaderComponent,
    IndividualMembershipFooterComponent,
  ],
  imports: [SharedModule],
  exports: [IndividualMembershipFooterComponent, IndividualMembershipHeaderComponent],
})
export class IndividualMembershipModule {}
