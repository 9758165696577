<div class="{{divClass}}">
  <div *ngFor="let toast of toasts">
    <ngb-toast *ngIf="toast.show" [autohide]="toast.autohide" [delay]="toast.delay" (hide)="close(toast)" style="position: relative;">
      <ng-template ngbToastHeader>
        <i *ngIf="toast.type === 'success'" class="icon icon-check"></i>
        <i *ngIf="toast.type === 'warning'" class="icon icon-warning"></i>
        <i *ngIf="toast.type === 'info'" class="icon icon-star"></i>
        <span *ngIf="toast.title" class="toast-title">{{toast.title}}</span>
        <small>{{toast.timeAgo}}</small>
      </ng-template>
      <span class="toast-message time-{{ toast.time }}">{{toast.message}}</span>
    </ngb-toast>
  </div>
</div>
