import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import qs from 'qs';
import { Observable, of } from 'rxjs';
// import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import airbrake from '../../airbrake';

export interface PaginatedApiResponse {
  data: any[];
  links?: {
    first: string;
    last: string;
    next?: string;
    prev?: string;
  };
  meta?: {
    current_page: number;
    from: number;
    last_page: number;
    path: string;
    per_page: number;
    to: number;
    total: number;
  };
}

@Injectable({
  providedIn: 'root',
})
export abstract class BaseService {
  get options(): object {
    return {
      withCredentials: true,
      headers: new HttpHeaders({
        // Authorization: this.cookie.get('user_token'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    };
  }

  // protected profile_id = this.cookie.get('profile_id') || 'self';
  // TODO: Better handling for saved/pending event id context (multiple tabs, etc)
  // protected event_id  = this.cookie.get('event_id');

  constructor(protected http: HttpClient, protected cookie: CookieService) {
    // Constructor stuff!
  }

  // protected get profile_id() {
  //   return this.cookie.get('profile_id') || 'self';
  // }

  protected baseUrl(): string {
    return environment.apiUrl;
  }

  protected url(path: string, filters?: any): string {
    const queryFilters = this.stringifyFilters(filters);
    return this.baseUrl() + '/' + path.replace(/^\//, '').replace(/\/+/g, '/') + queryFilters;
  }

  protected log(message: string): void {
    console.log(`${this.constructor.name}: ${message}`);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  protected handleError<T>(operation = 'operation', result?: T, data?: any): any {
    return (error: any): Observable<T> => {
      let severity = 'error';

      if (error.status && error.status < 500) {
        severity = 'info';
      }

      console.error(error); // log to console instead

      airbrake.notify({
        error,
        context: {
          severity,
          result,
          data,
        },
      });

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // if (error.status === 401) {
      //   console.log('Unauthorized! Prompt user to login');
      //   window.location.href = '/unauthorized';
      // }

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  protected parseFilters(query: string): object {
    return qs.parse(query);
  }

  protected stringifyFilters(params: any): string {
    return qs.stringify(params, {
      addQueryPrefix: true,
      arrayFormat: 'brackets',
    });
  }
}
