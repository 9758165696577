<div class="col-12 col-lg-3">
  <div class="order-summary">
    <div class="order-card">
      <h3 class="order-summary__title">Order summary</h3>
      <div class="order-summary__items">
        <!-- <div *ngIf="!paid" class="d-flex justify-content-between">
          <span>{{ plan | plan}} Plan</span>
        </div> -->

        <!-- <div *ngIf="premiumUpgradesCount > 0" class="d-flex justify-content-between">
          <span>Premium Upgrade x{{ premiumUpgradesCount }}</span>
          <span>{{ premiumUpgradesCount * premiumUpgradePrice | currency }}</span>
        </div> -->

        <div *ngIf="plusUpgradeCount > 0" class="d-flex justify-content-between">
          <span>Race+ Add On x{{ plusUpgradeCount }}</span>
          <span>{{ plusUpgradeCount * plusUpgradePrice | currency }}</span>
        </div>

        <!-- <div *ngIf="proLicense" class="d-flex justify-content-between">
          <span>Pro License x1</span>
          <span>{{ proLicensePrice | currency }}</span>
        </div> -->

        <div *ngIf="internationalLicense" class="d-flex justify-content-between">
          <span>International License x1</span>
          <span>{{ internationalLicensePrice | currency }}</span>
        </div>

        <div *ngIf="podiumPackagesCount > 0" class="d-flex justify-content-between">
          <span>Podium Package x{{ podiumPackagesCount }}</span>
          <span>{{ podiumPackagesCount * podiumPackagePrice | currency }}</span>
        </div>

        <div *ngIf="chosenDonationAmount > 0" class="d-flex justify-content-between text-danger">
          <span>USAC Foundation Donation</span>
          <span>+{{ chosenDonationAmount | currency }}</span>
        </div>
      </div>

      <hr class="order-summary__divider" />

      <div class="order-summary__total d-flex justify-content-between">
        <strong>Total</strong>
        <strong>{{ orderTotal | currency }}</strong>
      </div>

      <div *ngIf="autoRenew" class="my-3">
        <small>
          By Selecting Checkout, you will be auto-renewed at your current membership rate,
          plus applicable taxes every year. You’ll be notified before any future price changes.
        </small>
      </div>

      <button
        type="button"
        class="order-summary__button btn btn-primary btn-block"
        (click)="checkout()"
        [disabled]="isSubmitting || isLoading"
      >
        <span *ngIf="isSubmitting" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        Checkout
      </button>


      <div *ngIf="serverError" class="text-danger mt-3">Something went wrong. If this problem persists, please contact <a href="mailto:membership@usacycling.org">membership@usacycling.org</a>.</div>

      <div class="text-muted mt-3">Taxes and eligible discounts will be applied at checkout.</div>
    </div>

    <div *ngIf="showAutoRenew" class="form-check d-flex p-0 mt-2">
      <input class="form-check-input d-none m-0" type="checkbox" value="" id="renew" [(ngModel)]="autoRenew" />
      <label class="form-check-label" for="renew"> </label>
      <p class="font-weight-bold ml-2">
        <u>Automatic Renewal</u>:
        By checking this box, you agree that your annual membership will be automatically renewed each year
        by charging your card on file for the amount of your membership until you cancel. Your membership
        purchase is non-refundable. You may cancel automatic renewal at any time to stop future charges by
        visiting <a class="underline" [href]="adminUrl" target="_blank">My Account</a> to update your preferences.
      </p>
    </div>
  </div>
</div>
