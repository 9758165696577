<div class="row">
  <div class="col-6 col-md-3 mb-3">
    <p>Name</p>
    <strong>{{ name }}</strong>
  </div>
  <div class="col-6 col-md-3 mb-3">
    <p>Email</p>
    <strong>{{ email || '-' }}</strong>
  </div>
  <div class="col-6 col-md-3">
    <p>Member Since</p>
    <strong>{{ memberSince || '-' }}</strong>
  </div>
  <div class="col-6 col-md-3">
    <p>Membership Status</p>
    <app-loader *ngIf="membershipLoading" [align]="'left'" [small]="true"></app-loader>
    <app-member-status *ngIf="!membershipLoading"
      [profile]="user"
      [userSubscriptions]="usersSubscription"
      [currentMembership]="currentMembership"
      [showHeader]="false"
    ></app-member-status>
  </div>
</div>
