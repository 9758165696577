export interface BraintreeCustomerResponse {
  customer: BraintreeCustomer;
  clientToken: string;
  success?: boolean;
  message?: string;
}

export interface BraintreePaymentMethodResponse {
  customer: BraintreeCustomer;
  paymentMethod: BraintreePaymentMethodResponseObject;
}

export interface BraintreePaymentMethodResponseObject {
  success: boolean;
  paymentMethod: BraintreePaymentMethod;
  message?: string;
}

export interface BraintreeAddress {
  company?: string;
  firstName: string;
  lastName: string;
  streetAddress: string;
  extendedAddress: string;
  fullName?: string;
  locality: string;
  region: string;
  postalCode: string;
  countryName: string;
  countryCodeAlpha2: string;
}

export interface BraintreePaymentMethod {
  billingAddress: BraintreeAddress;
  cardType: string;
  cardholderName: string;
  default: boolean;
  expirationDate: string;
  expired: boolean;
  last4: string;
  status: string;
  token: string;
}

export interface BraintreeSubscription {
  id: string;
  planId: string;
  price: string;
  status: string;
  firstBillingDate: BraintreeDate;
  nextBillingDate: BraintreeDate;
  currentBillingCycle: number;
  numberOfBillingCycles: number;
  neverExpires: boolean;
  transactions: BraintreeTransaction[];
}

export interface BraintreeSubscriptionResponse {
  success: boolean;
  subscription: BraintreeSubscription;
}

export interface BraintreeSubscriptionsResponse {
  customer: BraintreeCustomer;
  subscriptions: BraintreeSubscription[];
  plans?: BraintreePlan[];
}

export interface BraintreeCheckoutResponse {
  subscriptions: BraintreeSubscriptionResponse[];
  transactions: BraintreeTransactionResponse[];
}

export interface BraintreeTransaction {
  customer: BraintreeCustomer;
}

export interface BraintreeTransactionResponse {
  success: boolean;
  transaction: BraintreeTransaction;
}

export interface BraintreePlan {
  id: string;
  name: string;
  description: string;
}

export interface BraintreeDate {
  date: Date;
  timezone_type: number;
  timezone: string;
}

export class BraintreeCustomer {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phone?: string;
  addresses: BraintreeAddress[];

  paymentMethods: BraintreePaymentMethod[];
  token: string;

  createdAt: BraintreeDate;
  updatedAt: BraintreeDate;

  constructor(data: any) {
    Object.assign(this, data);
  }
}
