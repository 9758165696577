<form [formGroup]="proLicenseForm" novalidate>

  <div class="mt-2">
    <!-- Pro Team Selection -->
    <div class="col-12 py-2">
      <div class="mb-1">
        Please select a pro team.
        <span class="star">*</span>
      </div>

      <div class="row">
        <div class="col-12">
          <ng-select
            formControlName="pro_team"
            class="select"
            id="pro-team"
            [items]="teamOptions"
            [clearable]="true"
            [searchable]="true"
            [loading]="optionsLoading"
            notFoundText="No Pro Teams Found"
            placeholder="Select..."
          >
          </ng-select>
        </div>
      </div>
    </div>

    <!-- Resulting Confirmation -->
    <ngb-alert *ngIf="!!proTeam" [type]="'success'" [dismissible]="false" class="mt-3 mx-2">{{successAlertMsg}}</ngb-alert>

  </div>


</form>
