<div [class]="setStatusClass(membershipStatus)">
  <div *ngIf="showHeader">
    <p>Membership Status:</p>
  </div>
  <div class="{{ membershipBannnerClass }} mb-1">
    <em>
      <i class="demo-icon icon-license"></i>
      {{ membershipLevelName || 'N/A' }}
    </em>
  </div>
  <div *ngIf="daysTilExpiration">
    <h5>{{ daysTilExpiration || 'N/A' }}</h5>
    <p>Exp Date: {{ membershipExpirationDate || 'N/A' }}</p>
    <a *ngIf="showManageLink  && !userEarlyRenewed" href="{{ membershipLink }}" target="{{ setLinkTarget(membershipLinkText) }}"
      class="{{ setLinkClass(membershipLinkText) }}">
      {{ membershipLinkText }}
    </a>
    <p *ngIf="userEarlyRenewed" class="mt-1" style="color: #51aa60;">
      Renews in {{expirationDays}} Days</p>
  </div>
</div>