<app-header [title]="'Technical Licensure'" [cartHidden]="true" [logoutRedirectUrl]="logoutRedirectUrl" [showLogout]="true"></app-header>

<div class="container">
  <div class="col-12 col-lg-10 offset-lg-1 p-4">

    <!-- Landing page (w/ login options) -->
    <app-technical-license-landing *ngIf="!loggedIn"></app-technical-license-landing>

    <!-- License menu (when logged in) -->
    <app-technical-license-menu *ngIf="loggedIn" [user]="user"></app-technical-license-menu>

    <app-footer [fullWidth]="true"></app-footer>
  </div>
</div>
