import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Location } from '../models';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class LocationsService {
  constructor(private apiService: ApiService) {}

  getAll(): Observable<Location[]> {
    return this.apiService.get('/locations').pipe(
      map((locations: any) => {
        return locations.states;
      })
    );
  }
}
