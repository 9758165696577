import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {
  LicensePostPurchaseComponent,
  RideUpgradeOptionsComponent,
  TechnicalLicensesComponent,
} from './ecosystems';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'technical-licenses',
    pathMatch: 'full',
  },
  {
    path: 'ride-upgrade',
    component: RideUpgradeOptionsComponent,
    data: { title: 'Ride Upgrade' },
  },
  {
    path: 'technical-licenses',
    component: TechnicalLicensesComponent,
    data: { title: 'Technical Licenses' },
  },
  {
    path: 'license-post-purchase',
    component: LicensePostPurchaseComponent,
    data: { title: 'Technical License Post Purchase' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ManagementRoutingModule {}
