/**
 * Represents a profile gender.
 *
 * Member can choose not to disclose or claim a non-binary status.
 */
export interface Gender {
  value: GenderCode;
  label: string;
}

export enum GenderCode {
  Male = 'M',
  Female = 'F',
  NonBinary = 'N',
  Undisclosed = 'U',
}
