<app-header [cartHidden]="false" [cartType]="'upgrade'" [rideTechUpgrade]="true" [hideCartContent]="hideCartContent" [user]="user"></app-header>

<app-loader *ngIf="isLoading && loggedIn" [overlay]="true"></app-loader>

<div class="container">
  <div class="row">
    <div class="col-12 col-lg-9">
      <app-status-indicator-header
        [user]="user"
        [allMemberships]="memberships"
        [firstName]="user.profile_first_name"
        [lastName]="user.profile_last_name"
        [email]="user.profile_email"
        [loading]="isLoading"
      ></app-status-indicator-header>
      <hr>
    </div>
    <div class="col-12 offset-md-1 col-md-10 offset-lg-0 col-lg-9 p-3">

      <app-form-title>Ride Tech Membership</app-form-title>

      <app-form-subtitle>
        Become a Ride Member, now at a special price for technical license holders.
      </app-form-subtitle>

      <!-- Login Module -->
      <div *ngIf="!loggedIn" class="login-container d-flex align-items-center row p-3 m-0 mt-4">
        <div class="col-2 col-md-1">
          <img src="assets/user-check.svg" alt="User" />
        </div>
        <div class="col-10 col-md-6">
          <p class="m-0">
            Upgrade your USA Cycling technical license with a Ride Membership. Log In to get started.
          </p>
        </div>
        <div
          class="d-flex justify-content-center justify-content-md-end col-12 col-md-4 offset-md-1 pt-4 pt-md-0"
        >
          <button type="button" class="btn btn-primary btn-block px-3" (click)="login()">LOG IN</button>
        </div>
      </div>


      <div *ngIf="loggedIn && !isLoading" class="upgrade-container row">
        <div class="col-12 col-sm-10 offset-sm-1" [ngClass]="{ 'col-lg-8 offset-lg-2 col-xl-6 offset-xl-3': upgradeEligible }">
          <!-- Display message if premium already purchased/included, or if user doesn't have a membership -->
          <div *ngIf="!upgradeEligible" class="login-container d-flex align-items-center row p-3 m-0 mt-4">
            <div class="col-2">
              <img src="assets/user-check.svg" alt="User" />
            </div>
            <div class="col-10 col-md-5">
              <p class="m-0">
                {{ ineligibilityText }}
              </p>
            </div>
            <div
              class="d-flex justify-content-center justify-content-md-end col-12 col-md-4 offset-md-1 pt-4 pt-md-0"
            >
              <button type="button" class="btn btn-primary btn-block px-3" (click)="ineligibilityBtnAction()">
                {{ ineligibilityBtnText }}
              </button>
            </div>
          </div>

          <!-- Premium / Racing Add-On Upgrade -->
          <div *ngIf="upgradeEligible">
            <p class="premium-card__price">
              +{{ rideTechPrice | currency }} <span class="premium-card__small"> /year</span>
            </p>

            <div class="d-flex flex-column text-left pb-4">
              <div class="d-flex align-items-start pb-2">
                <div><img class="pr-2" src="assets/images/check-red.svg" alt="Check" /></div>
                <span>Includes $25k worth of accident insurance</span>
              </div>
              <div class="d-flex align-items-start">
                <div><img class="pr-2" src="assets/images/check-red.svg" alt="Check" /></div>
                <span>Members-only access, deals, and benefits</span>
              </div>
            </div>

            <button type="button" class="btn btn-primary btn-block" [disabled]="isSubmitting" (click)="checkout()">
              <span *ngIf="isSubmitting" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              Purchase Ride Membership
            </button>

            <div *ngIf="serverError" class="text-danger text-center mt-2 mb-4">
              Something went wrong. If this problem persists, please contact
              <a href="mailto:membership@usacycling.org" class="text-danger font-weight-bold">membership@usacycling.org</a>.
            </div>

            <form
              [formGroup]="upgradeForm"
              class="membership-form m-0"
            >
              <!-- Auto-renewal -->
              <div class="d-flex align-items-center my-3">
                <input
                  class="custom-checkbox mr-2"
                  type="checkbox"
                  name="is_auto_renewal"
                  id="is_auto_renewal"
                  formControlName="is_auto_renewal"
                />
                <label for="is_auto_renewal" class="font-weight-bold m-0 mr-1">
                  <u>Automatic Renewal</u>:
                  By checking this box, you agree that your annual membership will be automatically renewed each year
                  by charging your card on file for the amount of your membership until you cancel. Your membership
                  purchase is non-refundable. You may cancel automatic renewal at any time to stop future charges by
                  visiting <a class="underline" [href]="adminUrl" target="_blank">My Account</a> to update your preferences.
                </label>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
