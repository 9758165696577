import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AuthService, ProfileService } from 'src/app/core';
import { RegistrationFacade } from 'src/app/core/facades';
import { Alert, ProfilePublic, Toast } from 'src/app/core/models';
import { RegistrationLocal } from 'src/app/core/models/registration.model';

import { ForgotPasswordModalComponent } from '../forgot-password-modal/forgot-password-modal.component';

@Component({
  selector: 'app-matching-account-modal',
  templateUrl: './matching-account-modal.component.html',
  styleUrls: ['./matching-account-modal.component.scss'],
})
export class MatchingAccountModalComponent implements OnInit, OnDestroy {
  @Input() matchingAccounts: ProfilePublic[];
  @Input() addedJunior: boolean;
  @Input() email: string;

  subscription: Subscription;
  loginForm: FormGroup;
  error?: string;
  isSubmitted: boolean;
  isSubmitting: boolean;
  signInError = false;
  selectedAccount: ProfilePublic;
  toasts: Toast[] = [];
  success: Alert[] = [];
  alerts: Alert[] = [];

  get userIdControl(): FormControl {
    return this.loginForm.get('user_id') as FormControl;
  }

  get passwordControl(): FormControl {
    return this.loginForm.get('password') as FormControl;
  }

  constructor(
    public activeModal: NgbActiveModal,
    private authService: AuthService,
    private profileService: ProfileService,
    private registrationFacade: RegistrationFacade,
    private fb: FormBuilder,
    private modal: NgbModal
  ) { }

  ngOnInit(): void {
    this.buildForm();
    if (this.addedJunior) {
      this.loginForm.patchValue({
        user_id: this.email,
      });
    } else {
      this.subscription = this.registrationFacade.registration$.subscribe(
        (registration: RegistrationLocal) => {
          this.loginForm.patchValue({
            user_id: registration.email,
          });
        }
      );
    }
    if (this.matchingAccounts.length === 1) {
      this.selectedAccount = this.matchingAccounts[0];
    }
  }

  selectAccount(account: ProfilePublic): void {
    this.selectedAccount = account;
  }
  resetPassword(): void {
    this.profileService.resetProfilePassword(this.selectedAccount).subscribe(resp => {
      if (resp) {
        this.showSuccessAlert('Password Reset Email has been sent! Check your email.');
        this.showSuccessToast();
      } else {
        this.showErrorAlert('Unable to send password reset email. Make sure the info provided is accurate.');
      }
    });

    const forgetPasswordModal = this.modal.open(ForgotPasswordModalComponent, {
      size: 'lg',
      centered: true,
    });

    forgetPasswordModal.componentInstance.profile = this.selectedAccount;
  }

  showSuccessToast(): void {
    this.toasts.push(new Toast('success', `Password Reset Email has been sent! Check your email.`, 'Success', false));
  }

  showErrorAlert(message: string): void {
    this.alerts.push(new Alert('danger', message, null, 'warning'));
  }

  showSuccessAlert(message: string): void {
    this.success.push(new Alert('success', message, null, 'success'));
  }

  goToLogin(): void {
    this.authService.doLogin(window.location.href);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  login(): void {
    this.authService.doLogin(window.location.href);
  }

  clearErrors(): void {
    this.error = null;
  }

  createNewAccount(): void {
    this.activeModal.close();
  }

  onSubmit(): void {
    this.isSubmitted = true;
    this.isSubmitting = true;
    this.authService.doLogin(window.location.href);
    this.activeModal.close();
  }

  private buildForm(): void {
    this.loginForm = this.fb.group({
      profile_username: [null, Validators.required],
      password: [null, [Validators.required, Validators.minLength(6)]],
    });
  }
}
