<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <img aria-hidden="true" class="close-button" src="assets/images/x-circle.svg" alt="X" (click)="cancel()" />
  </button>
</div>

<div class="modal-body">
  <p>{{message}}</p>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="cancel()">Cancel</button>
  <button type="button" class="btn btn-secondary" (click)="confirm()">Confirm</button>
</div>
