import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MembershipOption } from 'src/app/core/models';

import { AdminMembershipPaymentStepComponent } from './admin-membership-payment-step/admin-membership-payment-step.component';
import { EarlyRenewUpgradeStepComponent } from './early-renew-upgrade-step/early-renew-upgrade-step.component';
import { MemberInformationStepComponent } from './member-information-step/member-information-step.component';
import { MemberOffersComponent } from './member-offers/member-offers.component';
import { PostConfirmationComponent } from './post-confirmation/post-confirmation.component';
import { PostPurchaseComponent } from './post-purchase/post-purchase.component';
// import { PremiumUpgradeComponent } from './premium-upgrade/premium-upgrade.component';
import { RaceDetailsStepComponent } from './race-details-step/race-details-step.component';
import { RaceUpgradeComponent } from './race-upgrades/race-upgrade.component';
// import { RaceSpotInsuranceComponent } from './race-spot-insurance/race-spot-insurance.component';
import { RegistrationResolver } from './resolvers/registration.resolver';
import { RideTechUpgradeComponent } from './ride-tech-upgrade/ride-tech-upgrade.component';
import { SelectOptionsStepComponent } from './select-options-step/select-options-step.component';
// import { SpotInsuranceUpgradeComponent } from './spot-insurance-upgrade/spot-insurance-upgrade.component';

const routes: Routes = [
  // {
  //   path: 'ride/member-info',
  //   component: MemberInformationStepComponent,
  //   data: {
  //     title: 'Ride Member Information Form',
  //     membershipOption: MembershipOption.Ride,
  //   },
  //   resolve: {
  //     registration: RegistrationResolver,
  //   },
  // },
  {
    path: 'race/member-info',
    component: MemberInformationStepComponent,
    data: {
      title: 'Race Member Information Form',
      membershipOption: MembershipOption.Race,
    },
    resolve: {
      registration: RegistrationResolver,
    },
  },
  {
    path: 'race/options',
    component: SelectOptionsStepComponent,
    data: {
      title: 'Select Options Form',
      membershipOption: MembershipOption.Race,
    },
    resolve: {
      registration: RegistrationResolver,
    },
  },
  // {
  //   path: 'ride/spot-insurance',
  //   component: RaceSpotInsuranceComponent,
  //   data: {
  //     title: 'Spot Injury Insurance',
  //     membershipOption: MembershipOption.Ride,
  //   },
  //   resolve: {
  //     registration: RegistrationResolver,
  //   },
  // },
  // {
  //   path: 'race/spot-insurance',
  //   component: RaceSpotInsuranceComponent,
  //   data: {
  //     title: 'Spot Injury Insurance',
  //     membershipOption: MembershipOption.Race,
  //   },
  //   resolve: {
  //     registration: RegistrationResolver,
  //   },
  // },
  {
    path: 'race/member-offers',
    component: MemberOffersComponent,
    data: {
      title: 'Race Member Offers',
      membershipOption: MembershipOption.Race,
    },
    resolve: {
      registration: RegistrationResolver,
    },
  },
  {
    path: 'race/junior-member-offers',
    component: MemberOffersComponent,
    data: {
      title: 'Junior Race Member Offers',
      membershipOption: MembershipOption.Race,
      addedJunior: true,
    },
    resolve: {
      registration: RegistrationResolver,
    },
  },
  {
    path: 'race/details',
    component: RaceDetailsStepComponent,
    data: {
      title: 'Race Details Form',
      membershipOption: MembershipOption.Race,
    },
    resolve: {
      registration: RegistrationResolver,
    },
  },
  {
    path: 'race/junior-member-info',
    component: MemberInformationStepComponent,
    data: {
      title: 'Junior Member Information Form',
      membershipOption: MembershipOption.Race,
      addedJunior: true,
    },
    resolve: {
      registration: RegistrationResolver,
    },
  },
  {
    path: 'race/junior-details',
    component: RaceDetailsStepComponent,
    data: {
      title: 'Junior Race Details Form',
      membershipOption: MembershipOption.Race,
      addedJunior: true,
    },
    resolve: {
      registration: RegistrationResolver,
    },
  },
  // {
  //   path: 'race/junior-spot-insurance',
  //   component: RaceSpotInsuranceComponent,
  //   data: {
  //     title: 'Junior Race Spot Injury Insurance',
  //     membershipOption: MembershipOption.Race,
  //     addedJunior: true,
  //   },
  //   resolve: {
  //     registration: RegistrationResolver,
  //   },
  // },
  {
    path: 'admin-membership-payment',
    component: AdminMembershipPaymentStepComponent,
    data: { title: 'Admin Membership Payment' },
  },
  {
    path: 'post-purchase',
    component: PostPurchaseComponent,
    data: { title: 'Post Purchase Form(s)' },
  },
  {
    path: 'post-confirmation/:lineItems',
    component: PostConfirmationComponent,
    data: { title: 'Post Confirmation Form(s)' },
  },
  {
    path: 'upgrade',
    component: RaceUpgradeComponent,
    data: { title: 'Race Upgrade' },
  },
  // {
  //   path: 'upgrade/racing-add-on',
  //   component: PremiumUpgradeComponent,
  //   data: { title: 'Racing Add-On' },
  // },
  {
    path: 'upgrade/ride-tech',
    component: RideTechUpgradeComponent,
    data: { title: 'Ride Membership for Tech License Holders' },
  },
  // {
  //   path: 'upgrade/spot-insurance',
  //   component: SpotInsuranceUpgradeComponent,
  //   data: { title: 'Spot Insurance Add-On' },
  // },
  {
    path: 'upgrade/early-renewal',
    component: EarlyRenewUpgradeStepComponent,
    data: { title: 'Early Renewal Upgrade' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EnrollmentRoutingModule {}
