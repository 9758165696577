<div class="container-fluid">
  <span class="d-flex justify-content-end pt-3 pr-1">
    <img class="close-button" src="assets/images/x-circle.svg" alt="X"
      (click)="activeModal.dismiss()" />
  </span>
  <div class="row">
    <div class="col-12">
      <div class="renew-container text-center">
        <h2 class="header">{{ modalTitle }}</h2>

        <p class="py-2 m-0 mb-md-3 expired-status">
          <strong class="text-primary">{{ restrictedUserMessage }}</strong>
          <br>
          Please contact <a href="mailto:membershipservices1@usacycling.org">membershipservices1@usacycling.org</a> for more information.
        </p>

        <button type="button" class="col-6 col-md-4 btn btn-secondary mt-3 mr-2"
          (click)="activeModal.dismiss()">
          Log Out
        </button>
      </div>
    </div>
  </div>
</div>
