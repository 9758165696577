<div class="header-container d-flex align-items-center">
  <h2 class="header m-0">Purchase a Technical License</h2>
</div>
<h5 class="sub-header">In order to purchase this license you must have a USA Cycling Profile</h5>
<div class="existing-member-container d-flex align-items-center row p-3 m-0 mt-4" *appShowAuthed>
  <div class="col-2 col-md-1">
    <img src="assets/user-check.svg" alt="User" />
  </div>
  <div class="col-10 col-md-6">
    <p class="m-0">
        If you have ridden with USA Cycling before, Log In to get started. Or Sign Up easily here.
    </p>
  </div>
  <div
    class="d-flex justify-content-center justify-content-md-end col-12 col-md-4 offset-md-1 pt-4 pt-md-0"
  >
    <button type="button" class="btn btn-primary px-3 mr-3" (click)="openRegisterModal()">SIGN UP</button>
    <button type="button" class="btn btn-secondary px-3" (click)="login()">LOG IN</button>
  </div>
</div>

<div class='row'>
  <p class='col-md-10 mt-4'>
    Looking to purchase a USA Cycling Membership? <a class='text-danger' href='/'>Start here</a>. We’ll guide you to
    the plan and benefits that are right for your cycling needs, no matter your age, skill or
    experience level - or help you sign up the whole household.
  </p>

  <p class='col-md-8 mt-4 text-danger'>
    <em>
      NOTE: To change or add a club, you must buy or reissue your license. This is free if you have a
      green license. You may switch to a green license once you've selected "Reissue" below. In order
      to complete the process of purchasing a license on-line you will need to complete the process by
      signing a waiver electronically and paying via credit/debit card. If you would like to pay by
      other means or hand sign a waiver, you can download a license application.
    </em>
  </p>
</div>
