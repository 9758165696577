import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LicenseCategory, LicenseCategoryStatus, LicenseType, Profile } from 'src/app/core/models';
import { ShopifyProductVariant } from 'src/app/core/models/shopify/shopify_product_variant.class';
import { LicenseService } from 'src/app/core/services';

import { RequirementsModalComponent } from '../../molecules';

@Component({
  selector: 'app-license-menu-accordion',
  templateUrl: './license-menu-accordion.component.html',
  styleUrls: ['./license-menu-accordion.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LicenseMenuAccordionComponent implements OnInit {
  @Input() user: Profile;
  @Input() licenseType: LicenseType;

  @Output() selectedLicenses: EventEmitter<any> = new EventEmitter(null);

  toggleIcon = 'icon-chevron-down';
  licenseCategoryGroups = {};
  licenseForm: FormGroup;

  disabledStatuses = [
    // Removed "valid" status to allow early renewal - TODO: @Brennan - Re-add if needed
    // LicenseCategoryStatus.VALID,
    LicenseCategoryStatus.INELIGBLE,
  ];

  nonEligiblityStatuses = [
    LicenseCategoryStatus.VALID,
    LicenseCategoryStatus.EXPIRED,
    LicenseCategoryStatus.INVALID,
  ];

  officialsSkus = this.licenseService.officialsSkus;
  officialsAndMotoRefSkus = this.licenseService.officialsAndMotoRefSkus;

  get f(): any {
    return this.licenseForm.controls;
  }

  get licenseCategories(): LicenseCategory[] {
    return this.licenseType.license_categories;
  }

  get categoryGroupKeys(): string[] {
    return Object.keys(this.licenseCategoryGroups);
  }

  get categoryGroupEntries(): any[] {
    return Object.entries(this.licenseCategoryGroups);
  }

  get isGrouped(): boolean {
    return this.licenseCategories.some((category) => !!category.base_shopify_variant);
  }

  get isOfficials(): boolean {
    return this.licenseType.name.toLowerCase().includes('officials');
  }

  constructor(
    private fb: FormBuilder,
    private licenseService: LicenseService,
    private modal: NgbModal
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.subscribeToForm();
  }

  createForm(): void {
    const controls = {};
    let baseVariant: ShopifyProductVariant;
    const baseVariants: ShopifyProductVariant[] = [];
    const categories: LicenseCategory[] = [];

    // Grouped license categories (i.e. Officials)
    if (this.isGrouped) {
      this.licenseCategories.forEach((category: LicenseCategory) => {
        const categoryBaseVariant = category.base_shopify_variant || category.shopify_variant;
        // Handle officials licenses (non-motoRef)
        if (categoryBaseVariant) {
          if (this.isOfficials && this.officialsSkus.includes(categoryBaseVariant.sku)) {
            baseVariants.push(categoryBaseVariant);
            categories.push(category);
          } else {
            if (this.licenseCategoryGroups[categoryBaseVariant.sku]) {
              this.licenseCategoryGroups[categoryBaseVariant.sku].categories.push(category);
            } else {
              this.licenseCategoryGroups[categoryBaseVariant.sku] = {
                baseVariant: categoryBaseVariant,
                categories: [category],
              };
            }
          }
        }
      });

      if (this.isOfficials) {
        this.officialsSkus.forEach((sku) => {
          const skuVariant = baseVariants.find((variant) => variant.sku === sku);
          if (skuVariant && !baseVariant) {
            baseVariant = skuVariant;
          }
        });
        if (baseVariant) {
          this.licenseCategoryGroups[baseVariant.sku] = {
            baseVariant,
            categories,
          };
        }
        this.sortOfficialsGroups();
      }

      // // Handle officials descending order (not strictly alphabetical)
      // this.licenseCategoryGroups = {
      //   'T-OFFICIAL-IC-1': this.licenseCategoryGroups['T-OFFICIAL-IC-1'],
      //   'T-OFFICIAL-EC-1': this.licenseCategoryGroups['T-OFFICIAL-EC-1'],
      //   'T-OFFICIAL-NC-1': this.licenseCategoryGroups['T-OFFICIAL-NC-1'],
      //   'T-OFFICIAL-A-1': this.licenseCategoryGroups['T-OFFICIAL-A-1'],
      //   'T-OFFICIAL-B-1': this.licenseCategoryGroups['T-OFFICIAL-B-1'],
      //   'T-OFFICIAL-C-1': this.licenseCategoryGroups['T-OFFICIAL-C-1'],
      //   'T-OFFICIAL-M-C': this.licenseCategoryGroups['T-OFFICIAL-M-C'],
      //   'T-OFFICIAL-M-B': this.licenseCategoryGroups['T-OFFICIAL-M-B'],
      //   'T-OFFICIAL-M-A': this.licenseCategoryGroups['T-OFFICIAL-M-A'],
      //   'T-OFFICIAL-M-P': this.licenseCategoryGroups['T-OFFICIAL-M-P'],
      // };
      //   // For officials, filter out groups below highest eligible
      //   for (let i = 0; i < this.officialsSkus.length; i++) {
      //     if (
      //       !this.groupBtnDisabled(this.licenseCategoryGroups[this.officialsSkus[i]].categories)
      //     ) {
      //       const skusToRemove = this.officialsSkus.slice(i + 1);
      //       skusToRemove.forEach((sku) => delete this.licenseCategoryGroups[sku]);
      //       break;
      //     }
      //   }
      // }

      Object.entries(this.licenseCategoryGroups).forEach(([key, val]) => {
        // tslint:disable-next-line: no-string-literal
        const disabled = this.groupBtnDisabled(val['categories']);

        controls[key] = {
          value: false,
          disabled,
        };
      });
    } else {
      // Normal license categories list (not grouped)
      this.licenseCategories.forEach((category: LicenseCategory) => {
        const disabled = this.btnDisabled(category);
        if (category.shopify_variant) {
          controls[category.shopify_variant.sku] = {
            value: false,
            disabled,
          };
        }
      });
    }

    this.licenseForm = this.fb.group(controls);
  }

  subscribeToForm(): void {
    this.licenseForm.valueChanges.subscribe((val) => {
      const selectedLicenses = {};
      Object.entries(val).forEach(([key, value]) => {
        if (this.isGrouped) {
          this.licenseCategoryGroups[key].categories.forEach((category: LicenseCategory) => {
            selectedLicenses[category.shopify_variant.sku] = value;
          });
        } else {
          selectedLicenses[key] = value;
        }
      });
      this.selectedLicenses.emit(selectedLicenses);
    });
  }

  onPanelChange(event: any): void {
    this.toggleIcon = event.nextState ? 'icon-chevron-up' : 'icon-chevron-down';
  }

  sortOrder(): number {
    // Always return 0 for sort, keep same order as keys (defined in createForm() above)
    return 0;
  }

  toggleControlValue(category?: LicenseCategory, key?: string): void {
    const control = category ? this.categoryControl(category) : this.categoryGroupControl(key);
    if (control) {
      control.setValue(!control.value);
    }
  }

  openRequirementsModal(category?: LicenseCategory, key?: string): void {
    const modalRef = this.modal.open(RequirementsModalComponent, {
      size: 'md',
      centered: true,
    });
    if (category) {
      modalRef.componentInstance.categories = [category];
    } else if (key) {
      modalRef.componentInstance.baseVariant = this.licenseCategoryGroups[key].baseVariant;
      modalRef.componentInstance.categories = this.licenseCategoryGroups[key].categories;
    }
  }

  statusClass(status: string): string {
    return this.licenseService.licenseCategoryStatusClass(status);
  }

  iconClass(status: string): string {
    return this.licenseService.licenseCategoryIconClass(status);
  }

  requirementStatus(category: LicenseCategory): string {
    return category.requirements_completed ? 'Requirements Met' : 'Requirements Pending';
  }

  showExpiration(category: LicenseCategory): boolean {
    return category.expiration && category.status !== LicenseCategoryStatus.EXPIRED;
  }

  categoryControl(category: LicenseCategory): FormControl {
    return category.shopify_variant ? this.f[category.shopify_variant.sku] : null;
  }

  btnClass(category: LicenseCategory): string {
    const control = this.categoryControl(category);
    return control && !!control.value ? 'btn-primary' : 'btn-secondary';
  }

  btnDisabled(category: LicenseCategory): boolean {
    return (
      this.disabledStatuses.includes(category.status) ||
      (category.requirements_blocking && !category.requirements_completed)
    );
  }

  btnText(category: LicenseCategory): string {
    const control = this.categoryControl(category);
    let text = this.nonEligiblityStatuses.includes(category.status) ? 'Renew' : 'Add to Cart';
    text = control && !!control.value ? 'Remove' : text;
    return text;
  }

  // Category Groups
  groupStatus(categories: LicenseCategory[]): string {
    return this.licenseService.licenseCategoryGroupStatus(categories);
  }

  showGroupExpiration(categories: LicenseCategory[]): boolean {
    return categories.some((category) => this.showExpiration(category));
  }

  groupExpiration(categories: LicenseCategory[]): Date {
    const expirationDates = categories
      .map((category) => category.expiration)
      .filter((expiration) => !!expiration);
    return expirationDates.sort((a, b) => new Date(b).getTime() - new Date(a).getTime())[0];
  }

  categoryGroupControl(key: string): FormControl {
    return this.f[key];
  }

  groupRequirementStatus(categories: LicenseCategory[]): string {
    return categories.some((category) => !!category.requirements_completed)
      ? 'Requirements Met'
      : 'Requirements Pending';
  }

  groupBtnClass(key: string): string {
    const control = this.categoryGroupControl(key);
    return control && !!control.value ? 'btn-primary' : 'btn-secondary';
  }

  groupBtnDisabled(categories: LicenseCategory[]): boolean {
    return categories.every((category) => this.btnDisabled(category));
  }

  groupBtnText(key: string): string {
    const control = this.categoryGroupControl(key);
    let text = this.licenseCategoryGroups[key].categories.some((category: LicenseCategory) =>
      this.nonEligiblityStatuses.includes(category.status)
    )
      ? 'Renew'
      : 'Add to Cart';
    text = control && !!control.value ? 'Remove' : text;
    return text;
  }

  sortOfficialsGroups(): void {
    // Handle officials descending order (not strictly alphabetical)
    const sortedGroups = {};
    this.officialsAndMotoRefSkus.forEach((sku) => {
      const group = this.licenseCategoryGroups[sku];
      if (group) {
        sortedGroups[sku] = group;
      }
    });
    this.licenseCategoryGroups = sortedGroups;
  }
}
