import { Component, Input, OnInit } from '@angular/core';

import { Toast } from '../../core/models';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styles: [],
})
export class ToastComponent implements OnInit {
  divClass = 'top-right';

  @Input() toasts: Toast[] = [];
  @Input() containerClass: string;

  // constructor() {}

  ngOnInit(): void {
    if (this.containerClass) {
      this.divClass = this.containerClass;
    }

    this.startTimeAgo(this.toasts);
  }

  close(toast: Toast): void {
    toast.show = false;
  }

  startTimeAgo(toasts: Toast[]): void {
    const setTimeAgo = () => {
      toasts.forEach((toast: Toast) => {
        const timePassed = Date.now() - toast.time;
        let unit = 'secs';
        let divideBy = 1000;

        if (timePassed < 5000) {
          toast.timeAgo = 'Just now';
        } else {
          if (timePassed >= 3600000) {
            unit = 'hr';
            divideBy = 3600000;
            if (timePassed > 7200000) {
              unit = 'hrs';
            }
          } else if (timePassed >= 60000) {
            unit = 'min';
            divideBy = 60000;
            if (timePassed > 120000) {
              unit = 'mins';
            }
          }
          toast.timeAgo = Math.round(timePassed / divideBy) + ' ' + unit + ' ago';
        }
      });
    };

    setTimeAgo();

    setInterval(() => {
      setTimeAgo();
    }, 2000);
  }
}
