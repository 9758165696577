<app-form-title [customClasses]="['mb-4']">License Information</app-form-title>
<app-form-subtitle [customClasses]="['text-left']">
  Your information and current elegibility is shown below. If the license you are seeking
  requires additional learning or credentials, they are listed below. Contact the
  <a class="text-warning" href="https://usacyclinghelp.zendesk.com/hc/en-us/requests/new">USA Cycling Membership Office</a> with any questions.
</app-form-subtitle>
  <app-form-subtitle *ngIf="currentStep === 'post-purchase'" [customClasses]="['text-left']">
    You can manage your account details and more from your <a class="text-warning" href="{{ adminURL }}">My Account dashboard</a>.
  </app-form-subtitle>
<hr />

<table *ngIf="licenseGroups.length">
  <thead>
    <tr>
      <td>License</td>
      <td>Status</td>
      <td>Expiration</td>
    </tr>
  </thead>

  <tbody>
    <tr *ngFor="let group of licenseGroups">
      <th>{{group.type_name}}</th>
      <th [class]="statusClass(group)">{{statusText(group)}}</th>
      <th>{{group.expiration | date:'MM/d/y'}}</th>
    </tr>
  </tbody>
</table>
