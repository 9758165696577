import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormFieldErrors } from 'src/app/core/models';

const noop = () => {};

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements OnInit {
  isChecked = false;
  inlineStyle = '';
  typeClass = 'custom-checkbox';

  @Input() id: number;
  @Input() name: string;
  @Input() value: string;
  @Input() label: string;
  @Input() toggle: boolean;
  @Input() inline: boolean;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() formControlName: string;
  @Input() formSubmitted: boolean;
  @Input() errors: FormFieldErrors;

  // private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  @Input()
  get checked(): boolean {
    return this.isChecked;
  }

  set checked(val) {
    this.isChecked = val;
    // this.change.emit(this.isChecked);
    this.checkedChange.emit(this.isChecked);
    this.onChangeCallback(this.isChecked);
  }

  // @Output() change: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  writeValue(value: any): void {
    this.isChecked = value;
    this.onChangeCallback(value);
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  // registerOnTouched(fn: any): void {
  //   this.onTouchedCallback = fn;
  // }

  onCheckChange(event: any): void {
    if (event && event.target) {
      // this.checked = event.target.checked ? true : undefined;
      this.checked = event.target.checked;
      // this.onChangeCallback(event.target.checked);
    }
  }

  ngOnInit(): void {
    if (this.inline) {
      this.inlineStyle = 'custom-control-inline';
    }
    if (this.toggle) {
      this.typeClass = 'custom-switch';
    }
  }
}
