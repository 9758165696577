import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LicenseCategory, MerchantAccountTag, ProductVariant, Profile } from 'src/app/core/models';
import { ShopifyProductVariant } from 'src/app/core/models/shopify/shopify_product_variant.class';
import { ProfileService, ShopifyService, TransactionService } from 'src/app/core/services';
import { NonEnrollmentWaiverModalComponent } from 'src/app/shared/non-enrollment-waiver-modal/non-enrollment-waiver-modal.component';
import { environment } from 'src/environments/environment';

export interface OrderItem {
  label: string;
  price: number;
}

@Component({
  selector: 'app-license-cart',
  templateUrl: './license-cart.component.html',
  styleUrls: ['./license-cart.component.scss'],
})
export class LicenseCartComponent implements OnChanges {
  @Input() selectedLicenses: LicenseCategory[];
  @Input() licenseCount: number;
  @Input() user: Profile;

  isLoading = false;
  isSubmitting = false;
  waiverSigned = false;
  licenseGroups = {};

  officialsBaseVariants: ShopifyProductVariant[] = [];
  officialsLicenses: LicenseCategory[] = [];

  sortedLicenses: LicenseCategory[];

  serverError: any = null;

  get ghostModeActive(): boolean {
    return this.profileService.hasGhostModeCookie;
  }

  constructor(
    private modal: NgbModal,
    private shopifyService: ShopifyService,
    private profileService: ProfileService,
    private transactionService: TransactionService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.licenseCount) {
      this.licenseGroups = {};
      this.selectedLicenses.forEach((license) => {
        const baseVariant = license.base_shopify_variant || license.shopify_variant;

        if (this.isOfficialsLicenseVariant(baseVariant)) {
          // Handle officials license grouping
          if (!this.officialsBaseVariants.find((variant) => variant.sku === baseVariant.sku)) {
            this.officialsBaseVariants.push(baseVariant);
          }
        } else if (this.licenseGroups[baseVariant.sku]) {
          this.licenseGroups[baseVariant.sku].push(license);
        } else {
          this.licenseGroups[baseVariant.sku] = [license];
        }
      });
      if (this.officialsBaseVariants.length) {
        this.officialsBaseVariants.sort((a, b) => parseInt(b.price, 10) - parseInt(a.price, 10));
        const officialsBaseVariant = this.officialsBaseVariants[0];
        let officialsLicenses: LicenseCategory[] = [];
        this.officialsBaseVariants.forEach((variant) => {
          officialsLicenses = [...officialsLicenses, ...this.findOfficialsLicenses(variant)];
        });

        this.officialsLicenses = officialsLicenses;
        this.licenseGroups[officialsBaseVariant.sku] = this.officialsLicenses;
      }
      this.sortedLicenses = this.sortLicenses();
    }
  }

  isOfficialsLicenseVariant(variant: ShopifyProductVariant): boolean {
    return variant.sku.includes('OFFICIAL') && !variant.sku.includes('-M-');
  }

  isOfficialsLicense(license: LicenseCategory): boolean {
    const variant = license.base_shopify_variant || license.shopify_variant;
    return this.isOfficialsLicenseVariant(variant);
  }

  findOfficialsLicenses(variant: ShopifyProductVariant): LicenseCategory[] {
    return this.selectedLicenses.filter(
      (license) =>
        (license.base_shopify_variant && license.base_shopify_variant.sku === variant.sku) ||
        license.shopify_variant.sku === variant.sku
    );
  }

  useVariant(category: LicenseCategory): ShopifyProductVariant {
    // TODO: Use "for-of" loop instead? (lint warning)
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.selectedLicenses.length; i++) {
      const license = this.selectedLicenses[i];
      const baseVariant = license.base_shopify_variant;

      if (baseVariant) {
        const licenseGroup = this.licenseGroups[baseVariant.sku];
        if (licenseGroup && licenseGroup[0].id === category.id) {
          return category.base_shopify_variant;
        }
      }
    }

    return category.shopify_variant;
  }

  useVariants(category: LicenseCategory): ShopifyProductVariant[] {
    // TODO: Use "for-of" loop instead? (lint warning)
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.selectedLicenses.length; i++) {
      const license = this.selectedLicenses[i];
      const baseVariant = license.base_shopify_variant;

      if (baseVariant) {
        const licenseGroup = this.licenseGroups[baseVariant.sku];
        if (licenseGroup && licenseGroup[0].id === category.id) {
          // Include base variant for price AND normal variant for SKU
          return [category.base_shopify_variant, category.shopify_variant];
        }
      }
    }

    return [category.shopify_variant];
  }

  openWaiverModal(): void {
    if (!this.ghostModeActive || !this.waiverSigned) {
      const modal = this.modal.open(NonEnrollmentWaiverModalComponent, {
        centered: true,
        size: 'xl',
      });
      modal.componentInstance.user = this.user;
      modal.result
        .then(() => {
          this.waiverSigned = true;
          this.checkout();
        })
        .catch(() => {});
    } else {
      this.checkout();
    }
  }

  checkout(): void {
    this.serverError = null;
    this.isSubmitting = true;

    this.shopifyService.getProducts().then((products) => {
      try {
        const variantsBySku: { [key: string]: ProductVariant } = products
          .flatMap((product) => product.variants)
          .reduce((acc, productVariant) => {
            acc[productVariant.sku] = productVariant;
            return acc;
          }, {});

        const customAttributes: Array<any> = [
          {
            key: 'source',
            value: 'membership',
          },
          {
            key: 'return_url',
            value: `${environment.publicUrl}/management/license-post-purchase`,
          },
        ];

        const note = `membership, technical-membership, ${MerchantAccountTag.Membership}`;

        // const lineItems: Array<any> = this.selectedLicenses
        // .filter((license) => !!this.useVariant(license))
        // .map((license) => ({
        //   // Leave this logic in place, as Storefront variant ID is not the same as Admin variant ID
        //   variantId: variantsBySku[this.useVariant(license).sku].id,
        //   quantity: 1,
        // }));
        const lineItems: Array<any> = [];
        this.selectedLicenses
          .filter((license) => !!this.useVariants(license).length)
          .forEach((license) => {
            this.useVariants(license).forEach((variant) => {
              lineItems.push({
                // Leave this logic in place, as Storefront variant ID is not the same as Admin variant ID
                variantId: variantsBySku[variant.sku].id,
                quantity: 1,
              });
            });
          });

        return this.shopifyService
          .createCheckout(lineItems, customAttributes, note)
          .then((checkoutModel) => {
            const transaction = { shopify_uuid: checkoutModel.checkout.id };

            return new Promise<void>((resolve, reject) => {
              this.transactionService
                .createStandardTransaction(transaction)
                .pipe(
                  catchError((err) => {
                    this.isSubmitting = false;
                    this.serverError = err;
                    return throwError(err);
                  })
                )
                .subscribe(
                  () => {
                    window.location.href = this.transactionService.getCheckoutUrl(
                      checkoutModel.checkout.id,
                      {
                        email: this.user.profile_email,
                        firstName: this.user.profile_first_name,
                        lastName: this.user.profile_last_name,
                      }
                    );
                    resolve();
                  },
                  () => {
                    reject();
                  }
                );
            });
          });
      } catch (error) {
        return Promise.reject(error);
      }
    });
  }

  sortLicenses(): LicenseCategory[] {
    if (this.officialsLicenses.length) {
      let sortedLicenses: LicenseCategory[] = [];
      this.selectedLicenses.forEach((license) => {
        if (this.isOfficialsLicense(license)) {
          if (!sortedLicenses.some((lic) => this.isOfficialsLicense(lic))) {
            sortedLicenses = [...sortedLicenses, ...this.officialsLicenses];
          }
        } else {
          sortedLicenses.push(license);
        }
      });
      return sortedLicenses;
    }

    return this.selectedLicenses;
  }

  get total(): number {
    let total = 0;
    this.selectedLicenses
      .filter((license) => !!this.useVariant(license))
      .map((license) => this.useVariant(license))
      .forEach((variant) => (total += parseFloat(variant.price)));
    return total;
  }
}
