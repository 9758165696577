<div>
  <span class="d-flex justify-content-end pt-3 pr-3"
    ><img class="close-button" src="assets/images/x-circle.svg" alt="X" (click)="dismissModal()"
  /></span>
  <div class="container modal-container">
    <div class="row justify-content-center px-3">
      <div class="col-12 col-md-10 col-lg-8">
        <h3 class="modal-content__header">{{ title }}
          <i *ngIf="isCollegiate"
              class="icon icon-information small ml-2"
              placement="bottom"
              [ngbTooltip]="tipContent"
              tooltipClass="custom-tooltip"
              [openDelay]="250"
              tabindex="0"
            ></i>
          <ng-template #tipContent
          ><p>
            Please choose the collegiate club you are currently associated with.
          </p>
          <p class="mb-0">
            If your club is not listed, select the 'I don't see my School/My School is not active' option to proceed.
          </p>
        </ng-template>
        </h3>
        <p *ngIf="registration || isRide" class="modal-content__subheader">
          Fill out the following information to continue with your selection.
        </p>

        <ngb-alert *ngIf="error" type="danger" (close)="clearErrors()">
          {{error}}
        </ngb-alert>

        <ngb-alert *ngIf="!isUpgrade && missingRegistration" class="mt-3" type="info" [dismissible]="false">
          Thank you for your interest! There is an issue with your account. Please contact
          <a href="mailto:membership@usacycling.org">membership@usacycling.org</a> to complete your transaction.
        </ngb-alert>

        <form *ngIf="(isCollegiate && isUpgrade) || (isCollegiate && !missingRegistration)" [formGroup]="collegiateForm" (ngSubmit)="onSubmit()" novalidate>
          <div class="row">
            <div class="col-12">
              <p class="collegiate-club mb-2 mt-4">Collegiate club <span class="star">*</span></p>

              <ng-select
                [items]="collegiateClubs$ | async"
                bindLabel="club_name"
                [hideSelected]="true"
                [clearable]="false"
                [loading]="collegiateClubsLoading"
                [typeahead]="collegiateClubsInput$"
                [minTermLength]="2"
                typeToSearchText="Please enter 2 or more characters"
                notFoundText="No Collegiate Clubs Found"
                placeholder="Enter Club Name"
                formControlName="collegiate_club"
                (change)="updateCollegiateForm($event)"
              >
              </ng-select>

              <p class="collegiate__small">
                Collegiate riders must select an active collegiate club.
              </p>
            </div>
          </div>

          <div *ngIf="collegiateClub.value" class="club-box">
            <div class="row">
              <div class="col-12">
                <span class="club-box__small">Collegiate Club</span>
              </div>
              <div class="col-md-4">
                <strong class="club-box__name">
                  {{ collegiateClub.value.club_name || collegiateClub.value.club_id }}
                </strong>
              </div>

              <div class="club-box__content col-md-8 d-flex align-items-center">
                <img
                  class="club-box__flag"
                  src="assets/images/school.svg"
                  alt=""
                />

                <p class="club-box__description">
                  This club will be used for all of your collegiate racing disciplines. Only
                  one collegiate club can be assigned per collegiate member.
                </p>
              </div>
            </div>
          </div>
        </form>

        <app-international-license *ngIf="(isInternational && isUpgrade) || ( isInternational && !missingRegistration)"
          [registration]="registration"
          [isSubmitting]="isSubmitting"
          [formRaceAge]="user.profile_race_age"
          (formValues)="updateInternationalLicenseForm($event)"
        ></app-international-license>

        <div *ngIf="isRide" class="col-12 col-md-6">
          <form [formGroup]="raceGenderForm" (ngSubmit)="onSubmit()">
            <label for="race_gender">Sex<span class="star">*</span>
              <i
                class="icon icon-information"
                placement="bottom"
                [ngbTooltip]="raceGenderTipContent"
                tooltipClass="custom-tooltip"
                [openDelay]="250"
                tabindex="0"
              ></i>
            </label>
            <ng-select
              [items]="raceGenders"
              bindValue="value"
              bindLabel="label"
              formControlName="profile_race_gender"
              [clearable]="false"
              [searchable]="false"
              placeholder="Make Selection"
              autofocus
            >
            </ng-select>

            <ng-template #raceGenderTipContent>
              Members shall have an equal opportunity to participate in bicycle racing without
              discrimination, however a binary sex must be chosen for categorized competitive racing.
            </ng-template>
          </form>
        </div>

        <div class="mt-3 d-flex justify-content-end">
            <button id="back-btn" class="btn btn-secondary mr-2" type="button" [disabled]="isSubmitting" (click)="dismissModal()">
              Cancel
            </button>
            <button id="submit-btn" class="btn btn-primary" type="button" [disabled]="disableSubmit" (click)="onSubmit()">
              <span *ngIf="isSubmitting" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              Checkout
            </button>
        </div>
      </div>
    </div>
  </div>
</div>
