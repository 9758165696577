<div class="container" *ngIf="registration$ | async as registration">
  <div class="pre-prompt">
    <div class="row">
      <div class="col-12 text-center">
        <h1 class="pre-prompt__title">
          That's ok, we'll send an email to {{ registration.first_name }}
          {{ registration.last_name }} so they can sign later.
        </h1>

        <p class="pre-prompt__description">
          We will send the waiver to <strong>{{ registration.email }}</strong> via email for them to
          sign. You can still continue, but the membership will be pending until the waiver is signed.
        </p>
        <div class="col-md-12 text-center">
          <button type="button" (click)="close()" class="col-12 col-lg-4 btn btn-primary">Got it</button>
        </div>
      </div>
    </div>
  </div>
</div>
