<app-header [cartHidden]="false"></app-header>

<app-loader *ngIf="isLoading" [overlay]="true"></app-loader>

<div class="container">
  <div class="row">
    <div class="col-12 col-md-10 offset-md-1 col-lg-9 offset-lg-0">
      <app-status-indicator-header *ngIf="userLoaded"
      [user]="currentStatusIndicatorProfile"
      [firstName]="statusIndicatorFirstName"
      [lastName]="statusIndicatorLastName"
      [email]="statusIndicatorEmail"
      [allMemberships]="allMemberships"
      [loading]="membershipsLoading"
      ></app-status-indicator-header>
      <hr>
      <!-- comment out for mbr-2999 -->
      <!-- <p class="text-danger">*Please be aware that your SPOT insurance coverage will only become effective once your upgraded membership begins.</p> -->
      <div *ngIf="userRaceAge" class="row">
        <app-upgrade-card *ngFor="let option of upgradeOptions"
        class="col-sm-6 col-lg-4 p-2"
        [title]="option.title"
        [type]="option.type"
        [price]="option.price"
        [perYear]="option.perYear"
        [sku]="option.sku"
        [benefits]="option.benefits"
        [boldBorder]="option.boldBorder"
        [spotItem]="option.spotItem"
        [hideAutoRenew]="true"
        [isCurrentMembership]="option.isCurrentMembership"
        [isSelected]="option.isSelected"
        [isDisabled]="option.isDisabled"
        [isEarlyRenewal]="option.isEarlyRenewal"
        (addToCartUpdate)="selectUpgrade($event)"
        ></app-upgrade-card>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
