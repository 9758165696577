import { Component } from '@angular/core';

@Component({
  selector: 'app-individual-membership-footer',
  templateUrl: './individual-membership-footer.component.html',
  styleUrls: ['./individual-membership-footer.component.scss'],
})
export class IndividualMembershipFooterComponent {
  currentYear = new Date().getFullYear();
}
