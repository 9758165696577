
<form [formGroup]="interestsForm" novalidate>
  <div class="form-card">
    <app-form-title [customClasses]="['title-md', 'text-left']">Your Interests</app-form-title>
    <app-form-subtitle [customClasses]="['text-left']">
      Help us customize your membership experience based on your interests.
    </app-form-subtitle>

    <h3 class="form-title" *ngIf="showRaceRide">In addition to riding, do you participate in cycling events?</h3>
    <div *ngIf="showRaceRide" class="mb-5">
      <div class="custom-control custom-radio custom-control-inline mr-5">
        <input
          class="custom-control-input"
          type="radio"
          name="race_participation"
          id="race"
          [value]="true"
          formControlName="race_participation"
        />
        <label class="custom-control-label" for="race">
          Yes
        </label>
      </div>

      <div class="custom-control custom-radio custom-control-inline">
        <input
          class="custom-control-input"
          type="radio"
          name="race_participation"
          id="recreation"
          formControlName="race_participation"
          [value]="false"
        />
        <label class="custom-control-label" for="recreation">
          No
        </label>
      </div>
    </div>

    <h3 class="form-title">What kind of cycling do you like? <span class="required">*</span></h3>
    <p class="form-subtitle mb-4">
      Please select at least one. This helps us personalize your member experience.
    </p>

    <div class="row" *ngIf="registration">
      <div class="checkboxes col-12 col-md-6 col-lg-4" *ngFor="let interest of allInterests">
        <div
          [style.background-image]="'url(' + interest.imageUrl + ')'"
          class="form-check image-wrapper"
          [ngClass]="{ 'container-checked': isInterestSelected(interest) }"
        >
          <label class="container-checkbox">
            <span class="category-name-label">{{ interest.name }}</span>
            <input
              type="checkbox"
              [value]="interest.id"
              [checked]="isInterestSelected(interest)"
              (change)="toggleInterest(interest)"
            />

            <span class="checkmark"></span>
          </label>

          <button type="button" class="make-primary-badge btn btn-sm" *ngIf="isInterestSelected(interest) && !isInterestPrimary(interest)" (click)="makePrimary(interest)">
            <span class="badge badge-secondary">Make Primary</span>
          </button>

          <button type="button" class="primary-category-badge btn btn-sm" *ngIf="isInterestSelected(interest) && isInterestPrimary(interest)">
            <span class="badge badge-primary">PRIMARY</span>
          </button>

          <div class="sub-category-badges" *ngIf="showInterestSelectSub(interest)">
            <button type="button" class="sub-category-badge btn btn-sm" *ngFor="let disc of interest.disciplines" (click)="selectSub(interest, disc)">
              <span class="badge" [ngClass]="{ 'badge-dark': !isSubSelected(interest, disc), 'badge-success': isSubSelected(interest, disc) }">{{ disc.name }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <ngb-alert *ngIf="interestsForm.invalid" type="danger" [dismissible]="false">
      <p class="text-capitalize m-0">Please select at least 1 interest</p>
    </ngb-alert>


    <h3 class="collegiate d-flex align-items-center">
      Are you a member of a domestic cycling club?

      <i
        class="icon icon-information small ml-2"
        placement="bottom"
        [ngbTooltip]="tipContent"
        tooltipClass="custom-tooltip"
        [openDelay]="250"
        tabindex="0"
      ></i>

      <span class="optional ml-2">(optional)</span>
    </h3>

    <ng-template #tipContent>
      <p class="m-0">
        Domestic cycling clubs are any cycling club that does not have a Collegiate
        designation. Adding your club here helps us make sure non-collegiate results and
        reporting for qualifying races can be associated with your club.
      </p>
    </ng-template>

    <div>
      <div class="custom-control custom-radio custom-control-inline mr-5">
        <input
          class="custom-control-input"
          type="radio"
          name="is_domestic_club_member"
          id="domestic-club-member-true"
          [value]="true"
          formControlName="is_domestic_club_member"
        />
        <label class="custom-control-label" for="domestic-club-member-true">
          Yes
        </label>
      </div>

      <div class="custom-control custom-radio custom-control-inline">
        <input
          class="custom-control-input"
          type="radio"
          name="is_domestic_club_member"
          id="domestic-club-member-false"
          formControlName="is_domestic_club_member"
          [value]="false"
        />
        <label class="custom-control-label" for="domestic-club-member-false">
          No
        </label>
      </div>
    </div>


    <div class="mt-4" *ngIf="domesticClubMember.value">
      <label for="domestic-club">
        Enter the primary domestic club that you’re an active member of.
      </label>

      <ng-select
        [items]="domesticClubs$ | async"
        bindLabel="club_name"
        [hideSelected]="true"
        [clearable]="true"
        [loading]="domesticClubsLoading"
        [typeahead]="domesticClubsInput$"
        [minTermLength]="2"
        [trackByFn]="trackClubsBy"
        typeToSearchText="Please enter 2 or more characters"
        notFoundText="No Domestic Clubs Found"
        placeholder="Enter Club Name"
        id="domestic-club"
        formControlName="domestic_club"
      >
      </ng-select>

      <div *ngIf="domesticClub.value" class="club-box">
        <div class="row">
          <div class="col-12">
            <span class="club-box__small">Domestic Club</span>
          </div>
          <div class="col-md-4">
            <strong class="club-box__name">
              {{ domesticClub.value.club_name || domesticClub.value.club_id }}
            </strong>
          </div>

          <div class="club-box__content col-md-8 d-flex align-items-center">
            <img
              class="club-box__flag"
              src="assets/images/flag-usa.svg"
              alt=""
            />

            <p class="club-box__description">
              This club will be used for all of your domestic racing disciplines. You
              can add more clubs and update disciplines from your membership profile
              after enrollment.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
