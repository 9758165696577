import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { Gender, GendersService, Location, LocationsService, MemberInfo } from 'src/app/core';
import { FamilyMembershipFacade, Plans } from 'src/app/core/facades';
import { AppFacade } from 'src/app/core/facades/app.facade';
import { PersonTypes, Registration } from 'src/app/core/models/registration.model';
import { RegistrationService } from 'src/app/core/services/registration.service';
@Component({
  selector: 'app-member-information-modal',
  templateUrl: './member-information-modal.component.html',
  styleUrls: ['./member-information-modal.component.scss'],
})
export class MemberInformationModalComponent implements OnInit, OnDestroy {
  currentDate: string;

  genders: Gender[];
  gendersLoading = false;

  memberInfoForm: FormGroup;

  @Input() registrationId: string;
  @Input() registration: Registration;

  states$: Observable<Location[]> = this.loadStates();
  statesLoading = false;

  errors$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  isSubmitting = false;

  planTypes = Plans;
  personTypes = PersonTypes;

  // Comnbine single registration and family registration in one object
  vm$ = this.appFacade.vm$;

  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  birthdayErrorMessage = '';

  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private locationService: LocationsService,
    private gendersService: GendersService,
    private registrationService: RegistrationService,
    private familyFacade: FamilyMembershipFacade,
    private appFacade: AppFacade
  ) {
    this.createMemberInfoForm();
  }

  loadGenders(): void {
    this.gendersLoading = true;
    this.gendersService.getAll().subscribe((genders) => {
      this.gendersLoading = false;
      this.genders = genders;
    });
  }

  private loadStates(): Observable<Location[]> {
    return this.locationService.getAll().pipe(
      catchError((err: unknown) => {
        return throwError(err);
      }),
      finalize(() => {
        this.statesLoading = false;
      })
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.currentDate = this.getCurrentDate();
    this.loadGenders();
    this.loadStates();

    if (this.registration) {
      this.patchForm();
      this.onStateChange({ code: this.registration.state, name: '' });
    } else if (this.registrationId) {
      // Leaving in case if family membership post-purchase logic ever reinstated
      this.registrationService
        .getRegistration(this.registrationId)
        .pipe(
          tap((registration: Registration) => {
            this.registration = registration;
            this.patchForm();
            this.onStateChange({ code: this.registration.state, name: '' });
          }),
          takeUntil(this.unsubscribe$)
        )
        .subscribe();
    }
  }

  patchForm(): void {
    const registrationData = { ...this.registration };
    registrationData.birthdate = moment(this.registration.birthdate).format('MM/DD/YYYY');
    this.memberInfoForm.patchValue(registrationData);
  }

  onStateChange($event: Location): void {
    // Switch location name and code so it correctly displays in ng-select
    this.states$.pipe(takeUntil(this.unsubscribe$)).subscribe((states: Location[]) => {
      const location = states.find((state) => state.code === $event.code);
      if (location) {
        this.memberInfoForm.patchValue({
          state: location.code,
          address_state_code: location.name,
        });
      }
    });
  }

  submit(memberInfo: MemberInfo): void {
    if (this.memberInfoForm.valid) {
      this.clearErrors();
      memberInfo.state = this.memberInfoForm.get('state').value;
      delete memberInfo.address_state_code;

      this.isSubmitting = true;

      const registrationId = this.registration?.id || this.registrationId;

      this.registrationService
        .updateMemberInfo(registrationId, {
          ...memberInfo,
          order_id: this.registration.order.order_id,
        })
        .pipe(
          tap((registration: Registration) => {
            this.memberInfoForm.patchValue(registration);
            this.activeModal.close(registration);
          }),
          catchError((err: any) => {
            const errors = err.errors || {};
            Object.values(errors).forEach((e: any) => {
              this.errors$.next(this.errors$.getValue().concat([e]));
            });
            return throwError(err);
          }),
          finalize(() => {
            this.isSubmitting = false;
          })
        )
        .subscribe();
    } else {
      this.memberInfoForm.markAllAsTouched();
    }
  }

  private createMemberInfoForm(): void {
    this.memberInfoForm = this.fb.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      inherit_email: false,
      phone_number: [''],
      birthdate: ['', [Validators.required, Validators.minLength(10)]],
      gender: [null, Validators.required],
      address_street: ['', Validators.required],
      address_street2: [''],
      city: ['', Validators.required],
      state: [null, Validators.required],
      address_state_code: [''],
      zip: [
        '',
        [Validators.required, Validators.maxLength(5), Validators.pattern(/^\d{5}$|^\d{5}-\d{4}$/)],
      ],
    });

    this.memberInfoForm
      .get('inherit_email')
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((inheritEmail) => {
        if (inheritEmail) {
          const familyRepresentative = this.familyFacade.selectFamilyMembers.find(
            (member) => member.is_family_representative
          );
          this.memberInfoForm.get('email').setValue(`${familyRepresentative.email} (inherited)`);
          this.memberInfoForm.get('email').disable();
        }
      });
  }

  clearErrors(): void {
    this.errors$.next([]);
  }

  private getCurrentDate(): string {
    const d = new Date();
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }
}
