import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RegistrationFacade } from 'src/app/core/facades';
import { Registration } from 'src/app/core/models/registration.model';
import { RegistrationService } from 'src/app/core/services/registration.service';

@Injectable({
  providedIn: 'root',
})
export class RegistrationResolver implements Resolve<Observable<Registration>> {
  constructor(
    private registrationService: RegistrationService,
    private registrationFacade: RegistrationFacade
  ) {}

  resolve(): Observable<Registration> {
    if (this.registrationFacade.registrationId) {
      return this.registrationService.getRegistration(this.registrationFacade.registrationId).pipe(
        catchError(() => {
          return EMPTY;
        })
      );
    }

    return null;
  }
}
