import { NgModule } from '@angular/core';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';

import { ManagementModule } from '../management/management.module';
import { SharedModule } from '../shared';

import { AddJuniorSelectComponent } from './add-junior-select/add-junior-select.component';
import { AddOnsComponent } from './add-ons/add-ons.component';
import { AdminMembershipPaymentStepComponent } from './admin-membership-payment-step/admin-membership-payment-step.component';
import { AgreementModalComponent } from './agreement-modal/agreement-modal.component';
import { CollegiateQuestionsComponent } from './collegiate-questions/collegiate-questions.component';
import { EarlyRenewUpgradeStepComponent } from './early-renew-upgrade-step/early-renew-upgrade-step.component';
import { EnrollmentRoutingModule } from './enrollment-routing.module';
import { FamilyCountSelectComponent } from './family-count-select/family-count-select.component';
import { FamilyMemberInformationComponent } from './family-member-information/family-member-information.component';
import { FamilyMemberModalComponent } from './family-member-modal/family-member-modal.component';
import { GenderAndRegularContactComponent } from './gender-and-regular-contact/gender-and-regular-contact.component';
import { GetStartedModalComponent } from './get-started-modal/get-started-modal.component';
import { InterestsComponent } from './interests/interests.component';
import { InternationalLicenseComponent } from './international-license/international-license.component';
import { JuniorAgreementModalComponent } from './junior-agreement-modal/junior-agreement-modal.component';
import { JuniorNoWaiverModalComponent } from './junior-no-waiver-modal/junior-no-waiver-modal.component';
import { JuniorPrePromptComponent } from './junior-pre-prompt/junior-pre-prompt.component';
import { JuniorWaiverModalComponent } from './junior-waiver-modal/junior-waiver-modal.component';
import { LicenseCreateFeeModalComponent } from './license-create-fee-modal/license-create-fee-modal.component';
import { MemberInformationModalComponent } from './member-information-modal/member-information-modal.component';
import { MemberInformationStepComponent } from './member-information-step/member-information-step.component';
import { MemberOfferModalComponent } from './member-offer-modal/member-offer-modal.component';
import { MemberOffersComponent } from './member-offers/member-offers.component';
import { NoWaiverModalComponent } from './no-waiver-modal/no-waiver-modal.component';
import { PostPurchaseCartComponent } from './post-purchase-cart/post-purchase-cart.component';
import { PostConfirmationComponent } from './post-confirmation/post-confirmation.component';
import { PostPurchaseComponent } from './post-purchase/post-purchase.component';
import { PrePromptComponent } from './pre-prompt/pre-prompt.component';
import { PremiumUpgradeComponent } from './premium-upgrade/premium-upgrade.component';
import { ProLicenseComponent } from './pro-license/pro-license.component';
import { RaceCategoriesModalComponent } from './race-categories-modal/race-categories-modal.component';
import { RaceDetailsStepComponent } from './race-details-step/race-details-step.component';
import { RaceLicenseDetailsComponent } from './race-license-details/race-license-details.component';
// import { RaceSpotInsuranceComponent } from './race-spot-insurance/race-spot-insurance.component';
import { RaceUpgradeComponent } from './race-upgrades/race-upgrade.component';
import { RideTechUpgradeComponent } from './ride-tech-upgrade/ride-tech-upgrade.component';
import { SelectOptionsStepComponent } from './select-options-step/select-options-step.component';
import { UpgradeInfoModalComponent } from './upgrade-info-modal/upgrade-info-modal.component';
// import { SpotInsuranceInformationComponent } from './spot-insurance-information/spot-insurance-information.component';
// import { SpotInsuranceModalComponent } from './spot-insurance-modal/spot-insurance-modal.component';
// import { SpotInsuranceUpgradeComponent } from './spot-insurance-upgrade/spot-insurance-upgrade.component';
import { UpgradeToFamilyModalComponent } from './upgrade-to-family-modal/upgrade-to-family-modal.component';

const modals = [
  PrePromptComponent,
  NoWaiverModalComponent,
  AgreementModalComponent,
  MemberInformationModalComponent,
  RaceCategoriesModalComponent,
  JuniorNoWaiverModalComponent,
  JuniorWaiverModalComponent,
  JuniorPrePromptComponent,
  JuniorNoWaiverModalComponent,
  JuniorWaiverModalComponent,
  JuniorPrePromptComponent,
  JuniorAgreementModalComponent,
  LicenseCreateFeeModalComponent,
  MemberOfferModalComponent,
];
@NgModule({
  declarations: [
    AddJuniorSelectComponent,
    AddOnsComponent,
    AdminMembershipPaymentStepComponent,
    AgreementModalComponent,
    CollegiateQuestionsComponent,
    EarlyRenewUpgradeStepComponent,
    FamilyCountSelectComponent,
    FamilyMemberInformationComponent,
    FamilyMemberModalComponent,
    GenderAndRegularContactComponent,
    GetStartedModalComponent,
    InterestsComponent,
    InternationalLicenseComponent,
    MemberInformationStepComponent,
    MemberOffersComponent,
    NoWaiverModalComponent,
    PostPurchaseCartComponent,
    PostConfirmationComponent,
    PostPurchaseComponent,
    PremiumUpgradeComponent,
    PrePromptComponent,
    ProLicenseComponent,
    RaceDetailsStepComponent,
    RaceLicenseDetailsComponent,
    // RaceSpotInsuranceComponent,
    RaceUpgradeComponent,
    RideTechUpgradeComponent,
    SelectOptionsStepComponent,
    // SpotInsuranceInformationComponent,
    // SpotInsuranceModalComponent,
    // SpotInsuranceUpgradeComponent,
    UpgradeInfoModalComponent,
    UpgradeToFamilyModalComponent,
    ...modals,
  ],
  imports: [SharedModule, EnrollmentRoutingModule, ManagementModule, NgbAlertModule],
})
export class EnrollmentModule { }
