import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MembershipOrder } from '../models/membership-order.model';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class MembershipOrdersService {
  constructor(private apiService: ApiService) {}

  getById(membershipOrderId: string): Observable<MembershipOrder> {
    return this.apiService.get(`/order/${membershipOrderId}`).pipe(
      map((response: any) => {
        return {
          ...response,
          // This is a workaround for an issue where the insertion order of enrollments
          // was not being preserved.
          enrollments: response.enrollments.sort(
            (a: any, b: any) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
          ),
        };
      })
    );
  }

  remove(membershipOrderId: string): Observable<any> {
    return this.apiService.delete(`/order/${membershipOrderId}`);
  }
}
