/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { LoggingInterceptor } from './logging.interceptor';
import { MockApiInterceptor } from './mock-api.interceptor';
import { NoopInterceptor } from './noop.interceptor';
import { TokenInterceptor } from './token.interceptor';

/** Http interceptor providers in outside-in order */
const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: environment.production ? LoggingInterceptor : NoopInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: environment.enableMockApi ? MockApiInterceptor : NoopInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true,
  },
];

export { httpInterceptorProviders };
