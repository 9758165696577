import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';

import { FamilyMember, FamilyMemberInfoType } from '../models/family-member.model';
import { PersonTypes } from '../models/registration.model';

export interface FamilyMembershipState {
  familyMembers: FamilyMember[];
  numberOfAdults: number;
  numberOfJuniors: number;
  selectedAddress: any;
  selectedMember: FamilyMember;
  adultHasSpot: boolean;
  juniorHasSpot: boolean;
  // spotQuantity: number;
}

// tslint:disable-next-line: variable-name
let _state: FamilyMembershipState = {
  familyMembers: [],
  selectedMember: {
    id: '',
    first_name: '',
    person_type: PersonTypes.ADULT,
    email: '',
    last_name: '',
    is_family_representative: true,
    is_selected: true,
  },
  selectedAddress: {
    address_street: '',
    address_street2: '',
    city: '',
    state: '',
    zip: '',
  },
  numberOfAdults: 0,
  numberOfJuniors: 0,
  adultHasSpot: false,
  juniorHasSpot: false,
  // spotQuantity: 0,
};

@Injectable({
  providedIn: 'root',
})
export class FamilyMembershipFacade {
  get selectedMember(): FamilyMember {
    return this.store.getValue().familyMembers.find((member) => member.is_selected);
  }

  get selectFamilyMembers(): FamilyMember[] {
    return this.store.getValue().familyMembers;
  }

  get currentState(): FamilyMembershipState {
    return this.store.getValue();
  }

  get getNextFamilyMember(): boolean {
    const familyMembers = this.store.getValue().familyMembers;
    const currentMemberIndex = familyMembers.indexOf(
      familyMembers.find((member) => member.is_selected)
    );
    return currentMemberIndex + 1 < familyMembers.length;
  }

  get selectSelectedAddress(): any {
    return this.store.getValue().selectedAddress;
  }

  get hasNextMember(): boolean {
    const familyMembers = this.store.getValue().familyMembers;
    const unregistered = familyMembers.filter((member) => member.id === undefined);
    return unregistered.length > 0;
  }

  get representative(): FamilyMember {
    const familyMembers = this.store.getValue().familyMembers;
    return familyMembers.find((member) => !!member.is_family_representative);
  }

  get addedJunior(): FamilyMember {
    const familyMembers = this.store.getValue().familyMembers;
    return familyMembers.find(
      (member) => !member.is_family_representative && member.person_type === PersonTypes.JUNIOR
    );
  }

  // get spotQuantity(): number {
  //   return this.store.getValue().spotQuantity;
  // }

  get adultHasSpot(): boolean {
    return this.store.getValue().adultHasSpot;
  }

  get juniorHasSpot(): boolean {
    return this.store.getValue().juniorHasSpot;
  }

  private readonly store: BehaviorSubject<FamilyMembershipState> = new BehaviorSubject<
    FamilyMembershipState
  >(this.restoreState() || _state);

  private readonly state$: Observable<FamilyMembershipState> = this.store.asObservable();

  /** Observable of family members array */
  familyMembers$: Observable<FamilyMember[]> = this.state$.pipe(
    map((state: FamilyMembershipState) => state.familyMembers),
    distinctUntilChanged()
  );

  numberOfAdults$: Observable<number> = this.state$.pipe(
    map((state: FamilyMembershipState) => {
      const adults: FamilyMember[] = state.familyMembers.filter((member: FamilyMember) => {
        return member.person_type === PersonTypes.ADULT;
      });

      return adults.length;
    }),
    distinctUntilChanged()
  );

  numberOfJuniors$: Observable<number> = this.state$.pipe(
    map((state: FamilyMembershipState) => {
      const juniors: FamilyMember[] = state.familyMembers.filter((member: FamilyMember) => {
        return member.person_type === PersonTypes.JUNIOR;
      });

      return juniors.length;
    }),
    distinctUntilChanged()
  );

  selectedAddress$: Observable<unknown> = this.state$.pipe(
    map((state: any) => {
      const selectedAddress = state.selectedAddress;

      return selectedAddress;
    })
  );

  selectedMember$: Observable<FamilyMember> = this.state$.pipe(
    map((state: FamilyMembershipState) => {
      const selectedMember = state.familyMembers.find((member) => member.is_selected);

      return selectedMember;
    })
  );
  adultHasSpotSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  adultHasSpot$: Observable<boolean> = this.state$.pipe(
    map((state: FamilyMembershipState) => state.adultHasSpot),
    distinctUntilChanged(),
    tap((hasSpot: boolean) => {
      this.adultHasSpotSubject.next(hasSpot);
    })
  );

  juniorHasSpot$: Observable<boolean> = this.state$.pipe(
    map((state: FamilyMembershipState) => state.juniorHasSpot)
  );

  // spotQuantity$: Observable<number> = this.state$.pipe(
  //   map((state: FamilyMembershipState) => state.spotQuantity)
  // );

  /**
   * Viewmodel that resolves once all the data is ready (or updated)...
   */
  vm$: Observable<FamilyMembershipState> = combineLatest([
    this.familyMembers$,
    this.numberOfAdults$,
    this.numberOfJuniors$,
    this.selectedAddress$,
    this.selectedMember$,
    this.adultHasSpot$,
    this.juniorHasSpot$,
    // this.spotQuantity$,
  ]).pipe(
    map(
      ([
        familyMembers,
        numberOfAdults,
        numberOfJuniors,
        selectedAddress,
        selectedMember,
        adultHasSpot,
        juniorHasSpot,
        // spotQuantity,
      ]) => ({
        familyMembers,
        numberOfAdults,
        numberOfJuniors,
        selectedAddress,
        selectedMember,
        adultHasSpot,
        juniorHasSpot,
        // spotQuantity,
      })
    )
  ) as Observable<FamilyMembershipState>;

  updateCurrentFamilyMember(registration: FamilyMember): void {
    const familyMembers = [...this.currentState.familyMembers];

    const currentMemberIndex = familyMembers.indexOf(
      familyMembers.find((member) => member.is_selected)
    );

    familyMembers[currentMemberIndex].id = registration.id;
    familyMembers[currentMemberIndex].first_name = registration.first_name;
    familyMembers[currentMemberIndex].last_name = registration.last_name;
    familyMembers[currentMemberIndex].email = registration.email;
    familyMembers[currentMemberIndex].isCollegiate = registration.isCollegiate;

    this.updateState({ ..._state, familyMembers });
  }

  reset(): void {
    this.updateState({
      ..._state,
      familyMembers: [],
      numberOfAdults: 0,
      numberOfJuniors: 0,
    });
  }

  setStateFromLogin(registration: any, order: any): void {
    const enrollments = order.enrollments.sort(
      (a: any, b: any) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
    );

    const newState = {
      familyMembers: enrollments.map((item: any, index: number) => {
        return {
          id: item.id,
          person_type: item.person_type,
          email: item.email,
          first_name: item.first_name,
          last_name: item.last_name,
          is_family_representative: index === 0 ? true : false,
          is_collegiate: item.is_collegiate_cyclist,
          is_selected: item.email === registration.email,
        };
      }),
      selectedAddress: {
        address_street: registration.address_street,
        address_street2: registration.address_street2,
        city: registration.city,
        state: registration.state,
        zip: registration.zip,
      },
      selectedMember: {
        id: registration.id,
        first_name: registration.first_name,
        person_type: registration.person_type,
        email: registration.email,
        last_name: registration.last_name,
        is_family_representative: order.enrollments[0].email === registration.email,
        is_selected: true,
      },
      numberOfAdults: order.adults,
      numberOfJuniors: order.juniors,
      adultHasSpot:
        registration.person_type === PersonTypes.ADULT && registration.has_spot_insurance,
      juniorHasSpot:
        registration.person_type === PersonTypes.JUNIOR && registration.has_spot_insurance,
      // spotQuantity: 0,
    };

    this.updateState({ ...newState });
  }

  setRepresentative(): void {
    const familyMembers = _state.familyMembers.map((member) => {
      return {
        ...member,
        is_selected: false,
      };
    });

    const repMemberIndex = familyMembers.indexOf(
      familyMembers.find((member) => member.is_family_representative)
    );

    familyMembers[repMemberIndex].is_selected = true;
    const rep = familyMembers[0];
    const selectedMember = {
      ...rep,
      is_selected: true,
    };

    this.updateState({ ..._state, familyMembers, selectedMember });
  }

  setSelectedMember(familyMember: FamilyMember): void {
    const state = this.store.getValue();
    const familyMembers = [...state.familyMembers];
    const currentMemberIndex = familyMembers.indexOf(
      familyMembers.find((member) => member.is_selected)
    );
    familyMembers[currentMemberIndex].is_selected = false;
    familyMember.is_selected = true;

    const selectedMember = familyMember;
    this.updateState({ ...state, selectedMember });
  }

  updateNextMember(): void {
    const state = this.store.getValue();
    const familyMembers = [...state.familyMembers];
    const currentMemberIndex = familyMembers.indexOf(
      familyMembers.find((member) => member.is_selected)
    );
    familyMembers[currentMemberIndex].is_selected = false;
    familyMembers[currentMemberIndex + 1].is_selected = true;

    const selectedMember = {
      ...state.selectedMember,
    };

    selectedMember.id = '';
    selectedMember.first_name = familyMembers[currentMemberIndex + 1].first_name;
    selectedMember.last_name = familyMembers[currentMemberIndex + 1].last_name;
    selectedMember.email = '';
    selectedMember.person_type = familyMembers[currentMemberIndex + 1].person_type;
    selectedMember.is_family_representative = false;

    this.updateState({ ...state, familyMembers, selectedMember });
  }

  setEditMember(index: any): void {
    const state = this.store.getValue();
    const familyMembers = [...state.familyMembers];
    const currentMemberIndex = familyMembers.indexOf(
      familyMembers.find((member) => member.is_selected)
    );
    familyMembers[currentMemberIndex].is_selected = false;
    familyMembers[index].is_selected = true;

    const selectedMember = {
      ...state.selectedMember,
    };

    selectedMember.id = '';
    selectedMember.first_name = familyMembers[index].first_name;
    selectedMember.last_name = familyMembers[index].last_name;
    selectedMember.email = '';
    selectedMember.person_type = familyMembers[index].person_type;
    selectedMember.is_family_representative = familyMembers[index].is_family_representative;

    this.updateState({ ...state, familyMembers, selectedMember });
  }

  updateFamilyMembership(familyMembers: FamilyMember[] = []): void {
    const state = JSON.parse(localStorage.getItem('family-membership-state'));
    // Immutable
    const newFamilyMembers: FamilyMember[] = [...familyMembers];

    const adults = familyMembers.filter((member) => member.person_type === PersonTypes.ADULT);
    const juniors = familyMembers.filter((member) => member.person_type === PersonTypes.JUNIOR);

    this.updateState({
      ...state,
      familyMembers: newFamilyMembers,
      numberOfAdults: adults.length,
      numberOfJuniors: juniors.length,
    });
  }

  updateInfoFilled(type: FamilyMemberInfoType): void {
    const state = this.store.getValue();
    const selectedMember = {
      ...state.selectedMember,
    };
    const familyMembers = [...state.familyMembers];
    const currentMemberIndex = familyMembers.indexOf(
      familyMembers.find((member) => member.is_selected)
    );
    selectedMember[type] = true;
    familyMembers[currentMemberIndex][type] = true;
    this.updateState({ ...state, familyMembers, selectedMember });
  }

  updateAdultHasSpot(adultHasSpot: boolean): void {
    const state = this.store.getValue();
    this.updateState({ ...state, adultHasSpot });
  }

  updatejuniorHasSpot(juniorHasSpot: boolean): void {
    const state = this.store.getValue();
    this.updateState({ ...state, juniorHasSpot });
  }

  // updateSpotQuantity(spotQuantity: number): void {
  //   const state = this.store.getValue();
  //   this.updateState({ ...state, spotQuantity });
  // }

  updateState(state: FamilyMembershipState): void {
    this.store.next((_state = state));
    localStorage.setItem('family-membership-state', JSON.stringify(this.store.getValue()));
  }

  private restoreState(): FamilyMembershipState {
    return JSON.parse(localStorage.getItem('family-membership-state'));
  }
}
