import { Pipe, PipeTransform } from '@angular/core';
import { GenderCode, RaceGenderCode } from 'src/app/core';

@Pipe({
  name: 'gender',
})
export class GenderPipe implements PipeTransform {
  transform(value: GenderCode | RaceGenderCode): any {
    if (!value) {
      return 'Not selected';
    }
    // NOTE: these codes are technically equal, but maybe we should create a separate pipe?
    if (value === RaceGenderCode.Male) {
      return 'Male';
    }
    if (value === RaceGenderCode.Female) {
      return 'Female';
    }
    if (value === GenderCode.Male) {
      return 'Male';
    }
    if (value === GenderCode.Female) {
      return 'Female';
    }
    if (value === GenderCode.NonBinary) {
      return 'Non-Binary';
    }
    if (value === GenderCode.Undisclosed) {
      return 'Choose Not to Disclose';
    }
  }
}
