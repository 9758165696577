<app-header [cartHidden]="false"></app-header>

<app-loader *ngIf="isLoading" [overlay]="true"></app-loader>

<div class="container">
  <div class="row">
    <div class="col-12 col-md-10 offset-md-1 col-lg-9 offset-lg-0">
      <!-- Member Status Indicator -->
      <app-status-indicator-header *ngIf="userLoaded"
        [user]="user"
        [firstName]="user.profile_first_name"
        [lastName]="user.profile_last_name"
        [email]="user.profile_email"
        [allMemberships]="allMemberships"
        [loading]="membershipsLoading"
      ></app-status-indicator-header>
      <hr>
    </div>
    <div class="col-12 col-md-10 offset-md-1 col-lg-9 offset-lg-0">
      <!-- Step Progress Bar -->
      <app-step-progress-bar
        [currentStep]="currentStep"
        [nextStep]="nextStep"
        [isRace]="true"
        [isFamily]="isFamily"
      ></app-step-progress-bar>
    </div>

    <div class="col-12 offset-md-1 col-md-10 offset-lg-0 col-lg-9 p-3">
      <app-form-title>Member Offers</app-form-title>
      <app-form-subtitle>
        Get these specially discounted items for Members Only with your Membership Purchase
      </app-form-subtitle>

      <div class="row" *ngIf="!isLoading">
        <div class="card race-plus-card academy-product-collection-card col-sm-6 col-lg-4">
          <div class="card-body mx-auto">
            <div>
              <h3>Race+ Add On</h3>
              <h4>+ $240.00/year</h4>

              <ul class="mt-3">
                <li *ngFor="let benefit of racePlusBenefits">
                  {{ benefit }}
                </li>
              </ul>
            </div>

            <!-- Add to Cart Button -->
            <div class="mt-4">
              <button class="btn {{ btnClass }} btn-block" (click)="toggleRacePlus()" >
                {{ btnText }}
              </button>
            </div>
          </div>
        </div>

        <div *ngFor="let product of products" class="col-sm-6 col-lg-4 pb-4">
          <app-product-collection-card
            [product]="product"
            [user]="user"
            [removeBorder]="true"
            [showPrice]="true"
            [addOnItems]="addOnItems"
            [memberOfferStyle]="true"
            (openDescriptionModal)="openDescriptionModal($event)"
            (cartUpdate)="updateAddOnItems($event)"
          ></app-product-collection-card>
        </div>
      </div>

      <div class="row mt-3" *ngIf="!isLoading">
        <div class="col-12">
          <ngb-alert *ngFor="let error of errors$ | async" type="danger" (close)="clearErrors()">
            <p class="text-capitalize m-0">{{ error }}</p>
          </ngb-alert>
        </div>

        <!-- Navigation Buttons -->
        <div class="col-12 col-sm-6 col-md-3 col-lg-2 offset-md-6 offset-lg-8 mb-2">
          <button id="back-btn" class="btn btn-secondary btn-block" type="button"
            (click)="back()">Back</button>
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-2">
          <button id="submit-btn" class="btn btn-primary btn-block" type="button"
            (click)="continue()">
            <span *ngIf="isSubmitting" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              Continue
          </button>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
