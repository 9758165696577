import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-license-detail',
  templateUrl: './license-detail.component.html',
  styleUrls: ['./license-detail.component.scss'],
})
export class LicenseDetailComponent {
  @Input() title: string;
  @Input() value: string;
  @Input() isDate = false;
  @Input() titleClass = '';
  @Input() valueClass = '';
}
