import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-enrollment-in-proccess-modal',
  templateUrl: './enrollment-in-proccess-modal.component.html',
  styleUrls: ['./enrollment-in-proccess-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnrollmentInProccessModalComponent {
  constructor(private activeModal: NgbActiveModal, private router: Router) {}

  close(): void {
    this.activeModal.close();
    this.router.navigate(['/enrollment', 'member-info']);
  }
}
