import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/core';
import { FamilyMembershipFacade, Plans, RegistrationFacade } from 'src/app/core/facades';
import {
  License,
  MemberInfo,
  Membership,
  MembershipOption,
  MembershipStep,
  MembershipType,
  PersonTypes,
  Profile,
  RaceGender,
  Registration,
} from 'src/app/core/models';
import { ProfileService, RegistrationService } from 'src/app/core/services';

@Component({
  selector: 'app-select-options-step',
  templateUrl: './select-options-step.component.html',
  styleUrls: ['./select-options-step.component.scss'],
})
export class SelectOptionsStepComponent implements OnInit, OnDestroy {
  registrationData: any;
  user: Profile;
  profileLicenses: License[] = [];
  membershipOption: MembershipOption;

  optionsForm: FormGroup;
  genderRegularContactForm: FormGroup;
  collegiateForm: FormGroup;
  internationalLicenseForm: FormGroup;
  proLicenseForm: FormGroup;
  preventEmit = false;

  registrationId: string;
  registration: Registration;
  isPremiumRequired = false;
  isAuthenticated = false;
  plan: string;
  person_type: string;

  planTypes = Plans;
  personTypes = PersonTypes;
  personType = PersonTypes.ADULT;
  raceGenders: RaceGender[];
  profileGender: string;

  allMemberships: Membership[];

  isLoading = false;
  userLoaded = false;
  membershipsLoading = true;
  isSubmitting = false;
  isNavigating = false;
  needsAddress = true;
  errors$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  get Step(): typeof MembershipStep {
    return MembershipStep;
  }

  get of(): any {
    return this.optionsForm.controls;
  }

  get grf(): any {
    return this.genderRegularContactForm.controls;
  }

  get cf(): any {
    return this.collegiateForm.controls;
  }

  get ilf(): any {
    return this.internationalLicenseForm.controls;
  }

  get plf(): any {
    return this.proLicenseForm.controls;
  }

  get isJunior(): boolean {
    return this.registrationFacade.registration.person_type === PersonTypes.JUNIOR;
  }

  get isAdult(): boolean {
    return this.registrationFacade.registration.person_type === PersonTypes.ADULT;
  }

  get addInternational(): boolean {
    return this.of.international_license.value;
  }

  get addPro(): boolean {
    return this.of.pro_license.value;
  }

  get proEligible(): boolean {
    return !!this.user?.profile_is_pro;
  }

  get intlDisabled(): boolean {
    return this.isFamily || this.addPro;
  }

  get proDisabled(): boolean {
    return this.isFamily || this.addInternational;
  }

  get isFamily(): boolean {
    return this.registration && this.registration.is_family;
  }

  get licenseSelectionText(): string {
    return this.isFamily
      ? 'added junior membership on previous screen'
      : this.addInternational
      ? 'international license'
      : 'professional license';
  }

  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private registrationFacade: RegistrationFacade,
    private familyFacade: FamilyMembershipFacade,
    private profileService: ProfileService,
    private registrationService: RegistrationService,
    private router: Router
  ) {
    this.createForms();
    this.registrationId = this.registrationFacade.registrationId;
    this.registrationData = this.registrationFacade.registration$;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.authService.isAuthenticated$.subscribe((isAuthenticated) => {
      this.isAuthenticated = isAuthenticated;
    });

    this.authService.currentUser$
      .pipe(
        tap((profile: Profile) => {
          if (!profile) {
            return;
          }
          this.user = profile;
          if (profile.profile_id) {
            this.profileService
              .getLicenses(profile.profile_id, { active: true })
              .subscribe((licenses: License[]) => {
                this.profileLicenses = licenses;
              });
            this.profileService
              .getMemberships(profile.profile_id)
              .subscribe((memberships: Membership[]) => {
                if (memberships) {
                  this.allMemberships = memberships;
                }
                this.membershipsLoading = false;
              });
          } else {
            this.membershipsLoading = false;
          }
          this.userLoaded = true;
        })
      )
      .subscribe();

    this.loadRegistration();
  }

  private loadRegistration(): void {
    const registrationId = this.registrationFacade.registrationId;
    if (registrationId) {
      this.registrationId = registrationId;
      this.registrationService
        .getRegistration(registrationId, false)
        .subscribe((registration: Registration) => {
          if (registration) {
            this.setRegistrationAndFormValues(registration);
          } else {
            this.getRegistrationByProfile();
          }
        });
    } else {
      this.getRegistrationByProfile();
    }
  }

  private getRegistrationByProfile(): void {
    // If registration removed from local storage, retrieve latest enrollment using authed user's profile ID
    if (this.user) {
      this.registrationService
        .getRegistrationByProfile(this.user.profile_id)
        .pipe(
          tap((reg: Registration) => {
            this.setRegistrationAndFormValues(reg);
          }),
          catchError((err) => {
            // Registration not found - log out and return to intro form
            this.authService.doLogout();
            return throwError(err);
          })
        )
        .subscribe();
    }
  }

  private setRegistrationAndFormValues(registration: Registration): void {
    this.registration = registration;
    this.registrationFacade.updateRegistration(registration);
    this.registrationFacade.updatePlan(registration.is_family ? Plans.FAMILY : Plans.INDIVIDUAL);

    if (registration.is_international && !registration.is_international_paid) {
      this.of.international_license.setValue(true);
    }

    if (registration.is_professional && !registration.is_professional_paid) {
      this.of.pro_license.setValue(true);
    }
  }

  back(): void {
    this.onSubmit(false);
  }

  continue(): void {
    this.onSubmit();
  }

  private onSubmit(forward = true): void {
    if (this.optionsForm.invalid) {
      // Stop form submit
      this.optionsForm.markAllAsTouched();

      return;
    }

    const memberInfoCopy: MemberInfo = { ...this.optionsForm.value };

    memberInfoCopy.membership_type = this.registrationFacade.isFamilyPlan()
      ? MembershipType.Family
      : MembershipType.Individual;

    this.isSubmitting = forward;

    this.updateMemberInfo(memberInfoCopy, forward)
      .pipe(
        tap(() => {
          this.registrationFacade.updateMemberInfoFilled(true);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  clearErrors(): void {
    this.errors$.next([]);
  }

  private createForms(): void {
    this.optionsForm = this.fb.group({
      international_license: [false],
      pro_license: [false],
    });

    this.resetForm('genderRegularContactForm');
    this.resetForm('collegiateForm');
    this.resetForm('internationalLicenseForm');
    this.resetForm('proLicenseForm');

    this.of.international_license.valueChanges.subscribe((val: boolean) => {
      if (!val) {
        this.resetForm('internationalLicenseForm');
      }
    });

    this.of.pro_license.valueChanges.subscribe((val: boolean) => {
      if (!val) {
        this.registrationFacade.updateProfessional(false);
        this.resetForm('proLicenseForm');
      }
    });
  }

  private resetForm(formName: string): void {
    this[formName] = this.fb.group({});
  }

  updateFormValues(formName: string, $event: FormGroup): void {
    if (this[formName]) {
      this[formName] = $event;

      switch (formName) {
        case 'collegiateForm':
          this.registrationFacade.updateCollegiate(!!this.cf.collegiate_club_id.value);
          break;
        case 'internationalLicenseForm':
          this.registrationFacade.updateInternational(!!this.ilf.ok_to_proceed.value);
          break;
        case 'proLicenseForm':
          this.registrationFacade.updateProfessional(!!this.plf.pro_team.value);
          break;
        default:
          return;
      }
    }
  }

  // private toggleDisableInput(formValue: boolean, control: FormControl): void {
  //   !!formValue ? control.disable({ emitEvent: false }) : control.enable({ emitEvent: false });
  // }

  get birthdate(): Date {
    if (this.registration) {
      const birthdate = this.registration.birthdate;
      const dateString: string[] = birthdate.split('/'); // Ex: ["03", "20", "1994"];
      return new Date(+dateString[2], +dateString[0] - 1, +dateString[1]); // Month is indexed based, so reduce it by 1
    }
    return null;
  }

  get raceAge(): number {
    return new Date().getFullYear() - this.birthdate.getFullYear();
  }

  get disableBack(): boolean {
    return this.isSubmitting || this.isNavigating;
  }

  get formsInvalid(): boolean {
    return (
      this.invalidForm('optionsForm') ||
      this.invalidForm('genderRegularContactForm') ||
      this.invalidForm('collegiateForm') ||
      this.invalidForm('internationalLicenseForm') ||
      this.invalidForm('proLicenseForm')
    );
  }

  private invalidForm(formName: string): boolean {
    return this[formName] && this[formName].invalid;
  }

  get disableContinue(): boolean {
    return this.formsInvalid || this.isSubmitting || this.isNavigating;
  }

  private updateMemberInfo(optionsForm: MemberInfo, forward = true): Observable<Registration> {
    // Make a copy so parametar is not mutated
    const memberInfoCopy: MemberInfo = { ...optionsForm };

    const currentState = this.registrationFacade.currentState;

    const citizenship = this.ilf.racing_nationality?.value?.value || null;
    memberInfoCopy.gender = this.grf.gender?.value;
    memberInfoCopy.has_regular_contact_with_junior = this.grf.has_regular_contact_with_junior?.value;
    memberInfoCopy.is_collegiate_cyclist = currentState.registration.is_collegiate;
    memberInfoCopy.collegiate_club_id = this.cf.collegiate_club_id?.value || null;
    memberInfoCopy.is_international = currentState.registration.is_international;
    memberInfoCopy.is_professional = currentState.registration.is_professional;
    memberInfoCopy.pro_club_id = this.plf.pro_team?.value?.value || null;
    memberInfoCopy.dual_citizen = this.ilf.dual_citizen?.value || null;
    memberInfoCopy.dual_citizen_country = this.ilf.dual_citizen_country?.value?.value || null;
    memberInfoCopy.permanent_resident = this.ilf.permanent_resident?.value || null;
    memberInfoCopy.citizenship = citizenship;
    memberInfoCopy.racing_nationality = citizenship;
    const discipline = this.ilf.license_category?.value?.value?.discipline;
    if (discipline) {
      memberInfoCopy.disciplines = [discipline];
    }
    // memberInfoCopy.disciplines = discipline ? [discipline] : null;
    memberInfoCopy.intl_license_level = this.ilf.intl_license_level?.value?.value || null;
    memberInfoCopy.intl_bmx_category = this.ilf.intl_bmx_category?.value || null;

    return this.registrationService.updateMemberInfo(this.registrationId, memberInfoCopy).pipe(
      tap((registration: Registration) => {
        if (this.registrationFacade.isFamilyPlan()) {
          const currentFamilyState = this.familyFacade.currentState;

          const familyMembers = [...currentFamilyState.familyMembers];
          const currentMemberIndex = familyMembers.indexOf(
            familyMembers.find((member) => member.is_selected)
          );

          familyMembers[currentMemberIndex].id = registration.id;
          familyMembers[currentMemberIndex].first_name = registration.first_name;
          familyMembers[currentMemberIndex].last_name = registration.last_name;
          familyMembers[currentMemberIndex].email = registration.email;
          familyMembers[currentMemberIndex].isCollegiate = registration.is_collegiate_cyclist;

          const selectedMember = {
            ...currentFamilyState.selectedMember,
          };

          selectedMember.id = registration.id;
          selectedMember.first_name = registration.first_name;
          selectedMember.last_name = registration.last_name;
          selectedMember.email = registration.email;

          this.familyFacade.updateState({
            ...currentFamilyState,
            familyMembers,
            selectedMember,
          });
        }

        this.registrationFacade.updateRegistration(registration);
        this.registrationFacade.updateOrderId(registration.order.order_id);

        if (forward) {
          this.toUpgradeOptions();
        } else {
          this.toMemberInfo();
        }
      }),
      catchError((err: any) => {
        const errors = err.errors || {};
        Object.values(errors).forEach((e: any) => {
          this.errors$.next(this.errors$.getValue().concat([e]));
        });
        this.isSubmitting = false;
        return throwError(err);
      }),
      takeUntil(this.unsubscribe$)
    );
  }

  private toMemberInfo(): void {
    this.isNavigating = true;
    this.preventEmit = true;
    this.router.navigate(['enrollment', 'race', 'member-info']);
  }

  private toUpgradeOptions(): void {
    this.preventEmit = true;
    this.router.navigate(['enrollment', 'race', 'member-offers']);
  }
}
