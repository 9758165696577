import { Component } from '@angular/core';
import { AuthService } from 'src/app/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-individual-membership-header',
  templateUrl: './individual-membership-header.component.html',
  styleUrls: ['./individual-membership-header.component.scss'],
})
export class IndividualMembershipHeaderComponent {
  constructor(private authService: AuthService) {}

  logout(): void {
    this.authService.doLogout().subscribe((resp) => {
      if (resp) {
        window.location.assign(environment.publicUrl);
      }
    });
  }

  login(): void {
    this.authService.doLogin(window.location.href);
  }
}
