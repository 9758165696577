import { PersonTypes } from './registration.model';

export interface FamilyMember {
  id?: string;
  first_name: string;
  last_name: string;
  email?: string;
  is_selected?: boolean;
  is_family_representative?: boolean;
  person_type: PersonTypes;
  memberInfoFilled?: boolean;
  customizeInfoFilled?: boolean;
  waiverSigned?: boolean;
  isCollegiate?: boolean;
}

export enum FamilyMemberInfoType {
  member = 'memberInfoFilled',
  customize = 'customizeInfoFilled',
  waiver = 'waiverSigned',
}
