<footer class="footer-custom d-flex justify-content-start justify-content-md-between flex-column flex-md-row">
  <div class="order-last order-md-first text-center text-md-left pl-0">
    <span class="footer-owner">{{currentYear}} United States Cycling</span>
    <span class="footer-rights">All Rights Reserved</span>
  </div>

  <div
    class="order-first order-md-last text-center text-md-right pr-0 pb-3 pb-sm-0"
  >
    <a class="footer-link" href="https://www.usacycling.org/privacy" target="_blank"
      >Privacy Policy</a
    >
    <a class="footer-link" href="https://www.usacycling.org/about-us/contact-us" target="_blank"
      >Contact Support</a
    >
  </div>
</footer>
