<div class="col-12 col-lg-3 ml-lg-2">
  <div class="order-summary">
    <div class="order-card">
      <h3 class="order-summary__title">Order summary</h3>
      <div class="order-summary__items">
        <div *ngFor="let license of sortedLicenses" class="d-flex justify-content-between">
          <span>{{license.name}}</span>
          <span *ngIf="useVariant(license)">{{useVariant(license).price | currency}}</span>
        </div>

      <hr class="order-summary__divider" />

      <div class="order-summary__total d-flex justify-content-between">
        <strong>Total</strong>
        <strong>{{total | currency}}</strong>
      </div>

      <button
        type="button"
        class="order-summary__button btn btn-primary btn-block"
        (click)="openWaiverModal()"
        [disabled]="isSubmitting || isLoading"
      >
        <span *ngIf="isSubmitting" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        Checkout
      </button>

      <div *ngIf="serverError" class="text-danger mt-3">Something went wrong. If this problem persists, please contact <a href="mailto:membership@usacycling.org">membership@usacycling.org</a>.</div>

      <div class="text-muted mt-3">Taxes and eligible discounts will be applied at checkout.</div>
    </div>
  </div>
</div>
