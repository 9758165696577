import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalSize } from 'src/app/core/models';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styles: [],
})
export class ModalComponent implements OnInit, OnChanges {
  formattedId = '#';

  @Input() id: string;
  @Input() title: string;
  @Input() isOpen: boolean;
  @Input() allowClose = true;
  @Input() size: NgbModalSize;

  modalOptions: NgbModalOptions = {};

  @ViewChild('content', { static: false }) content: ElementRef;

  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();

  constructor(private modalService: NgbModal) {}

  handleClose(event: any): void {
    this.closeModal.emit(event);
  }

  syncModal(): void {
    if (this.isOpen) {
      const options: NgbModalOptions = { beforeDismiss: this.handleClose.bind(this) };
      if (!this.allowClose) {
        options.backdrop = 'static';
        options.keyboard = false;
      }
      if (this.size) {
        options.size = this.size;
      }
      this.modalService.open(this.content, options);
    } else {
      this.modalService.dismissAll();
    }
  }

  ngOnInit(): void {
    if (this.id) {
      this.formattedId = '#' + this.id;
    }

    this.syncModal();
  }

  ngOnChanges(): void {
    this.syncModal();
  }
}
