<app-loader *ngIf="isUpdating" [overlay]="true" [light]="true"></app-loader>

<div class="row">
  <div class="col-12">
    <div class="support-box">
      <div class="review-card">
        <div class="row">
          <div class="col-md-2">
            <img src="assets/logo-foundation.png" width="72" alt="" />
          </div>

          <div class="col-md-6">
            <h4>Support USA Cycling Foundation</h4>
            <p>
              All membership purchases support the USA Cycling Foundation to help make cycling
              safe, inclusive, fair and accessible to everyone.
            </p>
          </div>
          <div class="col-md-4">
            <p>Add Donation (any amt)</p>

            <div class="row d-flex align-items-center">
              <div class="col-md-8">
                <ng-select
                  class="select"
                  [items]="donationTiers"
                  bindValue="value"
                  [clearable]="false"
                  [searchable]="false"
                  [(ngModel)]="donationAmountInputValue"
                  placeholder="$0"
                >
                </ng-select>
              </div>
              <div class="col-md-4">
                <button
                  (click)="updateDonation()"
                  class="btn btn-outline-primary btn-block mt-3 mt-md-0"
                  type="button"
                >
                  {{chosenDonationAmount ? 'Set' : 'Add'}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="enrollmentsEligibleForPlus.length > 0 || enrollmentsEligibleForPodium.length > 0 || displayInternational" class="d-flex justify-content-between align-items-center">
      <h2 class="review-card-header__title review-card-header__title--no-wrap">
        Consider Adding
      </h2>
      <hr class="review-card-divider m-0 ml-3 w-100" />
    </div>

    <!-- Premium Upgrade / Racing Add-On: No longer in use now that Ride has been moved to Shopify store -->
    <!-- <div class="addon-card mt-4" *ngIf="displayPremium">
      <div class="row">
        <div class="col-md-6 d-flex align-items-end">
          <h2 class="review-card-header__title mb-2">Add a Race License</h2>
        </div>

        <div class="col-6 col-md-3 d-flex align-items-end">
          <p class="m-0"><small>Upgrade available for</small></p>
        </div>

        <div class="col-6 col-md-3">
          <p class="text-right m-0">
            <span class="upgrade__price d-block">{{ premiumUpgradePrice | currency }}</span>
            <span class="upgrade__per">/year /member</span>
          </p>
        </div>
      </div>

      <hr class="review-card-divider" />

      <div class="review-card__body">
        <div class="row">
          <div class="col-3 col-md-1 mb-2">
            <img src="assets/premium.svg" alt="upgrade" />
          </div>

          <div class="col-9 col-md-4 mb-2">
            <ul class="premium-list">
              <li class="premium-list__item">
                Race in ANY domestic or international event with a single upgrade fee.
              </li>
              <li class="premium-list__item">
                Premium ExpertVoice Benefits
              </li>
            </ul>
          </div>

          <div class="col-md-6 col-lg-4 offset-md-1 mb-3">
            <form [formGroup]="premiumAddonForm" (submit)="addPremiumUpgrades()" id="premium-addon-form">
              <div *ngFor="let control of premiumAddonFormMembers.controls; let i=index" class="form-check">
                <input
                  [formControl]="control"
                  type="checkbox"
                  class="form-check-input m-0 d-none"
                  id="premium-upgrade-{{ i }}"
                />
                <label class="premium__for form-check-label" for="premium-upgrade-{{ i }}">{{ enrollmentsEligibleForPremium[i].full_name }}</label>
              </div>
            </form>
          </div>

          <div class="col-lg-2">
            <button class="premium__add-btn btn btn-outline-primary btn-block" type="submit" form="premium-addon-form" [disabled]="premiumAddonForm.invalid">
              Add to Cart
            </button>
          </div>
        </div>
      </div>
    </div> -->


    <!-- Race Plus Upgrade (Spot Insurance) -->
    <div class="addon-card spot-card mt-4" *ngIf="enrollmentsEligibleForPlus.length > 0">
      <div class="row">
        <div class="col-md-6 d-flex align-items-end">
          <h2 class="review-card-header__title mb-2">Race+ Add On</h2>
        </div>

        <div class="col-6 col-md-3 d-flex align-items-end">
          <p class="m-0"><small>Upgrade available for</small></p>
        </div>

        <div class="col-6 col-md-3">
          <p class="text-right m-0">
            <span class="upgrade__price d-block">{{ plusUpgradePrice | currency }}</span>
            <span class="upgrade__per"> /member</span>
          </p>
        </div>
      </div>

      <hr class="review-card-divider" />

      <div class="row">
        <div class="col-3 col-md-1 mb-2">
          <img src="assets/premium.svg" alt="Race Plus Upgrade" />
        </div>

        <div class="col-9 col-md-4 mb-2">
          <ul class="premium-list">
            <li class="premium-list__item">
              Includes Race License
            </li>
            <li class="premium-list__item">
              Includes Spot Insurance
            </li>
          </ul>
        </div>

        <div class="col-md-6 col-lg-4 offset-md-1 mb-3">
          <form [formGroup]="plusUpgradeForm" (submit)="addPlus()" id="plus-upgrade-form">
            <div *ngFor="let control of plusUpgradeFormMembers.controls; let i=index" class="form-check">
              <input class="form-check-input m-0 d-none" type="checkbox" [formControl]="control" id="plus-upgrade-{{ i }}" />
              <label class="premium__for form-check-label" for="plus-upgrade-{{ i }}">{{ enrollmentsEligibleForPlus[i].full_name }}</label>
            </div>
          </form>
        </div>

        <div class="col-lg-2">
          <button type="submit" form="plus-upgrade-form" class="premium__add-btn btn btn-outline-primary btn-block" [disabled]="plusUpgradeForm.invalid">
            Add to Cart
          </button>
        </div>
      </div>
    </div>

    <!-- Pro License -->
    <!-- <div class="addon-card mt-4" *ngIf="displayPro">
      <div class="row">
        <div class="col-md-6 d-flex align-items-end">
          <h2 class="review-card-header__title mb-2">Pro License</h2>
        </div>

        <div class="col-6 col-md-3 d-flex align-items-end">
          <p class="m-0"><small>Upgrade available for</small></p>
        </div>

        <div class="col-6 col-md-3">
          <p class="text-right m-0">
            <span class="upgrade__price d-block">{{ proLicensePrice | currency }}</span>
            <span class="upgrade__per">/year /member</span>
          </p>
        </div>
      </div>

      <hr class="review-card-divider" />

      <div class="review-card__body">
        <div class="row">
          <div class="col-3 col-md-1 mb-2">
            <img src="assets/premium.svg" alt="upgrade" />
          </div>

          <div class="col-9 col-md-4 mb-2">
            <ul class="premium-list">
              <li class="premium-list__item">
                Race in ANY category in any discipline with a single upgrade fee.
              </li>
              <li class="premium-list__item">
                Premium ExpertVoice Benefits
              </li>
            </ul>
          </div>

          <div class="col-md-6 col-lg-4 offset-md-1 mb-3">
            <form [formGroup]="proAddonForm" (submit)="addProLicense()" id="pro-addon-form">
              <div class="form-check">
                <input
                  formControlName="pro_license"
                  type="checkbox"
                  class="form-check-input m-0 d-none"
                  name="pro_license"
                  id="pro_license"
                />
                <label class="premium__for form-check-label" for="pro_license">{{ mainEnrollmentName }}</label>
              </div>
            </form>
            <div class="mt-2 ml-2" *ngIf="addPro">
              <app-pro-license
                [isSubmitting]="isSubmitting"
                [postPurchase]="true"
                (formValues)="updateFormValues('proLicenseForm', $event)"
              ></app-pro-license>
            </div>
          </div>

          <div class="col-lg-2">
            <button class="premium__add-btn btn btn-outline-primary btn-block mt-2 ml-2" type="submit" form="pro-addon-form" [disabled]="!addPro || proLicenseForm.invalid">
              Add to Cart
            </button>
          </div>
        </div>
      </div>
    </div> -->

    <div class="addon-card mt-4" *ngIf="displayInternational">
      <div class="row">
        <div class="col-md-6 d-flex align-items-end">
          <h2 class="review-card-header__title mb-2">International License</h2>
        </div>

        <div class="col-6 col-md-3 d-flex align-items-end">
          <p class="m-0"><small>Upgrade available for</small></p>
        </div>

        <div class="col-6 col-md-3">
          <p class="text-right m-0">
            <span class="upgrade__price d-block">{{ internationalLicensePrice | currency }}</span>
            <span class="upgrade__per">/year /member</span>
          </p>
        </div>
      </div>

      <hr class="review-card-divider" />

      <div class="review-card__body">
        <div class="row">
          <div class="col-3 col-md-1 mb-2">
            <img src="assets/premium.svg" alt="upgrade" />
          </div>

          <div class="col-9 col-md-4 mb-2">
            <ul class="premium-list">
              <li class="premium-list__item">
                Race in ANY category in any discipline with a single upgrade fee.
              </li>
              <li class="premium-list__item">
                Premium ExpertVoice Benefits
              </li>
            </ul>
          </div>

          <div class="col-md-6 col-lg-4 offset-md-1 mb-3">
            <form [formGroup]="internationalAddonForm" (submit)="addInternationalLicense()" id="international-addon-form">
              <div class="form-check">
                <input
                  formControlName="international_license"
                  type="checkbox"
                  class="form-check-input m-0 d-none"
                  name="international_license"
                  id="international_license"
                />
                <label class="premium__for form-check-label" for="international_license">{{ mainEnrollmentName }}</label>
              </div>
            </form>
            <div class="mt-2 ml-2" *ngIf="addInternational">
              <app-international-license
                [isSubmitting]="isSubmitting"
                [registration]="registration"
                [postPurchase]="true"
                (formValues)="updateFormValues('internationalLicenseForm', $event)"
              ></app-international-license>
            </div>
          </div>

          <div class="col-lg-2">
            <button class="premium__add-btn btn btn-outline-primary btn-block mt-2 ml-2" type="submit" form="international-addon-form" [disabled]="!addInternational || internationalLicenseForm.invalid">
              Add to Cart
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Podium Package -->
    <div class="addon-card mt-4" *ngIf="enrollmentsEligibleForPodium.length > 0">
      <div class="row">
        <div class="col-md-6 d-flex align-items-end">
          <h2 class="review-card-header__title mb-2">Podium Package</h2>
        </div>

        <div class="col-6 col-md-3 d-flex align-items-end">
          <p class="m-0"><small>Package available for</small></p>
        </div>

        <div class="col-6 col-md-3">
          <p class="text-right m-0">
            <span class="upgrade__price d-block">{{ podiumPackagePrice | currency }}</span>
            <span class="upgrade__per"> /member</span>
          </p>
        </div>
      </div>

      <hr class="review-card-divider" />

      <div class="row">
        <div class="col-3 col-md-1 mb-2">
          <img class="podium-img" [src]="primaryProductImage" [alt]="'Podium Package'" />
        </div>

        <div class="col-9 col-md-4 mb-2">
          <ul class="premium-list">
            <li class="premium-list__item">
              Sleek 16 oz. Water Bottle
            </li>
            <li class="premium-list__item">
              Newly Designed Hat
            </li>
            <li class="premium-list__item">
              Handlebar Bag
            </li>
          </ul>
        </div>

        <div class="col-md-6 col-lg-4 offset-md-1 mb-3">
          <form [formGroup]="podiumAddonForm" (submit)="addPodiumPackages()" id="podium-addon-form">
            <div *ngFor="let control of podiumAddonFormMembers.controls; let i=index" class="form-check">
              <input class="form-check-input m-0 d-none" type="checkbox" [formControl]="control" id="podium-package-{{ i }}" />
              <label class="premium__for form-check-label" for="podium-package-{{ i }}">{{ enrollmentsEligibleForPodium[i].full_name }}</label>
            </div>
          </form>
        </div>

        <div class="col-lg-2">
          <button type="submit" class="premium__add-btn btn btn-outline-primary btn-block" form="podium-addon-form"  [disabled]="podiumAddonForm.invalid">
            Add to Cart
          </button>
        </div>
      </div>
    </div>

    <!-- Selected Add-Ons Management -->
    <div class="review-card" *ngIf="addOnsSelected">
      <div class="mb-4">
        <h2 class="review-card-header__title mb-2">Selected Add-Ons</h2>
        <hr class="review-card-divider" />
        <ng-container *ngFor="let enrollment of order.enrollments; let idx = index">
          <h3 *ngIf="showName(enrollment)" class="plan-type__title">
            {{ enrollment.first_name }} {{ enrollment.last_name }}
          </h3>

          <div *ngIf="idx === 0" #anchor></div>

          <!-- Premium Upgrade / Racing Add-On: No longer in use now that Ride has been moved to Shopify store -->
          <!-- <div *ngIf="enrollment.is_premium && !enrollment.is_premium_paid && enrollment.person_type !== personTypes.JUNIOR" class="col-12 improvement-container p-3 mb-4">
            <div class="d-flex justify-content-between align-items-center">
              <span class="upgrade__header">Race License</span>
              <span class="upgrade__price">{{ premiumUpgradePrice | currency }}</span>
            </div>
            <hr />
            <div class="row">
              <div class="col-3 col-lg-2 d-flex justify-content-center">
                <img class="improvement-image" src="assets/premium.svg" alt="Trophy" />
              </div>
              <div class="col-9 col-d-flex flex-column align-items-start pb-3 pb-lg-0">
                <div class="d-flex pb-1">
                  <img class="pr-1" src="assets/images/check-red.svg" alt="Check" />
                  <span>Race in ANY category in any discipline with a single upgrade fee.</span>
                </div>
                <div class="d-flex pb-1">
                  <img class="pr-1" src="assets/images/check-red.svg" alt="Check" />
                  <span>Premium ExpertVoice Benefits</span>
                </div>
              </div>
              <div class="col-12 mt-3 mt-lg-1 ml-lg-2">
                <button
                  type="button"
                  (click)="removePremium(enrollment)"
                  class="edit-link btn btn-link edit-member-info d-flex align-items-center p-0"
                >
                  <img src="assets/Trash.svg" alt="edit" class="pr-1" />
                  Remove
                </button>
              </div>
            </div>
          </div> -->

          <!-- Race Plus Upgrade (Spot Insurance) -->
          <div *ngIf="enrollment.has_spot_insurance && !enrollment.is_spot_paid" class="col-12 improvement-container p-3 mb-4">
            <div class="d-flex justify-content-between align-items-center">
              <span class="upgrade__header">Race+ Add On</span>
              <span *ngIf="!enrollment.spot_auto_renewal" class="upgrade__price">{{ plusUpgradePrice | currency }}</span>

              <div *ngIf="enrollment.spot_auto_renewal" class="text-right">
                <span class="upgrade__price d-block">{{ plusUpgradePrice | currency }}</span>
                <span *ngIf="enrollment.spot_auto_renewal" class="upgrade__per">*Auto-renew selected</span>
              </div>
            </div>
            <hr />

            <div class="row">
              <div class="col-3 col-lg-2">
                <img src="assets/premium.svg" alt="Race Plus Upgrade" />
              </div>

              <div class="col-9 col-d-flex flex-column align-items-start pb-3 pb-lg-0">
                <ul class="premium-list">
                  <li class="premium-list__item">
                    Includes Race License
                  </li>
                  <li class="premium-list__item">
                    Includes Spot Insurance
                  </li>
                </ul>
              </div>

              <div class="col-12 mt-3 mt-lg-1 ml-lg-2">
                <button
                  type="button"
                  (click)="removePlus(enrollment)"
                  class="edit-link btn btn-link edit-member-info d-flex align-items-center p-0"
                >
                  <img src="assets/Trash.svg" alt="edit" class="pr-1" />
                  Remove
                </button>
              </div>
            </div>
          </div>

          <!-- Pro License -->
          <!-- <div *ngIf="isPro && !isProPaid" class="col-12 improvement-container p-3 mb-4">
            <div class="d-flex justify-content-between align-items-center">
              <span class="upgrade__header">Pro License</span>
              <span class="upgrade__price">{{ proLicensePrice | currency }}</span>
            </div>
            <hr />
            <div class="row">
              <div class="col-3 col-lg-2 d-flex justify-content-center">
                <img class="improvement-image" src="assets/premium.svg" alt="Trophy" />
              </div>
              <div class="col-9 col-d-flex flex-column align-items-start pb-3 pb-lg-0">
                <div class="d-flex pb-1">
                  <img class="pr-1" src="assets/images/check-red.svg" alt="Check" />
                  <span>Race in ANY category in any discipline with a single upgrade fee.</span>
                </div>
                <div class="d-flex pb-1">
                  <img class="pr-1" src="assets/images/check-red.svg" alt="Check" />
                  <span>Premium ExpertVoice Benefits</span>
                </div>
              </div>
              <div class="col-12 mt-3 mt-lg-1 ml-lg-2">
                <button
                  type="button"
                  (click)="removeProLicense()"
                  class="edit-link btn btn-link edit-member-info d-flex align-items-center p-0"
                >
                  <img src="assets/Trash.svg" alt="edit" class="pr-1" />
                  Remove
                </button>
              </div>
            </div>
          </div> -->

          <!-- International License -->
          <div *ngIf="isInternational && !isInternationalPaid" class="col-12 improvement-container p-3 mb-4">
            <div class="d-flex justify-content-between align-items-center">
              <span class="upgrade__header">International License</span>
              <span class="upgrade__price">{{ internationalLicensePrice | currency }}</span>
            </div>
            <hr />
            <div class="row">
              <div class="col-3 col-lg-2 d-flex justify-content-center">
                <img class="improvement-image" src="assets/premium.svg" alt="Trophy" />
              </div>
              <div class="col-9 col-d-flex flex-column align-items-start pb-3 pb-lg-0">
                <div class="d-flex pb-1">
                  <img class="pr-1" src="assets/images/check-red.svg" alt="Check" />
                  <span>Race in ANY category in any discipline with a single upgrade fee.</span>
                </div>
                <div class="d-flex pb-1">
                  <img class="pr-1" src="assets/images/check-red.svg" alt="Check" />
                  <span>Premium ExpertVoice Benefits</span>
                </div>
              </div>
              <div class="col-12 mt-3 mt-lg-1 ml-lg-2">
                <button
                  type="button"
                  (click)="removeInternationalLicense()"
                  class="edit-link btn btn-link edit-member-info d-flex align-items-center p-0"
                >
                  <img src="assets/Trash.svg" alt="edit" class="pr-1" />
                  Remove
                </button>
              </div>
            </div>
          </div>

          <!-- Podium Package -->
          <div *ngIf="enrollment.is_podium && !enrollment.is_podium_paid" class="col-12 improvement-container p-3 mb-4">
            <div class="d-flex justify-content-between align-items-center">
              <span class="upgrade__header">Podium Package</span>
              <span class="upgrade__price">{{ podiumPackagePrice | currency }}</span>
            </div>
            <hr />
            <div class="row">
              <div class="col-3 col-lg-2 d-flex justify-content-center">
                <img class="podium-img" [src]="primaryProductImage" [alt]="'Podium Package'" />
              </div>
              <div class="col-9 col-lg-4 col-d-flex flex-column align-items-start pb-3 pb-lg-0">
                <div class="d-flex pb-1">
                  <img class="pr-1" src="assets/images/check-red.svg" alt="Check" />
                  <span>Sleek 16 oz. Water Bottle</span>
                </div>
                <div class="d-flex pb-1">
                  <img class="pr-1" src="assets/images/check-red.svg" alt="Check" />
                  <span>Newly Designed Hat</span>
                </div>
                <div class="d-flex pb-1">
                  <img class="pr-1" src="assets/images/check-red.svg" alt="Check" />
                  <span>Handlebar Bag</span>
                </div>
              </div>

              <div class="col-12 mt-3 mt-lg-1 ml-lg-2">
                <button
                  type="button"
                  (click)="removePodiumPackage(enrollment)"
                  class="edit-link btn btn-link edit-member-info d-flex align-items-center p-0"
                >
                  <img src="assets/Trash.svg" alt="edit" class="pr-1" />
                  Remove
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
