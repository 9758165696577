import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { GuardianRelationship, RegistrationService } from 'src/app/core';
import { RegistrationFacade } from 'src/app/core/facades/registration.facade';

@Component({
  selector: 'app-junior-no-waiver-modal',
  templateUrl: './junior-no-waiver-modal.component.html',
  styleUrls: ['./junior-no-waiver-modal.component.scss'],
})
export class JuniorNoWaiverModalComponent {
  legalGuardianForm: FormGroup;

  relationships = [
    { id: 1, name: GuardianRelationship.PARENT },
    { id: 2, name: GuardianRelationship.GUARDIAN },
  ];

  isSubmitting = false;

  get firstName(): FormControl {
    return this.legalGuardianForm.get('first_name') as FormControl;
  }

  get lastName(): FormControl {
    return this.legalGuardianForm.get('last_name') as FormControl;
  }

  get email(): FormControl {
    return this.legalGuardianForm.get('email') as FormControl;
  }

  get relationship(): FormControl {
    return this.legalGuardianForm.get('relationship') as FormControl;
  }

  constructor(
    private fb: FormBuilder,
    private activeModal: NgbActiveModal,
    private registrationFacade: RegistrationFacade,
    private registrationService: RegistrationService
  ) {
    this.createLegalGuardianForm();
  }

  submitForm(): void {
    if (this.legalGuardianForm.invalid) {
      this.legalGuardianForm.markAllAsTouched();
      return;
    }

    this.isSubmitting = true;
    this.registrationService
      .updateJuniorGuardianInfo(this.registrationFacade.registrationId, {
        legal_guardian_first_name: this.firstName.value,
        legal_guardian_last_name: this.lastName.value,
        legal_guardian_email: this.email.value,
        legal_guardian_relationship: this.relationship.value,
      })
      .pipe(
        tap(() => {
          this.onClose();
        }),
        catchError((error) => {
          console.log(error);
          this.isSubmitting = false;
          return throwError(error);
        })
      )
      .subscribe();
  }

  private createLegalGuardianForm(): void {
    this.legalGuardianForm = this.fb.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      relationship: [null, Validators.required],
    });
  }

  onClose(): void {
    this.activeModal.close();
  }
}
