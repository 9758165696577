import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FamilyMembershipFacade, RegistrationFacade } from 'src/app/core/facades';

import { JuniorNoWaiverModalComponent } from '../junior-no-waiver-modal/junior-no-waiver-modal.component';
import { JuniorWaiverModalComponent } from '../junior-waiver-modal/junior-waiver-modal.component';

@Component({
  selector: 'app-junior-pre-prompt',
  templateUrl: './junior-pre-prompt.component.html',
  styleUrls: ['./junior-pre-prompt.component.scss'],
})
export class JuniorPrePromptComponent implements OnInit {
  memberInfo: any;

  constructor(
    private modal: NgbModal,
    private activeModal: NgbActiveModal,
    private familyFacade: FamilyMembershipFacade,
    private registrationFacade: RegistrationFacade
  ) {}

  ngOnInit(): void {
    this.memberInfo = this.registrationFacade.isFamilyPlan()
      ? this.familyFacade.selectedMember
      : this.registrationFacade.registration;
  }

  openJuniorWaiverModal(): void {
    const modal = this.modal.open(JuniorWaiverModalComponent, {
      centered: true,
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
    });
    modal.result.then(() => this.activeModal.close('signed')).catch(() => {});
  }

  openJuniorNoWaiverModal(): void {
    const modal = this.modal.open(JuniorNoWaiverModalComponent, {
      centered: true,
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
    });
    modal.result.then(() => this.activeModal.close('dismissed')).catch(() => {});
  }
}
