import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RaceGender } from '../models';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class RaceGendersService {
  constructor(private apiService: ApiService) {}

  getAll(): Observable<RaceGender[]> {
    return this.apiService.get('/race_genders');
  }
}
