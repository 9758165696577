<form [formGroup]="internationalLicenseForm" novalidate>

  <div class="mt-2">
    <!-- Optional specify specific category (related to SKU/etc) -->
    <div class="col-12 py-2">
      <div class="mb-1">
        International license category. <span class="star">*</span>
        <i
          class="icon icon-information ml-1"
          placement="bottom"
          [ngbTooltip]="licenseCategoryTipContent"
          tooltipClass="custom-tooltip"
          [openDelay]="250"
          tabindex="0"
        ></i>
      </div>

      <!-- TODO: Confirm this text -->
      <ng-template #licenseCategoryTipContent>
        Select your primary discipline.
      </ng-template>

      <div class="row">
        <div class="col-12" [ngClass]="{'col-md-9 col-lg-7 col-xl-6': !postPurchase}">
          <ng-select
            formControlName="license_category"
            class="select"
            id="license-category-select"
            [items]="licenseCategoryOptions"
            [clearable]="false"
            [searchable]="true"
            placeholder="Select..."
            autofocus
          >
          </ng-select>
        </div>
      </div>
    </div>

    <!-- US Citizenship -->
    <div class="col-12 py-2">
      <div class="mb-1">
        Are you a US citizen? <span class="star">*</span>
        <i
          class="icon icon-information ml-1"
          placement="bottom"
          [ngbTooltip]="citizenTipContent"
          tooltipClass="custom-tooltip"
          [openDelay]="250"
          tabindex="0"
        ></i>
      </div>

      <!-- TODO: Confirm this text -->
      <ng-template #citizenTipContent>
        IMPORTANT: ALL first-time UCI license purchasers are required to send proof of citizenship
        (passport or birth certficate) to <strong>membership@usacycling.org</strong> after
        purchase in order to activate the license.
      </ng-template>

      <div class="row">
        <div class="col-6" [class.col-md-2]="!postPurchase">
          <div class="custom-control custom-radio">
            <input
              formControlName="us_citizen"
              class="custom-control-input"
              type="radio"
              name="us_citizen"
              id="us-citizen-true"
              [value]="true"
            />
            <label class="custom-control-label" for="us-citizen-true"> Yes </label>
          </div>
        </div>
        <div class="col-6">
          <div class="custom-control custom-radio custom-control-inline">
            <input
              formControlName="us_citizen"
              class="custom-control-input"
              type="radio"
              name="us_citizen"
              id="us-citizen-false"
              [value]="false"
            />
            <label class="custom-control-label" for="us-citizen-false"> No </label>
          </div>
        </div>
      </div>
    </div>

    <!-- Dual Citizenship -->
    <div *ngIf="usCitizen === true" class="col-12 py-2">
      <div class="mb-1">
        Do you have dual citizenship with another country? <span class="star">*</span>
      </div>

      <div class="row">
        <div class="col-6" [class.col-md-2]="!postPurchase">
          <div class="custom-control custom-radio">
            <input
              formControlName="dual_citizen"
              class="custom-control-input"
              type="radio"
              name="dual_citizen"
              id="dual-citizen-true"
              [value]="true"
            />
            <label class="custom-control-label" for="dual-citizen-true"> Yes </label>
          </div>
        </div>
        <div class="col-6">
          <div class="custom-control custom-radio custom-control-inline">
            <input
              formControlName="dual_citizen"
              class="custom-control-input"
              type="radio"
              name="dual_citizen"
              id="dual-citizen-false"
              [value]="false"
            />
            <label class="custom-control-label" for="dual-citizen-false"> No </label>
          </div>
        </div>
      </div>
    </div>

    <!-- US Permanent Residency -->
    <div *ngIf="usCitizen === false" class="col-12 py-2">
      <div class="mb-1">
        Are you a permanent resident? <span class="star">*</span>
      </div>

      <div class="row">
        <div class="col-6" [class.col-md-2]="!postPurchase">
          <div class="custom-control custom-radio">
            <input
              formControlName="permanent_resident"
              class="custom-control-input"
              type="radio"
              name="permanent_resident"
              id="permanent-resident-true"
              [value]="true"
            />
            <label class="custom-control-label" for="permanent-resident-true"> Yes </label>
          </div>
        </div>
        <div class="col-6">
          <div class="custom-control custom-radio custom-control-inline">
            <input
              formControlName="permanent_resident"
              class="custom-control-input"
              type="radio"
              name="permanent_resident"
              id="permanent-resident-false"
              [value]="false"
            />
            <label class="custom-control-label" for="permanent-resident-false"> No </label>
          </div>
        </div>
      </div>
    </div>

    <!-- Dual Citizenship Country -->
    <div *ngIf="dualCitizen === true" class="col-12 py-2">
      <div class="mb-1">
        Please select from dropdown with which country you have dual citizenship.
        <span class="star">*</span>
      </div>

      <div class="row">
        <div class="col-12" [ngClass]="{'col-md-9 col-lg-7 col-xl-6': !postPurchase}">
          <ng-select
            formControlName="dual_citizen_country"
            class="select"
            id="dual-citizen-country"
            [items]="nonUsOptions"
            [clearable]="false"
            [searchable]="true"
            [loading]="optionsLoading"
            placeholder="Select..."
          >
          </ng-select>
        </div>
      </div>
    </div>

    <!-- Racing Nationality -->
    <div *ngIf="displayRacingNationality" class="col-12 py-2">
      <div class="mb-1">
        Please select your Racing Nationality. <span class="star">*</span>
        <i
          class="icon icon-information ml-1"
          placement="bottom"
          [ngbTooltip]="nationalityTipContent"
          tooltipClass="custom-tooltip"
          [openDelay]="250"
          tabindex="0"
        ></i>
      </div>

      <!-- TODO: Confirm this text -->
      <ng-template #nationalityTipContent>
        IMPORTANT: If you have never previously held a UCI license, the following selection is
        important, because you cannot change it without submitting a formal request to the UCI;
        please choose carefully. If you have previously held a UCI license you cannot change your
        racing nationality without submitting a formal request to the UCI. Please contact
        <strong>membership@usacycling.org</strong> with questions - we're happy to help.
      </ng-template>

      <div class="row">
        <div class="col-12" [ngClass]="{'col-md-9 col-lg-7 col-xl-6': !postPurchase}">
          <ng-select
            formControlName="racing_nationality"
            class="select"
            id="racing-nationality"
            [items]="nationalityOptions"
            [clearable]="false"
            [searchable]="true"
            [loading]="optionsLoading"
            placeholder="Select..."
          >
          </ng-select>
        </div>
      </div>
    </div>

    <!-- Elite/Master Level -->
    <div *ngIf="displayLevel" class="col-12 py-2">
      <div class="mb-1">
        Please select your level. <span class="star">*</span>
      </div>
      <div class="row">
        <div class="col-12" [ngClass]="{'col-md-9 col-lg-7 col-xl-6': !postPurchase}">
          <ng-select
            formControlName="intl_license_level"
            class="select"
            id="international-level"
            [items]="visibleLevelOptions"
            [clearable]="false"
            [searchable]="false"
            placeholder="Select..."
          >
          </ng-select>
        </div>
      </div>
    </div>

    <!-- UCI / National Federation License in Current Year -->
    <div *ngIf="displayCurrentYear" class="col-12 py-2">
      <div class="mb-1">
        Have you been issued a license by the UCI or a National Federation other than USA Cycling in the current calendar year?
        <span class="star">*</span>
      </div>

      <div class="row">
        <div class="col-6" [class.col-md-2]="!postPurchase">
          <div class="custom-control custom-radio">
            <input
              formControlName="license_current_year"
              class="custom-control-input"
              type="radio"
              name="license_current_year"
              id="license-current-year-true"
              [value]="true"
            />
            <label class="custom-control-label" for="license-current-year-true"> Yes </label>
          </div>
        </div>
        <div class="col-6">
          <div class="custom-control custom-radio custom-control-inline">
            <input
              formControlName="license_current_year"
              class="custom-control-input"
              type="radio"
              name="license_current_year"
              id="license-current-year-false"
              [value]="false"
            />
            <label class="custom-control-label" for="license-current-year-false"> No </label>
          </div>
        </div>
      </div>
    </div>

    <!-- Resulting Confirmation -->
    <ngb-alert *ngIf="licenseCurrentYear === false" [type]="'success'" [dismissible]="false" class="mt-3 mx-2">
      {{successAlertMsg}}
    </ngb-alert>

    <ngb-alert *ngIf="!!licenseCurrentYear" [type]="'danger'" [dismissible]="false" class="mt-3 mx-2">
      Your license will need some individual attention to resolve. Please contact
      <a href="mailto:membership@usacycling.org"><strong>membership@usacycling.org</strong></a>
      or <a href="tel:719-434-4200"><strong>719.434.4200</strong></a> and we'll get you taken care of.
    </ngb-alert>

  </div>


</form>
