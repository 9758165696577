import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { AddOnsFacade, AddOnsState } from './add-ons.facade';
import { FamilyMembershipFacade, FamilyMembershipState } from './family-membership.facade';
import { RegistrationFacade, RegistrationState } from './registration.facade';

export interface AppState {
  family: FamilyMembershipState;
  registration: RegistrationState;
  addOns: AddOnsState;
}

@Injectable({
  providedIn: 'root',
})
export class AppFacade {
  constructor(
    private familyMembership: FamilyMembershipFacade,
    private registrationFacade: RegistrationFacade,
    private addOnsFacade: AddOnsFacade
  ) {}

  vm$: Observable<AppState> = combineLatest([
    this.familyMembership.vm$,
    this.registrationFacade.vm$,
    this.addOnsFacade.vm$,
  ]).pipe(
    map(
      ([family, registration, addOns]: [FamilyMembershipState, RegistrationState, AddOnsState]) => {
        return { family, registration, addOns };
      }
    )
  );
}
