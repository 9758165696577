import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { FamilyMembershipFacade, RegistrationFacade } from 'src/app/core/facades';
import {
  Address,
  Interest,
  License,
  LicenseFlag,
  LicenseGroup,
  LicenseTypeId,
  Location,
  Membership,
  MembershipOrder,
  MembershipStep,
  Profile,
  Toast,
} from 'src/app/core/models';
import { PersonTypes, Registration } from 'src/app/core/models/registration.model';
import {
  AuthService,
  LicenseService,
  LocationsService,
  ProfileService,
  RegistrationService,
} from 'src/app/core/services';
import { environment } from 'src/environments/environment';

import { JuniorPrePromptComponent } from '../junior-pre-prompt/junior-pre-prompt.component';
import { PrePromptComponent } from '../pre-prompt/pre-prompt.component';

@Component({
  selector: 'app-post-confirmation',
  templateUrl: './post-confirmation.component.html',
  styleUrls: ['./post-confirmation.component.scss'],
})
export class PostConfirmationComponent implements OnInit {
  user: Profile;
  profileAddress: Address;
  profileLicenses: License[] = [];
  allMemberships: Membership[] = [];
  membershipsLoading = true;

  licenseGroups: LicenseGroup[] = [];
  activeLicenseGroups: LicenseGroup[] = [];
  loading = true;

  states$: Observable<Location[]> = this.loadStates();
  statesLoading = true;

  primaryInterestId: number;
  selectedInterests: Interest[] = [];
  interestsForm: FormGroup;
  genderRegularContactForm: FormGroup;
  collegiateForm: FormGroup;
  raceCategoriesForm: FormGroup;
  isSubmitting = false;

  membershipLicenseType = [
    LicenseTypeId.DOMESTIC,
    LicenseTypeId.INTERNATIONAL,
    LicenseTypeId.COLLEGIATE,
    LicenseTypeId.PROFESSIONAL,
    LicenseTypeId.RIDE_MEMBERSHIP,
  ];

  allInterests: Interest[];
  registration: Registration;

  donationAmount = 0;
  cartOrder: MembershipOrder;

  toasts: Toast[] = [];
  toastQueue: any = {};

  lineItems: any;
  newLicenseGroup: LicenseGroup;

  get Step(): typeof MembershipStep {
    return MembershipStep;
  }

  get inf(): any {
    return this.interestsForm.controls;
  }

  get grf(): any {
    return this.genderRegularContactForm.controls;
  }

  get cf(): any {
    return this.collegiateForm.controls;
  }

  get rcf(): any {
    return this.raceCategoriesForm.controls;
  }

  get isDomesticCyclist(): FormControl {
    return this.inf.is_domestic_cyclist as FormControl;
  }

  get domesticClub(): FormControl {
    return this.inf.domestic_club as FormControl;
  }

  get interests(): FormControl {
    return this.inf.disciplines as FormControl;
  }

  get raceParticipation(): FormControl {
    return this.inf.race_participation as FormControl;
  }

  get raceSelected(): boolean {
    return !!this.raceParticipation.value;
  }

  get is_collegiate_cyclist(): FormControl {
    return this.cf.is_collegiate_cyclist as FormControl;
  }

  get collegiateClub(): FormControl {
    return this.cf.collegiate_club as FormControl;
  }

  get titleRideOrRace(): string {
    return this.newLicenseGroup.type === LicenseTypeId.RIDE_MEMBERSHIP ? 'Ride' : 'Race!';
  }

  get titleDescription(): string {
    return 'Your membership is renewed and will be charged on your annual renew date. Confirmation was sent to your email.';
  }

  get currentLicense(): LicenseGroup {
    return this.activeLicenseGroups.length && this.activeLicenseGroups[0];
  }

  get accountUrl(): string {
    return `${environment.adminUrl}/profile/${this.user.profile_id}`;
  }

  get licenseDateRange(): string {
    if (this.currentLicense) {
      const newStartDate = moment(this.currentLicense.licenses[0].expiration_date).format(
        'MM/DD/YY'
      );
      const newEndDate = moment(this.currentLicense.licenses[0].expiration_date)
        .add(1, 'year')
        .format('MM/DD/YY');

      return `${newStartDate} - ${newEndDate}`;
    }

    return 'PENDING';
  }

  constructor(
    private route: ActivatedRoute,
    private familyFacade: FamilyMembershipFacade,
    private registrationFacade: RegistrationFacade,
    private licenseService: LicenseService,
    private modal: NgbModal,
    private fb: FormBuilder,
    private authService: AuthService,
    private locationService: LocationsService,
    private profileService: ProfileService,
    private registrationService: RegistrationService
  ) {
    this.createForms();
  }

  ngOnInit(): void {
    this.lineItems = JSON.parse(atob(this.route.snapshot.params.lineItems));
    this.loadProfileWithAddressAndLicenses();
    if (this.registrationFacade.isFamilyPlan()) {
      this.familyFacade.setSelectedMember(this.familyFacade.representative);
    }
    this.loadRegistration();
  }

  private createForms(): void {
    // Set initial interests form values (to be overwritten by child component)
    this.interestsForm = this.fb.group({
      race_participation: [false],
    });
    this.genderRegularContactForm = this.fb.group({});
    this.collegiateForm = this.fb.group({});
    this.raceCategoriesForm = this.fb.group({
      domesticCategories: new FormArray([]),
      collegiateCategories: new FormArray([]),
    });
  }

  private makeFutureLicense(): void {
    // if user has same type of membership as line item, reuse matching license type
    // if not make a new default license
    let newLicenseType: number;
    if (this.lineItems.planId.includes('COL')) {
      newLicenseType = LicenseTypeId.COLLEGIATE;
    } else if (this.lineItems.planId.includes('INT')) {
      newLicenseType = LicenseTypeId.INTERNATIONAL;
    } else if (this.lineItems.planId.includes('RACE')) {
      newLicenseType = LicenseTypeId.DOMESTIC;
    } else if (this.lineItems.planId.includes('RIDE')) {
      newLicenseType = LicenseTypeId.RIDE_MEMBERSHIP;
    }

    const newLicense = this.activeLicenseGroups.find((license) => license.type === newLicenseType);

    if (newLicense) {
      newLicense.expiration = moment(newLicense.expiration).add(1, 'year').format('MM/DD/YY');
      this.newLicenseGroup = newLicense;
    } else {
      if (this.allMemberships.length) {
        if (newLicenseType === LicenseTypeId.COLLEGIATE) {
          const newCollegiateLicenseGroup: any = {
            expiration: moment(this.allMemberships[0].membership_expiration_date)
              .add(1, 'year')
              .format('MM/DD/YY'),
            licenses: this.licenseService.defaultCollegiateLicenses,
            status: null,
            type: newLicenseType,
            type_name: 'Collegiate Race',
          };
          this.newLicenseGroup = newCollegiateLicenseGroup;
        } else if (newLicenseType === LicenseTypeId.INTERNATIONAL) {
          const newInternationalLicenseGroup: any = {
            expiration: moment(this.allMemberships[0].membership_expiration_date)
              .add(1, 'year')
              .format('MM/DD/YY'),
            licenses: this.licenseService.defaultRaceLicenses,
            status: null,
            type: newLicenseType,
            type_name: 'International Race',
          };
          this.newLicenseGroup = newInternationalLicenseGroup;
        } else if (newLicenseType === LicenseTypeId.DOMESTIC) {
          const newDomesitcLicenseGroup: any = {
            expiration: moment(this.allMemberships[0].membership_expiration_date)
              .add(1, 'year')
              .format('MM/DD/YY'),
            licenses: this.licenseService.defaultRaceLicenses,
            status: null,
            type: newLicenseType,
            type_name: 'Domestic Race',
          };
          this.newLicenseGroup = newDomesitcLicenseGroup;
        }
      }
    }
  }

  private loadProfileWithAddressAndLicenses(): void {
    this.authService.currentUser$.subscribe((user: Profile) => {
      this.user = user;
      if (user.profile_id) {
        this.profileService.getPrimaryAddress(user.profile_id).subscribe((address: Address) => {
          this.registrationFacade.setAddressFromProfile(address);
        });
        this.loadLicenses();
        this.loadMemberships();
      }
      if (user.profile_birthdate) {
        const personType =
          this.age(user.profile_birthdate) < 19 ? PersonTypes.JUNIOR : PersonTypes.ADULT;
        this.registrationFacade.updatePersonType(personType);
      }
    });
  }

  private loadLicenses(): void {
    this.profileService.getLicenses(this.user.profile_id).subscribe((licenses: License[]) => {
      this.profileLicenses = licenses;
      this.licenseGroups = this.profileService.licenseGroups;
      const now = new Date();
      const today = Date.parse(`${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}`);
      this.activeLicenseGroups = this.licenseGroups.filter(
        (group) =>
          Date.parse(group.expiration) >= today && this.membershipLicenseType.includes(group.type)
      );
      this.makeFutureLicense();
      this.loading = false;
    });
  }

  private loadMemberships(): void {
    this.profileService
      .getMemberships(this.user.profile_id)
      .subscribe((memberships: Membership[]) => {
        if (memberships) {
          this.allMemberships = memberships;
        }
        this.membershipsLoading = false;
      });
  }

  private loadRegistration(): void {
    // Load family representative as default registration, if family plan
    const registrationId = this.registrationFacade.isFamilyPlan()
      ? this.familyFacade.representative.id
      : this.registrationFacade.registrationId;
    if (registrationId) {
      this.registrationService
        .getRegistration(registrationId)
        .subscribe((registration: Registration) => {
          if (registration) {
            this.registration = registration;
          } else {
            this.getRegistrationByProfile();
          }
        });
    } else {
      this.getRegistrationByProfile();
    }
  }

  private getRegistrationByProfile(): void {
    // If registration removed from local storage, retrieve latest enrollment using authed user's profile ID
    this.authService.currentUser$.subscribe((user: Profile) => {
      if (user.profile_id) {
        this.registrationService
          .getRegistrationByProfile(user.profile_id)
          .pipe(
            tap((reg: Registration) => {
              if (reg) {
                this.registration = reg;
              }
            }),
            catchError((err) => {
              // Registration not found - log out and return to intro form
              this.authService.doLogout();
              return throwError(err);
            })
          )
          .subscribe();
      }
    });
  }

  private loadStates(): Observable<Location[]> {
    this.statesLoading = true;
    return this.locationService.getAll().pipe(
      catchError((err: unknown) => {
        return throwError(err);
      }),
      finalize(() => {
        this.statesLoading = false;
      })
    );
  }

  queueToast(toast: Toast): void {
    if (this.toastQueue[toast.key]) {
      clearTimeout(this.toastQueue[toast.key]);
    }

    this.toastQueue[toast.key] = setTimeout(() => {
      this.toasts.push(toast);
    }, 1000);
  }

  updateFormValues(formName: string, $event: FormGroup): void {
    if (this[formName]) {
      this[formName] = $event;
    }

    this.partialFormApiUpdate(formName, $event)
      .pipe(
        tap((registration: Registration) => {
          // TODO: Other tap/middleware to update process here?
          // this.registrationFacade.updateYourInterestsFilled(true);
          this.registration = registration;

          this.queueToast(new Toast('success', 'Saved', 'Your changes have been saved.'));
        }),
        catchError((error) => {
          this.isSubmitting = false;
          return throwError(error);
        })
      )
      .subscribe();
  }

  updateRegistration(event: boolean): void {
    if (event) {
      this.registrationService
        .getRegistration(this.registration.id)
        .subscribe((registration: Registration) => {
          this.registration = registration;
        });
    }
  }

  updateDonationAmount(amount: number): void {
    this.donationAmount = amount;
  }

  updateCartOrder(order: MembershipOrder): void {
    this.cartOrder = Object.assign({}, order);
  }

  partialFormApiUpdate(formName: string, $event: FormGroup): Observable<any> {
    let apiUpdateMethod: (
      id: string,
      data: any
    ) => Observable<any> = this.registrationService.updateMemberInfo.bind(this.registrationService);

    if (formName === 'interestsForm') {
      apiUpdateMethod = this.registrationService.updateRegistrationInterests.bind(
        this.registrationService
      );
    } else if (formName === 'genderRegularContactForm') {
      apiUpdateMethod = this.registrationService.updateMemberInfo.bind(this.registrationService);
    } else if (formName === 'raceCategoriesForm') {
      apiUpdateMethod = this.registrationService.updateRacingCategories.bind(
        this.registrationService
      );
    }

    return apiUpdateMethod(this.registration.id, $event.value);
  }

  private age(birthdate: string): number {
    const date = new Date(birthdate);
    const today = new Date();
    let age = today.getFullYear() - date.getFullYear();
    const m = today.getMonth() - date.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
      age--;
    }
    return age;
  }

  private checkLicense(flag: LicenseFlag): boolean {
    return this.profileLicenses.some((license) => !!license[flag]);
  }

  get formInvalid(): boolean {
    return (
      this.interestsForm.invalid ||
      this.genderRegularContactForm.invalid ||
      (this.raceSelected && (this.collegiateForm.invalid || this.raceCategoriesForm.invalid))
    );
  }

  get mainEnrollment(): Registration {
    return this.cartOrder ? this.cartOrder.enrollments[0] : null;
  }

  get isAdult(): boolean {
    return this.mainEnrollment && this.mainEnrollment.person_type === PersonTypes.ADULT;
  }

  get isRace(): boolean {
    return this.mainEnrollment && this.mainEnrollment.is_premium;
  }

  get isRacePaid(): boolean {
    return (
      this.registration &&
      (this.registration.is_premium_paid ||
        (this.registration.status === 'paid' &&
          (this.registration.is_collegiate_cyclist ||
            this.registration.person_type === PersonTypes.JUNIOR)))
    );
  }

  get hasSpotInsurance(): boolean {
    if (this.allMemberships.length === 0) {
      return false;
    }
    return this.allMemberships[0].has_spot_insurance;
  }

  get isInternational(): boolean {
    return !!this.registration.is_international;
  }

  get hasPodiumPackages(): boolean {
    return this.cartOrder && this.cartOrder.enrollments.some((enrollment) => enrollment.is_podium);
  }

  get addOnsSelected(): boolean {
    const order = this.cartOrder;
    return (
      order &&
      (order.enrollments.some(
        (enrollment) =>
          (enrollment.is_podium && !enrollment.is_podium_paid) ||
          (enrollment.has_spot_insurance && !enrollment.is_spot_paid)
      ) ||
        this.donationAmount > 0 ||
        // (this.mainEnrollment.is_professional &&
        //   !this.mainEnrollment.is_professional_paid &&
        //   !this.checkLicense(LicenseFlag.PROFESSIONAL)) ||
        (this.mainEnrollment.is_international &&
          !this.mainEnrollment.is_international_paid &&
          !this.checkLicense(LicenseFlag.INTERNATIONAL)))
    );
  }

  get showCart(): boolean {
    return this.cartOrder && this.addOnsSelected;
  }

  get showJuniorWaiver(): boolean {
    // Members under age 18, or 19 in Alabama, will need a parent/guardian signature
    const { current_age, state } = this.registration;
    return current_age < 18 || (current_age === 18 && state === 'AL');
  }

  get waiverSigned(): boolean {
    return !!this.registration.sig_id;
  }

  get waiverBtnText(): string {
    return this.waiverSigned ? 'Waiver Signed' : 'Sign Waiver';
  }

  get waiverBtnClass(): string {
    return this.waiverSigned ? 'success' : 'primary';
  }

  signWaiver(): void {
    // If the waiver has already been signed
    if (this.waiverSigned) {
      return;
    }

    const modal = this.showJuniorWaiver
      ? this.modal.open(JuniorPrePromptComponent, {
          size: 'lg',
          centered: true,
          backdrop: 'static',
          keyboard: false,
        })
      : this.modal.open(PrePromptComponent, {
          size: 'lg',
          centered: true,
          backdrop: 'static',
          keyboard: false,
        });

    // Set parameters
    modal.componentInstance.registrationId = this.registration.id;

    // Set callbacks
    modal.result
      .then(() => {
        // Update registration to reflect signed waiver
        this.registrationService
          .getRegistration(this.registration.id)
          .pipe(
            tap((registration) => {
              this.registration = registration;
            })
          )
          .subscribe();
        // Refresh user licenses & profile to remove "pending" status
        this.loadLicenses();
        this.authService.populate();
      })
      .catch(() => {});
  }
}
