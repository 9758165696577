import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core';

import { RegisterModalComponent } from '../../../shared/register-modal/register-modal.component';

@Component({
  selector: 'app-technical-license-landing',
  templateUrl: './technical-license-landing.component.html',
  styleUrls: ['./technical-license-landing.component.scss'],
})
export class TechnicalLicenseLandingComponent {
  constructor(private modal: NgbModal, private authService: AuthService) {}

  openRegisterModal(): void {
    this.modal.open(RegisterModalComponent, {
      size: 'xl',
      centered: true,
    });
  }

  login(): void {
    this.authService.doLogin(window.location.href);
  }
}
