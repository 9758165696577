<ul class="stepper d-flex justify-content-between align-items-center">
  <li class="stepper__list-item">
    <span
      class="stepper__link {{customLinkClass}} disabled"
      [ngClass]="{
        'stepper__link--active': currentStep === 'license-menu',
        checked: currentStep === 'post-purchase'
      }"
    >
      <div class="stepper__circle"></div>
      Select License</span
    >
  </li>
  <li class="stepper__list-item">
    <span
      class="stepper__link {{customLinkClass}} disabled"
      [ngClass]="{
        'stepper__link--active': currentStep === 'checkout',
        checked: currentStep === 'post-purchase'
      }"
    >
      <div class="stepper__circle"></div>
      Checkout</span
    >
  </li>
  <li class="stepper__list-item">
    <span
      class="stepper__link {{customLinkClass}} content-none disabled"
      [ngClass]="{
        'stepper__link--active': currentStep === 'post-purchase',
        checked: currentStep === 'post-purchase'
      }"
    >
      <div class="stepper__circle"></div>
      Complete</span
    >
  </li>
</ul>
