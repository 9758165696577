import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Image } from 'src/app/core/services/shopify.types';
export interface Credentials {
  email: string;
  password: string;
}
@Component({
  selector: 'app-size-chart-modal',
  templateUrl: './size-chart-modal.component.html',
  styleUrls: ['./size-chart-modal.component.scss'],
})
export class SizeChartModalComponent {
  @Input() images: Image[];

  constructor(private activeModal: NgbActiveModal) {}

  closeModal(): void {
    this.activeModal.close();
  }
}
