import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Gender, PersonTypes, Registration } from 'src/app/core/models';
import { GendersService } from 'src/app/core/services';

@Component({
  selector: 'app-gender-and-regular-contact',
  templateUrl: './gender-and-regular-contact.component.html',
  styleUrls: ['./gender-and-regular-contact.component.scss'],
})
export class GenderAndRegularContactComponent implements OnInit, OnChanges {
  @Input() registration: Registration;
  @Input() isAdult: boolean;
  @Input() isSubmitting = false;
  @Input() preventEmit = false;

  @Output() formValues: EventEmitter<FormGroup> = new EventEmitter();

  genders$: Observable<Gender[]> = this.loadGenders();
  gendersLoading = true;

  genderRegularContactForm: FormGroup;
  formChanges: Subscription;

  get f(): any {
    return this.genderRegularContactForm.controls;
  }

  get showRegularContact(): boolean {
    return (
      (this.registration && this.registration.person_type === PersonTypes.ADULT) || this.isAdult
    );
  }

  constructor(private fb: FormBuilder, private gendersService: GendersService) {
    this.createForm();
  }

  ngOnInit(): void {
    this.loadGenders();
    this.setRegistrationValues();
    this.emitFormValues();
    this.subscribeToForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.registration && !changes.registration.firstChange) {
      // Handle gender updates from member info modal w/o triggering API call
      const currentGender = changes.registration.currentValue.gender;
      if (this.f.gender.value !== currentGender) {
        this.unsubscribeFromForm();
        this.setRegistrationValues();
        this.subscribeToForm();
      }
    }
  }

  private createForm(): void {
    this.genderRegularContactForm = this.fb.group({
      gender: [null, Validators.required],
      has_regular_contact_with_junior: [false],
    });
  }

  subscribeToForm(): void {
    this.formChanges = this.genderRegularContactForm.valueChanges.subscribe(() => {
      this.emitFormValues();
    });
  }

  unsubscribeFromForm(): void {
    this.formChanges.unsubscribe();
  }

  submitForm(): void {
    // May not be required when using this as a child component
  }

  private loadGenders(): Observable<Gender[]> {
    this.gendersLoading = true;
    return this.gendersService.getAll().pipe(
      catchError((err: unknown) => {
        return throwError(err);
      }),

      finalize(() => {
        this.gendersLoading = false;
      })
    );
  }

  private setRegistrationValues(): void {
    if (this.registration) {
      this.genderRegularContactForm.patchValue({
        gender: this.registration.gender,
        has_regular_contact_with_junior: this.registration.has_regular_contact_with_junior,
      });
    }
  }

  private emitFormValues(): void {
    if (!this.preventEmit) {
      this.formValues.emit(this.genderRegularContactForm);
    }
  }
}
