<div class="container-fluid">
  <span class="d-flex justify-content-end pt-3 pr-1">
    <img *ngIf="!addedJunior" class="close-button" src="assets/images/x-circle.svg" alt="X" (click)="activeModal.dismiss()" />
  </span>
  <div class="row">
    <div class="col-12" >
      <app-loader *ngIf="subsLoading"></app-loader>
      <div *ngIf="!subsLoading" class="renew-container text-center">
        <h2 class="header">Renew Your Membership</h2>

        <p *ngIf="!isEarlyRenewable" class="py-2 m-0 mb-md-3 {{ membershipTextClass }}">
          <strong class="text-primary">{{ renewInfoMessage }}</strong>
          <br>
          To make additional changes or address concerns, please contact
          <a href="mailto:membership@usacycling.org">membership@usacycling.org</a>
        </p>
        
        <p *ngIf="isEarlyRenewable && !notRenewable && !blockEarlyRenewal" class="py-2 m-0 mb-md-3 {{ membershipTextClass }}">
          <strong class="text-primary">Your USA Cycling Membership is current.</strong>
          <br>
          To renew your membership early, please click the Renew Early button below. Membership upgrades
          <br>
          are available via the Upgrade Membership button below
        </p>
        
        <p *ngIf="isEarlyRenewable && !notRenewable && blockEarlyRenewal" class="py-2 m-0 mb-md-3 {{ membershipTextClass }}">
          <strong class="text-primary">Your USA Cycling Membership is current.</strong>
          <br>
          Early renewal for plus-level products is not available at this time due to changes in insurance premiums for 2024.
          Please renew your membership in January once new insurance premiums are in place and 2024 products are available for
          purchase. If you wish to switch to a standard level of membership, rather than a plus product, you may early renew at
          this time.
        </p>

        <div class="col-6 offset-3 text-left pb-2 mb-4">
          <h3 class="d-flex justify-content-center align-items-center subheader">
            Current Status
          </h3>
          <app-loader *ngIf="subsLoading" [align]="'left'" [small]="true"></app-loader>
          <app-member-status *ngIf="!subsLoading"
            [profile]="profile"
            [userSubscriptions]="usersSubscription"
            [currentMembership]="currentMembership"
            [showManageLink]="notRenewable"
            [addedJunior]="addedJunior"
          ></app-member-status>
        </div>

        <p *ngIf="displayError  " class="small text-danger mt-3 mb-1">
          Failed to create new enrollment. Please try again or contact
          <a class="text-danger font-weight-bold" href="mailto:membership@usacycling.org">membership@usacycling.org</a>.
        </p>

        <!-- TODO: @Brennan - Handle added junior opt-out -->
        <button *ngIf="addedJunior" type="button" class="col-6 col-md-4 btn btn-secondary mt-3 mr-2" (click)="activeModal.dismiss()">
          Remove Junior
        </button>
        <button *ngIf="!addedJunior && !isEarlyRenewable" type="button" class="col-6 col-md-4 btn btn-secondary mt-3 mr-2" (click)="logout()">
          Log Out
        </button>
        <button *ngIf="(!addedJunior && notRenewable) || blockEarlyRenewal" type="link" class="col-6 col-md-5 btn btn-primary mt-3 mr-2" (click)="redirectToAdminPage()">
          Manage Membership
        </button>
        <button *ngIf="!addedJunior && notRenewable && !hasPremiumMembership" type="link" class="col-6 col-md-5 btn btn-primary mt-3 mr-2" (click)="redirectToUpgradePage()">
          Upgrade Membership
        </button>
        <button *ngIf="!notRenewable && !isEarlyRenewable && !hasMultipleBillingCycles" type="button" class="col-6 col-md-4 btn btn-primary mt-3" [disabled]="isSubmitting" (click)="renew($event)">
          <span *ngIf="isSubmitting" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
          {{ isSubmitting ? 'Loading…' : 'Renew' }}
        </button>
        <button *ngIf="!shouldUseInternationalPath2024 && isEarlyRenewable && !notRenewable && !hasMultipleBillingCycles" type="button" class="col-6 col-md-4 btn btn-secondary mt-3 mr-2" (click)="redirectToRenewalUpgradePage()">
          {{ blockEarlyRenewal ? 'RENEW MEMBERSHIP' : 'UPGRADE MEMBERSHIP' }}
        </button>
        <button *ngIf="isEarlyRenewable && !notRenewable && !hasMultipleBillingCycles && !blockEarlyRenewal" type="button" class="col-6 col-md-4 btn btn-primary mt-3" [disabled]="isSubmitting" (click)="checkout()">
          <span *ngIf="isSubmitting" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
          {{ isSubmitting ? 'Loading…' : 'RENEW EARLY' }}
        </button>
      </div>
    </div>
  </div>
</div>
