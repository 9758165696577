import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/core';
import {
  AddOnsFacade,
  FamilyMembershipFacade,
  Plans,
  RegistrationFacade,
} from 'src/app/core/facades';
import {
  AddOnItem,
  Address,
  FamilyMember,
  Interest,
  License,
  Location,
  Membership,
  MembershipOption,
  MembershipStep,
  MerchantAccountTag,
  PersonTypes,
  Product,
  ProductVariant,
  Profile,
  RaceGender,
  Registration,
} from 'src/app/core/models';
import {
  LocationsService,
  MembershipOrdersService,
  ProfileService,
  RegistrationService,
  ShopifyService,
  TransactionService,
} from 'src/app/core/services';
import { environment } from 'src/environments/environment';

import { AgreementModalComponent } from '../agreement-modal/agreement-modal.component';
import { JuniorWaiverModalComponent } from '../junior-waiver-modal/junior-waiver-modal.component';

@Component({
  selector: 'app-race-details-step',
  templateUrl: './race-details-step.component.html',
  styleUrls: ['./race-details-step.component.scss'],
})
export class RaceDetailsStepComponent implements OnInit, OnDestroy {
  registrationData: any;
  user: Profile;
  profileAddress: Address;
  profileLicenses: License[] = [];
  membershipOption: MembershipOption;
  addedJunior: boolean;

  primaryInterestId: number;
  selectedInterests: Interest[] = [];
  genderRegularContactForm: FormGroup;
  interestsForm: FormGroup;
  raceCategoriesForm: FormGroup;
  autoRenewalForm: FormGroup;

  registrationId: string;
  registration: Registration;
  isAuthenticated = false;
  plan: string;
  person_type: string;

  planTypes = Plans;
  personTypes = PersonTypes;
  personType = PersonTypes.ADULT;
  raceGenders: RaceGender[];
  profileGender: string;

  allMemberships: Membership[];

  states$: Observable<Location[]> = this.loadStates();
  statesLoading = true;

  isLoading = false;
  userLoaded = false;
  membershipsLoading = true;
  isSubmitting = false;
  isNavigating = false;
  needsAddress = true;
  errors$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  get adminUrl(): string {
    return environment.adminUrl;
  }

  get ghostModeActive(): boolean {
    return this.profileService.hasGhostModeCookie;
  }

  get Step(): typeof MembershipStep {
    return MembershipStep;
  }

  get grf(): any {
    return this.genderRegularContactForm.controls;
  }

  get inf(): any {
    return this.interestsForm.controls;
  }

  get rcf(): any {
    return this.raceCategoriesForm.controls;
  }

  get arf(): any {
    return this.autoRenewalForm.controls;
  }

  get isDomesticCyclist(): FormControl {
    return this.inf.is_domestic_cyclist as FormControl;
  }

  get domesticClub(): FormControl {
    return this.inf.domestic_club as FormControl;
  }

  get interests(): FormControl {
    return this.inf.disciplines as FormControl;
  }

  get isAutoRenewal(): FormControl {
    return this.arf.is_auto_renewal as FormControl;
  }

  get isJunior(): boolean {
    return this.registrationFacade.registration.person_type === PersonTypes.JUNIOR;
  }

  get isAdult(): boolean {
    return this.registrationFacade.registration.person_type === PersonTypes.ADULT;
  }

  get isPro(): boolean {
    return this.registration && this.registration.is_professional;
  }

  get isCollegiate(): boolean {
    return (
      (this.registration && this.registration.is_collegiate_cyclist) ||
      (this.isFamily && this.familyFacade.representative.isCollegiate)
    );
  }

  get isFamily(): boolean {
    return (
      (this.registration && this.registration.is_family) ||
      this.registrationFacade.currentState.isFamilyPlan
    );
  }

  get isFamilyAdult(): boolean {
    return this.isFamily && !this.addedJunior;
  }

  get title(): string {
    const title = 'Race Details';
    return this.addedJunior ? 'Junior ' + title : title;
  }

  get currentStep(): MembershipStep {
    return this.addedJunior ? MembershipStep.JuniorMember : MembershipStep.RaceDetails;
  }

  get addOnItems(): AddOnItem[] {
    return [...this.addOnsFacade.adult.items, ...this.addOnsFacade.junior.items];
  }

  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private familyFacade: FamilyMembershipFacade,
    private registrationFacade: RegistrationFacade,
    private addOnsFacade: AddOnsFacade,
    private locationService: LocationsService,
    private membershipOrderService: MembershipOrdersService,
    private modal: NgbModal,
    private profileService: ProfileService,
    private registrationService: RegistrationService,
    private route: ActivatedRoute,
    private router: Router,
    private shopifyService: ShopifyService,
    private transactionService: TransactionService
  ) {
    this.createForms();
    this.registrationId = this.registrationFacade.registrationId;
    this.registrationData = this.registrationFacade.registration$;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.addedJunior = !!this.route.snapshot.data.addedJunior;
    this.authService.isAuthenticated$.subscribe((isAuthenticated) => {
      this.isAuthenticated = isAuthenticated;
    });

    // Update family facade & select family rep member if not added junior
    if (this.isFamilyAdult) {
      this.familyFacade.setSelectedMember(this.familyFacade.representative);
    } else if (this.addedJunior) {
      this.familyFacade.setSelectedMember(this.familyFacade.addedJunior);
    }

    this.authService.currentUser$
      .pipe(
        tap((profile: Profile) => {
          if (!profile) {
            return;
          }
          this.user = profile;
          if (profile.profile_id) {
            this.profileService
              .getPrimaryAddress(profile.profile_id)
              .subscribe((address: Address) => {
                this.registrationFacade.setAddressFromProfile(address);
              });
            this.profileService
              .getLicenses(profile.profile_id, { active: true })
              .subscribe((licenses: License[]) => {
                this.profileLicenses = licenses;
              });
            this.profileService
              .getMemberships(profile.profile_id)
              .subscribe((memberships: Membership[]) => {
                if (memberships) {
                  this.allMemberships = memberships;
                }
                this.membershipsLoading = false;
              });
          } else {
            this.membershipsLoading = false;
          }
          this.userLoaded = true;
        })
      )
      .subscribe();

    this.loadRegistration();
  }

  private loadRegistration(): void {
    let registrationId = this.registrationFacade.registrationId;

    // Set registration ID to correct family member
    if (this.isFamily) {
      const currentMember = this.isFamilyAdult
        ? this.familyFacade.representative
        : this.familyFacade.addedJunior;
      registrationId = currentMember.id;
    }
    if (registrationId) {
      this.registrationId = registrationId;
      this.registrationService
        .getRegistration(registrationId, false)
        .subscribe((registration: Registration) => {
          if (registration) {
            this.setRegistration(registration);
          } else {
            this.getRegistrationByProfile();
          }
        });
    } else {
      this.getRegistrationByProfile();
    }
  }

  private getRegistrationByProfile(): void {
    // If registration removed from local storage, retrieve latest enrollment using authed user's profile ID
    if (this.user) {
      this.registrationService
        .getRegistrationByProfile(this.user.profile_id)
        .pipe(
          tap((reg: Registration) => {
            this.setRegistration(reg);
          }),
          catchError((err) => {
            // Registration not found - log out and return to intro form
            this.authService.doLogout();
            return throwError(err);
          })
        )
        .subscribe();
    }
  }

  private setRegistration(registration: Registration): void {
    this.registration = registration;
    this.registrationFacade.updateRegistration(registration);
    this.registrationFacade.updatePlan(registration.is_family ? Plans.FAMILY : Plans.INDIVIDUAL);
  }

  private loadStates(): Observable<Location[]> {
    this.statesLoading = true;
    return this.locationService.getAll().pipe(
      catchError((err: unknown) => {
        return throwError(err);
      }),
      finalize(() => {
        this.statesLoading = false;
      })
    );
  }

  private createForms(): void {
    // Set initial interests form values (to be overwritten by child component)
    if (this.addedJunior) {
      this.genderRegularContactForm = this.fb.group({});
    }
    this.interestsForm = this.fb.group({});
    this.raceCategoriesForm = this.fb.group({
      domesticCategories: new FormArray([]),
      collegiateCategories: new FormArray([]),
    });
    this.autoRenewalForm = this.fb.group({
      is_auto_renewal: [false],
    });
  }

  // private resetForm(formName: string): void {
  //   this[formName] = this.fb.group({});
  // }

  updateFormValues(formName: string, $event: FormGroup): void {
    if (this[formName]) {
      this[formName] = $event;
    }

    this.partialFormApiUpdate(formName, $event)
      .pipe(
        tap((registration: Registration) => (this.registration = registration)),
        catchError((error) => {
          this.isSubmitting = false;
          return throwError(error);
        })
      )
      .subscribe();
  }

  private partialFormApiUpdate(formName: string, $event: FormGroup): Observable<any> {
    let apiUpdateMethod: (
      id: string,
      data: any
    ) => Observable<any> = this.registrationService.updateMemberInfo.bind(this.registrationService);

    if (formName === 'interestsForm') {
      apiUpdateMethod = this.registrationService.updateRegistrationInterests.bind(
        this.registrationService
      );
    } else if (formName === 'raceCategoriesForm') {
      apiUpdateMethod = this.registrationService.updateRacingCategories.bind(
        this.registrationService
      );
    }

    return apiUpdateMethod(this.registration.id, $event.value);
  }

  updateRegistration(event: boolean): void {
    if (event) {
      this.registrationService
        .getRegistration(this.registration.id, false)
        .subscribe((registration: Registration) => {
          this.registration = registration;
        });
    }
  }

  onSubmit(): void {
    if (this.formsInvalid) {
      // Stop form submit
      return;
    }

    this.isSubmitting = true;

    if (this.isFamilyAdult) {
      // ADULT w/ ADDED JUNIOR NEXT
      this.signWaiver(this.registration);
    } else {
      // SINGLE MEMBER or ADDED JUNIOR
      // Update registration w/ auto-renewal selection
      this.registrationService
        .updateOptions(this.registration.id, {
          is_auto_renewal: this.isAutoRenewal.value,
        })
        .subscribe((updatedRegistration) => {
          // Require waiver before proceeding to checkout
          this.signWaiver(updatedRegistration);
        });
    }
  }

  showJuniorWaiver(registration: Registration): boolean {
    // Members under age 18, or 19 in Alabama, will need a parent/guardian signature
    const { current_age, state } = registration;
    return current_age < 18 || (current_age === 18 && state === 'AL');
  }

  private signWaiver(registration: Registration): void {
    if (this.ghostModeActive) {
      this.proceedToNextStep(registration);
    } else if (!registration.sig_id) {
      const modalOptions: NgbModalOptions = {
        centered: true,
        size: 'xl',
        backdrop: 'static',
        keyboard: false,
      };
      const showJuniorWaiver = this.showJuniorWaiver(registration);
      const modal = showJuniorWaiver
        ? this.modal.open(JuniorWaiverModalComponent, modalOptions)
        : this.modal.open(AgreementModalComponent, modalOptions);

      // Set parameters
      modal.componentInstance.showLegalGuardianCopy = showJuniorWaiver;

      // Set callbacks
      modal.result.then(() => this.proceedToNextStep(registration)).catch(() => {});
    } else {
      this.proceedToNextStep(registration);
    }
  }

  // THIS WAS UPDATED PROCEED WHEN USING SPOT INSURANCE
  // private proceedToNextStep(): void {
  //   if (this.addedJunior) {
  //     this.router.navigate(['enrollment', 'race', 'junior-spot-insurance']);
  //   } else {
  //     this.router.navigate(['enrollment', 'race', 'spot-insurance']);
  //   }
  // }

  private proceedToNextStep(registration: Registration): void {
    if (this.isFamilyAdult) {
      // ADULT w/ ADDED JUNIOR NEXT
      // Redirect to member-info step
      this.isSubmitting = false;
      this.router.navigate(['enrollment', 'race', 'junior-member-info']);
    } else {
      // SINGLE MEMBER or ADDED JUNIOR - Send to checkout
      if (this.ghostModeActive) {
        this.isSubmitting = false;
        this.router.navigate(['enrollment', 'admin-membership-payment']);
      } else if (this.addedJunior) {
        const familyRepId = this.familyFacade.representative.id;
        this.registrationService
          .getRegistration(familyRepId)
          .subscribe((familyRepReg: Registration) => {
            if (familyRepReg) {
              this.checkout(familyRepReg);
            } else {
              this.checkout(registration);
            }
          });
      } else {
        this.checkout(registration);
      }
    }
  }

  private checkout(registration: Registration): void {
    const skus: string[] = [];
    const lineItems: any[] = [];

    // tslint:disable: cyclomatic-complexity
    this.shopifyService.getProducts().then((products: Product[]) => {
      try {
        const variantsBySku: AnalyserOptions = products
          .flatMap((product: Product) => product.variants)
          .reduce((acc: AnalyserOptions, productVariant: ProductVariant) => {
            acc[productVariant.sku] = productVariant;
            return acc;
          }, {});

        // These are required for the custom thank you page redirect to work
        const customAttributes: any[] = [
          {
            key: 'source',
            value: 'membership',
          },
          {
            key: 'membership_order_id',
            value: registration.order.order_id,
          },
        ];

        const note = `membership, ${MerchantAccountTag.Membership}`;

        return this.membershipOrderService
          .getById(registration.order.order_id)
          .subscribe((order) => {
            if (order) {
              order.enrollments.forEach((enrollment) => {
                let licenseSku: string;
                const {
                  has_spot_insurance,
                  intl_bmx_category,
                  is_collegiate_cyclist,
                  is_international,
                  person_type,
                  is_professional,
                } = enrollment;

                if (is_international) {
                  licenseSku = 'INT';
                } else if (is_professional) {
                  licenseSku = 'PRO';
                } else {
                  licenseSku = 'RACE';
                }

                if (has_spot_insurance) {
                  licenseSku += '-PLUS';
                }
                if (is_collegiate_cyclist && !is_international && !is_professional) {
                  licenseSku += '-COL';
                } else if (person_type === PersonTypes.JUNIOR && !is_professional) {
                  licenseSku += '-JUN';
                }
                if (is_international && intl_bmx_category) {
                  licenseSku += '-BMX';
                }

                skus.push(licenseSku);
                // TODO: @Brennan - Include collegiate add-on if not RACE?
              });

              this.addOnItems.forEach((addOn) => skus.push(addOn.sku));

              skus.forEach((sku) => {
                const variant = variantsBySku[sku];
                if (variant) {
                  const existingLineItem = lineItems.find((item) => item.variantId === variant.id);
                  if (existingLineItem) {
                    existingLineItem.quantity += 1;
                  } else {
                    lineItems.push({
                      variantId: variantsBySku[sku].id,
                      quantity: 1,
                    });
                  }
                } else {
                  console.warn(`Missing Variant: SKU => '${sku}'`);
                }
              });

              return this.createCheckoutAndTransaction(
                lineItems,
                customAttributes,
                note,
                registration
              );
            } else {
              return Promise.reject();
            }
          });
      } catch (error) {
        return Promise.reject(error);
      }
    });
  }

  private async createCheckoutAndTransaction(
    lineItems: any[],
    customAttributes: any[],
    note: string,
    registration: Registration
  ): Promise<any> {
    const checkoutModel = await this.shopifyService.createCheckout(
      lineItems,
      customAttributes,
      note
    );
    const registrations = this.addedJunior
      ? this.familyFacade.currentState.familyMembers.map((member: FamilyMember) => {
          return { id: member.id };
        })
      : [{ id: registration.id }];
    const transaction = {
      shopify_uuid: checkoutModel.checkout.id,
      registrations,
    };
    return await this.createTransaction(transaction, checkoutModel, registration);
  }

  private createTransaction(
    transaction: any,
    checkoutModel: any,
    registration: Registration
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.transactionService.create(transaction).subscribe(
        () => {
          this.resetFacades();
          // Redirect for updated checkout flow  (usac-checkout)
          window.location.href = this.transactionService.getCheckoutUrl(checkoutModel.checkout.id, {
            membershipOrder: registration.order.order_id,
            email: registration.email,
            firstName: registration.first_name,
            lastName: registration.last_name,
          });
          this.isSubmitting = false;
          resolve(true);
        },
        () => {
          reject();
        }
      );
    });
  }

  resetFacades(): void {
    this.registrationFacade.reset();
    this.familyFacade.reset();
    this.addOnsFacade.reset();
  }

  clearErrors(): void {
    this.errors$.next([]);
  }

  back(): void {
    this.isNavigating = true;
    const route = this.addedJunior
      ? ['enrollment', 'race', 'junior-member-offers']
      : ['enrollment', 'race', 'member-offers'];
    this.router.navigate(route);
  }

  get disableBack(): boolean {
    return this.isSubmitting || this.isNavigating;
  }

  get formsInvalid(): boolean {
    return (
      this.invalidForm('interestsForm') ||
      this.invalidForm('raceCategoriesForm') ||
      this.invalidForm('autoRenewalForm')
    );
  }

  private invalidForm(formName: string): boolean {
    return this[formName] && this[formName].invalid;
  }

  get showAutoRenewal(): boolean {
    return (
      ((!this.registration.is_family && !this.isPro && this.registration.race_age !== 18) ||
        this.addedJunior) &&
      !this.isCollegiate
    );
  }

  get disableContinue(): boolean {
    return this.formsInvalid || this.isSubmitting || this.isNavigating;
  }

  get submitBtnText(): string {
    return this.isFamilyAdult || this.ghostModeActive ? 'Continue' : 'Checkout';
  }
}
