import { Component, Input } from '@angular/core';
import { LicenseGroup, Profile } from 'src/app/core/models';
import { LicenseService } from 'src/app/core/services';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-member-licenses',
  templateUrl: './member-licenses.component.html',
  styleUrls: ['./member-licenses.component.scss'],
})
export class MemberLicensesComponent {
  @Input() user: Profile;
  @Input() licenseGroups: LicenseGroup[];
  @Input() currentStep = '';

  constructor(private licenseService: LicenseService) {}
  get adminURL(): string {
    return `${environment.adminUrl}/profile/${this.user.profile_id}`;
  }
  statusText(licenseGroup: LicenseGroup): string {
    return this.licenseService.licenseGroupStatusText(licenseGroup);
  }

  statusClass(licenseGroup: LicenseGroup): string {
    return this.licenseService.licenseGroupStatusClass(licenseGroup);
  }
}
