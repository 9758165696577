import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import {
  Discipline,
  Interest,
  License,
  LicenseFlag,
  MembershipOrder,
  MembershipType,
  PersonTypes,
  Product,
  ProductVariant,
  Profile,
  Registration,
  ShirtSize,
  ShirtType,
} from 'src/app/core/models';
import {
  InterestsService,
  MembershipOrdersService,
  RegistrationService,
  ShopifyService,
} from 'src/app/core/services';
import { Image } from 'src/app/core/services/shopify.types';

// import { SpotInsuranceModalComponent } from '../spot-insurance-modal/spot-insurance-modal.component';

export interface OrderItem {
  label: string;
  price: number;
}

const atLeastOneSelected = (control: AbstractControl): { [key: string]: any } | null => {
  const truthy = control.value.filter((v: any) => !!v);
  return truthy.length === 0 ? { members: { value: control.value } } : null;
};

@Component({
  selector: 'app-add-ons',
  templateUrl: './add-ons.component.html',
  styleUrls: ['./add-ons.component.scss'],
})
export class AddOnsComponent implements OnInit, OnChanges {
  @Input() registration: Registration;
  @Input() user: Profile;
  @Input() licenses: License[];
  @Output() donationChange: EventEmitter<number> = new EventEmitter(null);
  @Output() orderChange: EventEmitter<MembershipOrder> = new EventEmitter(null);

  variantsBySku: { [key: string]: ProductVariant };

  donationTiers = [
    {
      label: '$0',
      value: 0,
    },
    {
      label: '$10',
      value: 10,
    },
    {
      label: '$20',
      value: 20,
    },
    {
      label: '$50',
      value: 50,
    },
    {
      label: '$100',
      value: 100,
    },
    {
      label: '$500',
      value: 500,
    },
  ];

  categoriesByEnrollment: any[] = [];
  allInterests: Interest[];
  order?: MembershipOrder;
  isLoading = false;

  orders: OrderItem[] = [];
  chosenDonationAmount = 0;
  donationAmountInputValue = 0;

  personTypes = PersonTypes;

  shirtTypes = [
    { label: "Men's", value: ShirtType.Male },
    { label: "Women's", value: ShirtType.Female },
  ];

  shirtSizes = [
    { label: ShirtSize.ExtraSmall, value: ShirtSize.ExtraSmall },
    { label: ShirtSize.Small, value: ShirtSize.Small },
    { label: ShirtSize.Medium, value: ShirtSize.Medium },
    { label: ShirtSize.Large, value: ShirtSize.Large },
    { label: ShirtSize.ExtraLarge, value: ShirtSize.ExtraLarge },
    { label: ShirtSize.ExtraExtraLarge, value: ShirtSize.ExtraExtraLarge },
  ];

  planSkus = this.shopifyService.planSkus;

  podiumPackage: Product;
  podiumPackagePrice = this.shopifyService.podiumPackagePrice;
  plusUpgradePrice = this.shopifyService.planSkus.racePlusUpgrade;

  get internationalLicensePrice(): string {
    const prices = this.isAdult ? this.planSkus.adult : this.planSkus.junior;
    // TODO: @Brennan - Do we need to also handle race to intl+ (juniors only)?
    return this.isPlusPaid
      ? this.variantsBySku[prices.internationalPlusFromRacePlus].priceV2.amount
      : this.variantsBySku[prices.internationalFromRace].priceV2.amount;
  }

  // get proLicensePrice(): number {
  //   return this.isPlusPaid ? this.planSkus.professionalPlus : this.planSkus.professional;
  // }

  isUpdating = false;

  premiumAddonForm = new FormGroup({
    members: new FormArray([], atLeastOneSelected),
  });

  get premiumAddonFormMembers(): FormArray {
    return this.premiumAddonForm.get('members') as FormArray;
  }

  podiumAddonForm = new FormGroup({
    members: new FormArray([], atLeastOneSelected),
  });

  get podiumAddonFormMembers(): FormArray {
    return this.podiumAddonForm.get('members') as FormArray;
  }

  plusUpgradeForm = new FormGroup({
    members: new FormArray([], atLeastOneSelected),
  });

  get plusUpgradeFormMembers(): FormArray {
    return this.plusUpgradeForm.get('members') as FormArray;
  }

  // proAddonForm: FormGroup;
  // proLicenseForm: FormGroup;

  internationalAddonForm: FormGroup;
  internationalLicenseForm: FormGroup;

  // get paf(): any {
  //   return this.proAddonForm.controls;
  // }

  // get plf(): any {
  //   return this.proLicenseForm.controls;
  // }

  // get addPro(): boolean {
  //   return this.proAddonForm && this.paf.pro_license.value;
  // }

  get iaf(): any {
    return this.internationalAddonForm.controls;
  }

  get ilf(): any {
    return this.internationalLicenseForm.controls;
  }

  get puf(): any {
    return this.plusUpgradeForm.controls;
  }

  // get proEligible(): boolean {
  //   return this.user && !!this.user.profile_is_pro;
  // }

  get isAdult(): boolean {
    return this.mainEnrollment && this.mainEnrollment.person_type === PersonTypes.ADULT;
  }

  get hasPlus(): boolean {
    return this.mainEnrollment.has_spot_insurance;
  }

  get isPlusPaid(): boolean {
    return this.mainEnrollment.is_spot_paid;
  }

  get addInternational(): boolean {
    return this.internationalAddonForm && this.iaf.international_license.value;
  }

  get podiumPackagesCount(): number {
    return this.order
      ? this.order.enrollments.filter((enrollment) => enrollment.is_podium).length
      : 0;
  }

  get premiumUpgradesCount(): number {
    return this.order
      ? this.order.enrollments.filter((enrollment) => enrollment.is_premium).length
      : 0;
  }

  get enrollmentsEligibleForPremium(): Registration[] {
    return this.order
      ? this.order.enrollments.filter(
        (enrollment) =>
          !enrollment.is_premium &&
          !enrollment.is_premium_paid &&
          !this.isProOrInternational &&
          !enrollment.is_international &&
          !enrollment.is_professional &&
          !enrollment.is_collegiate_cyclist &&
          enrollment.person_type === PersonTypes.ADULT
      )
      : [];
  }

  // get displayPremium(): boolean {
  //   return this.enrollmentsEligibleForPremium.length > 0 && !this.isInternational && !this.isPro;
  // }

  get mainEnrollment(): Registration {
    return this.order ? this.order.enrollments[0] : null;
  }

  get mainEnrollmentName(): string {
    return this.order && this.order.enrollments.length ? this.order.enrollments[0].full_name : '';
  }

  get isPro(): boolean {
    return this.mainEnrollment && !!this.mainEnrollment.is_professional;
  }

  get isProPaid(): boolean {
    return this.mainEnrollment && !!this.mainEnrollment.is_professional_paid;
  }

  get isInternational(): boolean {
    return this.mainEnrollment && !!this.mainEnrollment.is_international;
  }

  get isInternationalPaid(): boolean {
    return this.mainEnrollment && !!this.mainEnrollment.is_international_paid;
  }

  get isProOrInternational(): boolean {
    return this.isPro || this.isInternational;
  }

  get isProOrInternationalPaid(): boolean {
    return this.isProPaid || this.isInternationalPaid;
  }

  // get displayPro(): boolean {
  //   return (
  //     this.proEligible &&
  //     !this.checkLicense(LicenseFlag.PROFESSIONAL) &&
  //     !this.isProOrInternational &&
  //     !this.isProOrInternationalPaid &&
  //     !!this.proAddonForm &&
  //     !!this.proLicenseForm
  //   );
  // }

  get displayInternational(): boolean {
    return (
      !this.checkLicense(LicenseFlag.INTERNATIONAL) &&
      !this.isProOrInternational &&
      !this.isProOrInternationalPaid &&
      !!this.internationalAddonForm &&
      !!this.internationalLicenseForm
    );
  }

  get enrollmentsEligibleForPodium(): Registration[] {
    return this.order
      ? this.order.enrollments.filter(
        (enrollment) => !enrollment.is_podium && !enrollment.is_podium_paid
      )
      : [];
  }

  get enrollmentsEligibleForPlus(): Registration[] {
    return this.order
      ? this.order.enrollments.filter(
        (enrollment) => !enrollment.has_spot_insurance && !enrollment.is_spot_paid
      )
      : [];
  }

  get missingPodiumSelection(): boolean {
    return this.order.enrollments.some(
      (enrollment) => enrollment.is_podium
    );
  }

  get orderTotal(): number {
    const orderTotal = this.order ? this.order.total : 0;
    return orderTotal + this.chosenDonationAmount;
  }

  isSubmitting = false;
  serverError: any = null;
  autoRenew = true;

  constructor(
    // private modal: NgbModal,
    private registrationService: RegistrationService,
    private interestsService: InterestsService,
    private shopifyService: ShopifyService,
    private membershipOrdersService: MembershipOrdersService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.podiumImage();
    this.shopifyService.getProducts().then((products) => this.setVariantsBySku(products));
    this.loadInterests();
    this.loadOrder();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.registration && !changes.registration.firstChange) {
      this.loadOrder();
    }
  }

  private setVariantsBySku(products: Product[]): void {
    this.variantsBySku = products
      .flatMap((product) => product.variants)
      .reduce((acc, productVariant) => {
        acc[productVariant.sku] = productVariant;
        return acc;
      }, {});
  }

  private loadOrder(): void {
    const orderId = this.registration?.order?.order_id;
    if (orderId) {
      this.isLoading = true;
      this.membershipOrdersService
        .getById(orderId)
        .pipe(
          tap((order) => {
            this.order = order;
            this.categoriesByEnrollment = [];

            this.categoriesByEnrollment = [];
            order.enrollments.forEach((enrollment) => {
              const disciplines = this.allInterests
                .flatMap((x) => x.disciplines)
                .filter(
                  (x) => x.collegiateCategories.length > 0 && x.nonCollegiateCategories.length > 0
                );
              const selectedCategories = enrollment.racing_categories || [];

              const categories: any[] = [];

              disciplines.forEach((discipline: Discipline) => {
                const selectedCategoryId = discipline.nonCollegiateCategories.findIndex(
                  (category) => selectedCategories.some((rc) => rc.cd_type === category.id)
                );

                const selectedCategory = discipline.nonCollegiateCategories[selectedCategoryId];
                const isNovice = selectedCategoryId === 0;

                if (selectedCategory && !isNovice) {
                  categories.push({
                    disciplineName: discipline.name,
                    categoryName: selectedCategory.name,
                    categoryRequiresPremium: selectedCategory.requiresPremium,
                  });
                }
              });

              this.categoriesByEnrollment.push(categories);
            });

            this.createProInternationalForms();
            this.updatePremiumEligibleMembers(order);
            this.updatePodiumEligibleMembers(order);
            this.updateSpotEligibleMembers(order);
            this.orderChange.emit(order);
          }),
          catchError((error) => {
            console.log(error);
            return throwError(error);
          }),
          finalize(() => {
            this.isLoading = false;
            this.isUpdating = false;
          })
        )
        .subscribe();
    }
  }

  private loadInterests(): void {
    this.interestsService
      .getInterests()
      .pipe(
        tap((interests) => {
          this.allInterests = interests;
        })
      )
      .subscribe();
  }

  private createProInternationalForms(): void {
    if (this.mainEnrollment.membership_type === MembershipType.Individual) {
      this.internationalAddonForm = this.fb.group({
        international_license: [false],
      });
      this.internationalLicenseForm = this.fb.group({});

      // if (this.proEligible) {
      //   this.proAddonForm = this.fb.group({
      //     pro_license: [false],
      //   });
      //   this.proLicenseForm = this.fb.group({});
      // }
    }
  }

  updateFormValues(formName: string, $event: FormGroup): void {
    if (this[formName]) {
      this[formName] = $event;
    }
  }

  updatePremiumEligibleMembers(order: MembershipOrder): void {
    this.premiumAddonFormMembers.clear();
    order.enrollments
      .filter(
        (enrollment) =>
          !enrollment.is_premium &&
          !enrollment.is_collegiate_cyclist &&
          enrollment.person_type === PersonTypes.ADULT
      )
      .map(() => new FormControl(false))
      .forEach((control) => this.premiumAddonFormMembers.push(control));
  }

  updatePodiumEligibleMembers(order: MembershipOrder): void {
    this.podiumAddonFormMembers.clear();
    order.enrollments
      .filter((enrollment) => !enrollment.is_podium)
      .map(() => new FormControl(false))
      .forEach((control) => this.podiumAddonFormMembers.push(control));
  }

  updateSpotEligibleMembers(order: MembershipOrder): void {
    this.plusUpgradeFormMembers.clear();
    order.enrollments
      .filter((enrollment) => !enrollment.has_spot_insurance)
      .map(() => new FormControl(false))
      .forEach((control) => this.plusUpgradeFormMembers.push(control));
  }

  updateDonation(): void {
    this.chosenDonationAmount = this.donationAmountInputValue;
    this.donationChange.emit(this.chosenDonationAmount);
  }

  addPremiumUpgrades(): void {
    const enrollments = this.premiumAddonFormMembers.value
      .map((selected: boolean, i: number) =>
        selected ? this.enrollmentsEligibleForPremium[i] : null
      )
      .filter((v?: Registration) => v !== null);

    this.togglePremium(enrollments, true);
  }

  addPremium(enrollment: Registration): void {
    this.togglePremium([enrollment], true);
  }

  removePremium(enrollment: Registration): void {
    this.togglePremium([enrollment], false);
  }

  togglePremium(enrollments: Registration[], is_premium: boolean): void {
    const options = { is_premium };
    this.updateAddOns(enrollments, options);
  }

  // addProLicense(): void {
  //   this.togglePro(true);
  // }

  // removeProLicense(): void {
  //   this.togglePro(false);
  // }

  // togglePro(is_professional: boolean): void {
  //   const citizenship: any = null;
  //   const racing_nationality: any = null;
  //   const intl_license_level: any = null;
  //   const intl_bmx_category: any = null;
  //   const pro_club_id =
  //     is_professional && this.plf.pro_team.value ? this.plf.pro_team.value.value : null;
  //   const options = {
  //     is_premium: this.mainEnrollment.is_premium_paid || is_professional,
  //     is_international: this.mainEnrollment.is_international_paid || false,
  //     citizenship,
  //     racing_nationality,
  //     is_professional,
  //     pro_club_id,
  //     intl_license_level,
  //     intl_bmx_category,
  //   };

  //   this.updateAddOns([this.mainEnrollment], options);
  // }

  addInternationalLicense(): void {
    this.toggleInternational(true);
  }

  removeInternationalLicense(): void {
    this.toggleInternational(false);
  }

  toggleInternational(is_international: boolean): void {
    const pro_club_id: any = null;
    const dual_citizen = is_international ? this.ilf.dual_citizen?.value : null;
    const dual_citizen_country = is_international
      ? this.ilf.dual_citizen_country?.value?.value
      : null;
    const permanent_resident = is_international ? this.ilf.permanent_resident?.value : null;
    const citizenship = is_international ? this.ilf.racing_nationality?.value?.value : null;
    const racing_nationality = is_international ? this.ilf.racing_nationality?.value?.value : null;
    const intl_license_level = is_international ? this.ilf.intl_license_level?.value?.value : null;
    const intl_bmx_category = is_international ? this.ilf.intl_bmx_category?.value : null;
    const options = {
      is_premium: this.mainEnrollment.is_premium_paid || is_international,
      is_professional: this.mainEnrollment.is_professional_paid || false,
      pro_club_id,
      is_international,
      dual_citizen,
      dual_citizen_country,
      permanent_resident,
      citizenship,
      racing_nationality,
      intl_license_level,
      intl_bmx_category,
    };

    this.updateAddOns([this.mainEnrollment], options);
  }

  addPodiumPackages(): void {
    const enrollments = this.podiumAddonFormMembers.value
      .map((selected: boolean, i: number) =>
        selected ? this.enrollmentsEligibleForPodium[i] : null
      )
      .filter((v?: Registration) => v !== null);

    this.togglePodium(enrollments, true);
  }

  removePodiumPackage(enrollment: Registration): void {
    this.togglePodium([enrollment], false);
  }

  private togglePodium(enrollments: Registration[], is_podium: boolean): void {
    const options = { is_podium };
    this.updateAddOns(enrollments, options);
  }

  updatePodiumDetails(enrollment: Registration, key: string): void {
    const options = { [key]: enrollment[key] };
    this.updateAddOns([enrollment], options);
    this.orderChange.emit(this.order);
  }

  addPlus(): void {
    const enrollments = this.plusUpgradeFormMembers.value
      .map((selected: boolean, i: number) => (selected ? this.enrollmentsEligibleForPlus[i] : null))
      .filter((v?: Registration) => v !== null);

    this.togglePlus(enrollments, true);
  }

  removePlus(enrollment: Registration): void {
    this.togglePlus([enrollment], false);
  }

  private togglePlus(enrollments: Registration[], has_spot_insurance: boolean): void {
    const options = { has_spot_insurance };
    this.updateAddOns(enrollments, options);
  }

  showName(enrollment: Registration): boolean {
    return (
      (enrollment.is_podium && !enrollment.is_podium_paid) ||
      (enrollment.has_spot_insurance && !enrollment.is_spot_paid) ||
      (this.isInternational && !this.isInternationalPaid)
      // (this.isPro && !this.isProPaid)
    );
  }

  private checkLicense(flag: LicenseFlag): boolean {
    return this.licenses.some((license) => !!license[flag]);
  }

  private updateAddOns(enrollments: Registration[], options = {}): void {
    this.isUpdating = true;
    const updateTasks = enrollments.map((enrollment) => {
      return this.registrationService.updateOptions(enrollment.id, options);
    });

    forkJoin(updateTasks)
      .pipe(
        tap(() => {
          this.loadOrder();
        }),
        catchError((error) => {
          this.isUpdating = false;
          console.log(error);
          return throwError(error);
        })
      )
      .subscribe();
  }

  get addOnsSelected(): boolean {
    const order = this.order;
    return (
      (order &&
        order.enrollments.some(
          (enrollment) =>
            (enrollment.is_podium && !enrollment.is_podium_paid) ||
            (enrollment.has_spot_insurance && !enrollment.is_spot_paid)
        )) ||
      (this.isInternational && !this.isInternationalPaid)
      // (this.isPro && !this.isProPaid)
    );
  }

  podiumImage(): void {
    this.shopifyService.getProducts(['podium-package']).then((products) => {
      this.podiumPackage = products[0];
    });
  }
  get productImages(): Image[] {
    return this.podiumPackage.images;
  }

  get primaryProductImage(): Image {
    return this.productImages[0].originalSrc;
  }

  // openSpotModal(): void {
  //   this.modal.open(SpotInsuranceModalComponent, {
  //     centered: true,
  //     size: 'xl',
  //   });
  // }
}
