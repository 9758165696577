<ng-container>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="agreement">
          <h1 class="agreement__title">USAC Cycling Waiver</h1>

          <app-loader *ngIf="loading"></app-loader>

          <div *ngIf="!loading">
            <p class="agreement__subtitle">Please Read & Agree to the Below</p>
            <p class="agreement__participant">
              Participant:
              <strong>
                {{ user.profile_first_name }}
                {{ user.profile_last_name }}
              </strong>
            </p>

            <div class="agreement-card" [innerHTML]="waiverText | html"></div>

            <form [formGroup]="agreementForm" class="agreement-form">
              <!-- Junior parent/guardian fields -->
              <div *ngIf="!isAdult">
                <h2 class="agreement-form__title">Enter required information to sign the junior's waiver</h2>

                <div class="row mb-5">
                  <div class="col-12 col-md-6">
                    <label for="parent_first_name">Legal Guardian's First Name</label>
                    <input
                      class="form-control"
                      type="text"
                      id="parent_first_name"
                      placeholder="Enter First Name"
                      formControlName="parent_first_name"
                    />
                  </div>
                  <div class="col-12 col-md-6 mt-4 mt-md-0">
                    <label for="parent_last_name">Legal Guardian's Last Name</label>
                    <input
                      class="form-control"
                      type="text"
                      id="parent_last_name"
                      placeholder="Enter Last Name"
                      formControlName="parent_last_name"
                    />
                  </div>
                  <div class="col-12 col-md-6 mt-4">
                    <label for="signer_email">Legal Guardian's Email Address</label>
                    <input
                      class="form-control"
                      type="text"
                      id="signer_email"
                      placeholder="Enter Email Address"
                      formControlName="signer_email"
                    />
                  </div>
                  <div class="col-12 col-md-6 mt-4">
                    <label for="parent_relationship">Relationship</label>
                    <ng-select
                      id="parent_relationship"
                      [items]="relationships"
                      bindLabel="name"
                      bindValue="name"
                      placeholder="Make Selection"
                      [clearable]="false"
                      [searchable]="false"
                      formControlName="parent_relationship"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>

              <!-- Waiver agreement fields -->
              <h2 class="agreement-form__title">Sign & Agree</h2>

              <div class="form-group mt-1">
                <label for="fullName"
                  >{{
                    isAdult
                      ? 'Enter your Full Name'
                      : "Legal Guardian's Full Name"
                  }}<span class="star">*</span></label
                >
                <input
                  [ngClass]="{ 'border border-danger': fullNameError }"
                  formControlName="fullName"
                  type="text"
                  class="form-control"
                  id="fullName"
                  placeholder="Jane Smith"
                  #fullName
                  (keyup)="compareNames()"
                />

                <span class="text-danger" *ngIf="fullNameError">{{
                  isAdult
                    ? 'The name must match the first and last name associated with this membership'
                    : 'The legal guardian must enter their full name here to sign'
                }}</span>
              </div>

              <div class="card custom-control custom-checkbox">
                <input
                  formControlName="agreed"
                  class="card-body custom-control-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                  />
                <label
                  class="ml-2 mt-2 mb-2 custom-control-label"
                  for="defaultCheck1"
                  [innerHtml]="waiverAgreement"
                >
                </label>
              </div>

              <div class="card custom-control custom-checkbox mt-4">
                <input
                  formControlName="codeOfConductAgreed"
                  class="card-body custom-control-input"
                  type="checkbox"
                  value=""
                  id="codeOfConductCheck"
                  />
                <label
                  class="ml-2 mt-2 mb-2 custom-control-label"
                  for="codeOfConductCheck"
                  [innerHtml]="codeOfConduct"
                >
                </label>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <footer class="agreement__footer">
    <button
      type="button"
      (click)="signWaiver($event)"
      [disabled]="agreementForm.invalid || buttonDisabled || isSubmitting"
      class="agreement__button btn btn-primary"
    >
      <span *ngIf="isSubmitting" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
      I Agree to the Above
    </button>
  </footer>
</ng-container>
