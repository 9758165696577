import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Membership, Profile } from 'src/app/core/models';
import { BraintreeService } from 'src/app/core/services';
// import { ProfileService } from 'src/app/core/services';
// import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-status-indicator-header',
  templateUrl: './status-indicator-header.component.html',
  styleUrls: ['./status-indicator-header.component.scss'],
})
export class StatusIndicatorHeaderComponent implements OnInit {
  @Input() user: Profile;
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() email: string;
  @Input() allMemberships: Membership[];
  @Input() loading: boolean;

  usersSubscription: any;
  subsLoading = true;

  constructor(private braintreeService: BraintreeService) { }

  ngOnInit(): void {
    this.getUsersSubscription();
  }

  get membershipLoading(): boolean {
    return this.loading && this.subsLoading;
  }

  getUsersSubscription(): void {
    this.subsLoading = true;
    this.braintreeService.getSubscriptions().subscribe((resp) => {
      this.subsLoading = false;
      if (resp) {
        this.usersSubscription = resp.subscriptions;
      }
    });
  }

  get name(): string {
    return this.firstName || this.lastName ? `${this.firstName} ${this.lastName}` : '-';
  }

  get memberSince(): string {
    if (this.allMemberships && this.allMemberships.length) {
      const oldest: Membership = this.allMemberships.sort(
        (a: any, b: any) =>
          new Date(a.membership_purchase_date).getTime() -
          new Date(b.membership_purchase_date).getTime()
      )[0];

      return moment(oldest.membership_purchase_date).format('YYYY');
    }

    return null;
  }

  get currentMembership(): Membership {
    if (this.allMemberships && this.allMemberships.length) {
      const latest: Membership = this.allMemberships.sort(
        (a: any, b: any) =>
          new Date(b.membership_expiration_date).getTime() -
          new Date(a.membership_expiration_date).getTime()
      )[0];

      return latest;
    }

    return null;
  }
}
