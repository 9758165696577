<app-header [cartHidden]="false"></app-header>

<app-loader *ngIf="isLoading" [overlay]="true"></app-loader>

<div class="container">
  <div class="row">
    <div class="col-12 col-md-10 offset-md-1 col-lg-9 offset-lg-0">
      <!-- Member Status Indicator -->
      <app-status-indicator-header *ngIf="userLoaded"
        [user]="currentStatusIndicatorProfile"
        [firstName]="statusIndicatorFirstName"
        [lastName]="statusIndicatorLastName"
        [email]="statusIndicatorEmail"
        [allMemberships]="allMemberships"
        [loading]="membershipsLoading"
      ></app-status-indicator-header>
      <hr>
    </div>
    <div class="col-12 col-md-10 offset-md-1 col-lg-9 offset-lg-0">
      <!-- Step Progress Bar -->
      <app-step-progress-bar
        [currentStep]="currentStep"
        [nextStep]="nextStep"
        [isRace]="isRaceFlow"
        [isFamily]="isFamilyPlan"
      ></app-step-progress-bar>
    </div>

    <div class="col-12 offset-md-1 col-md-10 offset-lg-0 col-lg-9 p-3" *ngIf="vm$ | async as vm">
      <app-form-title *ngIf="!isFamilyPlan">
        Member Information
      </app-form-title>

      <app-form-title *ngIf="isFamilyPlan && !addedJunior">
        Adult Member Information
      </app-form-title>

      <app-form-title *ngIf="addedJunior">
        Junior Member Information
      </app-form-title>

      <app-form-subtitle *ngIf="!isFamilyPlan">
        Welcome to the USA Cycling community. Tell us a little bit about yourself so we can get you
        in the saddle with the right benefits as quickly as possible.
      </app-form-subtitle>

      <app-form-subtitle *ngIf="isFamilyPlan && !addedJunior">
        Welcome to the USA Cycling community. Let’s set up the membership account for the Family
        Representative first.
      </app-form-subtitle>
      <app-form-subtitle *ngIf="addedJunior">
        Family Representative membership complete! Now let’s add the Junior Member, who must be under Race Age 19.
      </app-form-subtitle>

      <p class="form-info">Required fields marked with <span class="star">*</span></p>

      <form
        [formGroup]="memberForm"
        (ngSubmit)="onSubmit(memberForm.value)"
        class="membership-form m-0"
      >
        <div class="form-card">
          <h3 class="form-title">Who is this membership for?</h3>
          <h4 class="form-subtitle">
            This information will be used for all membership communications and official membership
            records.
          </h4>

          <!-- First name, Last name -->
          <div class="row">
            <div class="col-xs-12 col-md-6">
              <label for="first-name">First Name <span class="star">*</span></label>
              <input
                trim
                formControlName="first_name"
                id="first-name"
                placeholder="John"
                type="text"
                class="form-control"
                autocomplete="given-name"
                [readOnly]="readOnlyInput('first_name')"
              />
            </div>
            <div class="col-xs-12 col-md-6">
              <label for="last-name">Last Name <span class="star">*</span></label>
              <input
                trim
                formControlName="last_name"
                id="last-name"
                type="text"
                class="form-control"
                placeholder="Smith"
                autocomplete="family-name"
                [readOnly]="readOnlyInput('last_name')"
              />
            </div>
          </div>

          <!-- Email address, Date of Birth -->
          <div class="row">
            <div class="col-xs-12 col-md-6">
              <label for="email">Email Address <span class="star">*</span></label>
              <input
                trim
                formControlName="email"
                id="email"
                placeholder="johndoe@gmail.com"
                type="email"
                class="form-control"
                autocomplete="email"
                [readOnly]="readOnlyInput('email')"
              />

              <div *ngIf="addedJunior">
                <input *ngIf="!readOnlyInput()"
                  formControlName="inherit_email"
                  type="checkbox"
                  class="custom-checkbox mr-2"
                  [value]="true"
                  id="inherit-email"
                />
                <input *ngIf="readOnlyInput()"
                  formControlName="inherit_email"
                  type="checkbox"
                  class="custom-checkbox mr-2"
                  [value]="true"
                  id="inherit-email"
                  disabled
                />
                <label class="m-0" for="inherit-email">Use Family Representative email</label>
              </div>
            </div>

            <div class="col-xs-12 col-md-6">
              <label for="birthdate">Date of Birth <span class="star">*</span></label>
              <app-segmented-datepicker
                formControlName="birthdate"
                [name]="'birthdate'"
                [minDate]="minBirthdate"
                [maxDate]="maxDate"
                [errors]="dob.errors"
                [disabled]="readOnlyInput('birthdate')"
              ></app-segmented-datepicker>
              <p class="text-danger mb-0 pb-0" *ngIf="birthdayErrorMessage">
                {{ birthdayErrorMessage }}
              </p>
            </div>

            <div class="col-xs-12 col-md-6">
              <label for="contact_emergency_contact_name">Emergency Contact Name</label>
              <input
                formControlName="contact_emergency_contact_name"
                id="contact_emergency_contact_name"
                type="text"
                class="form-control"
                placeholder="Jane Doe"
                autocomplete="family-name"
              />
            </div>

            <div class="col-xs-12 col-md-6">
              <label for="contact_emergency_contact_number">Emergency Contact Phone</label>
              <input
                formControlName="contact_emergency_contact_number"
                id="contact_emergency_contact_number"
                type="tel"
                class="form-control"
                placeholder="(303) 123-4567"
                autocomplete="family-name"
                appPhoneNumberFormatter
              />
            </div>

            <div class="col-xs-12 col-md-6">
              <label for="race_gender">Sex<span class="star">*</span>
                <i
                  class="icon icon-information"
                  placement="bottom"
                  [ngbTooltip]="raceGenderTipContent"
                  tooltipClass="custom-tooltip"
                  [openDelay]="250"
                  tabindex="0"
                ></i>
              </label>
              <ng-select
                [items]="raceGenders"
                bindValue="value"
                bindLabel="label"
                formControlName="race_gender"
                [clearable]="false"
                [searchable]="false"
                placeholder="Make Selection"
                [class.is-invalid]="raceGender.invalid && (raceGender.touched || raceGender.dirty)"
                [class.ng-select-disabled]="readOnlyInput('race_gender')"
              >
              </ng-select>

              <ng-template #raceGenderTipContent>
                Members shall have an equal opportunity to participate in bicycle racing without
                discrimination, however a binary sex must be chosen for categorized competitive racing.
              </ng-template>
            </div>
          </div>
        </div>

        <!-- Additional Junior Opt-in -->
        <!-- <div *ngIf="showAddedJuniorSelect" class="form-card" [class.disabled]="juniorDisabled">
          <div>
            <div class="license-title d-flex justify-content-between align-items-start">
              <div>
                <h3 class="form-title">Do you need to register a junior family member?</h3>
                <h4 class="form-subtitle">*Requires a primary adult member.</h4>
              </div>
              <img src="assets/users.svg" width="75px" class="pl-2" alt="Junior Membership">
            </div>
            <app-add-junior-select [isSubmitting]="isSubmitting" [preSelected]="isFamilyPlan" (formValues)="juniorSelectValues($event)"></app-add-junior-select>
          </div>
          <h4 *ngIf="juniorDisabled" class="form-subtitle text-info mt-3 mb-0">
            *If you wish to add a junior license, please de-select {{licenseSelectionText}} on the next screen.
          </h4>
        </div> -->

        <!-- Acount Verification -->
        <!-- need to add if no user is signed in -->
        <form [formGroup]="verificationForm" class="membership-form m-0" *ngIf="passwordFields">
        <div class="form-card">
          <h3 class="form-title">Verify Sporting Nationality & Citizenship</h3>
          <h4 class="form-subtitle">
            Review the information carefully before proceeding
          </h4>
          
          <div class="col-12 mb-2">
            <h5>I am a United States Citizen <span class="star">*</span></h5>
          </div>
          <div class="row">
            <div class="col-2 mb-3">
              <div class="custom-control custom-radio">
                <input formControlName="verify_is_us_citizen" class="custom-control-input" type="radio" name="verify_is_us_citizen" id="verify_is_us_citizen-true"
                [value]="true" (change)="vf.verify_is_us_citizen.setValue(true)" [checked]="(vf.verify_is_us_citizen.value)" />
                <label class="custom-control-label" for="verify_is_us_citizen-true"> Yes </label>
              </div>
            </div>
            <div class="col-2">
              <div class="custom-control custom-radio custom-control-inline">
                <input formControlName="verify_is_us_citizen" class="custom-control-input" type="radio" name="verify_is_us_citizen" id="verify_is_us_citizen-false"
                [value]="false" (change)="vf.verify_is_us_citizen.setValue(false)" />
                <label class="custom-control-label" for="verify_is_us_citizen-false"> No </label>
              </div>
            </div>
          </div>
          <div *ngIf="vf.verify_is_us_citizen.value == false">
            <div class="col-6 mb-3">
              <label for="profile_citizenship">Please select Country of Citizenship <span class="star">*</span></label>
              <ng-select formControlName="profile_citizenship" bindValue="value" [clearable]="false" [searchable]="false"
                bindLabel="label" placeholder="Please select your racing nationality" [items]="countries" ></ng-select>
            </div>
          </div>

          <h5>I have a USA sporting nationality and do not compete for another International Federation <span class="star">*</span></h5>
          <p><em>All license-holders shall be assigned the sporting nationality corresponding to their nationality, regardless
              of the national federation that issues the license. This sporting nationality shall be assigned upon issuance of
              the first license. A rider holding multiple nationalities shall be required to choose between them when first
              applying for a license. *Learn more about Sporting Nationality here</em>
          </p>
          <div class="row mb-3">
            <div class="col-2 mb-2">
              <div class="custom-control custom-radio">
                <input formControlName="verify_is_usa_sporting_nationality" class="custom-control-input" type="radio"
                  name="verify_is_usa_sporting_nationality" id="verify_is_usa_sporting_nationality-true" [value]="true"
                  (change)="vf.verify_is_usa_sporting_nationality.setValue(true)" [checked]="(vf.verify_is_usa_sporting_nationality.value)" />
                <label class="custom-control-label" for="verify_is_usa_sporting_nationality-true"> Yes </label>
              </div>
            </div>
            <div class="col-2">
              <div class="custom-control custom-radio custom-control-inline">
                <input formControlName="verify_is_usa_sporting_nationality" class="custom-control-input" type="radio"
                  name="verify_is_usa_sporting_nationality" id="verify_is_usa_sporting_nationality-false" [value]="false"
                  (change)="vf.verify_is_usa_sporting_nationality.setValue(false)" />
                <label class="custom-control-label" for="verify_is_usa_sporting_nationality-false"> No </label>
              </div>
            </div>
          </div>
          <div *ngIf="vf.verify_is_usa_sporting_nationality.value == false">
            <div class="col-6 mb-3">
              <label for="profile_racing_nationality">Please select Racing Nationality <span class="star">*</span></label>
              <ng-select formControlName="profile_racing_nationality" bindValue="value" [clearable]="false" [searchable]="false"
                bindLabel="label" placeholder="Please select your racing nationality" [items]="countries"></ng-select>
            </div>
          </div>

          <h5>I verify that my birthdate listed above is accurate <span class="star">*</span></h5>
          <div class="row mb-3">
            <div class="col-2 mb-2">
              <div class="custom-control custom-radio">
                <input formControlName="verify_is_dob_accurate" class="custom-control-input" type="radio"
                  name="verify_is_dob_accurate" id="verify_is_dob_accurate-true" [value]="true"
                  (change)="vf.verify_is_dob_accurate.setValue(true)" [checked]="(vf.verify_is_dob_accurate.value)" />
                <label class="custom-control-label" for="verify_is_dob_accurate-true"> Yes </label>
              </div>
            </div>
            <div class="col-2">
              <div class="custom-control custom-radio custom-control-inline">
                <input formControlName="verify_is_dob_accurate" class="custom-control-input" type="radio"
                  name="verify_is_dob_accurate" id="verify_is_dob_accurate-false" [value]="false"
                  (change)="vf.verify_is_dob_accurate.setValue(false)" />
                <label class="custom-control-label" for="verify_is_dob_accurate-false"> No </label>
              </div>
            </div>
            <div class="col-6 text-center d-flex align-items-center" *ngIf="vf.verify_is_dob_accurate.value === false">
              <span class="text-danger">Please contact <a target="_blank" href="https://usacyclinghelp.zendesk.com/hc/en-us/requests/new">Member Services</a> to update this</span>
            </div>
          </div>

          <h5>I verify that my sex listed above is accurate <span class="star">*</span></h5>
          <div class="row mb-3">
            <div class="col-2 mb-2">
              <div class="custom-control custom-radio">
                <input formControlName="verify_is_sex_accurate" class="custom-control-input" type="radio"
                  name="verify_is_sex_accurate" id="verify_is_sex_accurate-true" [value]="true"
                  (change)="vf.verify_is_sex_accurate.setValue(true)" [checked]="(vf.verify_is_sex_accurate.value)" />
                <label class="custom-control-label" for="verify_is_sex_accurate-true"> Yes </label>
              </div>
            </div>
            <div class="col-2">
              <div class="custom-control custom-radio custom-control-inline">
                <input formControlName="verify_is_sex_accurate" class="custom-control-input" type="radio"
                  name="verify_is_sex_accurate" id="verify_is_sex_accurate-false" [value]="false"
                  (change)="vf.verify_is_sex_accurate.setValue(false)" />
                <label class="custom-control-label" for="verify_is_sex_accurate-false"> No </label>
              </div>
            </div>
            <div class="col-6 text-center d-flex align-items-center" *ngIf="vf.verify_is_sex_accurate.value === false">
              <span class="text-danger">Please contact <a target="_blank" href="https://usacyclinghelp.zendesk.com/hc/en-us/requests/new">Member Services</a> to update this</span>
            </div>
          </div>

          <!-- if a new junior have form component be added for parent to sign -->
          <div *ngIf="isJunior">
            <p>
              I hearby attest that my response and the information provided on this form are true,
              complete and accurate and I understand that this information may be used to verify my
              child's lawful presence under the USA Sporting Nationality.
            </p>
            <div class="row col-12">
              <div class="col-5 mr-2 row flex-column mb-2">
                <label for="guardian_name">Parent/Guardian Name <span class="star">*</span></label>
                <input formControlName="guardian_name" id="guardian_name" type="text" class="form-control"
                  placeholder="Jane Smith" />
              </div>
              <div class="col-5 mr-2 row flex-column">
                <label for="guardian_email">Parent/Guardian Email <span class="star">*</span></label>
                <input formControlName="guardian_email" id="guardian_email" type="email" class="form-control"
                  placeholder="janesmith@gmail.com"
                />
              </div>
              <div class="col-5 mr-2 row flex-column mb-2">
                <label for="guardian_email">Phone <span class="star">*</span></label>
                <input formControlName="guardian_phone" id="guardian_phone" type="tel" class="form-control" type="tel" placeholder="(303) 123-4567" appPhoneNumberFormatter/>
              </div>
              <div class="col-5 mr-2 row flex-column">
                <label for="guardian_sign_date">Todays Date <span class="star">*</span></label>
                <input formControlName="guardian_sign_date" id="guardian_sign_date" type="date" class="form-control" [min]="todaySignDate | date:'yyyy-MM-dd'"
                [max]="todaySignDate | date:'yyyy-MM-dd'"/>
              </div>
            </div>
          </div>
        </div>
      </form>


        <!-- Password: disable if profile already exists (user has a password) -->
        <ng-container *ngIf="passwordFields">
          <div class="form-card">
            <h3 class="form-title">Create Account Credentials</h3>
            <h4 class="form-subtitle">
              Create an account to access your admin panel and manage your membership.
            </h4>

            <!-- Password -->
            <div class="row">
              <div class="col-12 col-md-6">
                <label for="password">Password <span class="star">*</span></label>
                <input
                  formControlName="password"
                  id="password"
                  placeholder="Enter Password"
                  type="password"
                  class="form-control"
                  autocomplete="new-password"
                />
                <p
                  class="text-danger mb-0 pb-0"
                  *ngIf="memberForm.controls['password'].hasError('pattern')"
                >
                  Password must be minimum 6 characters long, and should contain at least one number
                  and letter.
                </p>
              </div>
              <div class="col-12 col-md-6">
                <label for="confirm-password">Confirm Password <span class="star">*</span></label>
                <input
                  formControlName="password_confirmation"
                  id="confirm-password"
                  type="password"
                  class="form-control"
                  placeholder="Re-enter Password"
                  autocomplete="new-password"
                />
              </div>
              <div
                *ngIf="
                  memberForm.hasError('confirmPassword') &&
                  (memberForm.touched || memberForm.dirty) &&
                  !memberForm.controls['password'].hasError('pattern')
                "
                class="col text-danger"
              >
                Passwords are not the same
              </div>
            </div>
          </div>
        </ng-container>

        <!-- Auto-renewal -->
        <div *ngIf="!isRaceFlow" class="d-flex align-items-center my-3">
          <input
            class="custom-checkbox mr-2"
            type="checkbox"
            name="is_auto_renewal"
            id="is_auto_renewal"
            formControlName="is_auto_renewal"
          />
          <label for="is_auto_renewal" class="font-weight-bold m-0 mr-1">
            <u>Automatic Renewal</u>:
            By checking this box, you agree that your annual membership will be automatically renewed each year
            by charging your card on file for the amount of your membership until you cancel. Your membership
            purchase is non-refundable. You may cancel automatic renewal at any time to stop future charges by
            visiting <a class="underline" [href]="adminUrl" target="_blank">My Account</a> to update your preferences.
          </label>
        </div>

        <div class="row">
          <div class="col-12">
            <ngb-alert *ngFor="let error of errors$ | async" type="danger" (close)="clearErrors()">
              <p class="text-capitalize m-0">{{ error }}</p>
            </ngb-alert>
          </div>

          <!-- Navigation Buttons -->
          <div *ngIf="addedJunior" class="col-12 col-sm-6 col-md-3 col-lg-2 offset-md-6 offset-lg-8 mb-2">
            <button
              id="back-btn"
              [disabled]="disableBack"
              class="btn btn-secondary btn-block"
              type="button"
              (click)="toRaceDetails()"
            >Back</button>
          </div>
          <div class="col-12 col-sm-6 col-md-3 col-lg-2"
            [ngClass]="{'offset-md-9 offset-lg-10': !addedJunior}"
          >
            <button
              id="submit-btn"
              [disabled]="disableContinue"
              class="btn btn-primary btn-block"
              type="submit"
            >
              <span *ngIf="isSubmitting" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              {{ submitBtnText }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <app-footer></app-footer>
</div>
