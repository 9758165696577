import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

import { AuthService } from '../core';
import { MembershipOption } from '../core/models/membership-option.model';

@Component({
  selector: 'app-individual-membership',
  templateUrl: './individual-membership.component.html',
  styleUrls: ['./individual-membership.component.scss'],
})
export class IndividualMembershipComponent implements OnInit {
  constructor(private route: ActivatedRoute, private authService: AuthService) {}

  memberships = [
    {
      membershipOption: MembershipOption.Ride,
      benefitsSubheader: 'RIDE',
      benefitsSubtext: 'Now Includes Spot Injury Insurance',
      benefitsInfoText:
        "Be a part of the nation's largest cycling club and ride with the assurance of $0 deductible bike accident medical coverage.",
      benefits: [
        'Includes $25,000 of injury insurance for cyclists',
        'Members-only access to local and national clubs, event benefits, and discounts',
      ],
      imageSrc: 'assets/images/home/reggie_ride_price_diagonal_480x480@2x.jpeg',
      btn1Text: 'GET RIDE MEMBERSHIP',
    },
    {
      membershipOption: MembershipOption.Race,
      benefitsSubheader: 'RACE',
      benefitsSubtext: 'Your Ticket to Bike Racing',
      benefitsInfoText:
        'A USA Cycling Race Membership gives you access to thousands of events and National Championships, plus Collegiate and International racing.',
      benefits: [
        'Unlimited road, mountain, track and cyclocross',
        'Members-only access, discounts, and benefits from top events and brands',
      ],
      imageSrc: 'assets/images/home/race_membership_price_diagonal_480x480@2x.png',
      btn1Text: 'GET RACE MEMBERSHIP',
      btn2Text: 'FREE FOR JUNIORS',
    },
    {
      membershipOption: MembershipOption.RacePlus,
      benefitsSubheader: 'RACE+',
      benefitsSubtext: 'Race Access AND Spot Injury Insurance',
      benefitsInfoText:
        'A Race+ Membership gets you access to thousands of sanctioned races and bike accident medical coverage while training or in sanctioned racing.',
      benefits: [
        'Includes $25,000 of injury insurance for cyclists',
        'Members-only access, discounts, and benefits from top events and brands',
      ],
      imageSrc: 'assets/images/home/race_plus_membership_price_diagonal_480x480@2x.png',
      btn1Text: 'GET RACE+ MEMBERSHIP',
      btn2Text: '$40 FOR JUNIORS',
    },
  ];

  ngOnInit(): void {
    const queryParams = this.route.snapshot.queryParams;

    if (queryParams.renew && (queryParams.renew === 'true' || queryParams.renew === '1')) {
      // window.location.replace(environment.publicUrl + '/enrollment/race/member-info?renew=true');
      return;
    }

    window.location.replace(this.shopifyStoreUrl);
  }

  login(): void {
    this.authService.doLogin(window.location.href);
  }

  get signupUrl(): string {
    return environment.adminUrl + '/signup';
  }

  // TODO: Update w/ store subdomain (if used)
  get shopifyStoreUrl(): string {
    return environment.shopifyStoreUrl;
  }

  visitShopifyStore(): void {
    window.location.assign(this.shopifyStoreUrl);
  }
}
