import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  NgbAccordionModule,
  NgbAlertModule,
  NgbDatepickerModule,
  NgbPopoverModule,
  NgbToastModule,
  NgbTooltipModule,
  // NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxCleaveDirectiveModule } from 'ngx-cleave-directive';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

import { StepProgressBarComponent } from '../enrollment/step-progress-bar/step-progress-bar.component';

import { CheckboxComponent } from './checkbox/checkbox.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { PhoneNumberFormatterDirective } from './directives';
import { EnrollmentInProccessModalComponent } from './enrollment-in-proccess-modal/enrollment-in-proccess-modal.component';
import { ExistingMembershipModalComponent } from './existing-membership-modal/existing-membership-modal.component';
import { ForgotPasswordModalComponent } from './forgot-password-modal/forgot-password-modal.component';
import { FormSubtitleComponent } from './form-subtitle/form-subtitle.component';
import { FormTitleComponent } from './form-title/form-title.component';
import { ImproveMembershipComponent } from './improve-membership/improve-membership.component';
import {
  AdminGhostModeComponent,
  CartComponent,
  FooterComponent,
  HeaderComponent,
  PremiumUpgradeCartComponent,
} from './layout';
import { LoaderComponent } from './loader/loader.component';
import { LoginModalContentComponent } from './login-modal-content/login-modal-content.component';
import { MatchingAccountModalComponent } from './matching-account-modal/matching-account-modal.component';
import { MemberStatusComponent } from './member-status/member-status.component';
import { ModalBodyComponent } from './modal-body/modal-body.component';
import { ModalFooterComponent } from './modal-footer/modal-footer.component';
import { ModalComponent } from './modal/modal.component';
import { NeedPremiumModalComponent } from './need-premium-modal/need-premium-modal.component';
import { NonEnrollmentWaiverModalComponent } from './non-enrollment-waiver-modal/non-enrollment-waiver-modal.component';
import { GenderPipe } from './pipes/gender.pipe';
import { HtmlPipe } from './pipes/html.pipe';
import { PlanPipe } from './pipes/plan.pipe';
import { PodiumPackageModalComponent } from './podium-package-modal/podium-package-modal.component';
import { ProductCollectionCardComponent } from './product-collection-card/product-collection-card.component';
import { RegisterModalComponent } from './register-modal/register-modal.component';
import { RemovePremiumModalComponent } from './remove-premium-modal/remove-premium-modal.component';
import { RenewMembershipModalComponent } from './renew-membership-modal/renew-membership-modal.component';
import { RestrictedUserModalComponent } from './restricted-user-modal/restricted-user-modal.component';
import { SegmentedDatepickerComponent } from './segmented-datepicker/segmented-datepicker.component';
import { ShowAuthedDirective } from './show-authed.directive';
import { SizeChartModalComponent } from './size-chart-modal/size-chart-modal.component';
import { StatusIndicatorHeaderComponent } from './status-indicator-header/status-indicator-header.component';
import { ToastComponent } from './toast/toast.component';
import { UpgradeCardComponent } from './upgrade-card/upgrade-card.component';
import { UpgradeToPremiumModalComponent } from './upgrade-to-premium-modal/upgrade-to-premium-modal.component';

const modals = [
  ConfirmationModalComponent,
  EnrollmentInProccessModalComponent,
  ExistingMembershipModalComponent,
  ForgotPasswordModalComponent,
  ImproveMembershipComponent,
  LoginModalContentComponent,
  MatchingAccountModalComponent,
  MemberStatusComponent,
  ModalComponent,
  NeedPremiumModalComponent,
  NonEnrollmentWaiverModalComponent,
  PodiumPackageModalComponent,
  RegisterModalComponent,
  RemovePremiumModalComponent,
  RenewMembershipModalComponent,
  RestrictedUserModalComponent,
  SizeChartModalComponent,
  UpgradeToPremiumModalComponent,
];

@NgModule({
  declarations: [
    CheckboxComponent,
    DatepickerComponent,
    ShowAuthedDirective,
    AdminGhostModeComponent,
    HeaderComponent,
    FooterComponent,
    StatusIndicatorHeaderComponent,
    FormSubtitleComponent,
    FormTitleComponent,
    CartComponent,
    PremiumUpgradeCartComponent,
    LoaderComponent,
    GenderPipe,
    PlanPipe,
    StepProgressBarComponent,
    HtmlPipe,
    PhoneNumberFormatterDirective,
    ToastComponent,
    UpgradeCardComponent,
    ModalComponent,
    ModalBodyComponent,
    ModalFooterComponent,
    ProductCollectionCardComponent,
    SegmentedDatepickerComponent,
    ...modals,
  ],
  imports: [
    NgSelectModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbAccordionModule,
    NgbAlertModule,
    NgbDatepickerModule,
    NgbPopoverModule,
    NgbToastModule,
    NgbTooltipModule,
    NgxTrimDirectiveModule,
    NgxCleaveDirectiveModule,
  ],
  exports: [
    ProductCollectionCardComponent,
    CommonModule,
    CheckboxComponent,
    DatepickerComponent,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ShowAuthedDirective,
    AdminGhostModeComponent,
    HeaderComponent,
    FooterComponent,
    StatusIndicatorHeaderComponent,
    FormSubtitleComponent,
    FormTitleComponent,
    CartComponent,
    PremiumUpgradeCartComponent,
    LoaderComponent,
    NgSelectModule,
    UpgradeToPremiumModalComponent,
    ImproveMembershipComponent,
    NgxTrimDirectiveModule,
    GenderPipe,
    PlanPipe,
    StepProgressBarComponent,
    ModalComponent,
    ModalBodyComponent,
    NgbAccordionModule,
    NgbToastModule,
    NgbTooltipModule,
    NgxCleaveDirectiveModule,
    PhoneNumberFormatterDirective,
    HtmlPipe,
    SegmentedDatepickerComponent,
    ToastComponent,
    UpgradeCardComponent,
  ],
})
export class SharedModule {}
