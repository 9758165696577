import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Profile } from 'src/app/core/models';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-upgrade-card',
  templateUrl: './upgrade-card.component.html',
  styleUrls: ['./upgrade-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UpgradeCardComponent {
  @Input() user: Profile;
  @Input() title: string;
  @Input() type: string;
  @Input() price: number;
  @Input() comparedPrice: number;
  @Input() perYear = true;
  @Input() benefits: any = [];
  @Input() autoRenew = false;
  @Input() hideAutoRenew = false;
  @Input() boldBorder = false;
  @Input() spotItem = false;
  @Input() sku: string;
  @Input() useModal: boolean;
  @Input() isCurrentMembership: boolean;
  @Input() isSelected: boolean;
  @Input() isDisabled: boolean;
  @Input() isEarlyRenewal: boolean;

  @Output() addToCartUpdate: EventEmitter<any> = new EventEmitter(null);
  @Output() removeFromCartUpdate: EventEmitter<any> = new EventEmitter(null);

  // addedToCart: boolean;
  upgradeData: any;
  openUpgradeConfirmation: boolean;

  get showPrice(): boolean {
    return this.price !== undefined && this.price !== null;
  }

  get btnClass(): string {
    return this.isSelected ? 'btn-secondary' : 'btn-primary';
    //   return this.addedToCart ? 'btn-primary' : 'btn-secondary';
  }

  get btnText(): string {
    return this.isCurrentMembership
      ? 'RENEW EARLY'
      : this.isSelected
        ? 'REMOVE'
        : 'Purchase Upgrade';
    //   return this.addedToCart ? 'Remove' : 'Purchase Upgrade';
  }

  get adminUrl(): string {
    return environment.adminUrl;
  }

  private addToCart(): void {
    const data = {
      title: this.title,
      type: this.type,
      sku: this.sku,
      is_auto_renewal: this.autoRenew,
    };

    if (this.spotItem) {
      Object.assign(data, { has_spot_insurance: true });
    }
    if (this.isSelected) {
      this.removeFromCartUpdate.emit(data);
    } else {
      this.addToCartUpdate.emit(data);
    }
  }

  addToCartOrOpenModal(): void {
    if (this.useModal) {
      // this.addedToCart = !this.addedToCart;
      this.openModal({ type: this.type, sku: this.sku, is_auto_renewal: this.autoRenew });
    } else {
      this.addToCart();
    }
  }

  toggleAutoRenew(): void {
    this.autoRenew = !this.autoRenew;
  }

  confirm(): void {
    this.openUpgradeConfirmation = false;
    setTimeout(() => this.addToCart(), 100);
  }

  openModal(data: any): void {
    this.upgradeData = data;
    this.openUpgradeConfirmation = true;
  }

  closeModal(): void {
    this.openUpgradeConfirmation = false;
    // this.addedToCart = !this.addedToCart;
  }
}
