<div class="segmented-datepicker form-group">
  <label *ngIf="label" for="{{name}}>">
    {{label}}
    <span *ngIf="required" class="required">*</span>
    <span *ngIf="iconClass" class="icon {{iconClass}}" [ngbTooltip]="tooltipContent">
      <ng-template #tipContent>{{tooltipContent}}</ng-template>
    </span>
  </label>
  <div class="row mr-0">
    <!-- Month -->
    <ng-select
      class="col-5"
      [ngClass]="{'is-invalid': formSubmitted && errors}"
      [name]="name"
      placeholder="Month"
      [(ngModel)]="monthValue"
      [items]="monthOptions"
      [clearable]="false"
      [searchable]="false"
      [disabled]="disabled"
    ></ng-select>
    <!-- Day -->
    <ng-select
      class="col-3"
      [ngClass]="{'is-invalid': formSubmitted && errors}"
      placeholder="Day"
      [(ngModel)]="dayValue"
      [items]="dayOptions"
      [clearable]="false"
      [searchable]="false"
      [disabled]="disabled || !monthValue"
    ></ng-select>
    <!-- Year -->
    <ng-select
      class="col-4"
      [ngClass]="{'is-invalid': formSubmitted && errors}"
      placeholder="Year"
      [(ngModel)]="yearValue"
      [items]="yearOptions"
      [clearable]="false"
      [searchable]="false"
      [disabled]="disabled"
    ></ng-select>
  </div>
  <div *ngIf="formSubmitted && errors" class="invalid-feedback">
    <div *ngIf="errors.required">Required</div>
  </div>
  <div *ngIf="outOfBounds" class="invalid-feedback">
    Date out of bounds.
  </div>

</div>
