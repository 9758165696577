import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

import { environment } from '../environments/environment';

import { AuthService, ProfileService, TokenService } from './core';
import { RegistrationFacade } from './core/facades';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private gtmService: GoogleTagManagerService,
    private profileService: ProfileService,
    private router: Router,
    private registrationFacade: RegistrationFacade,
    private tokenService: TokenService
  ) {}

  ngOnInit(): void {
    const token = this.tokenService.getToken();
    if (token) {
      this.handleDeprecatedCookies();
    }

    if (this.profileService.hasGhostModeCookie) {
      this.checkAccuracyOfFacades();
    }

    this.authService.populate();

    this.router.events.forEach((event: Event) => {
      if (event instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: this.routeTitle || environment.publicUrl + event.url,
        };
        this.gtmService.pushTag(gtmTag);
      }
    });
  }

  handleDeprecatedCookies(): void {
    this.authService.doLogout().subscribe((resp) => {
      if (resp) {
        this.tokenService.destroyToken();
        window.location.assign(
          environment.adminUrl + `/login?redirectUrl=${environment.publicUrl}`
        );
      }
    });
  }

  checkAccuracyOfFacades(): void {
    const ghostCookie = this.profileService.ghostModeCookieData;
    const facade = this.registrationFacade.currentState;

    if (facade.registration.email !== ghostCookie.profile_email) {
      this.authService.resetFacades();
    }
  }

  get routeTitle(): string {
    const currentRoute = this.router.routerState.snapshot.root;
    let child = currentRoute.firstChild;
    // For nested routing modules, drill down to the destination child route
    while (child) {
      if (child.firstChild) {
        child = child.firstChild;
      } else if (child.data && child.data.title) {
        return child.data.title;
      } else {
        return null;
      }
    }
    return null;
  }
}
