import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, concat, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  ClubsService,
  ProfileService,
  RaceGendersService,
  RegistrationService,
} from 'src/app/core';
import { Club, Profile, RaceGender, Registration } from 'src/app/core/models';

export interface Credentials {
  user_id: string;
  password: string;
}
@Component({
  selector: 'app-upgrade-info-modal',
  templateUrl: './upgrade-info-modal.component.html',
  styleUrls: ['./upgrade-info-modal.component.scss'],
})
export class UpgradeInfoModalComponent implements OnInit {
  @Input() title: string;
  @Input() type: string;
  @Input() sku: string;
  @Input() autoRenew: boolean;
  @Input() user: Profile;
  @Input() registration: Registration;
  @Input() isUpgrade = false;

  error?: string;
  isSubmitting = false;
  submitted = false;
  collegiateForm: FormGroup;
  internationalForm: FormGroup;
  internationalLicenseForm: FormGroup;
  raceGenderForm: FormGroup;
  preventFormUpdates = false;

  collegiateClubs$: Observable<Club[]>;
  collegiateClubsInput$ = new Subject<string>();
  collegiateClubsLoading = false;
  customCollegiateClubError = '';
  raceGenders: RaceGender[];

  get cf(): any {
    return this.collegiateForm.controls;
  }

  get is_collegiate_cyclist(): FormControl {
    return this.cf.is_collegiate_cyclist as FormControl;
  }

  get collegiateClub(): FormControl {
    return this.cf.collegiate_club as FormControl;
  }

  get collegiateClubId(): FormControl {
    return this.cf.collegiate_club_id as FormControl;
  }

  get ilf(): any {
    return this.internationalLicenseForm.controls;
  }

  get isCollegiate(): boolean {
    return this.type === 'collegiate';
  }

  get isInternational(): boolean {
    return this.type === 'international';
  }

  get isRide(): boolean {
    return this.type === 'ride-race-plus' || this.type === 'race-standard';
  }

  get missingRegistration(): boolean {
    return !this.registration && !this.isRide;
  }

  get disableSubmit(): boolean {
    return this.isSubmitting || (this.isCollegiate && !this.collegiateForm.valid);
  }

  get rg(): any {
    return this.raceGenderForm.controls;
  }

  constructor(
    private activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private clubsService: ClubsService,
    private profileService: ProfileService,
    private registrationService: RegistrationService,
    private raceGenderService: RaceGendersService
  ) {}

  ngOnInit(): void {
    if (this.isCollegiate) {
      this.createCollegiateForm();
      this.loadCollegiateClubs();
    } else if (this.isRide) {
      this.loadRaceGenders();
      this.createGenderForm();
    }
  }

  private loadCollegiateClubs(): void {
    this.collegiateClubs$ = concat(
      of([]), // default items
      this.collegiateClubsInput$.pipe(
        debounceTime(250),
        distinctUntilChanged(),
        tap(() => (this.collegiateClubsLoading = true)),
        switchMap((term: string) =>
          this.clubsService.queryCollegiateClubs(term).pipe(
            catchError(() => of([])), // empty list on error
            finalize(() => (this.collegiateClubsLoading = false))
          )
        )
      )
    );
  }

  private loadRaceGenders(): void {
    this.raceGenderService
      .getAll()
      .pipe(
        tap((raceGenders: RaceGender[]) => {
          this.raceGenders = raceGenders;
        })
      )
      .subscribe();
  }

  updateCollegiateForm(data: any): void {
    if (!this.preventFormUpdates) {
      if (data.club_id) {
        this.cf.collegiate_club_id.setValue(data.club_id);
        this.cf.is_collegiate_cyclist.setValue(true);
      }
    }
  }

  clearErrors(): void {
    this.error = null;
  }

  createGenderForm(): void {
    this.raceGenderForm = this.fb.group({
      profile_race_gender: ['', [Validators.required]],
      profile_id: this.user.profile_id,
      profile_first_name: this.user.profile_first_name,
      profile_last_name: this.user.profile_last_name,
      profile_email: this.user.profile_email,
      profile_sex: this.user.profile_sex,
      profile_birthdate: this.user.profile_birthdate,
      profile_username: this.user.profile_username,
    });
  }

  createCollegiateForm(): void {
    this.collegiateForm = this.fb.group({
      collegiate_club_id: ['', [Validators.required]],
      is_collegiate_cyclist: [false, [Validators.required]],
      collegiate_club: ['', [Validators.required]],
    });
  }

  updateInternationalLicenseForm($event: FormGroup): void {
    if (!this.preventFormUpdates) {
      this.internationalLicenseForm = $event;
    }
  }

  private updateRegistration(values: any): void {
    this.registrationService
      .updateMemberInfo(this.registration.id, values)
      .subscribe((updatedRegistration) => {
        this.registration = updatedRegistration;
        if (updatedRegistration) {
          this.activeModal.close(updatedRegistration);
        }
      });
  }

  onSubmit(): void {
    this.preventFormUpdates = true;
    this.submitted = true;
    this.isSubmitting = true;

    if (this.isUpgrade) {
      this.activeModal.close();
    }

    if (this.isCollegiate && this.collegiateForm.valid) {
      const formValue = this.collegiateForm.value;
      this.updateRegistration({
        is_collegiate_cyclist: formValue.is_collegiate_cyclist,
        collegiate_club_id: formValue.collegiate_club_id,
        is_auto_renewal: this.autoRenew,
      });
    } else if (this.isInternational && this.internationalLicenseForm.valid) {
      const formValue = this.internationalLicenseForm.value;
      const citizenship = formValue.racing_nationality?.value || null;

      this.updateRegistration({
        is_international: true,
        dual_citizen: formValue.dual_citizen,
        dual_citizen_country: formValue.dual_citizen_country?.value || null,
        permanent_resident: formValue.permanent_resident,
        citizenship,
        racing_nationality: citizenship,
        intl_bmx_category: formValue.intl_bmx_category,
        intl_license_level: formValue.intl_license_level?.value || null,
        is_auto_renewal: this.autoRenew,
      });
    } else if (this.isRide && this.raceGenderForm.valid) {
      this.profileService.updateProfile(this.raceGenderForm.value).subscribe((updatedProfile) => {
        if (updatedProfile) {
          this.activeModal.close();
        }
      });
    } else {
      this.preventFormUpdates = false;
    }
  }

  dismissModal(): void {
    this.activeModal.dismiss();
  }
}
