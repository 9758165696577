<app-header [cartHidden]="false"></app-header>

<app-loader *ngIf="isLoading" [overlay]="true"></app-loader>

<div class="container">
  <div class="row">
    <div class="col-12 col-md-10 offset-md-1 col-lg-9 offset-lg-0">
      <!-- Member Status Indicator -->
      <app-status-indicator-header *ngIf="userLoaded"
        [user]="user"
        [firstName]="user.profile_first_name"
        [lastName]="user.profile_last_name"
        [email]="user.profile_email"
        [allMemberships]="allMemberships"
        [loading]="membershipsLoading"
      ></app-status-indicator-header>
      <hr>
    </div>
    <div class="col-12 col-md-10 offset-md-1 col-lg-9 offset-lg-0">
      <!-- Step Progress Bar -->
      <app-step-progress-bar
        [currentStep]="currentStep"
        [nextStep]="Step.Checkout"
        [isRace]="true"
        [isFamily]="isFamily"
      ></app-step-progress-bar>
    </div>

    <div class="col-12 offset-md-1 col-md-10 offset-lg-0 col-lg-9 p-3">
      <app-form-title>{{ title }}</app-form-title>
      <!-- <app-form-subtitle>
        *** We should probably add a description here with instructions ***
      </app-form-subtitle> -->

      <p class="form-info">Required fields marked with <span class="star">*</span></p>

      <div *ngIf="!registration">
        <app-loader></app-loader>
      </div>

      <div *ngIf="registration" class="membership-form m-0">
        <!-- Gender & Contact w/ Junior Athletes -->
        <app-gender-and-regular-contact *ngIf="addedJunior"
          [registration]="registration"
          [isAdult]="false"
          [isSubmitting]="isSubmitting"
          (formValues)="updateFormValues('genderRegularContactForm', $event)"
        ></app-gender-and-regular-contact>

        <!-- Race Participation, Disciplines, & Domestic Club -->
        <app-interests *ngIf="registration"
          [registration]="registration"
          [showRaceRide]="false"
          [isSubmitting]="isSubmitting"
          (formValues)="updateFormValues('interestsForm', $event)"
        ></app-interests>

        <!-- Race license info, if user participates in events -->
        <app-race-license-details *ngIf="registration"
          [registration]="registration"
          [isSubmitting]="isSubmitting"
          [raceSelected]="true"
          [formInvalid]="formsInvalid"
          (registrationUpdate)="updateRegistration($event)"
        ></app-race-license-details>

        <!-- Auto-renewal -->
        <form *ngIf="showAutoRenewal" [formGroup]="autoRenewalForm">
          <div class="d-flex align-items-center my-3">
            <input
              class="custom-checkbox mr-2"
              type="checkbox"
              name="is_auto_renewal"
              id="is_auto_renewal"
              formControlName="is_auto_renewal"
            />
            <label for="is_auto_renewal" class="font-weight-bold m-0 mr-1">
              <u>Automatic Renewal</u>:
              By checking this box, you agree that your annual membership will be automatically renewed each year
              by charging your card on file for the amount of your membership until you cancel. Your membership
              purchase is non-refundable. You may cancel automatic renewal at any time to stop future charges by
              visiting <a class="underline" [href]="adminUrl" target="_blank">My Account</a> to update your preferences.
            </label>
          </div>
        </form>


        <div class="row">
          <div class="col-12">
            <ngb-alert *ngFor="let error of errors$ | async" type="danger" (close)="clearErrors()">
              <p class="text-capitalize m-0">{{ error }}</p>
            </ngb-alert>
          </div>

          <!-- Navigation Buttons -->
          <div class="col-12 col-sm-6 col-md-3 col-lg-2 offset-md-6 offset-lg-8 mb-2">
            <button
              id="back-btn"
              [disabled]="disableBack"
              class="btn btn-secondary btn-block"
              type="button"
              (click)="back()"
            >Back</button>
          </div>
          <div class="col-12 col-sm-6 col-md-3 col-lg-2">
            <button
              id="submit-btn"
              [disabled]="disableContinue"
              class="btn btn-primary btn-block"
              type="button"
              (click)="onSubmit()"
            >
              <span *ngIf="isSubmitting" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              {{ submitBtnText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
