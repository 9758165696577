<div *ngIf="!overlay" class="row">
  <div class="col-12 text-{{align}}">
    <div *ngIf="!small" class="loader mt-4">
      <span class="spinner-border"></span>
      <p class="mt-1">
        <strong>{{text}}...</strong>
      </p>
    </div>

    <div *ngIf="small" class="loader loader-sm mt-3">
        <span class="spinner-border"></span>
        <span class="ml-2">
          <strong>{{text}}...</strong>
        </span>
    </div>
  </div>
</div>

<div *ngIf="overlay" class="loader loader-overlay mt-4 text-{{align}}" [class.light]="light">
  <div class="overlay-center">
    <span class="spinner-border"></span>
    <p class="loader-overlay-text mt-1">
      <strong>{{text}}...</strong>
    </p>
  </div>
</div>
