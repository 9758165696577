import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/core';
import { AddOnsFacade, Plans, RegistrationFacade } from 'src/app/core/facades';
import {
  Membership,
  MembershipStep,
  PersonTypes,
  Profile,
  Registration,
} from 'src/app/core/models';
import { LicenseService, ProfileService, RegistrationService } from 'src/app/core/services';
import { environment } from 'src/environments/environment';

import { LicenseCreateFeeModalComponent } from '../license-create-fee-modal/license-create-fee-modal.component';

@Component({
  selector: 'app-admin-membership-payment-step',
  templateUrl: './admin-membership-payment-step.component.html',
  styleUrls: ['./admin-membership-payment-step.component.scss'],
})
export class AdminMembershipPaymentStepComponent implements OnInit, OnDestroy {
  registrationData: any;
  user: Profile;

  registrationId: string;
  registration: Registration;
  isAuthenticated = false;

  isLoading = true;
  userLoaded = false;
  membershipsLoading = true;
  isNavigating = false;
  isSubmitting = false;
  errors$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  addedJunior: boolean;
  allMemberships: Membership[];
  addToCart = false;
  cartItems: any[] = [];
  hasSpotInsurance: boolean;

  loading = true;

  racePlusBenefits = ['Get $0 deductible on-the-bike accident coverage for just $50/year'];

  get Step(): typeof MembershipStep {
    return MembershipStep;
  }

  get isJunior(): boolean {
    return this.registration && this.registration.person_type === PersonTypes.JUNIOR;
  }

  get isAdult(): boolean {
    return this.registration && this.registration.person_type === PersonTypes.ADULT;
  }

  get isFamily(): boolean {
    return this.registration && this.registration.is_family;
  }

  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private addOnsFacade: AddOnsFacade,
    private authService: AuthService,
    private profileService: ProfileService,
    private licenseService: LicenseService,
    private registrationFacade: RegistrationFacade,
    private registrationService: RegistrationService,
    private route: ActivatedRoute,
    private router: Router,
    private modal: NgbModal
  ) {
    this.registrationId = this.registrationFacade.registrationId;
    this.registrationData = this.registrationFacade.registration$;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    const snapshot = this.route.snapshot;
    const routeData = snapshot.data;
    this.addedJunior = !!routeData.addedJunior;

    this.authService.isAuthenticated$.subscribe((isAuthenticated) => {
      this.isAuthenticated = isAuthenticated;
    });

    this.authService.currentUser$
      .pipe(
        tap((profile: Profile) => {
          if (!profile) {
            return;
          }
          this.user = profile;
          if (profile.profile_id) {
            this.profileService
              .getMemberships(profile.profile_id)
              .subscribe((memberships: Membership[]) => {
                if (memberships) {
                  this.allMemberships = memberships;
                }
                this.membershipsLoading = false;
              });
          }
          this.userLoaded = true;
        })
      )
      .subscribe();

    this.loadRegistration();
  }

  private loadRegistration(): void {
    const registrationId = this.registrationFacade.registrationId;
    if (registrationId) {
      this.registrationId = registrationId;
      this.registrationService
        .getRegistration(registrationId, false)
        .subscribe((registration: Registration) => {
          if (registration) {
            this.setRegistrationAndFormValues(registration);
          } else {
            this.getRegistrationByProfile();
          }
        });
    } else {
      this.getRegistrationByProfile();
    }
  }

  private getRegistrationByProfile(): void {
    // If registration removed from local storage, retrieve latest enrollment using authed user's profile ID
    if (this.user) {
      this.registrationService
        .getRegistrationByProfile(this.user.profile_id)
        .pipe(
          tap((reg: Registration) => {
            this.setRegistrationAndFormValues(reg);
          }),
          catchError((err) => {
            // Registration not found - log out and return to intro form
            this.authService.doLogout();
            return throwError(err);
          })
        )
        .subscribe();
    }
  }

  private setRegistrationAndFormValues(registration: Registration): void {
    this.registration = registration;
    this.updateEnrollmentId(registration);
    this.registrationFacade.updateRegistration(registration);
    this.registrationFacade.updatePlan(registration.is_family ? Plans.FAMILY : Plans.INDIVIDUAL);
    this.hasSpotInsurance = this.registration.has_spot_insurance;
    this.isLoading = false;
  }

  private updateEnrollmentId(registration: Registration): void {
    if (this.isAdult) {
      this.addOnsFacade.updateAdultEnrollmentId(registration.id);
    } else if (this.isJunior) {
      this.addOnsFacade.updateJuniorEnrollmentId(registration.id);
    }
  }

  handleCheckout(is_paid: boolean): void {
    const payload = {
      is_paid,
      enrollment_id: this.registration.id,
    };

    this.isLoading = true;

    this.licenseService.createAdminCreatedMembership(payload).subscribe((resp) => {
      if (resp) {
        const modal = this.modal.open(LicenseCreateFeeModalComponent, {
          centered: true,
          size: 'md',
        });

        modal.componentInstance.profile = this.user;
        modal.componentInstance.isPaid = is_paid;
        modal.componentInstance.draftOrderLink =
          environment.shopifyAdminUrl + `draft_orders/${resp.draft_order.id}`;

        modal.result.catch(() => {
          window.location.assign(environment.adminUrl + `/profile/${this.user.profile_id}`);
        });
      }
      this.isLoading = false;
    });
  }

  back(): void {
    this.toRaceDetails();
  }

  private toRaceDetails(): void {
    const route = this.addedJunior
      ? ['enrollment', 'race', 'junior-details']
      : ['enrollment', 'race', 'details'];
    this.router.navigate(route);
  }

  get currentStep(): MembershipStep {
    return MembershipStep.Checkout;
  }

  get nextStep(): MembershipStep {
    return MembershipStep.PostPurchase;
  }
}
