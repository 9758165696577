<div class="progressbar">
  <ul class="stepper d-flex justify-content-between align-items-center">
    <li class="stepper__list-item">
      <span class="stepper__link disabled" [ngClass]="{
          'stepper__link--active': activeStep(Step.MemberInfo),
          checked: memberInfoChecked
        }">
        <div class="stepper__circle"></div>
        Member Info
      </span>
    </li>
    <li class="stepper__list-item" *ngIf="isRace">
      <span class="stepper__link disabled" [ngClass]="{
          'stepper__link--active': activeStep(Step.SelectOptions),
          checked: selectOptionsChecked
        }">
        <div class="stepper__circle"></div>
        Select Options
      </span>
    </li>
    <li class="stepper__list-item" *ngIf="isRace">
      <span class="stepper__link disabled" [ngClass]="{
          'stepper__link--active': activeStep(Step.UpgradeOptions),
          checked: upgradeOptionsChecked
        }">
        <div class="stepper__circle"></div>
        Upgrade Options
      </span>
    </li>
    <li class="stepper__list-item" *ngIf="isRace">
      <span class="stepper__link disabled" [ngClass]="{
          'stepper__link--active': activeStep(Step.RaceDetails),
          checked: raceDetailsChecked
        }">
        <div class="stepper__circle"></div>
        Race Details
      </span>
    </li>
    <li class="stepper__list-item" *ngIf="isFamily">
      <span class="stepper__link disabled" [ngClass]="{
          'stepper__link--active': activeStep(Step.JuniorMember),
          checked: activeStep(Step.PostPurchase)
        }">
        <div class="stepper__circle"></div>
        Junior Member
      </span>
    </li>
    <!-- <li class="stepper__list-item">
      <span class="stepper__link disabled" [ngClass]="{
          'stepper__link--active': adultOrJuniorSpotInsuranceActive,
          checked: activeStep(Step.PostPurchase)
        }">
        <div class="stepper__circle"></div>
        Spot Insurance
      </span>
    </li> -->
    <li class="stepper__list-item">
      <span class="stepper__link disabled" [ngClass]="{
          'stepper__link--active': activeStep(Step.Checkout),
          checked: activeStep(Step.PostPurchase)
        }">
        <div class="stepper__circle"></div>
        Checkout
      </span>
    </li>
    <li class="stepper__list-item">
      <span class="stepper__link content-none disabled" [ngClass]="{
          checked: activeStep(Step.PostPurchase)
        }">
        <div class="stepper__circle"></div>
        Complete
      </span>
    </li>
  </ul>
  
  <div class="behind">
    <div class="progress">
      <div class="progress-bar" role="progressbar" [style.width.%]="percentage" attr.aria-valuenow="{{percentage}}"
        aria-valuemin="0" aria-valuemax="100">
      </div>
    </div>
  </div>
</div>
