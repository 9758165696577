import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Country, Gender, MemberInfo, Profile, SelectOption } from 'src/app/core/models';
import { nameRegex, passwordRegex } from 'src/app/shared/regexes';
import { confirmPasswordValidator } from 'src/app/shared/validators';

import { AuthService, CountryService, GendersService, ProfileService } from '../../core';

export interface Credentials {
  email: string;
  password: string;
}
@Component({
  selector: 'app-register-modal',
  templateUrl: './register-modal.component.html',
  styleUrls: ['./register-modal.component.scss'],
})
export class RegisterModalComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$: Subject<void> = new Subject<void>();
  errors$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  isSubmitting = false;
  showStateInput: boolean;
  showProvinceInput: boolean;
  authForm: FormGroup;

  maxDate: NgbDate;
  minDate: NgbDate;

  maxDateString = '';
  minDateString = '1900-01-01';

  birthdayErrorMessage = '';

  genders$: Observable<Gender[]> = this.loadGenders();
  gendersLoading = true;

  provinces: SelectOption[] = [{ value: '', label: 'Select...' }];
  countriesLoading = true;
  country: Country;

  get f(): any {
    return this.authForm.controls;
  }

  get email(): FormControl {
    return this.f.profile_email;
  }

  get password(): FormControl {
    return this.f.password;
  }

  constructor(
    private activeModal: NgbActiveModal,
    private calendar: NgbCalendar,
    private fb: FormBuilder,
    private gendersService: GendersService,
    private countryService: CountryService,
    private profileService: ProfileService,
    private authService: AuthService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.setMinMaxDates();
    this.createAuthForm();
  }

  ngOnInit(): void {
    this.subscribeServiceToForm();
    if (!this.countryService.countries.length) {
      this.countryService.getShopifyCountries().subscribe(() => {
        this.countriesLoading = false;
        this.f.address_country_code.setValue('US');
      });
    } else {
      this.countriesLoading = false;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setMinMaxDates(): void {
    const maxDate = this.calendar.getToday();
    maxDate.year -= 6;
    this.maxDate = maxDate;
    this.maxDateString = [
      maxDate.year,
      this.padDatePart(maxDate.month),
      this.padDatePart(maxDate.day),
    ].join('-');
    this.minDate = new NgbDate(1900, 1, 1);
  }

  private padDatePart(part: number): string {
    return part.toString().padStart(2, '0');
  }

  private loadGenders(): Observable<Gender[]> {
    this.gendersLoading = true;
    return this.gendersService.getAll().pipe(
      catchError((err: unknown) => {
        return throwError(err);
      }),

      finalize(() => {
        this.gendersLoading = false;
      })
    );
  }

  get countries(): SelectOption[] {
    return this.countryService.countryOptions;
  }

  get disableSubmit(): boolean {
    return this.authForm.invalid || this.isSubmitting;
  }

  get birthdate(): string {
    return this.f.profile_birthdate.value;
  }

  get formattedBirthdate(): string {
    return moment(this.birthdate).format('YYYY-MM-DD');
  }

  private validateBirthdate(): void {
    if (!this.birthdate || this.birthdate.length < 10 || this.birthdate === 'Invalid date') {
      return;
    }

    const birthdate = this.formattedBirthdate;

    if (birthdate > this.maxDateString || birthdate < this.minDateString) {
      this.birthdayErrorMessage = 'Date out of bounds.';
      this.f.profile_birthdate.setErrors({ incorrect: true });
    } else {
      this.f.profile_birthdate.setErrors();
      this.birthdayErrorMessage = '';
    }
  }

  clearErrors(): void {
    this.errors$.next([]);
  }

  createAuthForm(): void {
    this.authForm = this.fb.group(
      {
        profile_first_name: ['', [Validators.required, Validators.pattern(nameRegex)]],
        profile_last_name: ['', [Validators.required, Validators.pattern(nameRegex)]],
        profile_email: ['', [Validators.required, Validators.email]],
        profile_birthdate: ['', Validators.required],
        contact_phone_1: [''],
        profile_sex: [null, Validators.required],
        address_street: [null, Validators.required],
        address_street2: [null],
        address_city: [null, Validators.required],
        address_state: [''],
        address_province: [''],
        address_postal_code: [null, Validators.required],
        address_country: [null, Validators.required],
        address_country_code: ['', Validators.required],
        password: ['', [Validators.required, Validators.pattern(passwordRegex)]],
        password_confirmation: ['', Validators.required],
      },
      { validators: [confirmPasswordValidator] }
    );

    // Watch for changes in birthday to show an error message
    this.f.profile_birthdate.valueChanges.subscribe(() => this.validateBirthdate());
  }

  subscribeServiceToForm(): void {
    this.f.address_country_code.valueChanges.subscribe((val: string) => {
      if (val) {
        const country = this.countryService.countries.find((c) => c.code === val);
        if (country) {
          this.country = country;
          this.f.address_country.setValue(country.name);
          this.setStateorProvinceDisplayAndValidation();
          if (country.provinces) {
            const provinceOptions = country.provinces.map((p) => ({
              value: p.code,
              label: p.name,
            }));
            provinceOptions.unshift({ value: '', label: 'Select...' });
            this.provinces = provinceOptions;
          } else {
            this.provinces = [{ value: '', label: 'Select...' }];
          }
        }
      }
    });
  }

  onSubmit(): void {
    const profileData = this.authForm.value;

    if (this.authForm.valid) {
      this.isSubmitting = true;
      this.clearErrors();
      this.register(profileData).subscribe();
    } else {
      this.authForm.markAllAsTouched();
    }
  }

  closeModal(): void {
    this.activeModal.close();
  }

  private register(profileData: MemberInfo): Observable<unknown> {
    return this.recaptchaV3Service.execute('signup').pipe(
      switchMap((recaptcha_response: string) => {
        profileData.recaptcha_response = recaptcha_response;
        return this.profileService.createProfile(profileData, true).pipe(
          tap((profile: Profile) => {
            this.closeModal();
            this.authService.storeAuth(profile);
          }),
          catchError((err) => {
            const errors = err.errors || {};
            Object.values(errors).forEach((e: any) => {
              this.errors$.next(this.errors$.getValue().concat([e]));
            });
            this.isSubmitting = false;
            return throwError(err);
          }),
          finalize(() => {
            this.isSubmitting = false;
          }),
          takeUntil(this.unsubscribe$)
        );
      })
    );
  }

  setStateorProvinceDisplayAndValidation(): void {
    if (this.f.address_country_code.value === 'US') {
      this.showStateInput = true;
      this.showProvinceInput = false;
      this.requireField(this.f.address_state);
      this.unrequireField(this.f.address_province);
    } else if (this.country.provinces) {
      this.showProvinceInput = true;
      this.showStateInput = false;
      this.requireField(this.f.address_province);
      this.unrequireField(this.f.address_state);
    } else {
      this.showProvinceInput = false;
      this.showStateInput = false;
      this.unrequireField(this.f.address_state);
      this.unrequireField(this.f.address_province);
    }
  }

  private requireField(control: FormControl): void {
    control.setValidators(Validators.required);
    control.updateValueAndValidity();
  }

  private unrequireField(control: FormControl): void {
    control.clearValidators();
    control.updateValueAndValidity();
  }
}
