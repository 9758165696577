import { Category } from './category.model';

export interface Discipline {
  /**
   * Category Discipline ID (cd_id in the database)
   */
  id: number;
  cd_id?: number;

  /**
   * Human-readable discipline name
   */
  name: string;

  /**
   * Underlying collegiate categories that can be selected in this discipline
   */
  collegiateCategories: Category[];

  /**
   * Underlying non-collegiate categories that can be selected in this discipline
   */
  nonCollegiateCategories: Category[];
}

export enum DisciplineId {
  Road = 19,
  Track = 20,
  BmxRace = 22,
  Cyclocross = 23,
  BmxFreestyle = 30,
  MtbCx = 31,
  MtbGravity = 32,
}
