import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, combineLatest } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { WaiverService } from 'src/app/core';
import {
  AppFacade,
  AppState,
  FamilyMembershipFacade,
  RegistrationFacade,
} from 'src/app/core/facades';
import { FamilyMemberInfoType, Guardian, PersonTypes, Waiver } from 'src/app/core/models';
import { RegistrationService } from 'src/app/core/services/registration.service';

@Component({
  selector: 'app-agreement-modal',
  templateUrl: './agreement-modal.component.html',
  styleUrls: ['./agreement-modal.component.scss'],
})
export class AgreementModalComponent implements OnInit, OnDestroy {
  constructor(
    private fb: FormBuilder,
    private registrationFacade: RegistrationFacade,
    private registrationService: RegistrationService,
    private familyFacade: FamilyMembershipFacade,
    public activeModal: NgbActiveModal,
    private waiverService: WaiverService,
    // private router: Router,
    private appFacade: AppFacade
  ) {
    this.createAgreementForm();
  }

  get f(): any {
    return this.agreementForm.controls;
  }

  get registrationId(): string {
    return this.memberInfo && this.memberInfo.id;
  }

  get codeOfConduct(): string {
    return this.waiverService.codeOfConduct;
  }

  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  agreementForm: FormGroup;
  memberInfo: any;
  fullNameError = false;
  buttonDisabled = false;
  isSubmitting = false;
  vm$: Observable<{ waiver: Waiver; app: AppState }>;
  personTypes = PersonTypes;
  guardian?: Guardian;

  compareNames(name: string, firstName: string, lastName: string): void {
    let nameIsMatch: boolean;

    // If there is a guardian, check their name instead
    if (this.guardian) {
      nameIsMatch =
        name.localeCompare(`${this.guardian.first_name} ${this.guardian.last_name}`, undefined, {
          sensitivity: 'accent',
        }) === 0;
    } else {
      // MBR-2900
      nameIsMatch = firstName.length > 0 || lastName.length > 0;
      // nameIsMatch =
      //   name.localeCompare(`${firstName} ${lastName}`, undefined, {
      //     sensitivity: 'accent',
      //   }) === 0;
    }

    if (!nameIsMatch) {
      this.fullNameError = true;
      this.buttonDisabled = true;
    } else {
      this.buttonDisabled = false;
      this.fullNameError = false;
    }
  }

  signWaiver(waiver: Waiver, registrationId: string): void {
    if (this.agreementForm.valid) {
      this.isSubmitting = true;

      let observable: Observable<any>;
      const payload = {
        waiver_id: waiver.waiver_id,
        waiver_name: waiver.waiver_name,
        code_of_conduct_agreed: this.f.codeOfConductAgreed.value,
      };

      if (this.guardian) {
        observable = this.registrationService.signEnrollmentWaiverAsGuardian(registrationId, {
          ...payload,
          full_name: `${this.memberInfo.first_name} ${this.memberInfo.last_name}`,
          legal_guardian_full_name: this.f.fullName.value,
          signer_email: this.guardian.email,
          relationship: this.guardian.relationship,
        });
      } else {
        observable = this.registrationService.signEnrollmentWaiver(registrationId, {
          ...payload,
          full_name: this.f.fullName.value,
          signer_email: this.memberInfo.email,
        });
      }

      observable
        .pipe(
          tap(() => {
            if (this.registrationFacade.isFamilyPlan()) {
              this.familyFacade.updateInfoFilled(FamilyMemberInfoType.waiver);
            }
            this.activeModal.close();
          }),
          takeUntil(this.unsubscribe$)
        )
        .subscribe();
    } else {
      this.agreementForm.markAllAsTouched();
    }
  }

  ngOnInit(): void {
    this.memberInfo = this.registrationFacade.isFamilyPlan()
      ? this.familyFacade.selectedMember
      : this.registrationFacade.registration;
    this.vm$ = combineLatest([
      this.registrationService.getEnrollmentWaiver(this.registrationId),
      this.appFacade.vm$,
    ]).pipe(
      map(([waiver, app]: [Waiver, AppState]) => {
        return { waiver, app };
      }),
      takeUntil(this.unsubscribe$)
    );
  }

  private createAgreementForm(): void {
    this.agreementForm = this.fb.group({
      fullName: ['', Validators.required],
      agreed: [false, Validators.requiredTrue],
      codeOfConductAgreed: [false, Validators.requiredTrue],
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
