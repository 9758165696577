import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Interests, MemberInfo } from '..';
import { FamilyMembershipFacade, Plans, RegistrationFacade } from '../facades';
import { GenderCode, Profile, ProfileData } from '../models';
import { Registration, RegistrationCreateResponse } from '../models/registration.model';
import { Waiver } from '../models/waiver.model';

import { ApiService } from './api.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private registrationFacade: RegistrationFacade,
    private familyFacade: FamilyMembershipFacade
  ) { }

  isEarlyRenewalMonthForInternational(): Observable<string> {
    return this.apiService.get<string>('/early-renewal-month-for-international').pipe(
      tap((month: any) => month)
    );
  }

  getRegistration(id: string, updateFacade = true): Observable<Registration> {
    return this.apiService.get<Registration>(`/registration/${id}`).pipe(
      map((registration: Registration) => {
        if (updateFacade) {
          this.updateFacadeFromRegistration(registration);
        }
        return registration;
      })
    );
  }

  private updateFacadeFromRegistration(registration: Registration): Registration {
    const currentState = this.registrationFacade.currentState;

    this.checkIfDomesticCyclist(registration);
    this.formatBirthdate(registration);

    this.registrationFacade.updateState({
      ...currentState,
      registration: {
        email: registration.email,
        id: registration.id,
        first_name: registration.first_name,
        last_name: registration.last_name,
        person_type: registration.person_type,
        gender: registration.gender,
        phone_number: registration.phone_number,
        birthdate: registration.birthdate,
        address_street: registration.address_street,
        address_street2: registration.address_street2,
        city: registration.city,
        state: registration.state,
        zip: registration.zip,
        has_spot_insurance: registration.has_spot_insurance,
      },
    });

    if (this.registrationFacade.isFamilyPlan()) {
      const selectedFamily = this.familyFacade.selectedMember;

      if (selectedFamily && !selectedFamily.id) {
        this.registrationFacade.updateState({
          ...currentState,
          plan: registration.membership_type === 1 ? Plans.INDIVIDUAL : Plans.FAMILY,
          orderId: registration.order?.order_id,
          registration: {
            id: '', // Id
            first_name: selectedFamily.first_name,
            last_name: selectedFamily.last_name,
            email: '', // Email
            person_type: selectedFamily.person_type,
            gender: '', // Gender
            phone_number: '',
            birthdate: '',
            address_street: '',
            address_street2: '',
            city: '',
            state: '',
            zip: '',
          },
        });

        registration = null;
      }
    }

    return registration;
  }

  private formatBirthdate(registration: Registration): void {
    const [year, month, date] = registration.birthdate.split('-');
    registration.birthdate = this.formatDate(
      new Date(Number(year), Number(month) - 1, Number(date))
    );
  }

  private checkIfDomesticCyclist(registration: Registration): void {
    if (!registration.is_domestic_cyclist) {
      registration.is_domestic_cyclist = false;
    }
  }

  registrationCreateFromLogin(
    profileId: number,
    password: string
  ): Observable<RegistrationCreateResponse> {
    const payload = {
      profileId,
      password,
    };

    return this.apiService
      .post<RegistrationCreateResponse>('/registration/createFromLogin', payload)
      .pipe(
        tap((registration: any) => {
          this.registrationFacade.updateFromProfile(
            '',
            registration.enrollment.first_name,
            registration.enrollment.last_name,
            registration.enrollment.email,
            registration.enrollment.person_type,
            registration.enrollment.gender,
            1,
            ''
          );
        })
      );
  }

  registrationCreateFromProfile(
    profileId: number,
    payload = {}
  ): Observable<RegistrationCreateResponse> {
    return this.apiService
      .post<RegistrationCreateResponse>('/registration/createFromProfile/' + profileId, payload)
      .pipe(
        tap((registration: any) => {
          this.updateFacadeFromRegistration(registration.enrollment);
          this.registrationFacade.updateFromProfile(
            registration.enrollment.id,
            registration.enrollment.first_name,
            registration.enrollment.last_name,
            registration.enrollment.email,
            registration.enrollment.person_type,
            registration.enrollment.gender,
            registration.enrollment.is_family ? 2 : 1,
            registration.enrollment.order?.order_id
          );
        })
      );
  }

  getRegistrationByEmail(email: string): Observable<Registration> {
    const params: HttpParams = new HttpParams().set('email', email);

    return this.apiService.get<Registration>('/registration/search', params).pipe(
      tap((registration: Registration) => {
        this.partialUpdateRegistration(registration);
      })
    );
  }

  getRegistrationByProfile(profileId: number): Observable<Registration> {
    return this.apiService
      .get<Registration>(`/registration/profile/${profileId}`)
      .pipe(map((registration: Registration) => this.updateFacadeFromRegistration(registration)));
  }

  profileDataFromMemberInfo(memberInfo: MemberInfo): ProfileData {
    return {
      profile_first_name: memberInfo.first_name,
      profile_last_name: memberInfo.last_name,
      profile_email: memberInfo.email,
      profile_password: memberInfo.password,
      profile_birthdate: memberInfo.birthdate,
    };
  }

  createProfile(memberInfo: MemberInfo, login = false): Observable<Profile> {
    const profileData = {
      profile_first_name: memberInfo.first_name,
      profile_last_name: memberInfo.last_name,
      profile_email: memberInfo.email,
      profile_password: memberInfo.password,
      profile_birthdate: memberInfo.birthdate,
      profile_sex: memberInfo.gender,
      login: login ? 'yes' : null,
    };

    return this.apiService.post<Profile>('/profile', profileData).pipe(
      tap((profile: Profile) => {
        console.log('Created profile');
        if (login) {
          this.authService.storeAuth(profile);
        }
      })
    );
  }

  // TODO: Update method signature/interface and connect with latest "in_one_go" endpoint
  createFullRegistration(
    memberInfo: MemberInfo,
    interests: Interests,
    raceDetails: { hasRegularContact: boolean; raceGender: GenderCode },
    categories: any[]
  ): Observable<Registration> {
    const payload = {
      memberInfo,
      interests,
      raceDetails,
      categories,
    };

    return this.apiService.post<Registration>('/registration/in_one_go', payload).pipe(
      tap((registration: Registration) => {
        this.partialUpdateRegistration(registration);
      })
    );
  }

  createRegistrationAndLogin(memberInfo: MemberInfo): Observable<Registration> {
    Object.assign(memberInfo, { login: 'yes' });

    return this.apiService.post<Registration>('/registration', memberInfo).pipe(
      tap((registration: Registration) => {
        this.partialUpdateRegistration(registration);
        this.authService.populate();
      })
    );
  }

  createRegistration(memberInfo: MemberInfo): Observable<Registration> {
    return this.apiService.post<Registration>('/registration', memberInfo).pipe(
      tap((registration: Registration) => {
        this.partialUpdateRegistration(registration);
      })
    );
  }

  updateMemberInfo(registrationId: string, memberInfo: MemberInfo): Observable<Registration> {
    return this.apiService
      .put<Registration>(`/registration/${registrationId}/member_info`, memberInfo)
      .pipe(
        tap((registration: Registration) => {
          this.partialUpdateRegistration(registration);
        })
      );
  }

  updateMembershipType(registrationId: string, membership_type: Plans): Observable<Registration> {
    return this.apiService
      .put<Registration>(`/registration/${registrationId}/membership_type`, {
        membership_type,
      })
      .pipe(
        tap((registration: Registration) => {
          this.partialUpdateRegistration(registration);
        })
      );
  }

  updateRegistrationInterests(
    registrationId: string,
    interests: Interests | any
  ): Observable<Registration> {
    return this.apiService.put(`/registration/${registrationId}/your_interests`, interests);
  }

  updateRaceDetails(
    registrationId: string,
    raceDetails: { raceGender: GenderCode }
  ): Observable<Registration> {
    return this.apiService.put(`/registration/${registrationId}/race_details`, {
      // has_regular_contact_with_junior: raceDetails.hasRegularContact,
      race_gender: !raceDetails.raceGender ? null : raceDetails.raceGender,
      is_domestic_cyclist: null,
    });
  }

  updateRacingCategories(registrationId: string, categories: any[]): Observable<Registration> {
    return this.apiService.put(`/registration/${registrationId}/racing_categories`, {
      racing_categories: categories,
    });
  }

  optOutPremium(registrationId: string): Observable<Registration> {
    return this.apiService.put(`/registration/${registrationId}/premium_opt_out`);
  }

  getEnrollmentWaiver(registrationId: string): Observable<Waiver> {
    return this.apiService.get<Waiver>(`/registration/${registrationId}/usac_waiver`);
  }

  signEnrollmentWaiver(registrationId: string, waiver: any): Observable<any> {
    return this.apiService.put(`/registration/${registrationId}/usac_waiver`, waiver);
  }

  signEnrollmentWaiverAsGuardian(registrationId: string, waiver: any): Observable<any> {
    return this.apiService.put(`/registration/${registrationId}/usac_junior_waiver`, waiver);
  }

  updateJuniorGuardianInfo(registrationId: string, guardianInfo: any): Observable<any> {
    return this.apiService.patch(
      `/registration/${registrationId}/legal_guardian_info`,
      guardianInfo
    );
  }

  deleteRegistration(registrationId: string): Observable<any> {
    return this.apiService.delete(`/registration/${registrationId}`);
  }

  private partialUpdateRegistration(registration: Registration): void {
    this.registrationFacade.updateFromProfile(
      registration.id,
      registration.first_name,
      registration.last_name,
      registration.email,
      registration.person_type,
      registration.gender,
      registration.membership_type,
      registration.order?.order_id
    );
  }

  updateOptions(
    registrationId: string,
    options: {
      is_podium?: boolean;
      is_premium?: boolean;
      is_auto_renewal?: boolean;
      has_spot_insurance?: boolean;
      spot_auto_renewal?: boolean;
      is_international?: boolean;
      is_collegiate?: boolean;
    }
  ): Observable<any> {
    return this.apiService.put(`/registration/${registrationId}/options`, options);
  }

  private formatDate(date: Date): string {
    return (
      (date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) +
      '/' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      '/' +
      date.getFullYear()
    );
  }
}
