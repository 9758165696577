<app-header
  [title]="'Technical Licensure'"
  [showLogoPrompt]="false"
  [cartHidden]="true"
  [logoutRedirectUrl]="logoutRedirectUrl"
  [showLogout]="true"
></app-header>

<div class="container">
  <div class="row">
    <div class="col-12">
      <app-status-indicator-header
        [user]="user"
        [allMemberships]="allMemberships"
        [firstName]="user.profile_first_name"
        [lastName]="user.profile_last_name"
        [email]="user.profile_email"
        [loading]="membershipsLoading"
      ></app-status-indicator-header>
      <hr>
    </div>
    <div class="col-12 col-lg-10 offset-lg-1 p-4">
      <app-license-step-progress-bar
        class="mb-3"
        customLinkClass="adjust-line"
        currentStep="post-purchase"
        previousStep="checkout"
      ></app-license-step-progress-bar>

      <app-loader *ngIf="loading"></app-loader>

      <div class="mt-5" *ngIf="!loading">
        <!-- Membership Information & Licenses -->
        <div class="mb-5">
          <app-member-licenses currentStep="post-purchase" [user]="user" [licenseGroups]="licenseGroups"></app-member-licenses>
        </div>

        <!-- Styled licenses -->
        <app-license-card *ngFor="let group of activeLicenseGroups" [user]="user" [licenseGroup]="group"></app-license-card>

      </div>

      <app-footer [fullWidth]="true"></app-footer>
    </div>
  </div>
</div>
