<div class="card" [ngClass]="{'bold-border': boldBorder}">
  <div class="card-body text-center">
    <div>
      <h3>{{ title }}</h3>
      <span *ngIf="isCurrentMembership">(Your Current Membership)</span>
      <h4>
        <span *ngIf="showPrice">{{isEarlyRenewal ? '' : '+'}} {{ price | currency }}</span>
        <span class="compared-price" *ngIf="comparedPrice">{{ comparedPrice | currency }}</span>
        <span *ngIf="perYear">/year</span>
      </h4>

      <ul class="mt-4">
        <li *ngFor="let benefit of benefits">
          {{ benefit }}
        </li>
      </ul>
    </div>

    <!-- Add to Cart Button -->
    <div class="mt-4">
      <button [disabled]="isDisabled" class="btn {{ btnClass }} btn-block" (click)="addToCartOrOpenModal()">
        {{ btnText }}
      </button>
    </div>
  </div>

  <!-- Auto-renewal -->
  <div class="auto-renew d-flex align-items-center my-3 pl-2" *ngIf="!hideAutoRenew">
    <input
      class="custom-checkbox mr-2"
      type="checkbox"
      name="auto_renewal"
      id="auto_renewal"
      [checked]="autoRenew"
      (change)="toggleAutoRenew()"
    />
    <label for="is_auto_renewal" class="font-weight-bold m-0 mr-1">
      <u>Automatic Renewal</u>:
      By checking this box, you agree that your annual membership will be automatically renewed each year
      by charging your card on file for the amount of your membership until you cancel. Your membership
      purchase is non-refundable. You may cancel automatic renewal at any time to stop future charges by
      visiting <a class="underline" [href]="adminUrl" target="_blank">My Account</a> to update your preferences.
    </label>
  </div>
</div>

<app-modal [isOpen]="openUpgradeConfirmation" [id]="'ride-upgrade-modal'" [title]="'Confirm Upgrade'" [allowClose]="false">
  <app-modal-body>
    <p>Are you sure you would like to continue with this upgrade selection?</p>
  </app-modal-body>

  <app-modal-footer>
    <button type="button" class="btn btn-outline-primary" (click)="closeModal()">Cancel</button>
    <button type="button" class="btn btn-secondary" (click)="confirm()">Confirm</button>
  </app-modal-footer>
</app-modal>
