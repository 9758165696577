import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  @Input() small = false;
  @Input() overlay = false;
  @Input() light = false;
  @Input() align = 'center';
  @Input() text = 'Loading';
}
