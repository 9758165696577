import { Component, OnInit } from '@angular/core';
import { Profile } from 'src/app/core';
import { AuthService } from 'src/app/core/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-technical-licenses',
  templateUrl: './technical-licenses.component.html',
  styleUrls: ['./technical-licenses.component.scss'],
})
export class TechnicalLicensesComponent implements OnInit {
  user: Profile;
  logoutRedirectUrl = window.location;
  loggedIn = false;
  logoutReturnUrl = environment.publicUrl + '/management';

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.authService.currentUser$.subscribe((user) => {
      this.user = user;
      this.loggedIn = !!user.profile_id;
    });
  }
}
