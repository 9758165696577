import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MembershipStep } from 'src/app/core/models';

@Component({
  selector: 'app-step-progress-bar',
  templateUrl: './step-progress-bar.component.html',
  styleUrls: ['./step-progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepProgressBarComponent implements OnInit {
  @Input() currentStep: MembershipStep;
  @Input() previousStep: MembershipStep;
  @Input() nextStep: MembershipStep;
  @Input() isRace: boolean;
  @Input() isFamily: boolean;

  allSteps = [
    MembershipStep.MemberInfo,
    MembershipStep.SelectOptions,
    MembershipStep.UpgradeOptions,
    MembershipStep.RaceDetails,
    MembershipStep.JuniorMember,
    MembershipStep.Checkout,
    // MembershipStep.SpotInsurance,
    // MembershipStep.JuniorSpotInsurance,
    MembershipStep.PostPurchase,
  ];

  percentage: number;

  ngOnInit(): void {
    const currentStepIndex = this.allSteps.findIndex((step) => step === this.currentStep);
    this.percentComplete(this.allSteps, currentStepIndex);
  }

  get stepsAfterMemberInfo(): MembershipStep[] {
    return this.shiftSteps(this.allSteps);
  }

  get stepsAfterSelectOptions(): MembershipStep[] {
    return this.shiftSteps(this.stepsAfterMemberInfo);
  }

  get stepsAfterUpgradeOptions(): MembershipStep[] {
    return this.shiftSteps(this.stepsAfterSelectOptions);
  }

  get stepsAfterRaceDetails(): MembershipStep[] {
    return this.shiftSteps(this.stepsAfterUpgradeOptions);
  }

  // get stepsAfterSpotInsurance(): MembershipStep[] {
  //   return this.shiftSteps(this.stepsAfterRaceDetails);
  // }

  get Step(): typeof MembershipStep {
    return MembershipStep;
  }

  get memberInfoChecked(): boolean {
    return this.stepsAfterMemberInfo.includes(this.currentStep);
  }

  get selectOptionsChecked(): boolean {
    return this.stepsAfterSelectOptions.includes(this.currentStep);
  }

  get upgradeOptionsChecked(): boolean {
    return this.stepsAfterUpgradeOptions.includes(this.currentStep);
  }

  get raceDetailsChecked(): boolean {
    return this.stepsAfterRaceDetails.includes(this.currentStep);
  }

  // get spotInsuranceChecked(): boolean {
  //   return this.stepsAfterSpotInsurance.includes(this.currentStep);
  // }

  // get adultOrJuniorSpotInsuranceActive(): boolean {
  //   return (
  //     this.currentStep === MembershipStep.SpotInsurance ||
  //     this.currentStep === MembershipStep.JuniorSpotInsurance
  //   );
  // }

  activeStep(step: MembershipStep): boolean {
    return this.currentStep === step;
  }

  private shiftSteps(steps: MembershipStep[]): MembershipStep[] {
    const newSteps = [...steps];
    newSteps.shift();
    return newSteps;
  }

  percentComplete(steps: any[], index: number): number {
    const current = index + 1;
    if (current < steps.length) {
      return (this.percentage = (current / steps.length) * 100);
    } else {
      return (this.percentage = 100);
    }
  }
}
