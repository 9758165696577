import { Directive, ElementRef, HostListener } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Directive({
  selector: '[appPhoneNumberFormatter]',
})
export class PhoneNumberFormatterDirective implements ControlValueAccessor {
  element: HTMLInputElement;
  private changes: string[] = [];

  constructor(elRef: ElementRef, private c: NgControl) {
    this.element = elRef.nativeElement;
  }

  @HostListener('input', ['$event.target.value'])
  input(value: any): void {
    const canonical = this.removeNonNumericCharacters(value);
    const formatted = this.format(canonical);
    this.assign(formatted);
  }
  writeValue(value: any): void {
    const canonical = this.removeNonNumericCharacters(value.toString());
    const formatted = this.format(canonical);
    this.assign(formatted);
  }

  registerOnChange(): void {}

  registerOnTouched(): void {}

  // setDisabledState(isDisabled: boolean): void {}

  private removeNonNumericCharacters(value: string): string {
    return value.replace(/\D/g, '');
  }

  private format(value: string): string {
    if (value.length < 4) {
      return value;
    } else {
      let area: string;
      let local1: string;
      let local2: string;
      let local: string;
      const lastChange = this.changes[this.changes.length - 1];
      const oneMore = !!lastChange ? value.substring(1) === lastChange.substring(1) : false;

      if (value.length > 10 && !oneMore) {
        const end = value.length;
        area = value.substring(end - 10, end - 7);
        local = `${value.substring(end - 7, end - 4)}-${value.substring(end - 4)}`;
      } else {
        area = value.substring(0, 3);
        local1 = value.substring(3, 6);
        local2 = value.substring(6);
        local = local2 ? `${local1}-${local2}` : `${local1}`;
      }
      return `(${area}) ${local}`;
    }
  }

  private assign(value: string): void {
    this.c.control.setValue(value);
    this.changes.push(this.removeNonNumericCharacters(value));
  }
}
