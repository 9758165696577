import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Club } from '../models';

import { ApiService } from './api.service';

const DOMESTIC_CLUB_TYPE_ID = 1;
const COLLEGIATE_CLUB_TYPE_ID = 2;
// const PRO_CLUB_TYPE_ID = 3;
const PRO_CLUB_AFF_TYPE_ID = 6;

@Injectable({
  providedIn: 'root',
})
export class ClubsService {
  constructor(private apiService: ApiService) {}

  proClubs: Club[] = [];

  queryDomesticClubs(query?: string): Observable<Club[]> {
    return query
      ? this.apiService.get(`/club_search/${DOMESTIC_CLUB_TYPE_ID}/${query}`)
      : this.apiService.get(`/club_search/${DOMESTIC_CLUB_TYPE_ID}`);
  }

  queryCollegiateClubs(query?: string): Observable<Club[]> {
    return query
      ? this.apiService.get(`/club_search/${COLLEGIATE_CLUB_TYPE_ID}/${query}`)
      : this.apiService.get(`/club_search/${COLLEGIATE_CLUB_TYPE_ID}`);
  }

  queryProClubs(nameQuery?: string): Observable<Club[]> {
    const affTypeQuery = `?aff_type=${PRO_CLUB_AFF_TYPE_ID}`;
    return nameQuery
      ? this.apiService
          .get<Club[]>(`/club_search/${DOMESTIC_CLUB_TYPE_ID}/${nameQuery}${affTypeQuery}`)
          .pipe(tap((clubs) => (this.proClubs = clubs)))
      : this.apiService
          .get<Club[]>(`/club_search/${DOMESTIC_CLUB_TYPE_ID}${affTypeQuery}`)
          .pipe(tap((clubs) => (this.proClubs = clubs)));
  }
}
