// Validates phone numbers (default formats: (xxx)-xxx-xxxx, xxx-xxx-xxxx, & xxxxxxxxxx but can be passed other RegEx)
import { AbstractControl, ValidatorFn } from '@angular/forms';

export function phoneNumberValidator(
  patterns: RegExp[] = [
    /([0-9]{10})/,
    /^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/,
    /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
  ]
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let test = false;
    patterns.forEach((pattern) => {
      if (!test) {
        test = control.value && control.value !== '' ? pattern.test(control.value) : true;
      }
    });
    return test ? null : { phoneNumber: { value: control.value } };
  };
}
