<div class="container">
  <div class="junior-waiver">
    <div class="text-center">
      <h2 class="header">That's ok, we'll send an email so they can sign later.</h2>
      <p class="mt-4">Please enter the junior's parent or legal guardian's information below so that we can send an email.</p>
    </div>

    <form [formGroup]="legalGuardianForm" (submit)="submitForm()">
      <div class="row mt-5">
        <div class="col-12 col-md-6">
          <label for="first-name">Legal Guardian’s First Name</label>
          <input
            class="form-control"
            type="text"
            id="first-name"
            placeholder="Enter First Name"
            formControlName="first_name"
          />
        </div>
        <div class="col-12 col-md-6 mt-4 mt-md-0">
          <label for="last-name">Legal Guardian’s Last Name</label>
          <input
            class="form-control"
            type="text"
            id="last-name"
            placeholder="Enter Last Name"
            formControlName="last_name"
          />
        </div>
        <div class="col-12 col-md-6 mt-4">
          <label for="email">Legal Guardian’s Email Address</label>
          <input
            class="form-control"
            type="text"
            id="email"
            placeholder="Enter Email Address"
            formControlName="email"
          />
        </div>
        <div class="col-12 col-md-6 mt-4">
          <label for="guardian-relationship">Relationship</label>
          <ng-select
            id="guardian-relationship"
            [items]="relationships"
            bindLabel="name"
            bindValue="name"
            placeholder="Make Selection"
            [clearable]="false"
            [searchable]="false"
            formControlName="relationship"
          >
          </ng-select>
        </div>

        <div class="col-12 col-md-6 offset-md-3 mt-5">
          <button type="submit" class="btn btn-primary btn-block" [disabled]="legalGuardianForm.invalid || isSubmitting">
            <span *ngIf="isSubmitting" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            Got it
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
