<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="information-container">
        <h2 class="header pb-2">
          Race Categories
        </h2>

        <p class="section-text">
          Your USA Cycling race license lets you race as a Novice in any domestic cycling race across
          all disciplines. If you need to race in a higher category, you can make those selections
          here.
        </p>

        <form *ngIf="!isLoading" [formGroup]="raceCategoriesForm">
          <div *ngIf="registration.is_collegiate_cyclist" class="d-none d-sm-flex align-items-center justify-content-end p-2">
            <div></div>
            <div class="d-flex pr-3">
              <div style="width: 150px;">
                <div class="category-type d-flex align-items-center">
                  Collegiate
                  <i
                    class="icon icon-information ml-1"
                    placement="bottom"
                    [ngbTooltip]="tipContentCollegiate"
                    tooltipClass="custom-tooltip"
                    [openDelay]="250"
                    tabindex="0"
                  ></i>
                </div>
              </div>
            </div>

            <div style="width: 165px;">
              <div class="category-type d-flex align-items-center">
                Domestic
              </div>
            </div>
          </div>

          <ng-template #tipContentCollegiate>
            You can select a category above D from your admin portal or by contacting
            <span class="font-weight-bold">membership@usacycling.org</span> after you complete your
            membership and request to be upgraded to a higher category.
          </ng-template>

          <div
            class="edit-category-item d-flex align-items-center justify-content-between px-4 py-2 mb-3 flex-column flex-lg-row"
            *ngFor="let discipline of disciplines; let idx=index"
          >
            <span class="category-name-edit pb-2 pb-lg-0">{{ discipline.name }}</span>
            <div class="d-flex flex-column flex-lg-row justify-content-around">
              <div *ngIf="registration.is_collegiate_cyclist" formArrayName="collegiateCategories" class="d-flex flex-column">
                <div class="d-flex align-items-center d-lg-none category-type py-2">
                  Collegiate
                  <i
                    class="icon icon-information ml-1"
                    placement="bottom"
                    [ngbTooltip]="tipContentCollegiate"
                    tooltipClass="custom-tooltip"
                    [openDelay]="250"
                    tabindex="0"
                  ></i>
                </div>

                <ng-select
                  class="category-select"
                  [items]="discipline.collegiateCategories"
                  bindLabel="name"
                  [clearable]="false"
                  [searchable]="false"
                  [formControlName]="idx"
                >
                  <ng-template ng-label-tmp let-item="item">
                    <span>{{ item.name }}</span>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item">
                    <div class="d-flex justify-content-between align-items-center">
                      <span>{{ item.name }}</span>
                      <img
                        *ngIf="item.requiresApproval"
                        class="ml-1"
                        height="15"
                        width="15"
                        src="assets/images/Information.svg"
                        alt="i"
                        placement="bottom"
                        [ngbTooltip]="tipContentCollegiate"
                        tooltipClass="custom-tooltip"
                        container="body"
                      />
                    </div>
                  </ng-template>
                </ng-select>

                <ng-template #tipContentCollegiate>
                  You can select a category above D from your admin portal or by contacting
                  <span class="font-weight-bold">membership@usacycling.org</span>
                  after you complete your membership and request to be upgraded to a higher category.
                </ng-template>
              </div>

              <div formArrayName="domesticCategories" class="d-flex flex-column ml-lg-3">
                <span *ngIf="registration.is_collegiate_cyclist" class="d-inline d-lg-none category-type py-2">
                  Domestic
                </span>

                <ng-select
                  class="category-select"
                  [items]="discipline.nonCollegiateCategories"
                  bindLabel="name"
                  [clearable]="false"
                  [searchable]="false"
                  [formControlName]="idx"
                  (change)="onChange($event, idx)"
                >
                  <ng-template ng-label-tmp let-item="item">
                    <div class="d-flex justify-content-between align-items-center">
                      <span>{{ item.name }}</span>
                      <img
                        class="mx-1"
                        *ngIf="item.requiresPremium === true"
                        height="15"
                        width="15"
                        src="assets/images/trophy.svg"
                        alt="Trophy"
                      />
                    </div>
                  </ng-template>

                  <ng-template ng-option-tmp let-item="item">
                    <div class="d-flex justify-content-between align-items-center">
                      <span>{{ item.name }}</span>
                      <img
                        class="mx-1"
                        *ngIf="item.requiresPremium === true && !item.requiresApproval"
                        height="15"
                        width="15"
                        src="assets/images/trophy.svg"
                        alt="Trophy"
                      />
                      <img
                        class="ml-1"
                        *ngIf="item.requiresApproval"
                        height="15"
                        width="15"
                        src="assets/images/Information.svg"
                        alt="i"
                        placement="bottom"
                        [ngbTooltip]="tipContent"
                        tooltipClass="custom-tooltip"
                        container="body"
                      />
                    </div>
                  </ng-template>
                </ng-select>

                <ng-template #tipContent>
                  Selecting this category requires approval from an upgrade coordinator. Contact
                  <span class="font-weight-bold">membership@usacycling.org</span>
                  after you complete your membership and request to be upgraded to a higher category.
                </ng-template>

              </div>
            </div>
          </div>

          <div class="d-flex flex-column flex-lg-row align-items-stretch align-items-lg-center justify-content-lg-end pt-3">
            <button
              type="button"
              class="cancel-link btn btn-link px-5"
              (click)="activeModal.dismiss()"
            >
              Cancel
            </button>

            <button
              type="submit"
              (click)="saveDisciplineCategories()"
              [disabled]="isSaving"
              class="btn btn-primary px-5"
            >
              <span *ngIf="isSaving" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
