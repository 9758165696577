<div class="container modal-container">
  <div class="row">
    <div class="col-12 text-center">
      <div class="premium-modal">
        <img class="premium-icon" src="assets/premium.svg" alt="premium" />

        <h2 class="premium-title">Add a Race License</h2>

        <p class="premium-description">
          Racing above Novice categories requires a Race License for adults.
        </p>

        <div class="row">
          <div class="col-12 col-lg-6 offset-lg-3">
            <div class="premium-card text-left m-auto">
              <h3 class="premium-card__title">Race License</h3>
              <p class="premium-card__price">
                +{{ premiumUpgradePrice | currency }} <span class="premium-card__small"> /year</span>
              </p>

              <hr class="premium-card__divider" />

              <ul class="premium-card__list">
                <li class="premium-card__element">
                  Race in ANY domestic or international event with a single upgrade fee.
                </li>
                <li class="premium-card__element">Premium ExpertVoice Benefits</li>
              </ul>

              <button type="button" class="btn btn-primary btn-block" (click)="upgrade()">
                Add a Race License
              </button>

              <button
                type="button"
                class="premium-no-thanks-button btn btn-link btn-block"
                (click)="downgrade()"
              >
                No Thanks, I’ll stay in Novice
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
