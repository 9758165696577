import { ChangeDetectionStrategy, Component } from '@angular/core';
// import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
// import { FamilyMembershipFacade } from 'src/app/core/facades/family-membership.facade';
import { RegistrationFacade } from 'src/app/core/facades/registration.facade';

@Component({
  selector: 'app-no-waiver-modal',
  templateUrl: './no-waiver-modal.component.html',
  styleUrls: ['./no-waiver-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoWaiverModalComponent {
  registration$ = this.registrationFacade.registration$;

  constructor(
    private activeModal: NgbActiveModal,
    private registrationFacade: RegistrationFacade // private familyFacade: FamilyMembershipFacade, // private router: Router
  ) {}

  close(): void {
    this.activeModal.close();
  }
}
