import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import airbrake from '../../airbrake';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  private determineSeverity(error: HttpErrorResponse): string {
    let severity = 'error';
    if (error.status && error.status < 500) {
      severity = 'info';
    }
    return severity;
  }

  private formatErrors(error: HttpErrorResponse): Observable<never> {
    console.log(error);

    airbrake.notify({
      error,
      context: {
        severity: this.determineSeverity(error),
      },
    });

    return throwError(error.error);
  }

  get<T>(path: string, params: HttpParams = new HttpParams()): Observable<T> {
    return this.http
      .get<T>(`${environment.apiUrl}${path}`, { params, withCredentials: true })
      .pipe(catchError((error) => this.formatErrors(error)));
  }

  put<T>(path: string, body: object = {}): Observable<T> {
    return this.http
      .put<T>(`${environment.apiUrl}${path}`, body, { withCredentials: true })
      .pipe(catchError((error) => this.formatErrors(error)));
  }

  patch<T>(path: string, body: object = {}): Observable<T> {
    return this.http
      .patch<T>(`${environment.apiUrl}${path}`, body, { withCredentials: true })
      .pipe(catchError((error) => this.formatErrors(error)));
  }

  post<T>(path: string, body: object = {}): Observable<T> {
    return this.http
      .post<T>(`${environment.apiUrl}${path}`, body, { withCredentials: true })
      .pipe(catchError((error) => this.formatErrors(error)));
  }

  delete<T>(path: string): Observable<T> {
    return this.http
      .delete<T>(`${environment.apiUrl}${path}`, { withCredentials: true })
      .pipe(catchError((error) => this.formatErrors(error)));
  }
}
