import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

// import { FamilyMembershipComponent } from './family-membership/family-membership.component';
import { IndividualMembershipComponent } from './individual-membership/individual-membership.component';

const routes: Routes = [
  {
    path: '',
    component: IndividualMembershipComponent,
    data: { title: 'Membership Intro Form' },
  },
  {
    path: 'individual-membership',
    component: IndividualMembershipComponent,
    data: { title: 'Membership Intro Form' },
  },
  {
    path: 'enrollment',
    loadChildren: () => import('./enrollment/enrollment.module').then((m) => m.EnrollmentModule),
  },
  {
    path: 'management',
    loadChildren: () => import('./management/management.module').then((m) => m.ManagementModule),
  },
  {
    path: 'one-day',
    loadChildren: () => import('./one-day/one-day.module').then((m) => m.OneDayModule),
  },
  {
    path: 'olympic-development-academy',
    loadChildren: () =>
      import('./olympic-development-academy/olympic-development-academy.module').then(
        (m) => m.OlympicDevelopmentAcademyModule
      ),
  },

  // Redirects
  // {
  //   path: 'ride',
  //   redirectTo: 'enrollment/ride/member-info',
  // },
  {
    path: 'race',
    redirectTo: 'enrollment/race/member-info',
  },
  {
    path: 'junior',
    redirectTo: 'enrollment/race/member-info',
  },

  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
