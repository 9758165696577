import { Component, Input } from '@angular/core';
import * as moment from 'moment';
import {
  License,
  LicenseStatus,
  LicenseTypeId,
  Membership,
  Profile,
  SpotClassOneIds,
} from 'src/app/core/models';
import { ProfileService } from 'src/app/core/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-member-status',
  templateUrl: './member-status.component.html',
  styleUrls: ['./member-status.component.scss'],
})
export class MemberStatusComponent {
  @Input() profile: Profile;
  @Input() currentMembership: Membership;
  @Input() userSubscriptions: any[] = [];
  @Input() licenses: License[] = [];
  @Input() showManageLink = true;
  @Input() showHeader = true;
  @Input() addedJunior = false;

  actionLinkClass = 'btn-link m-0 p-0';
  expirationDays: number;
  membershipLicenseTypes = [
    LicenseTypeId.DOMESTIC,
    LicenseTypeId.COLLEGIATE,
    LicenseTypeId.INTERNATIONAL,
    LicenseTypeId.PROFESSIONAL,
  ];
  renewalUrl = '/?renew=true';

  constructor(private profileService: ProfileService) { }

  get profilePath(): string {
    return `profile/${this.profile.profile_id}`;
  }

  get spotClassOne(): boolean {
    return this.profile.spot_customer_json_data && this.profile.spot_customer_json_data.some((data) =>
      [
        SpotClassOneIds.STAGING_ID_2022,
        SpotClassOneIds.PRODUCTION_ID_2022,
        SpotClassOneIds.STAGING_ID_2023,
        SpotClassOneIds.PRODUCTION_ID_2023,
      ].includes(data.event_id)
    );
  }

  get userEarlyRenewed(): boolean {
    return this.userSubscriptions && this.userSubscriptions.length
      ? this.userSubscriptions[0].numberOfBillingCycles > this.userSubscriptions[0].currentBillingCycle ||
      this.userSubscriptions[0].numberOfBillingCycles === null
      : false;
  }

  get isRacePlusMember(): boolean {
    return (
      this.currentMembership.membership_level === 2 &&
      this.profile.should_show_plus_designation
    );
  }

  get rideLevel(): string {
    if (!this.currentMembership) {
      return null;
    }

    return this.profile.should_show_plus_designation ? 'Ride+' : 'Ride';
  }

  get raceLevel(): string {
    if (!this.currentMembership) {
      return null;
    }

    return this.isRacePlusMember ? 'Race+ ' : 'Race';
  }

  get membershipLevel(): string {
    if (!this.currentMembership) {
      return null;
    }

    return this.currentMembership.membership_level === 1 ? this.rideLevel : this.raceLevel;
  }

  get membershipLevelName(): string {
    let name = this.membershipLevel;

    if (!name) {
      return null;
    }

    if (this.isJuniorMember) {
      name = name.concat('/Junior Member');
    }

    if (this.hasCollegiateLicense) {
      name = name.concat('/Collegiate Member');
    }

    if (this.hasProLicense) {
      name = name.concat('/Pro');
    }
    if (this.hasInternationalLicense) {
      name = name.concat('/Intl');
    }

    return name;
  }

  get isJuniorMember(): boolean {
    return this.profile.profile_race_age < 19;
  }

  get profileLicenses(): License[] {
    return this.profile.licenses || this.licenses;
  }

  get hasInternationalLicense(): boolean {
    return this.hasLicense(LicenseTypeId.INTERNATIONAL);
  }

  get hasProLicense(): boolean {
    return this.hasLicense(LicenseTypeId.PROFESSIONAL);
  }

  get hasCollegiateLicense(): boolean {
    return this.hasLicense(LicenseTypeId.COLLEGIATE);
  }

  get adminMembershipLink(): string {
    return `${environment.accountUrl}/${this.profilePath}/membership`;
  }

  get membershipStatus(): string {
    const today = new Date().toISOString();
    if (!this.currentMembership) {
      return null;
    }

    if (this.currentMembership.membership_expiration_date < today) { return 'EXPIRED'; }

    return this.expirationDays <= 30 && this.userSubscriptions && this.userSubscriptions.length
      ? 'EXPIRED'
      : 'ACTIVE';
  }

  get membershipLink(): string {
    if (!this.membershipStatus) {
      return environment.publicUrl;
    }

    return this.membershipStatus === 'EXPIRED' &&
      this.userSubscriptions &&
      this.userSubscriptions.length
      ? this.renewalUrl
      : this.adminMembershipLink;
  }

  get membershipLinkText(): string {
    return this.setLinkText(this.membershipLink);
  }

  get membershipExpirationDate(): string {
    return this.currentMembership
      ? moment(this.currentMembership.membership_expiration_date).format('YYYY-MM-DD')
      : null;
  }

  get daysTilExpiration(): any {
    if (this.isUserSuspended || this.isLicenseSuspended) {
      return `SUSPENDED`;
    }

    if (this.isUserAdminHold) {
      return 'ADMIN HOLD';
    }

    const today = new Date().toISOString();
    if (!this.currentMembership) {
      return null;
    }

    const expiration = moment(this.currentMembership.membership_expiration_date).format(
      'YYYY-MM-DD'
    );
    const current_date = moment(today).format('YYYY-MM-DD');
    const days = moment(expiration).diff(current_date, 'days');
    this.expirationDays = days;

    if (days < 0) {
      return 'EXPIRED';
    }

    if (this.isPending) {
      return 'PENDING';
    }

    return `Expires ${days} Days`;
  }

  get membershipBannnerClass(): string {
    if (!this.currentMembership) {
      return null;
    }

    if (this.isUserAdminHold || this.isUserSuspended || this.isPending || this.isLicenseSuspended) {
      return 'expired-membership-banner';
    }

    return this.membershipStatus === 'EXPIRED'
      ? 'expired-membership-banner'
      : 'valid-membership-banner';
  }

  get isPending(): boolean {
    return this.currentLicenseStatus === LicenseStatus.PENDING;
  }

  get isLicenseSuspended(): boolean {
    return this.currentLicenseStatus === LicenseStatus.SUSPENDED;
  }

  get isUserAdminHold(): boolean {
    return this.profile.profile_has_admin_hold;
  }

  get isUserSuspended(): boolean {
    return this.profile.profile_is_suspended;
  }

  hasLicense(licenseType: LicenseTypeId): boolean {
    const now = new Date();
    const today = Date.parse(`${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}`);

    return this.profileLicenses.some(
      (license) =>
        Date.parse(license.expiration_date) >= today && license.license_type === licenseType
    );
  }

  get currentLicenseStatus(): string | any {
    if (this.profileLicenses.length > 0) {
      const licenseGroups = this.profileService.groupLicensesByTypeAndDate(this.profile.licenses);
      const filteredGroups = licenseGroups.filter((group) =>
        this.membershipLicenseTypes.includes(group.type)
      );
      if (filteredGroups.length) {
        return filteredGroups[0].status;
      } else {
        return null;
      }
    }
  }

  setStatusClass(status: string): string {
    if (this.isUserAdminHold || this.isUserSuspended || this.isLicenseSuspended) {
      return 'expired-status';
    }
    if (status === null) {
      return '';
    }
    if (this.isPending) {
      return 'pending-status';
    }

    return status === 'EXPIRED' || status === 'FAIL' ? 'expired-status' : 'active-status';
  }

  setLinkText(link: string): string {
    switch (link) {
      case environment.publicUrl:
        return 'Create Membership';
      case this.renewalUrl:
        return 'renew';
      case this.adminMembershipLink:
      default:
        return 'manage';
    }
  }

  setLinkClass(linkText: string): string {
    return linkText === 'renew' ? this.actionLinkClass + ' text-danger' : this.actionLinkClass;
  }

  setLinkTarget(linkText: string): string {
    return linkText === 'renew' || this.addedJunior ? '_blank' : '_self';
  }
}
