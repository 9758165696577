<app-header
  [title]="'Ride Upgrade'"
  [showLogoPrompt]="false"
  [cartHidden]="true"
  [logoutRedirectUrl]="logoutRedirectUrl"
  [showLogout]="true"
></app-header>

<app-loader *ngIf="loggedIn && showLoader" [overlay]="true"></app-loader>

<div class="container">
  <div class="row">
    <div class="col-12">
      <app-status-indicator-header
        [user]="user"
        [allMemberships]="allMemberships"
        [firstName]="user.profile_first_name"
        [lastName]="user.profile_last_name"
        [email]="user.profile_email"
        [loading]="membershipsLoading"
      ></app-status-indicator-header>
      <hr>
    </div>
    <div class="col-12 col-lg-10 offset-lg-1 p-4">

      <app-form-title>Upgrade Your Renewing Membership</app-form-title>

      <app-form-subtitle>
        Members will be enrolled into a Race Membership if a new plan is not selected.
        <p>{{ billingDateText }}</p>
      </app-form-subtitle>

      <!-- Login / Message Module -->
      <div *ngIf="!loggedIn || (message && !showLoader)" class="login-container d-flex align-items-center row p-3 m-0 mt-4">
        <div class="col-2 col-md-1">
          <img src="assets/user-check.svg" alt="User" />
        </div>
        <div class="col-10 col-md-6">
          <p *ngIf="!loggedIn" class="m-0">
            Upgrade your USA Cycling Ride Membership to one of our new plans. Log In to get started.
          </p>

          <h4 *ngIf="successfulUpgrade" class="text-success">Successfully Upgraded</h4>
          <p *ngIf="message" class="m-0">
            {{ message }}
          </p>

        </div>
        <div
          class="d-flex justify-content-center justify-content-md-end col-12 col-md-4 offset-md-1 pt-4 pt-md-0"
        >
          <button *ngIf="!loggedIn" type="button" class="btn btn-primary btn-block px-3" (click)="login()">LOG IN</button>
          <button *ngIf="message" type="button" class="btn btn-primary btn-block px-3" (click)="toAdmin()">Return to My Account</button>
        </div>
      </div>

      <!-- Upgrade Cards -->
      <div class="row" *ngIf="loggedIn && upgradeEligible && !showLoader">
        <app-upgrade-card
          class="col-sm-6 col-lg-4 p-2"
          [title]="'Race Membership'"
          [type]="'race'"
          [price]="69"
          [sku]="'RACE-2021'"
          [comparedPrice]="99"
          [benefits]="raceBenefits"
          [hideAutoRenew]="true"
          [boldBorder]="true"
          [useModal]="true"
          (addToCartUpdate)="updateSubscriptionPlan($event)"
        ></app-upgrade-card>

        <app-upgrade-card
          class="col-sm-6 col-lg-4 p-2"
          [title]="'Race+ Membership'"
          [type]="'race'"
          [price]="109"
          [sku]="'RACE-PLUS-2021'"
          [comparedPrice]="139"
          [benefits]="racePlusBenefits"
          [hideAutoRenew]="true"
          [boldBorder]="true"
          [useModal]="true"
          (addToCartUpdate)="updateSubscriptionPlan($event)"
        ></app-upgrade-card>

        <app-upgrade-card
          class="col-sm-6 col-lg-4 p-2"
          [title]="'Ride Membership'"
          [type]="'race'"
          [price]="69"
          [sku]="'RIDE'"
          [benefits]="rideBenefits"
          [hideAutoRenew]="true"
          [boldBorder]="true"
          [useModal]="true"
          (addToCartUpdate)="updateSubscriptionPlan($event)"
        ></app-upgrade-card>
      </div>
    </div>
  </div>
</div>

<app-footer [fullWidth]="true"></app-footer>
