import { PlatformModule } from '@angular/cdk/platform';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';

import { AirbrakeErrorHandler } from './airbrake-error-handler';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core';
import { EnrollmentModule } from './enrollment/enrollment.module';
import { IndividualMembershipModule } from './individual-membership/individual-membership.module';
import { ManagementModule } from './management/management.module';
// import { OlympicDevelopmentAcademyModule } from './olympic-development-academy/olympic-development-academy.module';
// import { OneDayModule } from './one-day/one-day.module';
import { SharedModule } from './shared';

@NgModule({
  declarations: [AppComponent],
  imports: [
    NgbModule,
    BrowserModule,
    RecaptchaV3Module,
    CoreModule,
    HttpClientModule,
    PlatformModule,
    AppRoutingModule,
    EnrollmentModule,
    IndividualMembershipModule,
    ManagementModule,
    // OlympicDevelopmentAcademyModule,
    // OneDayModule,
    SharedModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: AirbrakeErrorHandler },
    { provide: 'googleTagManagerId', useValue: environment.gtmId },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaSiteKey },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
