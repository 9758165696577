import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  IntlDisciplineSelectOption,
  LicenseLevel,
  Registration,
  SelectOption,
  UciCountry,
} from 'src/app/core/models';
import { UciService } from 'src/app/core/services';
import mockCountries from 'src/assets/mock/uci-countries.json';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-international-license',
  templateUrl: './international-license.component.html',
  styleUrls: ['./international-license.component.scss'],
})
export class InternationalLicenseComponent implements OnInit, OnDestroy {
  @Input() registration: Registration;
  @Input() isSubmitting = false;
  @Input() postPurchase = false;
  @Input() formRaceAge: number;
  @Input() preventEmit = false;
  @Output() formValues: EventEmitter<FormGroup> = new EventEmitter();

  countryOptions: SelectOption[] = [];
  nonUsOptions: SelectOption[] = [];
  nationalityOptions: SelectOption[] = [];
  usOption: SelectOption;
  licenseCategoryOptions: IntlDisciplineSelectOption[] = [];

  noLevelBmxCategories = ['BMX Race Challenge', 'BMX Masters (Vet Pro)'];
  levelOptions: SelectOption[] = [
    { value: LicenseLevel.Elite, label: 'Elite' },
    { value: LicenseLevel.Master, label: 'Master' },
    { value: LicenseLevel.Junior, label: 'Junior' },
    { value: LicenseLevel.U23, label: 'U23' },
  ];
  visibleLevelOptions: SelectOption[] = [
    { value: LicenseLevel.Elite, label: 'Elite' },
    { value: LicenseLevel.Master, label: 'Master' },
  ];

  optionsLoading = true;

  internationalLicenseForm: FormGroup;
  ilfValues: any;
  subValues = {};

  get f(): any {
    return this.internationalLicenseForm.controls;
  }

  get usCitizen(): any {
    return this.f.us_citizen.value;
  }

  get dualCitizen(): any {
    return this.f.dual_citizen.value;
  }

  get dualCitizenCountry(): any {
    return this.f.dual_citizen_country.value;
  }

  get permanentResident(): any {
    return this.f.permanent_resident.value;
  }

  get racingNationality(): any {
    return this.f.racing_nationality.value;
  }

  get licenseCategory(): IntlDisciplineSelectOption {
    return this.f.license_category.value;
  }

  get internationalLevel(): any {
    return this.f.intl_license_level.value;
  }

  get licenseCurrentYear(): any {
    return this.f.license_current_year.value;
  }

  get displayRacingNationality(): boolean {
    return (
      this.dualCitizen === false || !!this.dualCitizenCountry || this.permanentResident !== null
    );
  }

  get raceAge(): number {
    return this.registration ? this.registration.race_age : this.formRaceAge;
  }

  get bmxCategorySelected(): boolean {
    return this.licenseCategory && this.licenseCategory.label.includes('BMX');
  }

  get displayLevel(): boolean {
    return !this.bmxCategorySelected && this.raceAge >= 30 && !!this.racingNationality;
  }

  get displayCurrentYear(): boolean {
    return this.raceAge >= 30 && !this.bmxCategorySelected
      ? !!this.internationalLevel
      : !!this.racingNationality;
  }

  get successAlertMsg(): string {
    return this.postPurchase
      ? 'International license can be added to cart.'
      : 'Added international license to cart.';
  }

  constructor(private fb: FormBuilder, private uciService: UciService) {
    this.createForm();
  }

  ngOnInit(): void {
    this.updateLicenseCategoryOptions();
    this.getCountryOptions();
    this.emitFormValues();
    this.subscribeToForm();
    // Set values from current registration after form subscription
    this.setValuesFromRegistration();
    this.subscribeToFormEmit();

    this.setLevelRequirement();
    this.applyLicenseLevelRules();
  }

  ngOnDestroy(): void {
    // Unrequire and nullify all form controls
    this.unsubscribeFromControls();
    Object.keys(this.f).forEach((key) => this.unrequireField(this.f[key]));
    if (this.ilfValues) {
      this.ilfValues.unsubscribe();
    }
  }

  private createForm(): void {
    this.internationalLicenseForm = this.fb.group({
      us_citizen: [null, Validators.required],
      dual_citizen: [null],
      dual_citizen_country: [null],
      permanent_resident: [null],
      racing_nationality: [null, Validators.required],
      license_current_year: [null, Validators.required],
      intl_license_level: [null],
      intl_bmx_category: [null],
      license_category: [null, Validators.required],
      ok_to_proceed: [null, Validators.required],
    });
  }

  private subscribeToForm(): void {
    // tslint:disable-next-line: no-string-literal
    this.subValues['licenseCategory'] = this.f.license_category.valueChanges.subscribe(() => {
      this.setLevelRequirement();
      this.applyLicenseLevelRules();
      this.updateBmxCategory();
    });

    // tslint:disable-next-line: no-string-literal
    this.subValues['usCitizen'] = this.f.us_citizen.valueChanges.subscribe((val: boolean) => {
      this.toggleRequireField(val, 'dual_citizen');
      this.toggleRequireField(!val, 'permanent_resident');
      this.nationalityOptions = val ? [this.usOption] : this.nonUsOptions;
    });
    // tslint:disable-next-line: no-string-literal
    this.subValues['dualCitizen'] = this.f.dual_citizen.valueChanges.subscribe((val: boolean) => {
      this.toggleRequireField(val, 'dual_citizen_country');
      if (val === false) {
        this.nationalityOptions = [this.usOption];
      }
      const selectNationality = val === false ? this.usOption : null;
      this.f.racing_nationality.setValue(selectNationality);
    });
    // tslint:disable-next-line: no-string-literal
    this.subValues['dualCitizenCountry'] = this.f.dual_citizen_country.valueChanges.subscribe(
      (val: SelectOption) => {
        if (val) {
          this.nationalityOptions = [this.usOption, val];
          this.f.racing_nationality.setValue(null);
        }
      }
    );
    // tslint:disable-next-line: no-string-literal
    this.subValues['racingNationality'] = this.f.racing_nationality.valueChanges.subscribe(() => {
      this.setOkToProceed();
    });
    // tslint:disable-next-line: no-string-literal
    this.subValues['internationalLevel'] = this.f.intl_license_level.valueChanges.subscribe(
      (val: SelectOption) => {
        if (val === null) {
          this.f.license_current_year.setValue(null);
        }
        this.setOkToProceed();
      }
    );
    // tslint:disable-next-line: no-string-literal
    this.subValues['licenseCurrentYear'] = this.f.license_current_year.valueChanges.subscribe(() =>
      this.setOkToProceed()
    );
  }

  private subscribeToFormEmit(): void {
    setTimeout(() => {
      this.emitFormValues();
      this.ilfValues = this.internationalLicenseForm.valueChanges.subscribe(() =>
        this.emitFormValues()
      );
    }, 2000);
  }

  private unsubscribeFromControls(): void {
    Object.keys(this.subValues).forEach((key) => this.subValues[key].unsubscribe());
  }

  private emitFormValues(): void {
    if (!this.preventEmit) {
      this.formValues.emit(this.internationalLicenseForm);
    }
  }

  private updateLicenseCategoryOptions(): void {
    const options = [...this.uciService.licenseCategoryOptions];

    if (this.raceAge < 5) {
      this.removeLicenseCategoryOption('BMX Race Challenge', options);
    }
    if (this.raceAge < 17) {
      this.removeLicenseCategoryOption('BMX Race Championship (Jr., U23, Elite)', options);
    }
    if (this.raceAge < 30) {
      this.removeLicenseCategoryOption('BMX Masters (Vet Pro)', options);
    }
    if (this.raceAge < 15) {
      this.removeLicenseCategoryOption('BMX Freestyle (UCI Events)', options);
    }

    this.licenseCategoryOptions = [...options];
  }

  private removeLicenseCategoryOption(label: string, options: IntlDisciplineSelectOption[]): void {
    const index = options.findIndex((option) => option.label === label);
    if (index > -1) {
      if (this.licenseCategory && this.licenseCategory.label === label) {
        this.f.license_category.setValue(null);
      }
      options.splice(index, 1);
    }
  }

  private setLevelRequirement(): void {
    if (this.raceAge >= 30 && !this.bmxCategorySelected) {
      this.requireField(this.f.intl_license_level);
    } else {
      this.unrequireField(this.f.intl_license_level);
    }
  }

  private applyLicenseLevelRules(): void {
    const label = this.licenseCategory?.label;
    let level: SelectOption = null;
    if (label) {
      // Handle BMX-specific rules
      if (label === 'BMX Championship') {
        if (this.raceAge === 17 || this.raceAge === 18) {
          level = this.levelOptionByValue(LicenseLevel.Junior);
        } else if (this.raceAge >= 19) {
          level = this.levelOptionByValue(LicenseLevel.Elite);
        }
      } else if (label === 'BMX Freestyle') {
        level = this.levelOptionByValue(LicenseLevel.Elite);
      } else if (!this.noLevelBmxCategories.includes(label)) {
        // Handle age-based rules for non-BMX categories
        if (this.raceAge <= 18) {
          level = this.levelOptionByValue(LicenseLevel.Junior);
        } else if (this.raceAge >= 19 && this.raceAge <= 22) {
          level = this.levelOptionByValue(LicenseLevel.U23);
        } else if (this.raceAge >= 23 && this.raceAge <= 29) {
          level = this.levelOptionByValue(LicenseLevel.Elite);
        }
      }
    }

    this.f.intl_license_level.setValue(level);
  }

  private updateBmxCategory(): void {
    const bmxCategory = this.bmxCategorySelected ? this.licenseCategory.value.lc_id : null;
    this.f.intl_bmx_category.setValue(bmxCategory);
  }

  private levelOptionByValue(level: LicenseLevel): SelectOption {
    return this.levelOptions.find((option) => option.value === level);
  }

  private setOkToProceed(): void {
    const okValue = !!this.racingNationality && this.licenseCurrentYear === false ? true : null;
    this.f.ok_to_proceed.setValue(okValue);
  }

  private getCountryOptions(): void {
    // TODO: Remove mock data fallback once UCI test API working again
    if (environment.env === 'production') {
      this.uciService.countries.length
        ? this.setCountryOptions(this.uciService.countries)
        : this.uciService
            .getCountries()
            .subscribe((countries) => this.setCountryOptions(countries));
    } else {
      this.setCountryOptions(mockCountries);
    }
  }

  private setCountryOptions(countries: UciCountry[]): void {
    this.countryOptions = countries.map((c) => Object.assign({ label: c.Name, value: c.Code }));
    this.usOption = this.countryOptions.find((c) => c.value === 'USA');
    const usIndex = this.countryOptions.findIndex((c) => c.value === 'USA');
    this.nonUsOptions = this.countryOptions.slice();
    this.nonUsOptions.splice(usIndex, 1);
    this.nationalityOptions = this.f.us_citizen.value ? [this.usOption] : this.countryOptions;
    this.optionsLoading = false;

    // Set values from current registration once countries loaded
    this.setValuesFromRegistration();
  }

  private setValuesFromRegistration(): void {
    if (this.registration && this.registration.is_international) {
      let selectedOption: IntlDisciplineSelectOption;
      const intlBmxCategory = this.registration.intl_bmx_category;

      if (intlBmxCategory) {
        selectedOption = this.licenseCategoryOptions.find(
          (option) => option.value.lc_id === intlBmxCategory
        );
      } else {
        const primaryDiscipline = this.registration.disciplines.find(
          (discipline) => discipline.is_primary
        );
        if (primaryDiscipline) {
          selectedOption = this.licenseCategoryOptions.find(
            (option) => option.value.discipline.cd_id === primaryDiscipline.cd_id
          );
        }
      }

      if (selectedOption) {
        const citizenship = this.registration.citizenship;
        const nationality = this.registration.racing_nationality;
        const nationalityOption = this.countryOptions.find(
          (option) => option.value === nationality
        );
        const isDualCitizen = !!this.registration.dual_citizen;
        const dualCitizenOption = this.countryOptions.find(
          (option) => option.value === this.registration.dual_citizen_country
        );
        const isUsCitizen = citizenship === 'USA' || isDualCitizen;
        const isPermanentResident = !!this.registration.permanent_resident;
        const levelOption = this.levelOptions.find(
          (option) => option.value === this.registration.intl_license_level
        );

        this.f.license_category.setValue(selectedOption);
        this.f.us_citizen.setValue(isUsCitizen);
        if (this.usCitizen) {
          this.f.dual_citizen.setValue(isDualCitizen);
          this.f.dual_citizen_country.setValue(isDualCitizen ? dualCitizenOption : null);
        } else {
          this.f.permanent_resident.setValue(isPermanentResident);
        }
        if (nationalityOption) {
          this.f.racing_nationality.setValue(nationalityOption);
        }
        if (levelOption) {
          this.f.intl_license_level.setValue(levelOption);
        }
        this.f.license_current_year.setValue(false);
      }
    }
  }

  private requireField(field: FormControl): void {
    field.setValidators(Validators.required);
    field.updateValueAndValidity();
  }

  private unrequireField(field: FormControl): void {
    field.clearValidators();
    field.setValue(null);
    field.updateValueAndValidity();
  }

  private toggleRequireField(value: boolean, field: string): void {
    const control = this.f[field];
    value ? this.requireField(control) : this.unrequireField(control);
  }
}
