<footer class="footer-custom">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-lg-5 order-last order-lg-first text-center text-lg-left pl-0">
        <span class="footer-owner">{{currentYear}} USA Cycling</span>
        <span class="footer-rights">All Rights Reserved</span>
      </div>

      <div
        class="col-sm-12 col-lg-4 order-first order-lg-last text-center text-lg-right pr-0 pb-3 pb-sm-0"
        [class.col-lg-7]="fullWidth"
      >
        <a class="footer-link" href="https://www.usacycling.org/privacy" target="_blank"
          >Privacy Policy</a
        >
        <a class="footer-link" href="https://www.usacycling.org/about-us/contact-us" target="_blank"
          >Contact Support</a
        >
      </div>
    </div>
  </div>
</footer>
