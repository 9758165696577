<app-toast [toasts]="toasts"></app-toast>

<div class="container">
  <span class="d-flex justify-content-end pt-3 pr-1">
    <img class="close-button" src="assets/x-circle.svg" alt="X" (click)="activeModal.dismiss('Close click')" />
  </span>

  <div class="row justify-content-center pt-4 pb-5">
    <div class="col-12 col-md-10">
      <h2 class="header text-center">We Found a Matching Email!</h2>
      <p class="text-center py-3" style="font-size: 18px;">
        We noticed existing USA Cycling email(s) matches another account we have on file. Is this you?
        <br />
        If so, log in to checkout faster.
      </p>

      <p class="text-center py-3" style="font-size: 18px;">
        Trying to register with an existing email on file? 
        <a href="https://usacyclinghelp.zendesk.com/hc/en-us/articles/12345631430299-Can-I-register-another-athlete-with-my-email" target="_blank"><strong>Learn more</strong></a> 
        about why we require unique emails for all USAC accounts.
      </p>

      <div class="col-12 row matching-accounts-container" *ngIf="matchingAccounts">
        <h3 class="col-12 text-center mt-4 matching-accounts-title">Matching Accounts</h3>
        <p *ngIf="matchingAccounts.length > 1" class="col-12 text-center py-3" style="font-size: 18px;">Please select your account</p>
        <div class="col-xl-8 row matching-accounts mb-4">
          <div class="d-flex flex-wrap justify-content-center">
            <div *ngFor="let matchingAccount of matchingAccounts" class="mt-2 mb-2 p-3 matching-account" [ngClass]="{
                'selected-account': selectedAccount && selectedAccount.profile_id === matchingAccount.profile_id && matchingAccounts.length > 1,
                'pointer': matchingAccounts.length > 1
              }" (click)="selectAccount(matchingAccount)">
              <p>{{ matchingAccount.profile_first_name }} {{ matchingAccount.profile_last_name }}</p>
              <p>{{ matchingAccount.profile_email }}</p>
              <p *ngIf="matchingAccount.profile_race_age">Racing Age:  {{ matchingAccount.profile_race_age }}</p>
              <p *ngIf="matchingAccount.profile_city_state">{{ matchingAccount.profile_city_state}}</p>
              <p>{{ matchingAccount.profile_comp_id }}</p>
            </div>
          </div>
        </div>
      </div>

      <form
      [formGroup]="loginForm"
      (ngSubmit)="onSubmit()"
      class="row justify-content-center mt-4"
      >
        <div class="col-xs-8 col-md-8 justify-content-center mt-4">
          <div class="col-12 col-md-10 col-lg-12 center-block">
            <button type="button" style="width: 50%; margin-left: auto; margin-right: auto;" class="btn btn-secondary btn-block" type="submit">
              <span *ngIf="isSubmitting" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              LOG IN
            </button>
            
            <button type="button" [disabled]="!selectedAccount" style="width: 50%; margin-left: auto; margin-right: auto;" class="btn btn-primary btn-block" (click)="resetPassword()">
              RESET MY PASSWORD
            </button>
          </div>
        </div>
      </form>
      <div class="text-center mt-5">
        <span>Contact <a href="https://usacyclinghelp.zendesk.com/hc/en-us/requests/new" style="text-decoration: underline;">Member Services</a></span>
      </div>
    </div>
  </div>
</div>
