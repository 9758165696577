import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Club, Registration, SelectOption } from 'src/app/core/models';
import { ClubsService } from 'src/app/core/services';

@Component({
  selector: 'app-pro-license',
  templateUrl: './pro-license.component.html',
  styleUrls: ['./pro-license.component.scss'],
})
export class ProLicenseComponent implements OnInit, OnDestroy {
  @Input() registration: Registration;
  @Input() isSubmitting = false;
  @Input() postPurchase = false;
  @Input() preventEmit = false;
  @Output() formValues: EventEmitter<FormGroup> = new EventEmitter();

  teamOptions: SelectOption[] = [];
  optionsLoading = true;

  proLicenseForm: FormGroup;
  plfValues: any;
  subValues = {};

  get f(): any {
    return this.proLicenseForm.controls;
  }

  get proTeam(): any {
    return this.f.pro_team.value;
  }

  get successAlertMsg(): string {
    return this.postPurchase ? 'Pro license can be added to cart.' : 'Added pro license to cart.';
  }

  constructor(private fb: FormBuilder, private clubsService: ClubsService) {
    this.createForm();
  }

  ngOnInit(): void {
    this.getProTeams();
    this.emitFormValues();
    this.subscribeToForm();
  }

  ngOnDestroy(): void {
    // Unrequire and nullify all form controls
    this.unsubscribeFromControls();
    this.plfValues.unsubscribe();
  }

  private createForm(): void {
    this.proLicenseForm = this.fb.group({
      pro_team: [null, Validators.required],
    });
  }

  private subscribeToForm(): void {
    this.plfValues = this.proLicenseForm.valueChanges.subscribe(() => this.emitFormValues());
  }

  private unsubscribeFromControls(): void {
    Object.keys(this.subValues).forEach((key) => this.subValues[key].unsubscribe());
  }

  private emitFormValues(): void {
    if (!this.preventEmit) {
      this.formValues.emit(this.proLicenseForm);
    }
  }

  private getProTeams(): void {
    this.clubsService.proClubs.length
      ? this.setTeamOptions(this.clubsService.proClubs)
      : this.clubsService.queryProClubs().subscribe((clubs) => this.setTeamOptions(clubs));
  }

  private setTeamOptions(clubs: Club[]): void {
    this.teamOptions = clubs.map((c) => Object.assign({ label: c.club_name, value: c.club_id }));
    this.optionsLoading = false;
    this.setRegistrationValues();
  }

  private setRegistrationValues(): void {
    if (this.registration && this.registration.is_professional) {
      const proTeamOption = this.teamOptions.find(
        (option) => option.value === this.registration.pro_club_id
      );
      if (proTeamOption) {
        this.f.pro_team.setValue(proTeamOption);
      }
    }
  }
}
