import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

const urls: any = [];

@Injectable()
export class MockApiInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    for (const element of urls) {
      console.log(request.url, element.url);
      if (request.url === element.url) {
        // tslint:disable-next-line:no-console
        console.debug('[MOCK-API]: Responding to request: ' + request.url);
        return of(new HttpResponse({ status: 200, body: element.json }));
      }
    }

    return next.handle(request);
  }
}
