import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LicenseGroup, Membership, Profile } from 'src/app/core';
import { AuthService, ProfileService } from 'src/app/core/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-license-post-purchase',
  templateUrl: './license-post-purchase.component.html',
  styleUrls: ['./license-post-purchase.component.scss'],
})
export class LicensePostPurchaseComponent implements OnInit {
  loading = true;
  membershipsLoading = true;

  user: Profile;
  logoutRedirectUrl = environment.publicUrl + '/management/technical-licenses';
  loggedIn = false;
  logoutReturnUrl = environment.publicUrl + '/management';

  licenseGroups: LicenseGroup[] = [];
  activeLicenseGroups: LicenseGroup[] = [];
  allMemberships: Membership[];

  constructor(
    private authService: AuthService,
    private profileService: ProfileService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.authService.isAuthenticated$.subscribe((isAuthenticated) => {
      if (!isAuthenticated) {
        this.router.navigate(['/management']);
      }
    });
    this.loadLicenses();
  }

  private loadLicenses(): void {
    this.authService.currentUser$.subscribe((user: Profile) => {
      this.user = user;
      if (user.profile_id) {
        this.profileService.getLicenses(user.profile_id).subscribe(() => {
          this.licenseGroups = this.profileService.licenseGroups;
          const now = new Date();
          const today = Date.parse(`${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}`);
          this.activeLicenseGroups = this.licenseGroups.filter(
            (group) => Date.parse(group.expiration) >= today
          );
          this.loading = false;
        });
        this.profileService
          .getMemberships(this.user.profile_id)
          .subscribe((memberships: Membership[]) => {
            if (memberships) {
              this.allMemberships = memberships;
            }
            this.membershipsLoading = false;
          });
      }
    });
  }
}
