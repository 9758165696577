

<div class="cart" [ngClass]="{ 'd-none d-lg-block': !cartOpened, 'ghost-mode-active': ghostModeActive }" *ngIf="!cartHidden">
  <div class="my-cart-toggle navbar-toggler justify-content-between">
    <a (click)="closeCart()" class="d-lg-none">
      <img
        class="pl-4 navbar-brand"
        height="30px"
        src="assets/images/close.svg"
        alt="Close"
      />
    </a>

    <div class="d-flex">
      <img class="pl-3" height="20px" src="assets/images/shopping-cart.svg" alt="Shopping cart" />
      <a class="pr-3 pt-1 nav-text">MY CART</a>
    </div>

    <button *ngIf="!startOverHidden && loggedIn" type="button" class="start-over-btn btn btn-link text-white" (click)="startOver()">Log Out</button>
  </div>
  <div class="cart-container">
    <div *ngIf="!hideCartContent" >
      <div class="container plan-container d-flex flex-column">
        <!-- Premium Upgrade / Race License -->
        <div class="d-flex justify-content-between align-items-center mt-2">
          <span class="family-subtotal-text pr-1">{{upgradeTitle}}</span>
          <span class="family-subtotal-amount">{{ upgradePrice | currency }}</span>
        </div>
      </div>

      <div class="family-member family-member--selected">
        <p class="p-0 m-0">
          <span class="member-name member-name--selected">
            {{ fullName }}
          </span>
        </p>
        <p class="member-type p-0 m-0">
          {{ personType }}
        </p>
      </div>
    </div>
  </div>
</div>
