import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TokenService } from '../services';

interface HttpHeadersConfig {
  [name: string]: string | string[];
}

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private tokenService: TokenService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headersConfig: HttpHeadersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    const token = this.tokenService.getToken();

    if (token) {
      headersConfig.Authorization = token;
    }

    const request = req.clone({ setHeaders: headersConfig });
    return next.handle(request);
  }
}
