import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, combineLatest, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import {
  Category,
  Discipline,
  InterestsService,
  MembershipLevel,
  PersonTypes,
  RaceGender,
  Registration,
  RegistrationService,
} from 'src/app/core';
import {
  FamilyMembershipFacade,
  FamilyMembershipState,
  Plans,
  RegistrationFacade,
  RegistrationState,
} from 'src/app/core/facades';
import { ProfileService } from 'src/app/core/services/profile.service';
// *Only needed if ride path re-introduced
// import { NeedPremiumModalComponent } from 'src/app/shared/need-premium-modal/need-premium-modal.component';
// import { UpgradeToPremiumModalComponent } from 'src/app/shared/upgrade-to-premium-modal/upgrade-to-premium-modal.component';

import { MemberInformationModalComponent } from '../member-information-modal/member-information-modal.component';

@Component({
  selector: 'app-race-license-details',
  templateUrl: './race-license-details.component.html',
  styleUrls: ['./race-license-details.component.scss'],
})
export class RaceLicenseDetailsComponent implements OnInit {
  @Input() familyMemberModal = false;
  @Input() registration: Registration;
  @Input() raceSelected = false;
  @Input() isSubmitting = false;
  @Input() formInvalid = false;

  @Output() registrationUpdate: EventEmitter<boolean> = new EventEmitter();
  @Output() detailsFormValues: EventEmitter<FormGroup> = new EventEmitter();

  planTypes = Plans;
  personTypes = PersonTypes;

  raceGenders: RaceGender[];
  disciplines: Discipline[];

  isLoading = true;

  isSaving = false;
  isEditing = false;
  raceCategoriesForm: FormGroup;

  hasNextFamilyMember = this.familyFacade.hasNextMember;
  family$: Observable<FamilyMembershipState> = this.familyFacade.vm$;
  registration$: Observable<RegistrationState> = this.registrationFacade.vm$;

  // Comnbine single registration and family registration in one object
  vm$ = combineLatest([this.family$, this.registration$]).pipe(
    map(([family, registration]) => {
      return { family, registration };
    })
  );

  get domesticCategories(): FormArray {
    return this.raceCategoriesForm.get('domesticCategories') as FormArray;
  }

  get collegiateCategories(): FormArray {
    return this.raceCategoriesForm.get('collegiateCategories') as FormArray;
  }

  constructor(
    private fb: FormBuilder,
    private modal: NgbModal,
    private profileService: ProfileService,
    private interestsService: InterestsService,
    private familyFacade: FamilyMembershipFacade,
    private registrationFacade: RegistrationFacade,
    private registrationService: RegistrationService
  ) {
    this.createForms();
  }

  ngOnInit(): void {
    this.loadPageData();
  }

  private createForms(): void {
    this.createRaceCategoriesForm();
  }

  private createRaceCategoriesForm(): void {
    this.raceCategoriesForm = this.fb.group({
      domesticCategories: new FormArray([]),
      collegiateCategories: new FormArray([]),
    });
  }

  get hasAddress(): boolean {
    return (
      this.registration &&
      (!!this.registration.address_street ||
        !!this.registration.city ||
        !!this.registration.state ||
        !!this.registration.zip)
    );
  }

  toggleEditMode(): void {
    this.isEditing = !this.isEditing;
  }

  serializeCategories(): any[] {
    // Raw value retrieves all control values regardless of the disabled state
    const rawValue = this.raceCategoriesForm.getRawValue();
    const categories: any[] = [];

    // Add collegiate categories and their associated categories
    if (this.registration.is_collegiate_cyclist) {
      rawValue.collegiateCategories.forEach((collegiateCategory: Category, index: number) => {
        categories.push({
          cd_id: this.disciplines[index].id,
          cd_level: collegiateCategory.requiresPremium
            ? MembershipLevel.Premium
            : MembershipLevel.Standard,
          cd_type: collegiateCategory.id,
        });
      });
    }

    // Add domestic categories and their associated categories
    rawValue.domesticCategories.forEach((domesticCategory: Category, index: number) => {
      categories.push({
        cd_id: this.disciplines[index].id,
        cd_level: domesticCategory.requiresPremium
          ? MembershipLevel.Premium
          : MembershipLevel.Standard,
        cd_type: domesticCategory.id,
      });
    });

    return categories;
  }

  saveDisciplineCategories(toggleEditMode = false): void {
    const categories = this.serializeCategories();

    this.isSaving = true;
    this.registrationService
      .updateRacingCategories(this.registration.id, categories)
      .pipe(
        tap(() => {
          if (toggleEditMode) {
            this.toggleEditMode();
          }
        }),
        catchError((error) => throwError(error)),
        finalize(() => {
          this.isSaving = false;
        })
      )
      .subscribe();
  }

  openMemberInfoModal(): void {
    const modal = this.modal.open(MemberInformationModalComponent, {
      size: 'xl',
      centered: true,
    });

    // Set parameters
    modal.componentInstance.registration = this.registration;

    // Set callbacks
    modal.result
      .then(() => {
        this.registrationService
          .getRegistration(this.registration.id, false)
          .pipe(
            tap((registration) => {
              this.registration = registration;
              this.registrationUpdate.emit(true);
            })
          )
          .subscribe();
      })
      .catch(() => {});
  }

  // *Only needed if ride path re-introduced
  // private openPremiumUpgradeOptOut(): void {
  //   const modal = this.modal.open(NeedPremiumModalComponent, {
  //     centered: true,
  //     size: 'xl',
  //   });

  //   modal.result
  //     .then(() => {
  //       this.registration.is_premium = true;
  //       this.registrationService
  //         .updateOptions(this.registration.id, { is_premium: true })
  //         .pipe(
  //           catchError((error) => {
  //             this.registration.is_premium = false;
  //             return throwError(error);
  //           })
  //         )
  //         .subscribe();
  //     })
  //     .catch(() => {
  //       this.resetCategoriesForm(this.disciplines);
  //     });
  // }

  // onChange(model: Category, controlIndex: number): void {
  //   if (
  //     model.requiresPremium &&
  //     !this.registration.is_premium &&
  //     this.registration.person_type === PersonTypes.ADULT &&
  //     !this.registration.is_collegiate_cyclist
  //   ) {
  //     const modal = this.modal.open(UpgradeToPremiumModalComponent, {
  //       centered: true,
  //       size: 'xl',
  //     });

  //     modal.result
  //       .then(() => {
  //         this.registration.is_premium = true;
  //         this.registrationService
  //           .updateOptions(this.registration.id, { is_premium: true })
  //           .pipe(
  //             tap(() => {
  //               this.registrationUpdate.emit(true);
  //             }),
  //             catchError((error) => {
  //               this.registration.is_premium = false;
  //               return throwError(error);
  //             })
  //           )
  //           .subscribe();
  //       })
  //       .catch(() => {
  //         this.domesticCategories.controls[controlIndex].setValue(
  //           this.disciplines[controlIndex].nonCollegiateCategories[0]
  //         );
  //       });
  //   }
  // }

  // private isEligibleForPremium(registration: Registration): boolean {
  //   return (
  //     registration.is_premium ||
  //     registration.is_collegiate_cyclist ||
  //     registration.person_type === PersonTypes.JUNIOR
  //   );
  // }

  private createCategoryControl(defaultValue: Category): FormControl {
    return new FormControl(defaultValue, Validators.required);
  }

  private setupCategoriesForm(disciplines: Discipline[]): void {
    this.createRaceCategoriesForm();
    disciplines.forEach((discipline) => {
      const domesticNovice = discipline.nonCollegiateCategories[0];
      const domesticCategoryControl = this.createCategoryControl(domesticNovice);
      this.domesticCategories.push(domesticCategoryControl);

      const collegiateNovice = discipline.collegiateCategories[0];
      const collegiateCategoryControl = this.createCategoryControl(collegiateNovice);
      this.collegiateCategories.push(collegiateCategoryControl);
    });
  }

  // *Only needed if ride path re-introduced
  // private resetCategoriesForm(disciplines: Discipline[]): void {
  //   disciplines.forEach((discipline, index) => {
  //     const domesticNovice = discipline.nonCollegiateCategories[0];
  //     this.domesticCategories.controls[index].setValue(domesticNovice);

  //     const collegiateNovice = discipline.collegiateCategories[0];
  //     this.collegiateCategories.controls[index].setValue(collegiateNovice);
  //   });
  // }

  private populateCategoriesForm(disciplines: Discipline[], categoryIds: number[]): void {
    disciplines.forEach((discipline, index) => {
      const domesticCategory = discipline.nonCollegiateCategories.find(
        (category) => categoryIds.indexOf(category.id) !== -1
      );

      if (domesticCategory) {
        this.domesticCategories.controls[index].setValue(domesticCategory);
      }

      // There are no selectable collegiate categories other than Category D
      const collegiateCategory = discipline.collegiateCategories.find(
        (category) => categoryIds.indexOf(category.id) !== -1
      );

      if (collegiateCategory) {
        this.collegiateCategories.controls[index].setValue(collegiateCategory);
      }
    });
  }

  private normalizeCategoriesForm(
    disciplines: Discipline[],
    opts: { allowPremium: boolean }
  ): void {
    disciplines.forEach((discipline, index) => {
      const domesticNovice = discipline.nonCollegiateCategories[0];
      const domesticCategoryControl = this.domesticCategories.controls[index];
      const domesticCategory = domesticCategoryControl.value as Category;

      if (domesticCategory.requiresPremium && !opts.allowPremium) {
        domesticCategoryControl.setValue(domesticNovice);
      }
    });
  }

  private loadPageData(): void {
    const categories = this.registration.racing_categories || [];

    this.interestsService
      .getInterests()
      .pipe(
        tap((allInterests) => {
          // Disable category selection for categories that require approval
          const disciplines = allInterests
            .flatMap((x) => x.disciplines)
            .filter(
              (x) => x.collegiateCategories.length > 0 && x.nonCollegiateCategories.length > 0
            );

          disciplines.forEach((discipline) => {
            discipline.nonCollegiateCategories.forEach((category) => {
              if (category.requiresApproval) {
                category.disabled = true;
              }
            });

            discipline.collegiateCategories.forEach((category) => {
              if (category.requiresApproval) {
                category.disabled = true;
              }
            });
          });

          this.setupCategoriesForm(disciplines);

          // Persist the disciplines
          this.disciplines = disciplines;
        }),
        tap(() => {
          let month: string;
          let date: string;
          let year: string;
          if (/\d{2}\/\d{2}\/\d{4}/.test(this.registration.birthdate)) {
            [month, date, year] = this.registration.birthdate.split('/');
          } else if (/\d{4}\-\d{2}\-\d{2}/.test(this.registration.birthdate)) {
            [year, month, date] = this.registration.birthdate.split('-');
          }
          this.profileService
            .getPastCategories({
              email: this.registration.email,
              dob: `${year}-${month}-${date}`,
            })
            .subscribe((pastCategories: any[]) => {
              if (pastCategories.length > 0) {
                const categoryIds: number[] = pastCategories.map(
                  (category: any) => category.category_id
                );

                this.populateCategoriesForm(this.disciplines, categoryIds);

                // *Only needed if ride path re-introduced
                // const isPremiumRequired = pastCategories.some(
                //   (category: any) => category.is_premium
                // );
                // if (isPremiumRequired && !this.isEligibleForPremium(this.registration)) {
                //   this.openPremiumUpgradeOptOut();

                //   this.registration.is_premium = true;
                //   this.registrationService
                //     .updateOptions(this.registration.id, { is_premium: true })
                //     .pipe(
                //       catchError((error) => {
                //         this.registration.is_premium = false;
                //         return throwError(error);
                //       }),
                //       finalize(() => {
                //         this.isLoading = false;
                //       })
                //     )
                //     .subscribe();
                // } else {
                //   this.isLoading = false;
                // }
                this.isLoading = false;

                // Enable inputs for selected disciplines where the selected category does not
                // require upgrade coordinator approval
                this.disciplines.forEach((discipline, index) => {
                  const registrationDisciplines = this.registration.disciplines || [];
                  const isSelected = registrationDisciplines.some((x) => x.cd_id === discipline.id);
                  const category = this.domesticCategories.controls[index].value as Category;

                  if (isSelected && !category.requiresPremium) {
                    this.domesticCategories.controls[index].enable();
                  }
                });

                // Submit initial categories in case user doesn't manually change them
                this.saveDisciplineCategories();
              } else {
                const categoryIds = categories.map((category) => category.cd_type);
                this.populateCategoriesForm(this.disciplines, categoryIds);
                this.normalizeCategoriesForm(this.disciplines, {
                  // *Only needed if ride path re-introduced
                  // allowPremium: this.isEligibleForPremium(this.registration),
                  allowPremium: true,
                });

                // Enable inputs for selected disciplines
                this.disciplines.forEach((discipline, index) => {
                  const registrationDisciplines = this.registration.disciplines || [];
                  const isSelected = registrationDisciplines.some((x) => x.cd_id === discipline.id);
                  if (isSelected) {
                    this.domesticCategories.controls[index].enable();
                  }
                });

                // Submit initial categories in case user doesn't manually change them
                this.saveDisciplineCategories();
                this.isLoading = false;
              }
            });
        }),
        catchError((error) => {
          console.log(error);
          return throwError(error);
        })
      )
      .subscribe();
  }
}
