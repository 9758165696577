<app-toast [toasts]="toasts"></app-toast>

<app-header [showLogoPrompt]="false" [cartHidden]="true" [showLogout]="true"></app-header>

<div class="container">
  <div class="row">
    <div class="col-12" [ngClass]="{'col-lg-9': showCart}">
      <app-status-indicator-header [user]="user" [allMemberships]="allMemberships" [firstName]="user.profile_first_name"
        [lastName]="user.profile_last_name" [email]="user.profile_email"
        [loading]="membershipsLoading"></app-status-indicator-header>
      <hr>
    </div>
    <div class="col-12" [ngClass]="{'col-lg-9': showCart}">
      <app-step-progress-bar [currentStep]="Step.PostPurchase" [previousStep]="Step.Checkout"
        [isRace]="isRace"></app-step-progress-bar>
    </div>

    <div class="col-12 p-3" [ngClass]="{'col-lg-9': showCart}">

      <app-loader *ngIf="!newLicenseGroup"></app-loader>
      <div *ngIf="newLicenseGroup" class="col-lg-10 mx-auto mt-5 mb-5">
        <!-- Membership Information & Licenses -->
        <div class="mb-5">
          <app-form-title [customClasses]="['mb-4']">You're In! Let's {{ titleRideOrRace }}</app-form-title>
          <app-form-subtitle [customClasses]="['mb-1']">
            {{ titleDescription }}
          </app-form-subtitle>
          <p *ngIf="hasSpotInsurance" class="text-center" style="color: #db3734;font-weight: 600">
            Plus insurance coverage begins on your renewal date.
          </p>
          <p class="text-center">You can manage your account details and more from your <a href="{{accountUrl}}">My Account dashboard.</a></p>
          <app-form-subtitle *ngIf="currentLicense" [customClasses]="['text-center usac-primary-dark']">
            License Valid {{ licenseDateRange }}
          </app-form-subtitle>
        </div>

        <!-- Styled licenses -->
        <div *ngIf="currentLicense" class="col-md-10 mx-auto"
          [ngClass]="{'col-lg-12': showCart, 'col-lg-9': !showCart}">
          <app-license-card [user]="user" [licenseGroup]="newLicenseGroup" [premiumMembership]="isRacePaid"
            [useDateRange]="true" [showSafeSport]="false" [inPostConfirmation]="true"></app-license-card>
        </div>
      </div>

      <div *ngIf="registration">
        <app-form-title [customClasses]="['mb-3 mt-5']">Options and Upgrades</app-form-title>

        <!-- Race Participation, Disciplines, & Domestic Club -->
        <app-interests *ngIf="registration && !isRacePaid" [registration]="registration" [isSubmitting]="isSubmitting"
          (formValues)="updateFormValues('interestsForm', $event)"></app-interests>

        <!-- Gender & Contact w/ Junior Athletes -->
        <app-gender-and-regular-contact *ngIf="registration && !isRacePaid" [registration]="registration"
          [isSubmitting]="isSubmitting"
          (formValues)="updateFormValues('genderRegularContactForm', $event)"></app-gender-and-regular-contact>

        <!-- Race license info, if user participates in events -->
        <app-race-license-details *ngIf="registration && !isRacePaid" [registration]="registration"
          [isSubmitting]="isSubmitting" [raceSelected]="raceSelected" [formInvalid]="formInvalid"
          (registrationUpdate)="updateRegistration($event)"></app-race-license-details>

        <!-- Donation, Premium Upgrade, & Podium Package -->
        <app-add-ons *ngIf="registration" [registration]="registration" [user]="user" [licenses]="profileLicenses"
          (donationChange)="updateDonationAmount($event)" (orderChange)="updateCartOrder($event)"></app-add-ons>
        </div>
      </div>

      <app-post-purchase-cart *ngIf="showCart" [chosenDonationAmount]="donationAmount" [licenses]="profileLicenses"
        [order]="cartOrder" [registration]="registration"></app-post-purchase-cart>
  </div>
  <app-footer></app-footer>
</div>